import React from 'react';
import PropTypes from 'prop-types';
import PersonsTabPeopleModule from './PersonsTabPeopleModule';
import './PersonsTab.css';


function PersonsTab({
  applicationProp, disabled, updateOriginalObject, updateOriginalObjectMass, onAddFile, onDeleteFile, files,
}) {
  return (
    <div className="personsTab-wrapper">
      <div className="common-subTitle">8. Сведения о соисполнителях</div>
      <PersonsTabPeopleModule
        elementsArrayProp={applicationProp.applications2PersonsTabPeople_ids}
        applicationId={applicationProp.id}
        disabled={disabled}
        projectTerm={applicationProp.projectTerm}
        updateOriginalObject={updateOriginalObject}
        updateOriginalObjectMass={updateOriginalObjectMass}
        onAddFile={onAddFile}
        onDeleteFile={onDeleteFile}
        files={files}
        applicationProp={applicationProp}
      />
    </div>
  );
}

PersonsTab.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  updateOriginalObject: PropTypes.func.isRequired,
  updateOriginalObjectMass: PropTypes.func.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  files: PropTypes.shape().isRequired,
};

export default PersonsTab;
