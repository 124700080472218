const planFactRows = [
  {
    label: 'Общее число планируемых публикаций по результатам реализации Проекта',
    field: 'projectPublications',
  },
  {
    label: 'в научных журналах перечня ВАК с Импакт-фактором > 0,5 (на момент публикации материала)',
    field: 'projectVAC',
  },
  {
    label: 'в научных журналах, индексируемых РИНЦ',
    field: 'projectRINC',
  },
  {
    label: 'Число результатов интеллектуальной деятельности, планируемых к получению по результатам реализации Проекта',
    field: 'projectRID',
  },
  {
    label: 'Количество планируемых мероприятий по демонстрации результатов реализации Проекта',
    field: 'projectEventsDemonstration',
  },
  {
    label: 'Ожидаемая аудитория мероприятий по демонстрации результатов реализации Проекта из числа профессионального сообщества и представителей здравоохранения города Москвы',
    field: 'projectAudience',
  },
  {
    label: 'Количество планируемых выступлений с докладами на международных научно-практических конференциях, симпозиумах, форумах',
    field: 'projectEvents',
  },
  {
    label: 'Общее количество пациентов, предусмотренных для выполнения Проекта',
    field: 'projectPatients',
  },
  {
    label: 'Пациенты основной группы',
    field: 'projectPatientsMain',
  },
  {
    label: 'Пациенты контрольной группы',
    field: 'projectPatientsControl',
  },
  {
    label: 'Пациенты группы сравнения',
    field: 'projectPatientsCompare',
  },
  {
    label: 'Акты и протоколы испытаний',
    field: 'projectActs',
  },
  {
    label: 'Наличие опытных образцов',
    field: 'projectExamples',
  },
];

export default planFactRows;

