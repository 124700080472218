import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Upload, Row, Col, Button, Input, Form } from 'antd';
import {
  InboxOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import {
  generateConclusionFile,
  getApplications2ById,
  uploadFile,
} from '../../../../utils/http';

const { TextArea } = Input;
function ConclusionsTab({
  applicationProp,
  disabled,
  formRef,
  onPercentagesUpdate,
}) {
  const [conclusionText, setConclusionText] = useState('');
  const [isShowFileUpload, setIsShowFileUpload] = useState(applicationProp.conclusions_application_file?.uploadFile || false);
  const [uploadedFile, setUploadedFile] = useState(
    applicationProp.conclusions_application_file?.uploadFile || null
  );
  
  console.log(applicationProp)

  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.setAttribute('download', 'Файл предварительной оценки Заявки');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const getGeneratedFileId = async () => {
    const req = await getApplications2ById(applicationProp.id);

    const fileId = req.conclusions_application_file.id;
    return fileId;
  };

  const onGenerateFile = async () => {
    const req = await generateConclusionFile({
      text: conclusionText,
      application2: applicationProp.id,
    });

    const { id, url } = req;

    if (!id || !url) return;

    downloadFile(url);
    setIsShowFileUpload(true);
  };

  const onAttachFile = async (file) => {
    await generateConclusionFile({
      text: conclusionText,
      application2: applicationProp.id,
      uploadFile: file?.id,
      id: await getGeneratedFileId(),
    });
  };

  const onAddFile = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers).then((file) => {
        setUploadedFile(file);
        onAttachFile(file);
      });
    }
  };


  const onDeleteFile = async () => {
    setUploadedFile(null);

    await generateConclusionFile({
      application2: applicationProp.id,
      uploadFile: null,
      id: await getGeneratedFileId(),
    });
  };

  return (
    <div className="conclusionsTab-wrapper">
      <Form.Item name="id" initialValue={applicationProp.id} hidden />

      <div className="common-field-wrapper">
        <span className="common-field-label">Заключение специалиста</span>
        <Form.Item name="conclusion">
          <TextArea
            style={{
              borderColor:
                conclusionText.length > 0 && conclusionText.length < 500
                  ? 'red'
                  : 'inherit',
            }}
            autoSize
            disabled={false}
            minrows={4}
            onChange={(e) => setConclusionText(e.target.value)}
            placeholder="Введите текст"
          />
        </Form.Item>
        <span className="common-field-hint" style={{ marginTop: '0px' }}>
          (от 500 печатных знаков с пробелами)
        </span>
        <span className="common-field-hint" style={{ marginTop: '0px' }}>
          {`Требуется ${conclusionText.length} из 500 символов`}
        </span>
      </div>

      <div>
        <Button
          type="primary"
          disabled={conclusionText.length < 500}
          onClick={onGenerateFile}
          style={{ marginRight: '15px' }}
        >
          Сгенерировать файл на подпись
        </Button>
        {conclusionText.length < 500 && (
          <span className="common-field-hint" style={{ marginTop: '0px' }}>
            Минимум 500 символов
          </span>
        )}
      </div>

      {isShowFileUpload && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontStyle: 'italic' }}>
            Загрузите подписанный файл заключения по Заявке
          </span>
          <Dragger
            showUploadList={false}
            onChange={(file) => onAddFile(file, 'financeFiles_ids')}
            disabled={false}
            action={null}
            accept=".docx,.doc,.pdf"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Выберите или перенесите файл в формате .docx/.doc/.pdf, чтобы
              загрузить (не более 15мб).
            </p>
          </Dragger>
          {uploadedFile?.id && (
            <Row
              key={uploadedFile.id}
              style={{ margin: '5px 0px' }}
              gutter={12}
            >
              {uploadedFile.urlLink && (
                <Col>
                  <DownloadOutlined
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open(uploadedFile.urlLink)}
                  />
                </Col>
              )}
              {!disabled && (
                <Col>
                  <DeleteOutlined
                    style={{ cursor: 'pointer' }}
                    onClick={() => onDeleteFile()}
                  />
                </Col>
              )}
              <Col>{uploadedFile.originalFileName || uploadedFile.id}</Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
}

ConclusionsTab.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  formRef: PropTypes.shape().isRequired,
  onPercentagesUpdate: PropTypes.func.isRequired,
};

export default ConclusionsTab;
