import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form,
} from 'antd';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';


class Director extends Component {
  render() {
    const {
      loading, visible, application,
    } = this.props;
    return (
      <div className="application-main-wrapper" style={{ display: visible ? 'flex' : 'none' }}>
        <div className="application-main-box-wrapper">
          <div className="application-main-box-mask" />

          <div className="application-main-box-content-wrapper">
            <div className="application-subTitle">Сведения о научном руководителе Проекта</div>
            <div className="application-table-wrapper">
              <div className="application-table-row">
                <div className="application-table-row-cell">ФИО</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="supervisorFullName"
                  initialValue={application.supervisorFullName}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Должность</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="position"
                  initialValue={application.position}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Ученая степень</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="degree"
                  initialValue={application.degree}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Ученое звание</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="title"
                  initialValue={application.title}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Телефон</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="phone"
                  initialValue={application.phone}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>E-mail</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="email"
                  initialValue={application.email}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="application-main-box-content-wrapper">
            <div className="application-subTitle" style={{ marginTop: '20px' }}>Сведения об ответственном контактном лице по заявке и Проекту (по общим вопросам)</div>
            <div className="application-table-wrapper">
              <div className="application-table-row">
                <div className="application-table-row-cell">ФИО</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="contactCommonFullName"
                  initialValue={application.contactCommonFullName}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Должность</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="specialistPosition"
                  initialValue={application.specialistPosition}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Телефон</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="specialistPhone"
                  initialValue={application.specialistPhone}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>E-mail</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="specialistEmail"
                  initialValue={application.specialistEmail}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
            </div>
          </div>

          {getUserRole() === 'bigAdmin' && (
          <div className="application-main-box-content-wrapper">
            <div className="application-subTitle" style={{ marginTop: '20px' }}>Сведения об ответственном контактном лице, отвечающем за предоставление финансовых данных по заявке и Проекту (смета, финансовый отчет)</div>
            <div className="application-table-wrapper">
              <div className="application-table-row">
                <div className="application-table-row-cell">ФИО</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="contactFinanceFullName"
                  initialValue={application.contactFinanceFullName}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Должность</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="employeeReportPosition"
                  initialValue={application.employeeReportPosition}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Телефон</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="employeeReportPhone"
                  initialValue={application.employeeReportPhone}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>E-mail</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="employeeReportEmail"
                  initialValue={application.employeeReportEmail}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </div>
          )}

        </div>
      </div>
    );
  }
}

Director.propTypes = {
  loading: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  application: PropTypes.shape().isRequired,
};

export default Director;
