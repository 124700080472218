/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Card, Col, Row, Popconfirm, notification, Radio, DatePicker, Modal,
} from 'antd';
import { editApplications2AuthorTabRid } from '../../../../utils/http';


const labels = [
  {
    label: 'Вид охранного документа',
    key: 'ridType',
  },
  {
    label: 'Наименование РИД',
    key: 'name',
  },
  {
    label: 'Дата регистрации охранного документа',
    key: 'regDate',
  },
  {
    label: 'Аннотация',
    key: 'annotation',
  },
];

const { TextArea } = Input;
function AuthorTabRidModule({
  elementsArrayProp, applicationId, disabled, updateOriginalObject, isAuthorTabPeople,
}) {
  const [elementsArray, setElementsArray] = useState([]);
  const [objectInEditId, setObjectInEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  // const [ridType, setRidType] = useState(null);
  const formRef = useRef(null);
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  useEffect(() => {
    setElementsArray(elementsArrayProp);
    setIsFormDisabled(disabled);
  }, []);

  const onEditClick = (id) => {
    setObjectInEditId(id);
    // setRidType(elementsArray.find((pub) => pub.id === id).ridType);
  };

  const onAddClick = () => {
    setLoading(true);
    const objToSave = {};
    if (isAuthorTabPeople) {
      objToSave.applications2AuthorTabPeople_id = applicationId;
    } else {
      objToSave.applications2_id = applicationId;
    }
    editApplications2AuthorTabRid(objToSave)
      .then((id) => {
        setObjectInEditId(id);
        const updatedArray = [...elementsArray, { id }];
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject('applications2AuthorTabRid_ids', updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onDeleteClick = (id) => {
    setLoading(true);
    editApplications2AuthorTabRid({ id, isDeleted: true })
      .then(() => {
        setObjectInEditId(null);
        const updatedArray = elementsArray.filter((p) => p.id !== id);
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject('applications2AuthorTabRid_ids', updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onCancelClick = () => {
    const values = formRef.current.getFieldsValue();
    const valuesKeys = Object.keys(values);
    let isEmpty = true;
    valuesKeys.forEach((k) => {
      if (values[k]) {
        isEmpty = false;
      }
    });
    if (isEmpty) {
      onDeleteClick(objectInEditId);
    } else {
      setObjectInEditId(null);
      // setRidType(null);
    }
  };

  const onSaveClick = () => {
    const values = formRef.current.getFieldsValue();

    let error = false;

    Object.entries(values).forEach(([key, value]) => {
      if (!value && !error) {
        const { label } = labels.find((l) => l.key === key);
        error = true;
        notification.error({ message: `Не заполнено поле: ${label}` });
      }
    });

    if (error) return;
    setLoading(true);
    editApplications2AuthorTabRid({ id: objectInEditId, ...values })
      .then(() => {
        const updatedArray = elementsArray.map((p) => (p.id === objectInEditId ? {
          id: objectInEditId, ...values,
        } : p));
        setObjectInEditId(null);
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject('applications2AuthorTabRid_ids', updatedArray);
        notification.success({ message: 'Успешно' });
      });
  };

  const onExpand = (id) => {
    setObjectInEditId(id);
    setIsExpanded(true);
    setIsFormDisabled(true);
  };

  const onCollapse = () => {
    setObjectInEditId(null);
    setIsExpanded(false);
    setIsFormDisabled(disabled);
  };

  return (
    <div style={{
      margin: '20px', padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
    }}
    >
      <span style={{ fontSize: '24px', color: '#212121' }}>РИД</span>
      {elementsArray.map((p, i) => (
        <Card
          key={p.id}
          style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
          bodyStyle={{
            borderLeft: objectInEditId === p.id ? '4px solid #1890FF' : '',
            borderRadius: objectInEditId === p.id ? '5px' : '',
          }}
        >
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                {p.name || `РИД №${i + 1}`}
              </Col>
              <Col>
                <Row align="middle" gutter={10}>
                  {!objectInEditId && (<Col><Button type="primary" onClick={() => onEditClick(p.id)} disabled={isFormDisabled}>Редактировать</Button></Col>)}
                  {!objectInEditId && (
                    <Col>
                      <Popconfirm
                        title="Уверены, что хотите удалить РИД?"
                        onConfirm={() => onDeleteClick(p.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button type="danger" disabled={isFormDisabled}>Удалить</Button>
                      </Popconfirm>
                    </Col>
                  )}
                  {!objectInEditId && (<Col><Button onClick={() => onExpand(p.id)}>Показать</Button></Col>)}
                </Row>
              </Col>
            </Row>
            {objectInEditId === p.id
                && (
                  <Modal title="Редактирование РИД" visible footer={null} width="90vw" maskClosable={false} closable={false}>
                    {/* <Row justify="end">
                      {objectInEditId === p.id && isExpanded && (<Col><Button type="primary" onClick={() => onCollapse()}>Закрыть</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button onClick={onCancelClick}>Отменить</Button></Col>)}
                    </Row> */}
                    <Form initialValues={{ remember: true }} ref={formRef} style={{ marginTop: '20px' }}>
                      <Col>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Вид охранного документа</span>
                          <span className="common-field-hint">Выберите вид охранного документа</span>
                          <Form.Item
                            name="ridType"
                            initialValue={p.ridType}
                          >
                            <Radio.Group
                              options={[
                                { label: 'Патент', value: 'Патент' },
                                { label: 'Заявка на патент', value: 'Заявка на патент' },
                              ]}
                              disabled={loading}
                              optionType="button"
                              buttonStyle="solid"
                              // onChange={(e) => setRidType(e.target.value)}
                            />
                          </Form.Item>
                        </div>

                        {/* {ridType === 'Свидетельство' && (
                        <div className="common-field-wrapper">
                          <span className="common-field-label">2. Тип свидетельства</span>
                          <span className="common-field-hint">Выберите из выпадающего списка</span>
                          <Form.Item
                            name="certificateType"
                            initialValue={p.certificateType}
                          >
                            <Select disabled={loading}>
                              {[
                                'На товарный знак (знак обслуживания)',
                                'На право пользования наименованием места происхождения товара',
                                'О государственной регистрации базы данных',
                                'О государственной регистрации топологии интегральной микросхемы',
                                'О государственной регистрации программы ЭВМ',
                              ].map((u) => <Option key={u} value={u}>{u}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>
                        )} */}

                        {/* {ridType === 'Патент' && (
                        <div className="common-field-wrapper">
                          <span className="common-field-label">2. Тип патента</span>
                          <span className="common-field-hint">Выберите из выпадающего списка</span>
                          <Form.Item
                            name="patentType"
                            initialValue={p.patentType}
                          >
                            <Select disabled={loading}>
                              {[
                                'На изобретение',
                                'На полезную модель',
                                'На промышленный образец',
                                'На селекционное достижение',
                              ].map((u) => <Option key={u} value={u}>{u}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>
                        )} */}

                        <div className="common-field-wrapper">
                          <span className="common-field-label">Наименование РИД</span>
                          <span className="common-field-hint">Указано в охранном документе</span>
                          <Form.Item
                            name="name"
                            initialValue={p.name}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        {/* <div className="common-field-wrapper">
                          <span className="common-field-label">4. Наименование и реквизиты правообладателя (правообладателей)</span>
                          <span className="common-field-hint">Указано в охранном документе</span>
                          <Form.Item
                            name="fio"
                            initialValue={p.fio}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div> */}

                        {/* <div className="common-field-wrapper">
                          <span className="common-field-label">5. Номер заявки на государственную регистрацию объекта интеллектуальной собственности</span>
                          <span className="common-field-hint">Номер заявки указан на первой странице охранного документа</span>
                          <Form.Item
                            name="applicationNumber"
                            initialValue={p.applicationNumber}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div> */}

                        {/* <div className="common-field-wrapper">
                          <span className="common-field-label">6. Дата подачи заявки на государственную регистрацию объекта интеллектуальной собственности</span>
                          <span className="common-field-hint">Дата заявки указана на первой странице охранного документа</span>
                          <Form.Item
                            name="date"
                            initialValue={p.date}
                          >
                            <DatePicker disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">7. Номер охранного документа</span>
                          <span className="common-field-hint">Указан на первой странице охранного документа</span>
                          <Form.Item
                            name="documentNumber"
                            initialValue={p.documentNumber}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div> */}

                        <div className="common-field-wrapper">
                          <span className="common-field-label">Дата регистрации охранного документа</span>
                          <span className="common-field-hint">Указана на первой странице охранного документа</span>
                          <Form.Item
                            name="regDate"
                            initialValue={p.regDate}
                          >
                            <DatePicker disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        {/* <div className="common-field-wrapper">
                          <span className="common-field-label">9. Дата окончания действия охранного документа</span>
                          <span className="common-field-hint">Указана на первой странице охранного документа</span>
                          <Form.Item
                            name="endDate"
                            initialValue={p.endDate}
                          >
                            <DatePicker disabled={isFormDisabled} />
                          </Form.Item>
                        </div> */}

                        <div className="common-field-wrapper">
                          <span className="common-field-label">Аннотация</span>
                          <span className="common-field-hint">Приводится краткое описание объекта интеллектуальной собственности</span>
                          <Form.Item
                            name="annotation"
                            initialValue={p.annotation}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        {/* <div className="common-field-wrapper">
                          <span className="common-field-label">11. Ссылка на документ на сайте ФИПС</span>
                          <span className="common-field-hint">Указывается ссылка (URL адрес) на документ на сайте ФИПС (http://www1.fips.ru)</span>
                          <Form.Item
                            name="documentURL"
                            initialValue={p.documentURL}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div> */}

                      </Col>
                    </Form>
                    <Row justify="end">
                      {objectInEditId === p.id && isExpanded && (<Col><Button type="primary" onClick={() => onCollapse()}>Закрыть</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button onClick={onCancelClick}>Отменить</Button></Col>)}
                    </Row>
                  </Modal>
                )}
          </Col>
        </Card>
      ))}
      {!objectInEditId && <Button type="primary" onClick={onAddClick} style={{ marginTop: '20px', width: '200px', fontSize: '16px' }} disabled={isFormDisabled}>Добавить РИД</Button>}
    </div>
  );
}

AuthorTabRidModule.propTypes = {
  elementsArrayProp: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  applicationId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  updateOriginalObject: PropTypes.func.isRequired,
  isAuthorTabPeople: PropTypes.bool,
};

AuthorTabRidModule.defaultProps = {
  isAuthorTabPeople: false,
};

export default AuthorTabRidModule;
