/* eslint-disable max-len */
import React from 'react';
import {
  Input, Checkbox, Select,
} from 'antd';
import PropTypes from 'prop-types';


const { Option } = Select;
function SurveyField({
  label, isFormDisabled, options, values, onCheckBox, onSelect, onInput, field, type,
}) {
  return (
    <>
      <div style={{ marginTop: '20px' }}>{label}</div>
      {type === 'checkbox' && options.map((e) => (
        <div key={e.value} style={{ display: 'flex', flexDirection: 'column' }}>
          <Checkbox defaultChecked={values[field]?.includes(e.value)} onChange={(i) => onCheckBox(field, i.target.checked, e.value)}>{e.value}</Checkbox>
          {values[field]?.includes(e.value) && e.additionalFieldName && e.type === 'select'
              && (
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                <Select disabled={isFormDisabled} onChange={(i) => onSelect(e.additionalFieldName, i)} defaultValue={values[e.additionalFieldName]} placeholder={e.additionalFieldLabel}>
                  {e.options.map((u) => <Option key={u.value} value={u.value}>{u.value}</Option>)}
                </Select>
                {values[e.additionalFieldName] === e.options.find((k) => k.additionalFieldName)?.value && e.options.find((k) => k.additionalFieldName)?.additionalFieldName
                && <Input disabled={isFormDisabled} style={{ marginTop: '5px', marginLeft: '20px' }} onChange={(k) => onInput(e.options.find((l) => l.additionalFieldName)?.additionalFieldName, k.target.value)} defaultValue={values[e.options.find((l) => l.additionalFieldName)?.additionalFieldName]} />}
              </div>
              )}

          {values[field]?.includes(e.value) && e.additionalFieldName && e.type === 'checkbox' && (
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
            {e.options.map((o) => (
              <div key={o.value} style={{ display: 'flex', flexDirection: 'column' }}>
                <Checkbox defaultChecked={values[e.additionalFieldName]?.includes(o.value)} onChange={(i) => onCheckBox(e.additionalFieldName, i.target.checked, o.value)}>{o.value}</Checkbox>
                {values[e.additionalFieldName]?.includes(o.value) && o.additionalFieldName && <Input disabled={isFormDisabled} style={{ margin: '5px 0px', marginLeft: '20px' }} onChange={(i) => onInput(o.additionalFieldName, i.target.value)} placeholder={o.additionalFieldLabel} defaultValue={values[o.additionalFieldName]} />}
              </div>
            ))}
          </div>
          )}

          {values[field]?.includes(e.value) && e.additionalFieldName && e.type === 'input' && (
          <Input disabled={isFormDisabled} style={{ marginTop: '5px', marginLeft: '20px' }} onChange={(k) => onInput(e.additionalFieldName, k.target.value)} placeholder={e.additionalFieldLabel} defaultValue={values[e.additionalFieldName]} />
          )}

        </div>
      ))}

      {type === 'select' && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Select disabled={isFormDisabled} onChange={(e) => onSelect(field, e)} defaultValue={values[field]}>
            {options.map((u) => <Option key={u.value} value={u.value}>{u.value}</Option>)}
          </Select>

          {options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'checkbox') && (
          <div style={{
            display: 'flex', flexDirection: 'column', marginLeft: '20px', marginTop: '5px',
          }}
          >
            {options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'checkbox')?.options.map((o) => (
              <div key={o.value} style={{ display: 'flex', flexDirection: 'column' }}>
                <Checkbox defaultChecked={values[options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'checkbox')?.additionalFieldName]?.includes(o.value)} onChange={(i) => onCheckBox(options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'checkbox')?.additionalFieldName, i.target.checked, o.value)}>{o.value}</Checkbox>
                {values[options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'checkbox')?.additionalFieldName]?.includes(o.value) && o.additionalFieldName && <Input disabled={isFormDisabled} style={{ marginTop: '5px', marginLeft: '20px' }} onChange={(i) => onInput(o.additionalFieldName, i.target.value)} placeholder={o.additionalFieldLabel} defaultValue={values[o.additionalFieldName]} />}
              </div>
            ))}
          </div>
          )}

          {options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select') && (
          <div style={{
            display: 'flex', flexDirection: 'column', marginLeft: '20px', marginTop: '5px',
          }}
          >
            <Select disabled={isFormDisabled} onChange={(i) => onSelect(options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select')?.additionalFieldName, i)} defaultValue={values[options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select')?.additionalFieldName]} placeholder={options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select')?.additionalFieldLabel}>
              {options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select')?.options.map((u) => <Option key={u.value} value={u.value}>{u.value}</Option>)}
            </Select>
            {options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select')?.options.find((p) => p.additionalFieldName && p.type === 'input' && p.value === values[options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select')?.additionalFieldName])
                && (
                <Input
                  disabled={isFormDisabled}
                  defaultValue={values[options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select')?.options.find((p) => p.additionalFieldName && p.type === 'input' && p.value === values[options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select')?.additionalFieldName])?.additionalFieldName]}
                  style={{ marginTop: '5px', marginLeft: '20px' }}
                  onChange={(k) => onInput(options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select')?.options.find((p) => p.additionalFieldName && p.type === 'input' && p.value === values[options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'select')?.additionalFieldName])?.additionalFieldName, k.target.value)}
                />
                )}
          </div>
          )}

          {options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'input') && (
          <Input
            disabled={isFormDisabled}
            style={{ marginTop: '5px', marginLeft: '20px' }}
            onChange={(k) => onInput(options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'input')?.additionalFieldName, k.target.value)}
            placeholder={options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'input')?.additionalFieldLabel}
            defaultValue={values[options.find((l) => l.value === values[field] && l.additionalFieldName && l.type === 'input')?.additionalFieldName]}
          />
          )}
        </div>
      )}
    </>
  );
}


SurveyField.propTypes = {
  label: PropTypes.string.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onInput: PropTypes.func.isRequired,
  onCheckBox: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default SurveyField;
