/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import { message, Button } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  getAllApplications, getAllApplicationsByUserMobile, getUsersAdmin, getAllApplicationReports,
  getApplicationReportsByUser,
} from '../../../../utils/http';
import logo from '../../../../assets/images/logoGood.svg';
import logoExit from '../../../../assets/icons/exit.png';
import getUserRole from '../../../../utils/localeStorage/getUserRole';
import getUserId from '../../../../utils/localeStorage/getUserId';
import getUserShortName from '../../../../utils/localeStorage/getUserShortName';
import logout from '../../../../utils/logout';
import ApplicationMobile from './components/ApplicationMobile';
import UserMobile from './components/UserMobile';
import './HomePageMobile.less';


@withRouter
class HomePageMobile extends Component {
    state = {
      applications: [],
      currentSection: 'applications',
      users: [],
      originalUsers: [],
      isShowAddUserModal: false,
      loading: true,
    }

    componentDidMount() {
      if (getUserRole() === 'user') {
        Promise.all([getAllApplicationsByUserMobile(getUserId()), getUsersAdmin()])
          .then((response) => this.setState({
            applications: response[0],
            users: response[1],
            loading: false,
            originalUsers: response[1],
          }))
          .catch((error) => message.error(error.message));
      }

      if (getUserRole() === 'bigAdmin') {
        Promise.all([getAllApplications(), getUsersAdmin(), getAllApplicationReports()])
          .then((response) => this.setState({
            applications: [...response[0]?.data, ...response[2]],
            users: response[1],
            loading: false,
            originalUsers: response[1],
          }))
          .catch((error) => message.error(error.message));
      }

      if (getUserRole() === 'person') {
        Promise.all([getApplicationReportsByUser(getUserId()), getUsersAdmin()])
          .then((response) => this.setState({
            applications: response[0],
            users: response[1],
            loading: false,
            originalUsers: response[1],
          }))
          .catch((error) => message.error(error.message));
      }
    }

    onMenuClick = (currentSection) => {
      this.setState({ currentSection });
    }

    onAddUser = (user) => {
      this.setState((prevState) => ({ isShowAddUserModal: false, users: [...prevState.users, user] }));
    }

    onSearch = (value) => {
      if (value === '') {
        this.setState((prevState) => ({ users: prevState.originalUsers }));
      } else {
        this.setState((prevState) => ({
          users: prevState.originalUsers
            .filter((element) => element.shortName.toLowerCase().indexOf(value.toLowerCase()) >= 0),
        }));
      }
    }

    render() {
      const {
        currentSection, users, applications, isShowAddUserModal, loading,
      } = this.state;
      const { history } = this.props;
      if (loading) {
        return (
          <div className="homePageMobile-wrapper">
          <div className="homePageMobile-header">
            <img src={logo} alt="logo" className="homePageMobile-logo" />
            <div className="homePageMobile-header-logout-wrapper">
              <div className="homePageMobile-header-logout-name">{getUserShortName()}</div>
              <img src={logoExit} alt="logoExit" className="homePageMobile-header-logout-icon" onClick={() => logout(history)} />
            </div>
          </div>
          </div>
        );
      }
      return (
        <div className="homePageMobile-wrapper">
          <div className="homePageMobile-header">
            <img src={logo} alt="logo" className="homePageMobile-logo" />
            <div className="homePageMobile-header-logout-wrapper">
              <div className="homePageMobile-header-logout-name">{getUserShortName()}</div>
              <img src={logoExit} alt="logoExit" className="homePageMobile-header-logout-icon" onClick={() => logout(history)} />
            </div>
          </div>
          <div style={{ borderTop: '1px solid #ababab', width: '100%', margin: '10px' }} />
          {/* {getUserRole() !== 'external' && currentSection === 'applications' && getUserRole() !== 'person' && (
        <Button
          className="homePageMobile-buttons-apply"
          onClick={() => history.push(routeGenerator.applicationLink('new'))}
        >
          Подать заявку
        </Button>
          )} */}
        {getUserRole() === 'bigAdmin' && currentSection === 'users' && (
        <Button
          className="homePageMobile-buttons-apply"
          onClick={() => this.setState({ isShowAddUserModal: true })}
        >
          Добавить учреждение
        </Button>
        )}
        {getUserRole() !== 'user' && getUserRole() !== 'person' && (
          <div className="homePageMobile-header" style={{ paddingBottom: '0px' }}>
            <Button
              className="homePageDesktop-buttons-menu"
              style={{
                width: '45%',
                backgroundColor: currentSection === 'applications' ? '#06b5f1' : '#fff',
                color: currentSection === 'applications' ? '#fff' : '#212121',
              }}
              onClick={() => this.onMenuClick('applications')}
            >
              Реестр заявок
            </Button>
            <Button
              className="homePageDesktop-buttons-menu"
              style={{
                width: '45%',
                backgroundColor: currentSection === 'users' ? '#06b5f1' : '#fff',
                color: currentSection === 'users' ? '#fff' : '#212121',
              }}
              onClick={() => this.onMenuClick('users')}
            >
              Статистика заявок
            </Button>
          </div>
        )}
        {(getUserRole() === 'expertDZM' || getUserRole() === 'expertFederal') && (
            <Button
              className="homePageDesktop-buttons-menu"
              style={{ marginTop: 140 }}
              onClick={() => this.onMenuClick('expertDZMProjects')}
            >
              Проекты 2023
            </Button>
        )}
        {currentSection === 'applications' && <ApplicationMobile history={history} users={users} dataSource={applications} />}
        {currentSection === 'users' && (
          <UserMobile
            users={users}
            isShowAddUserModal={isShowAddUserModal}
            onCancel={() => this.setState({ isShowAddUserModal: false })}
            onAddUser={this.onAddUser}
            onSearch={this.onSearch}
          />
        )}
        </div>
      );
    }
}

HomePageMobile.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(HomePageMobile);
