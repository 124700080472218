const stepsNumber2022 = 4;
const stepsNumber2023 = 3;


export const getHeaderSingleColumn = (isGrants2023) => {
  const result = [
    {
      isVerticalMerge: true,
      name: '№',
      width: 15,
      field: 'index',
    },
    {
      isVerticalMerge: true,
      name: 'Номер заявки',
      width: 15,
      field: 'displayID',
    },
    {
      isVerticalMerge: true,
      name: 'Название проекта',
      width: 15,
      field: 'theme',
    },
    {
      isVerticalMerge: true,
      name: 'Количество этапов',
      width: 15,
      field: 'maxStepsNumber',
    },
    {
      isVerticalMerge: true,
      name: 'Общая сумма средств гранта по Соглашению',
      width: 15,
      field: 'allStepsSum',
    },
    {
      isVerticalMerge: true,
      name: 'Средств грантополучателя из общей суммы средств гранта',
      width: 15,
      field: 'peopleMainTotalSum',
    },
    {
      isVerticalMerge: true,
      name: 'Объем средств, фактически полученных от АНО на текущий момент грантополучателем',
      width: 15,
      field: 'currentTransferedSum',
    },
  ];

  Array(isGrants2023 ? stepsNumber2023 : stepsNumber2022).fill(0).forEach((e, i) => {
    if (!isGrants2023) {
      result.push(...[
        {
          parentName: 'Аванс',
          name: 'Объем перечисленных средств',
          width: 15,
          field: `step${i + 1}Transfered`,
          isBottomRow: true,
          stepName: `Этап ${i + 1}`,
        },
        {
          name: 'Дата платежа',
          width: 15,
          field: `step${i + 1}Date`,
          isBottomRow: true,
        },
        {
          name: 'Реквизиты ПП',
          width: 15,
          field: `step${i + 1}Requisites`,
          isBottomRow: true,
        },
        {
          name: 'Комментарии',
          width: 15,
          field: `step${i + 1}Comment`,
          isBottomRow: true,
        },
      ]);
    }
    result.push(...[
      {
        parentName: isGrants2023 ? `Этап ${i + 1}` : 'Закрывающий платеж',
        name: 'Объем перечисленных средств',
        width: 15,
        field: `step${i + 1}TransferedClose`,
        isBottomRow: true,
      },
      {
        name: 'Дата платежа',
        width: 15,
        field: `step${i + 1}DateClose`,
        isBottomRow: true,
      },
      {
        name: 'Реквизиты ПП',
        width: 15,
        field: `step${i + 1}RequisitesClose`,
        isBottomRow: true,
      },
      {
        name: 'Комментарии',
        width: 15,
        field: `step${i + 1}CommentClose`,
        isBottomRow: true,
      },
    ]);
  });

  result.push(...[
    {
      parentName: 'Дополнительный платеж',
      name: 'Объем перечисленных средств',
      width: 15,
      field: 'stepNTransfered',
      isBottomRow: true,
      isMergeFirstTwo: true,
    },
    {
      name: 'Дата платежа',
      width: 15,
      field: 'stepNDate',
      isBottomRow: true,
    },
    {
      name: 'Реквизиты ПП',
      width: 15,
      field: 'stepNRequisites',
      isBottomRow: true,
    },
    {
      name: 'Комментарии',
      width: 15,
      field: 'stepNComment',
      isBottomRow: true,
    },
  ]);

  return result;
};

export const getPersonColumn = (isGrants2023) => {
  const result = [
    {
      isVerticalMerge: true,
      name: 'Организации соисполнители',
      width: 15,
      field: 'form',
    },
    {
      isVerticalMerge: true,
      name: 'Средств соисполнителей из общей суммы средств гранта',
      width: 15,
      field: 'totalSum',
    },
    {
      isVerticalMerge: true,
      name: 'Доведено средств ИТОГО по всем этапам',
      width: 15,
      field: 'allStepTransferedTotal',
    },
  ];

  Array(isGrants2023 ? stepsNumber2023 : stepsNumber2022).fill(0).forEach((e, i) => {
    if (!isGrants2023) {
      result.push(...[
        {
          parentName: 'Аванс',
          name: 'Объем перечисленных средств',
          width: 15,
          field: `step${i + 1}Transfered`,
          stepName: `Этап ${i + 1}`,
        },
        {
          name: 'Дата платежа',
          width: 15,
          field: `step${i + 1}Date`,
        },
        {
          name: 'Реквизиты ПП',
          width: 15,
          field: `step${i + 1}Requisites`,
        },
        {
          name: 'Комментарии',
          width: 15,
          field: `step${i + 1}Comment`,
        },
      ]);
    }
    result.push(...[
      {
        parentName: isGrants2023 ? `Этап ${i + 1}` : 'Закрывающий платеж',
        name: 'Объем перечисленных средств',
        width: 15,
        field: `step${i + 1}TransferedClose`,
      },
      {
        name: 'Дата платежа',
        width: 15,
        field: `step${i + 1}DateClose`,
      },
      {
        name: 'Реквизиты ПП',
        width: 15,
        field: `step${i + 1}RequisitesClose`,
      },
      {
        name: 'Комментарии',
        width: 15,
        field: `step${i + 1}CommentClose`,
      },
    ]);
  });

  result.push(...[
    {
      parentName: 'Дополнительный платеж',
      name: 'Объем перечисленных средств',
      width: 15,
      field: 'stepNTransfered',
      isMergeFirstTwo: true,
    },
    {
      name: 'Дата платежа',
      width: 15,
      field: 'stepNDate',
    },
    {
      name: 'Реквизиты ПП',
      width: 15,
      field: 'stepNRequisites',
    },
    {
      name: 'Комментарии',
      width: 15,
      field: 'stepNComment',
    },
  ]);
  return result;
};

