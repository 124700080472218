/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Button,
} from 'antd';
import './Survey.css';
import SurveyResult from './SurveyResult';
import { editApplicationSurveyPostmonitoring, getApplicationSurveyPostmonitoring } from '../../utils/http';
import SurveyRid from './SurveyRid';


function SurveyPostMonitor({ applicationId }) {
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [values, setValues] = useState(null);

  useEffect(() => {
    getApplicationSurveyPostmonitoring({ id: applicationId })
      .then((result) => {
        setValues(result.data[0]);
        setIsFormDisabled(false);
      });
  }, []);

  const onInput = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const onSave = () => {
    setIsFormDisabled(true);
    const objToSave = { ...values };
    delete objToSave.applicationSurveyPostmonitoringRID_ids;
    delete objToSave.applicationSurveyPostmonitoringResult_ids;
    editApplicationSurveyPostmonitoring(objToSave)
      .then(() => setIsFormDisabled(false));
  };

  return (
    <div
      className="applicationStep2-wrapper"
      style={{
        overflowY: 'scroll', height: '80vh', width: '75vw', backgroundColor: '#fff',
      }}
    >
      {values && (
      <div
        style={{
          marginTop: '20px', padding: '15px', borderRadius: '5px', display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
          <Button onClick={onSave} disabled={isFormDisabled}>Сохранить</Button>
          {/* <Button type="primary" style={{ marginLeft: '10px' }}>Завершить</Button> */}
        </div>
        <div style={{ fontWeight: 'bold' }}>ПОСТ-мониторинг внедрения научно-практических проектов</div>
        <div>получивших финансовую поддержку в рамках конкурсного отбора среди коллективов специалистов в медицинских организациях государственной системы здравоохранения</div>

        {/* <div style={{ fontWeight: 'bold' }}>Введите номер проекта</div>
        <Input disabled={isFormDisabled} style={{ margin: '5px 0px' }} onChange={(e) => onInput('displayID', e.target.value)} defaultValue={values.displayID} /> */}

        <SurveyResult elementsArrayProp={values?.applicationSurveyPostmonitoringResult_ids || []} surveyId={values?.id} />

        <div style={{ fontWeight: 'bold' }}>14. Общее количество объектов интеллектуальной собственности, уже полученных в рамках реализации проекта:</div>
        <div>Патенты</div>
        <SurveyRid
          elementsArrayProp={values?.applicationSurveyPostmonitoringRID_ids.filter((e) => e.type === 'patent')}
          applicationId={values?.id}
          // disabled={isFormDisabled}
          type="patent"
        />
        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ width: '50px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>№</div>
          <div style={{ width: '300px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>Наименование патента</div>
          <div style={{ width: '200px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>Номер патента</div>
          <div style={{ width: '250px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>Правообладатель</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{
            height: '40px', width: '50px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px',
          }}
          >
            1
          </div>
          <div style={{ width: '300px', border: '1px solid #d9d9d9' }}>
            <Input disabled={isFormDisabled} style={{ height: '40px' }} onChange={(e) => onInput('patentName', e.target.value)} defaultValue={values.patentName} />
          </div>
          <div style={{ width: '200px', border: '1px solid #d9d9d9' }}>
            <Input disabled={isFormDisabled} style={{ height: '40px' }} onChange={(e) => onInput('patentNumber', e.target.value)} defaultValue={values.patentNumber} />
          </div>
          <div style={{ width: '250px', border: '1px solid #d9d9d9' }}>
            <Input disabled={isFormDisabled} style={{ height: '40px' }} onChange={(e) => onInput('patentOwner', e.target.value)} defaultValue={values.patentOwner} />
          </div>
        </div> */}

        <div style={{ marginTop: '20px' }}>Свидетельства о регистрации программы для ЭВМ</div>
        <SurveyRid
          elementsArrayProp={values?.applicationSurveyPostmonitoringRID_ids.filter((e) => e.type === 'program')}
          applicationId={values?.id}
          // disabled={isFormDisabled}
          type="program"
        />
        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ width: '50px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>№</div>
          <div style={{ width: '300px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>Наименование ЭВМ</div>
          <div style={{ width: '200px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>Номер свидетельства</div>
          <div style={{ width: '250px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>Правообладатель</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{
            height: '40px', width: '50px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px',
          }}
          >
            1
          </div>
          <div style={{ width: '300px', border: '1px solid #d9d9d9' }}>
            <Input disabled={isFormDisabled} style={{ height: '40px' }} onChange={(e) => onInput('programName', e.target.value)} defaultValue={values.programName} />
          </div>
          <div style={{ width: '200px', border: '1px solid #d9d9d9' }}>
            <Input disabled={isFormDisabled} tyle={{ height: '40px' }} onChange={(e) => onInput('programNumber', e.target.value)} defaultValue={values.programNumber} />
          </div>
          <div style={{ width: '250px', border: '1px solid #d9d9d9' }}>
            <Input disabled={isFormDisabled} style={{ height: '40px' }} onChange={(e) => onInput('programOwner', e.target.value)} defaultValue={values.programOwner} />
          </div>
        </div> */}

        <div style={{ marginTop: '20px' }}>Регистрационные удостоверения</div>
        <SurveyRid
          elementsArrayProp={values?.applicationSurveyPostmonitoringRID_ids.filter((e) => e.type === 'paper')}
          applicationId={values?.id}
          // disabled={isFormDisabled}
          type="paper"
        />
        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ width: '50px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>№</div>
          <div style={{ width: '300px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>Наименование РУ</div>
          <div style={{ width: '200px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>Номер РУ</div>
          <div style={{ width: '250px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px' }}>Правообладатель</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{
            height: '40px', width: '50px', border: '1px solid #d9d9d9', padding: '0px 0px 0px 5px',
          }}
          >
            1
          </div>
          <div style={{ width: '300px', border: '1px solid #d9d9d9' }}>
            <Input disabled={isFormDisabled} style={{ height: '40px' }} onChange={(e) => onInput('paperName', e.target.value)} defaultValue={values.paperName} />
          </div>
          <div style={{ width: '200px', border: '1px solid #d9d9d9' }}>
            <Input disabled={isFormDisabled} style={{ height: '40px' }} onChange={(e) => onInput('paperNumber', e.target.value)} defaultValue={values.paperNumber} />
          </div>
          <div style={{ width: '250px', border: '1px solid #d9d9d9' }}>
            <Input disabled={isFormDisabled} style={{ height: '40px' }} onChange={(e) => onInput('paperOwner', e.target.value)} defaultValue={values.paperOwner} />
          </div>
        </div> */}

        <div style={{ marginTop: '20px', fontWeight: 'bold' }}>15. Планируемое к получению количество объектов интеллектуальной собственности:</div>
        <div>патенты</div>
        <Input disabled={isFormDisabled} style={{ margin: '5px 0px' }} onChange={(e) => onInput('patentPlan', e.target.value)} defaultValue={values.patentPlan} />
        <div>свидетельства о регистрации программы для ЭВМ</div>
        <Input disabled={isFormDisabled} style={{ margin: '5px 0px' }} onChange={(e) => onInput('programPlan', e.target.value)} defaultValue={values.programPlan} />
        <div>регистрационные удостоверения</div>
        <Input disabled={isFormDisabled} style={{ margin: '5px 0px' }} onChange={(e) => onInput('paperPlan', e.target.value)} defaultValue={values.paperPlan} />

      </div>
      )}
    </div>
  );
}

SurveyPostMonitor.propTypes = {
  applicationId: PropTypes.string.isRequired,
};

export default SurveyPostMonitor;
