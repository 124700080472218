import React, { Component } from 'react';
import HomePageDesktop from './components/HomePageDesktop/HomePageDesktop';
import HomePageMobile from './components/HomePageMobile/HomePageMobile';


export default class HomePage extends Component {
  state = {
    width: window.innerWidth,
  }

  render() {
    const { width } = this.state;
    const isMobile = width <= 799;
    if (isMobile) {
      return <HomePageMobile />;
    }
    return (
      <HomePageDesktop />
    );
  }
}
