import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Upload, Row, Col,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';


const { Dragger } = Upload;
class Files extends Component {
  render() {
    const {
      loading, visible, files, onUploadFile, onRemoveFile,
    } = this.props;
    return (
      <div className="application-main-wrapper" style={{ display: visible ? 'flex' : 'none' }}>
        <div className="application-main-box-wrapper">
          <div className="application-main-box-mask" />
          <div className="application-main-box-content-wrapper">
            <div className="application-main-label">Дополнительные файлы</div>
            <span className="application-main-box-label">Приложения с дополнительной информацией по проекту</span>
            <Dragger
              showUploadList={false}
              onChange={onUploadFile}
              disabled={loading}
              multiple
              action={null}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
            </Dragger>
            {files.map((f) => (
              <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                <Col><Button type="primary" onClick={() => window.open(f.urlLink)}>Скачать</Button></Col>
                {!loading && <Col><Button onClick={() => onRemoveFile(f)}>Удалить</Button></Col>}
                <Col style={{ color: '#212121', fontSize: '20px' }}>{f.name}</Col>
              </Row>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Files.propTypes = {
  loading: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Files;
