const salaryRows = [{ field: 'Supervisor', label: 'Научный руководитель' }, { field: 'Doctor', label: 'Врачи' }, { field: 'Middle', label: 'Средний медицинский персонал' }, { field: 'Junior', label: 'Младший медицинский персонал' }, { field: 'Other', label: 'Прочие сотрудники' }];

const fields = [
  {
    key: 'index',
    name: '№ п/п',
    width: 10,
  },
  {
    key: 'displayID',
    name: 'Номер заявки',
    width: 15,
  },
  {
    key: 'status',
    name: 'Статус',
    width: 25,
  },
  {
    key: 'projectName',
    name: 'Тематика работы',
    width: 100,
  },
  {
    key: 'organisationFullName',
    name: 'Организация- грантополучатель',
    width: 50,
  },
  {
    key: 'supervisorFullName',
    name: 'Научный руководитель проекта',
    width: 40,
  },
  {
    key: 'scientificDirection',
    name: 'Направление работы',
    width: 30,
  },
  {
    key: 'projectTerm',
    name: 'Срок выполнения, полных лет',
    width: 20,
  },
  {
    key: 'moneyTotal',
    name: 'Запрашиваемая сумма всего, руб (больница + соисполнители)',
    width: 30,
  },
  {
    key: 'moneyTotal2023',
    name: 'Сумма на 2023 год всего, руб',
    width: 30,
  },
  {
    key: 'moneyTotal2024',
    name: 'Сумма на 2024 год всего, руб',
    width: 30,
  },
  {
    key: 'moneyTotal2025',
    name: 'Сумма на 2025 год всего, руб',
    width: 30,
  },
  {
    key: 'moneyTotalOrg',
    name: 'Запрашиваемая сумма на больницу всего, руб (только больница)',
    width: 30,
  },
  {
    key: 'moneyTotal2023Org',
    name: 'Сумма на больницу на 2023 год, руб',
    width: 30,
  },
  {
    key: 'moneyTotal2024Org',
    name: 'Сумма на больницу на 2024 год, руб',
    width: 30,
  },
  {
    key: 'moneyTotal2025Org',
    name: 'Сумма на больницу на 2025 год, руб',
    width: 30,
  },
  {
    key: 'annotation',
    name: 'Краткая аннотация',
    width: 100,
  },
  {
    key: 'type',
    name: 'Продукт, планируемый к получению по итогам выполнения работ',
    width: 100,
  },
  {
    key: 'numberOfPublicationsTotal',
    name: 'Число планируемых публикаций по результатам реализации Проекта',
    width: 30,
  },
  {
    key: 'numberOfPublicationsVAC',
    name: 'в научных журналах перечня ВАК с Импакт-фактором > 0,5 (на момент публикации материала)',
    width: 30,
  },
  {
    key: 'numberOfPublicationsRINC',
    name: 'в научных журналах, индексируемых РИНЦ',
    width: 30,
  },
  {
    key: 'numberOfRID',
    name: 'Число результатов интеллектуальной деятельности, планируемых к получению по результатам реализации Проекта',
    width: 30,
  },
  {
    key: 'numberOfEvents',
    name: 'Количество планируемых мероприятий по демонстрации результатов реализации Проекта',
    width: 30,
  },
  {
    key: 'numberOfEventsPeople',
    name: 'Ожидаемая аудитория мероприятий по демонстрации результатов реализации Проекта из числа профессионального сообщества и представителей здравоохранения города Москвы',
    width: 30,
  },
  {
    key: 'numberOfPerformance',
    name: 'Количество планируемых выступлений с докладами на международных научно-практических конференциях, симпозиумах, форумах',
    width: 30,
  },
  {
    key: 'numberOfPatienceTotal',
    name: 'Общее количество пациентов, предусмотренных для выполнения Проекта',
    width: 30,
  },
  {
    key: 'numberOfPatienceMain',
    name: 'Пациенты основной группы',
    width: 30,
  },
  {
    key: 'numberOfPatienceControl',
    name: 'Пациенты контрольной группы',
    width: 30,
  },
  {
    key: 'numberOfPatienceCompare',
    name: 'Пациенты группы сравнения',
    width: 30,
  },
  {
    key: 'numberOfActs',
    name: 'Протоколы испытаний',
    width: 30,
  },
  {
    key: 'numberOfExamples',
    name: 'Наличие опытных образцов',
    width: 30,
  },
  {
    key: 'fotTotal',
    name: 'Оплата труда работников (включая расходы на оплату страховых взносов на ФОТ)',
    width: 30,
  },
  {
    key: 'materialTotal',
    name: 'Расходы на приобретение материалов и комплектующих, необходимых для реализации проекта',
    width: 30,
  },
  {
    key: 'equipmentTotal',
    name: 'Расходы, на приобретение оборудования и иного имущества, необходимых для реализации Проекта',
    width: 30,
  },
  {
    key: 'otherTotal',
    name: 'Прочие прямые расходы',
    width: 30,
  },
  {
    key: 'paperTotal',
    name: 'Накладные и общехозяйственные расходы, предусмотренные учетной политикой организации',
    width: 30,
  },
  {
    key: 'salaryDoctorAverage',
    name: 'Средняя оплата труда одного сотрудника группы (Врачи) в год, руб.',
    width: 30,
  },
  {
    key: 'salaryJuniorAverage',
    name: 'Средняя оплата труда одного сотрудника группы (Младший медицинский персонал) в год, руб.',
    width: 30,
  },
  {
    key: 'salaryMiddleAverage',
    name: 'Средняя оплата труда одного сотрудника группы (Средний медицинский персонал) в год, руб.',
    width: 30,
  },
  {
    key: 'salaryOtherAverage',
    name: 'Средняя оплата труда одного сотрудника группы (Прочие сотрудники) в год, руб.',
    width: 30,
  },
  {
    key: 'salarySupervisorAverage',
    name: 'Средняя оплата труда одного сотрудника группы (Научный руководитель) в год, руб.',
    width: 30,
  },
  {
    key: 'peopleList',
    name: 'Перечень ключевых членов коллектива специалистов (перечислить ФИО и должности)',
    width: 50,
  },
  {
    key: 'peopleTotal',
    name: 'Количество средств на всех соисполнителей всего, руб',
    width: 30,
  },
  {
    key: 'peopleTotal2023',
    name: 'Сумма всех соисполнителей на 2023 год, руб',
    width: 30,
  },
  {
    key: 'peopleTotal2024',
    name: 'Сумма всех соисполнителей на 2024 год, руб',
    width: 30,
  },
  {
    key: 'peopleTotal2025',
    name: 'Сумма всех соисполнителей на 2025 год, руб',
    width: 30,
  },
  {
    key: 'companies',
    fields: [
      {
        key: 'fullName',
        name: 'Соисполнитель',
        width: 30,
      },
      {
        key: 'total',
        name: 'Сумма на Соисполнителя всего, руб',
        width: 30,
      },
      {
        key: 'total2023',
        name: 'Сумма на Соисполнителя на 2023 год, руб',
        width: 30,
      },
      {
        key: 'total2024',
        name: 'Сумма на Соисполнителя на 2024 год, руб',
        width: 30,
      },
      {
        key: 'total2025',
        name: 'Сумма на Соисполнителя на 2025 год, руб',
        width: 30,
      },
    ],
  },
];


const prepareXlsData = (rawData) => {
  let maxNumberOfPeople = 0;
  const result = {
    fields,
    applications: [],
  };
  rawData.forEach((app, index) => {
    const years = [];
    if (['1', '2', '3'].includes(app.projectTerm)) {
      years.push('2023');
    }
    if (['2', '3'].includes(app.projectTerm)) {
      years.push('2024');
    }
    if (['3'].includes(app.projectTerm)) {
      years.push('2025');
    }
    const numberOfCompanies = app.applications2PersonsTabPeople_ids.length;
    if (numberOfCompanies > maxNumberOfPeople) {
      maxNumberOfPeople = numberOfCompanies;
    }
    const obj = {
      id: app.id,
      index: index + 1,
      displayID: app.displayID,
      status: app.status,
      projectName: app.projectName,
      organisationFullName: app.organisationFullName,
      supervisorFullName: app.supervisorFullName,
      scientificDirection: app.scientificDirection,
      projectTerm: app.projectTerm,
      moneyTotal: parseFloat(`${app.financeTotalMajor || 0}.${(`${app.financeTotalMinor}`).length === 1 ? `0${app.financeTotalMinor}` : app.financeTotalMinor}`),
      moneyTotal2023: parseFloat(`${app.financeTotal2023Major || 0}.${(`${app.financeTotal2023Minor}`).length === 1 ? `0${app.financeTotal2023Minor}` : app.financeTotal2023Minor}`),
      moneyTotal2024: parseFloat(`${app.financeTotal2024Major || 0}.${(`${app.financeTotal2024Minor}`).length === 1 ? `0${app.financeTotal2024Minor}` : app.financeTotal2024Minor}`),
      moneyTotal2025: parseFloat(`${app.financeTotal2025Major || 0}.${(`${app.financeTotal2025Minor}`).length === 1 ? `0${app.financeTotal2025Minor}` : app.financeTotal2025Minor}`),
      moneyTotalOrg: parseFloat(`${app.financeTotalMajor || 0}.${(`${app.financeTotalMinor}`).length === 1 ? `0${app.financeTotalMinor}` : app.financeTotalMinor}`) - parseFloat(`${app.peopleTotalMajor || 0}.${(`${app.peopleTotalMinor}`).length === 1 ? `0${app.peopleTotalMinor}` : app.peopleTotalMinor}`),
      moneyTotal2023Org: parseFloat(`${app.financeTotal2023Major || 0}.${(`${app.financeTotal2023Minor}`).length === 1 ? `0${app.financeTotal2023Minor}` : app.financeTotal2023Minor}`) - parseFloat(`${app.people2023Major || 0}.${(`${app.people2023Minor}`).length === 1 ? `0${app.people2023Minor}` : app.people2023Minor}`),
      moneyTotal2024Org: parseFloat(`${app.financeTotal2024Major || 0}.${(`${app.financeTotal2024Minor}`).length === 1 ? `0${app.financeTotal2024Minor}` : app.financeTotal2024Minor}`) - parseFloat(`${app.people2024Major || 0}.${(`${app.people2024Minor}`).length === 1 ? `0${app.people2024Minor}` : app.people2024Minor}`),
      moneyTotal2025Org: parseFloat(`${app.financeTotal2025Major || 0}.${(`${app.financeTotal2025Minor}`).length === 1 ? `0${app.financeTotal2025Minor}` : app.financeTotal2025Minor}`) - parseFloat(`${app.people2025Major || 0}.${(`${app.people2025Minor}`).length === 1 ? `0${app.people2025Minor}` : app.people2025Minor}`),
      annotation: app.projectAnnotation,
      type: app.type,
      fotTotal: parseFloat(`${app.fotTotalMajor || 0}.${(`${app.fotTotalMinor}`).length === 1 ? `0${app.fotTotalMinor}` : app.fotTotalMinor}`),
      materialTotal: parseFloat(`${app.materialTotalMajor || 0}.${(`${app.materialTotalMinor}`).length === 1 ? `0${app.materialTotalMinor}` : app.materialTotalMinor}`),
      equipmentTotal: parseFloat(`${app.equipmentTotalMajor || 0}.${(`${app.equipmentTotalMinor}`).length === 1 ? `0${app.equipmentTotalMinor}` : app.equipmentTotalMinor}`),
      otherTotal: parseFloat(`${app.otherTotalMajor || 0}.${(`${app.otherTotalMinor}`).length === 1 ? `0${app.otherTotalMinor}` : app.otherTotalMinor}`),
      paperTotal: parseFloat(`${app.paperTotalMajor || 0}.${(`${app.paperTotalMinor}`).length === 1 ? `0${app.paperTotalMinor}` : app.paperTotalMinor}`),
      peopleList: `${app.supervisorFullName}, ${app.applications2AuthorTabPeople_ids.map((p) => `${p.lastName} ${p.firstName} ${p.middleName}`).join(', ')}`,
      peopleTotal: parseFloat(`${app.peopleTotalMajor || 0}.${(`${app.peopleTotalMinor}`).length === 1 ? `0${app.peopleTotalMinor}` : app.peopleTotalMinor}`),
      peopleTotal2023: parseFloat(`${app.people2023Major || 0}.${(`${app.people2023Minor}`).length === 1 ? `0${app.people2023Minor}` : app.people2023Minor}`),
      peopleTotal2024: parseFloat(`${app.people2024Major || 0}.${(`${app.people2024Minor}`).length === 1 ? `0${app.people2024Minor}` : app.people2024Minor}`),
      peopleTotal2025: parseFloat(`${app.people2025Major || 0}.${(`${app.people2025Minor}`).length === 1 ? `0${app.people2025Minor}` : app.people2025Minor}`),
      companies: app.applications2PersonsTabPeople_ids.map((c) => {
        let total2023 = 0;
        let total2024 = 0;
        let total2025 = 0;

        const yearsInner = ['2023', '2024', '2025'];

        yearsInner.forEach((year) => {
          if (year === '2023') {
            total2023 = c.step1Sum || 0;
          }
          if (year === '2024') {
            total2024 += (c.step2Sum || 0) + (c.step3Sum || 0);
          }
          if (year === '2025') {
            total2025 += (c.step4Sum || 0) + (c.step5Sum || 0);
          }
        });

        return {
          id: c.id,
          fullName: c.fullName,
          total: c.stepTotal,
          total2023,
          total2024,
          total2025,
        };
      }),
      numberOfPublicationsTotal: app.numberOfPublicationsTotal,
      numberOfPublicationsVAC: app.numberOfPublicationsVAC,
      numberOfPublicationsRINC: app.numberOfPublicationsRINC,
      numberOfRID: app.numberOfRID,
      numberOfEvents: app.numberOfEvents,
      numberOfEventsPeople: app.numberOfEventsPeople,
      numberOfPerformance: app.numberOfPerformance,
      numberOfPatienceTotal: app.numberOfPatienceTotal,
      numberOfPatienceMain: app.numberOfPatienceMain,
      numberOfPatienceControl: app.numberOfPatienceControl,
      numberOfPatienceCompare: app.numberOfPatienceCompare,
      numberOfActs: app.numberOfActs,
      numberOfExamples: app.numberOfExamples,
    };
    salaryRows.forEach((salaryRow) => {
      obj[`salary${salaryRow.field}Average`] = 0;
      years.forEach((year) => {
        const major = app[`salary${salaryRow.field}AverageYear${year}`] ? app[`salary${salaryRow.field}AverageYear${year}`].split(' руб.')[0] : 0;
        const minor = app[`salary${salaryRow.field}AverageYear${year}`] ? app[`salary${salaryRow.field}AverageYear${year}`].split(' руб. ')[1].split(' коп.')[0] : 0;
        obj[`salary${salaryRow.field}Average`] += parseFloat(`${major || 0}.${(`${minor}`).length === 1 ? `0${minor}` : minor}`);
      });
      obj[`salary${salaryRow.field}Average`] = parseFloat((obj[`salary${salaryRow.field}Average`] / years.length).toFixed(2));
    });
    result.applications.push(obj);
  });
  result.maxNumberOfPeople = maxNumberOfPeople;
  return result;
};

export default prepareXlsData;
