import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Input, Form, DatePicker,
} from 'antd';


const { TextArea } = Input;
class PersonPaymentStep extends Component {
  render() {
    const {
      isEdit, person, disabled, fieldNameDate, fieldNameRequisites, fieldNameTransfered, fieldNameComment, isShort,
      fieldNameDateClose, fieldNameRequisitesClose, fieldNameTransferedClose, fieldNameCommentClose, fieldNameTransferedTotal,
    } = this.props;

    return (
      <Col>
        {!isShort && (
        <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
          <Col span={3} style={{ fontSize: '1.5vh' }}>
            Доведено средств ИТОГО
          </Col>
          <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
            {isEdit
              ? (
                <Form.Item
                  name={fieldNameTransferedTotal}
                  initialValue={person[fieldNameTransferedTotal]}
                >
                  <Input disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              )
              : <span>{person[fieldNameTransferedTotal] ? person[fieldNameTransferedTotal] : 'Не заполнено'}</span>}
          </Col>
        </Row>
        )}
        <Row>
          <Col span={12}>
            <Row style={{ color: '#212121', fontSize: '1.3rem', marginBottom: '10px' }}>{isShort ? 'Платеж' : 'Аванс'}</Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Дата платежа
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                {isEdit
                  ? (
                    <Form.Item
                      name={fieldNameDate}
                      initialValue={person[fieldNameDate]}
                    >
                      <DatePicker disabled={disabled} style={{ width: '100%' }} />
                    </Form.Item>
                  )
                  : <span>{person[fieldNameDate] ? person[fieldNameDate].format('DD MMMM YYYY') : 'Не заполнено'}</span>}
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Реквизиты ПП
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                {isEdit
                  ? (
                    <Form.Item
                      name={fieldNameRequisites}
                      initialValue={person[fieldNameRequisites]}
                    >
                      <TextArea disabled={disabled} style={{ width: '100%' }} />
                    </Form.Item>
                  )
                  : <span>{person[fieldNameRequisites] ? person[fieldNameRequisites] : 'Не заполнено'}</span>}
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Объем перечисленных средств
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                {isEdit
                  ? (
                    <Form.Item
                      name={fieldNameTransfered}
                      initialValue={person[fieldNameTransfered]}
                    >
                      <Input disabled={disabled} style={{ width: '100%' }} />
                    </Form.Item>
                  )
                  : <span>{person[fieldNameTransfered] ? person[fieldNameTransfered] : 'Не заполнено'}</span>}
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Комментарий
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                {isEdit
                  ? (
                    <Form.Item
                      name={fieldNameComment}
                      initialValue={person[fieldNameComment]}
                    >
                      <TextArea disabled={disabled} style={{ width: '100%' }} />
                    </Form.Item>
                  )
                  : <span>{person[fieldNameComment] ? person[fieldNameComment] : 'Не заполнено'}</span>}
              </Col>
            </Row>
          </Col>

          {!isShort && (
          <Col span={12}>
            <Row style={{ color: '#212121', fontSize: '1.3rem', marginBottom: '10px' }}>Закрывающий платеж</Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Дата платежа
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                {isEdit
                  ? (
                    <Form.Item
                      name={fieldNameDateClose}
                      initialValue={person[fieldNameDateClose]}
                    >
                      <DatePicker disabled={disabled} style={{ width: '100%' }} />
                    </Form.Item>
                  )
                  : <span>{person[fieldNameDateClose] ? person[fieldNameDateClose].format('DD MMMM YYYY') : 'Не заполнено'}</span>}
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Реквизиты ПП
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                {isEdit
                  ? (
                    <Form.Item
                      name={fieldNameRequisitesClose}
                      initialValue={person[fieldNameRequisitesClose]}
                    >
                      <TextArea disabled={disabled} style={{ width: '100%' }} />
                    </Form.Item>
                  )
                  : <span>{person[fieldNameRequisitesClose] ? person[fieldNameRequisitesClose] : 'Не заполнено'}</span>}
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Объем перечисленных средств
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                {isEdit
                  ? (
                    <Form.Item
                      name={fieldNameTransferedClose}
                      initialValue={person[fieldNameTransferedClose]}
                    >
                      <Input disabled={disabled} style={{ width: '100%' }} />
                    </Form.Item>
                  )
                  : <span>{person[fieldNameTransferedClose] ? person[fieldNameTransferedClose] : 'Не заполнено'}</span>}
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Комментарий
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                {isEdit
                  ? (
                    <Form.Item
                      name={fieldNameCommentClose}
                      initialValue={person[fieldNameCommentClose]}
                    >
                      <TextArea disabled={disabled} style={{ width: '100%' }} />
                    </Form.Item>
                  )
                  : <span>{person[fieldNameCommentClose] ? person[fieldNameCommentClose] : 'Не заполнено'}</span>}
              </Col>
            </Row>
          </Col>
          )}
        </Row>
      </Col>
    );
  }
}

PersonPaymentStep.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  person: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  fieldNameDate: PropTypes.string.isRequired,
  fieldNameRequisites: PropTypes.string.isRequired,
  fieldNameTransfered: PropTypes.string.isRequired,
  fieldNameComment: PropTypes.string.isRequired,
  fieldNameDateClose: PropTypes.string.isRequired,
  fieldNameRequisitesClose: PropTypes.string.isRequired,
  fieldNameTransferedClose: PropTypes.string.isRequired,
  fieldNameCommentClose: PropTypes.string.isRequired,
  fieldNameTransferedTotal: PropTypes.string.isRequired,
  isShort: PropTypes.bool.isRequired,
};


export default PersonPaymentStep;
