import React, { Component } from 'react';
import moment from 'moment';
import ru from 'moment/locale/ru';
import { ConfigProvider } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ru_RU from 'antd/es/locale/ru_RU';
import PrivateRoute from './utils/privateRoute';
import LoginPage from './pages/Login/LoginPage';
import HomePage from './pages/Home/HomePage';
// import RegistrationModule from './pages/RegistrationModule/RegistrationModule';
// import ApplicationModule from './pages/Application/ApplicationModule';
import { routes } from './utils/routes';
import { I18nProvider, LOCALES } from './utils/i18n';
import Header from './components/Header/Header';
import getUserTheme from './utils/localeStorage/getUserTheme';
import colors from './assets/styles/colors';
import ApplicationDM from './pages/ApplicationDZMMain/ApplicationDM';
import ApplicationStep2 from './pages/ApplicationStep2/ApplicationStep2';
import Project from './pages/Project/Project';
import ProfileModule from './pages/Profile/ProfileModule';


moment.updateLocale('ru', ru);
export default class App extends Component {
  state = {
    language: LOCALES.RUSSIAN,
    width: window.innerWidth,
  };

  onChangeLanguage = (language) => {
    this.setState({ language });
  }

  render() {
    const {
      language, width,
    } = this.state;
    const isMobile = width <= 799;
    return (
      <I18nProvider locale={language}>
        <ConfigProvider locale={ru_RU}>
          <Router>
            <div
              className="body-wrapper"
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '100vw',
                background: colors[getUserTheme()].mainBackgroundColor,
                color: colors[getUserTheme()].mainTextColor,
              }}
            >
              {!isMobile && <Header />}
              <Switch>
                {/* <Route path={routes.registration}>
                  <RegistrationModule />
                </Route> */}
                <Redirect exact from={routes.home} to={routes.homePage} />
                <PrivateRoute path={routes.homePage}>
                  <HomePage />
                </PrivateRoute>
                <PrivateRoute exact path={routes.application}>
                  <ApplicationDM />
                </PrivateRoute>
                <PrivateRoute exact path={routes.application2}>
                  <ApplicationStep2 />
                </PrivateRoute>
                <PrivateRoute exact path={routes.project}>
                  <Project />
                </PrivateRoute>
                <PrivateRoute exact path={routes.profile}>
                  <ProfileModule />
                </PrivateRoute>
                <Route path={routes.home}>
                  <LoginPage />
                </Route>
              </Switch>
            </div>
          </Router>
        </ConfigProvider>
      </I18nProvider>
    );
  }
}
