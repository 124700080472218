function setLocalStorage(fields = {}) {
  return Object
    .keys(fields)
    .forEach(
      (field) => localStorage.setItem(
        field,
        fields[field],
      ),
    );
}

function getLocalStorage(fields = []) {
  const readBuffer = {};

  fields
    .forEach((field) => {
      let value = localStorage.getItem(field);
      value = value === 'null' ? null : value;
      value = value === 'undefined' ? undefined : value;

      readBuffer[field] = value;
    });

  return readBuffer;
}

export {
  setLocalStorage,
  getLocalStorage,
};
