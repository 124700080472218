import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { getApplications2 } from '../../../../../../utils/http';
import getUserId from '../../../../../../utils/localeStorage/getUserId';
import getColumns from '../ApplicationsStep2/getColumns';


function DraftApplications() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDatasource] = useState([]);


  useEffect(() => {
    getApplications2({ webUserId: getUserId(), status: 'В архиве' })
      .then((applications) => {
        setLoading(false);
        setDatasource(applications);
      });
  }, []);

  return (
    <div className="applications-wrapper">
      <div className="applications-table-wrapper ">
        <Table
          dataSource={dataSource}
          columns={getColumns()}
          loading={loading}
          scroll={{ y: '55vh' }}
          pagination={false}
          rowKey="id"
        />
      </div>
    </div>
  );
}

export default DraftApplications;
