/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form,
} from 'antd';


const { TextArea } = Input;
function ApplicationsPlan({
  loading, visible, applicationsPlan, steps,
}) {
  return (
    <div className="application-main-wrapper" style={{ display: visible ? 'flex' : 'none' }}>
      <div className="application-main-box-wrapper">
        <div className="application-main-box-mask" />

        <div className="application-main-box-content-wrapper">
          <div className="application-subTitle">Оценка фактического выполнения плана работ в отчетном периоде</div>

          <div className="application-main-box-content-wrapper" style={{ width: '100%', padding: 0 }}>
            <div className="application-table-row">
              <div className="application-table-row-cell" style={{ width: '10%' }}>№</div>
              <div className="application-table-row-cell" style={{ textAlign: 'center' }}>
                Содержание работ, запланированных в рамках реализации проекта
                <br />
                <span style={{ fontStyle: 'italic', fontSize: '12px' }}>(план-график реализации проекта, утвержденный приложением №1 к Соглашению)</span>
              </div>
              <div className="application-table-row-cell" style={{ textAlign: 'center' }}>
                Ожидаемые результаты
                <br />
                <span style={{ fontStyle: 'italic', fontSize: '12px' }}>(план-график реализации проекта, утвержденный приложением №1 к Соглашению)</span>
              </div>
              <div className="application-table-row-cell" style={{ textAlign: 'center' }}>
                Выполненные работы в рамках реализации проекта
                <br />
                <span style={{ fontStyle: 'italic', fontSize: '12px' }}>(в соответствии с отчетной документацией – итоговым отчетом о реализации проекта, отчетами соисполнителей (при наличии)</span>
              </div>
              <div className="application-table-row-cell" style={{ borderRight: '1px solid #d9d9d9', textAlign: 'center' }}>
                Полученные в рамках реализации проекта результаты
                <br />
                <span style={{ fontStyle: 'italic', fontSize: '12px' }}>(в соответствии с отчетной документацией – итоговым отчетом о реализации проекта, отчетами соисполнителей (при наличии) </span>
              </div>
            </div>

            {Array(steps || 1).fill(0).map((el, index) => (
              <div className="application-table-row" key={index + 1}>
                <div className="application-table-row-cell" style={{ width: '10%' }}>{index + 1}</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name={`n${index + 1}`}
                  initialValue={applicationsPlan[`n${index + 1}`]}
                >
                  <TextArea autoSize disabled minrows={2} />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  name={`d${index + 1}`}
                  initialValue={applicationsPlan[`d${index + 1}`]}
                >
                  <TextArea autoSize disabled minrows={2} />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  name={`w${index + 1}`}
                  initialValue={applicationsPlan[`w${index + 1}`]}
                >
                  <TextArea autoSize disabled={loading} minrows={2} placeholder="Введите текст" />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  name={`r${index + 1}`}
                  initialValue={applicationsPlan[`r${index + 1}`]}
                >
                  <TextArea autoSize disabled={loading} minrows={2} placeholder="Введите текст" />
                </Form.Item>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

ApplicationsPlan.propTypes = {
  loading: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  applicationsPlan: PropTypes.shape().isRequired,
  steps: PropTypes.number.isRequired,
};

export default ApplicationsPlan;
