const scientificDirectionCatalogue = [
  'Акушерство и гинекология',
  'Аллергология-иммунология',
  'Анестезиология-реаниматология',
  'ВИЧ инфекции',
  'Гастроэнтерология',
  'Гематология',
  'Гериатрия',
  'Гигиена детей и подростков',
  'Дерматовенерология и косметология',
  'Диетология',
  'Инфекционные болезни',
  'Кардиология',
  'Клиническая лабораторная диагностика',
  'Клиническая фармакология',
  'Колопроктология',
  'Лучевая и инструментальная диагностика',
  'Медицина катастроф',
  'Медицинская генетика',
  'Медицинская профилактика',
  'Медицинская реабилитация и санаторно-курортное лечение',
  'Неврология',
  'Нейрохирургия',
  'Неонатология',
  'Нефрология',
  'Общая врачебная практика',
  'Онкология',
  'Остеопатия',
  'Оториноларингология',
  'Оториноларингология и сурдология',
  'Офтальмология',
  'Паллиативная помощь',
  'Патологическая анатомия',
  'Педиатрия',
  'Первая помощь',
  'Первичная медико-санитарная помощь населению',
  'Пластическая хирургия',
  'Профпатология',
  'Психиатрия',
  'Психиатрия-наркология',
  'Пульмонология',
  'Радиология',
  'Ревматология',
  'Рентгеноэндоваскулярная диагностика и лечение',
  'Репродуктивное здоровье',
  'Сердечно-сосудистая хирургия',
  'Сестринская деятельность',
  'Скорая помощь',
  'Сочетанная травма',
  'Спортивная медицина',
  'Стоматология',
  'Судебная медицинская экспертиза',
  'Терапия',
  'Токсикология',
  'Торакальная хирургия',
  'Травматология-ортопедия',
  'Трансплантология',
  'Трансфузиология',
  'Урология и андрология',
  'Фтизиатрия',
  'Хирургия и эндоскопия',
  'Челюстно-лицевая хирургия',
  'Эндокринология',
  'Эпидемиология',
  'Иное',
];

export default scientificDirectionCatalogue;
