/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Breadcrumb, Col, Row, Popconfirm,
} from 'antd';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { routes } from '../../../../../utils/routes';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';


function Navigation({
  application, view, onChangeView, isShowSurveyTab, isShowPaymentTab, onAddStep,
}) {
  const isAdmin = getUserRole() === 'bigAdmin';
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href={routes.homePage}>
            Список заявок
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {`Заявка №${application.displayID}`}
        </Breadcrumb.Item>
      </Breadcrumb>
      <span style={{ fontSize: '22px', fontWeight: 'bold' }}>{application.theme}</span>

      <Row
        justify="space-between"
        align="middle"
        style={{
          width: '100%', margin: '20px 0px', backgroundColor: '#fff', borderRadius: '25px', padding: '20px',
        }}
      >
        <Col>
          <Row gutter={40}>
            <Col>
              <Row style={{ alignItems: 'center', cursor: view === 'common' ? 'initial' : 'pointer' }} onClick={() => onChangeView('common')}>
                <Col>
                  <div style={{
                    padding: '5px', borderRadius: '100%', width: '40px', height: '40px', backgroundColor: view === 'common' ? '#1890FF' : '#e9e9e9', color: view === 'common' ? '#fff' : '#959595', fontSize: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                  >
                    1
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      color: view === 'common' ? '#1890FF' : '#959595', fontSize: '18px', marginLeft: '10px',
                    }}
                  >
                    Информация о заявке
                  </div>
                </Col>
              </Row>
            </Col>
            {Array(application.maxStepsNumber).fill(0).map((_, index) => {
              const endDate = application.applicationStepReports.find((e) => Number(e.stepNumber) === index + 1)?.endDate;
              // const stepId = application.applicationStepReports.find((e) => Number(e.stepNumber) === index + 1)?.id;
              return (
                <Col key={index}>
                  <Row style={{ alignItems: 'center', cursor: view === `${index}` ? 'initial' : 'pointer' }} onClick={() => onChangeView(`${index}`)}>
                    <Col>
                      <div style={{
                        padding: '5px', borderRadius: '100%', width: '40px', height: '40px', backgroundColor: view === `${index}` ? '#1890FF' : '#e9e9e9', color: view === `${index}` ? '#fff' : '#959595', fontSize: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                      }}
                      >
                        {index + 1}
                      </div>
                    </Col>
                    <Col>
                      <div
                        style={{
                          color: view === `${index}` ? '#1890FF' : '#959595', fontSize: '18px', marginLeft: '10px',
                        }}
                      >
                        <div>{`Этап ${index + 1}`}</div>
                        {/* {isAdmin && (
                        <DatePicker
                          style={{ width: '100%', padding: 0, paddingLeft: '3px' }}
                          placeholder="Заполняется автоматически"
                          defaultValue={endDate}
                          onChange={(e) => editStepDate({ id: stepId, endDate: e })}
                          className="step-date"
                          format="DD.MM.YYYY"
                        />
                        )}
                        {!isAdmin && <div style={{ fontSize: '11px' }}>{endDate ? endDate.format('DD.MM.YYYY') : ''}</div>} */}
                        <div style={{ fontSize: '11px' }}>{endDate ? endDate.format('DD.MM.YYYY') : ''}</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              );
            })}
            {isShowSurveyTab && (
            <Col>
              <Row style={{ alignItems: 'center', cursor: view === 'survey' ? 'initial' : 'pointer' }} onClick={() => onChangeView('survey')}>
                <Col>
                  <div style={{
                    padding: '5px', borderRadius: '100%', width: '40px', height: '40px', backgroundColor: view === 'survey' ? '#1890FF' : '#e9e9e9', color: view === 'survey' ? '#fff' : '#959595', fontSize: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                  >
                    -
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      color: view === 'survey' ? '#1890FF' : '#959595', fontSize: '18px', marginLeft: '10px',
                    }}
                  >
                    Опрос
                  </div>
                </Col>
              </Row>
            </Col>
            )}

            {isShowPaymentTab && (
            <Col>
              <Row style={{ alignItems: 'center', cursor: view === 'payment' ? 'initial' : 'pointer' }} onClick={() => onChangeView('payment')}>
                <Col>
                  <div style={{
                    padding: '5px', borderRadius: '100%', width: '40px', height: '40px', backgroundColor: view === 'payment' ? '#1890FF' : '#e9e9e9', color: view === 'payment' ? '#fff' : '#959595', fontSize: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                  >
                    -
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      color: view === 'payment' ? '#1890FF' : '#959595', fontSize: '18px', marginLeft: '10px',
                    }}
                  >
                    Сведения об оплатах
                  </div>
                </Col>
              </Row>
            </Col>
            )}

            {isAdmin && (
            <Col>
              <Popconfirm
                title="Вы действительно добавить этап?"
                onConfirm={onAddStep}
                okText="Да"
                cancelText="Нет"
              >
                <Row style={{ alignItems: 'center', cursor: 'pointer' }}>
                  <Col>
                    <div style={{
                      padding: '5px', borderRadius: '100%', width: '40px', height: '40px', backgroundColor: '#e9e9e9', color: '#959595', fontSize: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    }}
                    >
                      +
                    </div>
                  </Col>
                </Row>
              </Popconfirm>
            </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

Navigation.propTypes = {
  application: PropTypes.shape().isRequired,
  view: PropTypes.string.isRequired,
  onChangeView: PropTypes.func.isRequired,
  isShowSurveyTab: PropTypes.bool.isRequired,
  isShowPaymentTab: PropTypes.bool.isRequired,
  // editStepDate: PropTypes.func.isRequired,
  onAddStep: PropTypes.func.isRequired,
};

export default withRouter(Navigation);
