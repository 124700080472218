import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, Tooltip, Input,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';


const { TextArea } = Input;
function CheckListRow({
  onCheckBoxChange, onCommentChange, index, title, description, comment, isShowCheckbox, isShowDescription, isHeader, isError, application, fieldName,
  isDisabled,
}) {
  const [isChecked, setIsChecked] = useState(application[`${fieldName}CheckBox`]);

  const onCheckBox = (value) => {
    onCheckBoxChange(value);
    setIsChecked(value);
  };

  return (
    <div style={{
      display: 'flex', flexDirection: 'row', borderBottom: '1px solid',
    }}
    >
      <div style={{
        width: '5%', padding: '5px', fontWeight: 'bold', borderLeft: '1px solid',
      }}
      >
        {index}
      </div>
      {isHeader && (
      <div style={{
        width: '95%', padding: '5px', borderLeft: '1px solid', borderRight: '1px solid', fontWeight: 'bold',
      }}
      >
        {title}
      </div>
      )}
      {!isHeader && (
      <div style={{
        width: '80%', borderLeft: '1px solid', borderRight: '1px solid', display: 'flex', flexDirection: 'column',
      }}
      >
        <div style={{ fontWeight: 'bold', borderBottom: '1px solid', padding: '5px' }}>
          {title}
        </div>
        {isShowDescription && (
        <div style={{ borderBottom: isChecked ? '' : '1px solid', padding: '5px', fontStyle: 'italic' }}>
          {description}
        </div>
        )}
        {!isChecked && (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: '5px', textDecoration: 'underline' }}>Комментарий:</div>
            <Tooltip title={comment}>
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
          <TextArea autoSize disabled={isDisabled} onChange={(e) => onCommentChange(e.target.value)} status={isError ? 'error' : ''} defaultValue={application[`${fieldName}Comment`]} />
        </div>
        )}
      </div>
      )}
      {isShowCheckbox && (
      <div style={{
        width: '15%', padding: '5px', borderRight: '1px solid', display: 'flex', justifyContent: 'center',
      }}
      >
        <Checkbox disabled={isDisabled} onChange={(e) => onCheckBox(e.target.checked)} defaultChecked={application[`${fieldName}CheckBox`]} />
      </div>
      )}
      {!isShowCheckbox && !isHeader && (
      <div style={{
        width: '15%', padding: '5px', borderRight: '1px solid',
      }}
      />
      )}
    </div>
  );
}

CheckListRow.propTypes = {
  onCheckBoxChange: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  isShowCheckbox: PropTypes.bool.isRequired,
  isShowDescription: PropTypes.bool.isRequired,
  isHeader: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  application: PropTypes.shape().isRequired,
  fieldName: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
};

CheckListRow.defaultProps = {
  fieldName: null,
};

export default CheckListRow;
