import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input,
} from 'antd';
import './RequisitesTab.css';


const { TextArea } = Input;
function RequisitesTab({
  applicationProp, disabled,
}) {
  return (
    <div className="requisitesTab-wrapper">

      <Form.Item
        name="id"
        initialValue={applicationProp.id}
        hidden
      />

      <div className="common-field-wrapper">
        <span className="common-field-label">Реквизиты грантополучателя</span>
        <Form.Item
          name="requisites"
          initialValue={applicationProp.requisites}
        >
          <TextArea autoSize disabled={disabled} minrows={10} />
        </Form.Item>
      </div>

    </div>
  );
}

RequisitesTab.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default RequisitesTab;
