import React, { useState, useEffect } from 'react';
import {
  Row, Button, notification, Col, Modal, Upload,
} from 'antd';
import { InboxOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { editApplicationPersons, uploadFile } from '../../../../../utils/http';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';


const { Dragger } = Upload;
function PersonPaymentStepDocs({ originalPerson, fieldName }) {
  const [person, setPerson] = useState(null);
  const [isShowUploadMultipleFileModal, setIsShowUploadMultipleFileModal] = useState(null);
  const [multipleFile, setMultipleFile] = useState([]);

  useEffect(() => {
    setPerson(originalPerson);
  }, []);

  const onMultipleFileUpload = async (originalFile) => {
    const form = new FormData();
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    form.append('file', originalFile);
    const file = await uploadFile(form, headers);
    setMultipleFile([...multipleFile, file]);
  };

  const onMultipleFileRemove = (file) => {
    setMultipleFile(multipleFile.filter((f) => f.id !== file.id));
  };

  const onSaveMultipleFileModal = () => {
    if (multipleFile.length > 0) {
      editApplicationPersons({ id: person.id, [isShowUploadMultipleFileModal]: multipleFile.map((f) => f.id).join(',') })
        .then(() => {
          setPerson({ ...person, [isShowUploadMultipleFileModal]: multipleFile });
          notification.success({ message: 'Сохранено' });
          setMultipleFile([]);
          setIsShowUploadMultipleFileModal(null);
        });
    } else {
      notification.error({ message: 'Загрузите хотя бы 1 файл' });
    }
  };

  return (
    <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }}>
      <Row justify="space-between">
        <Col>
          <span style={{ fontSize: '18px' }}>Документы</span>
        </Col>
        {getUserRole() === 'bigAdmin' && (
        <Col>
          <Button onClick={() => setIsShowUploadMultipleFileModal(fieldName)}>Загрузить</Button>
        </Col>
        )}
      </Row>
      <Col span={24} style={{ padding: 0 }}>
        {person && person[fieldName].map((f) => (
          <Row key={f.id} style={{ margin: '5px 0px', width: '100%' }} align="middle">
            <Col><Button type="primary" onClick={() => window.open(f.urlLink)} style={{ marginRight: '10px' }}>Скачать</Button></Col>
            <Col>{f.name}</Col>
          </Row>
        ))}
      </Col>
      {isShowUploadMultipleFileModal && (
      <Modal
        visible
        width="70vw"
        maskClosable={false}
        onCancel={() => { setMultipleFile([]); setIsShowUploadMultipleFileModal(null); }}
        title="Загрузка файла"
        footer={[
          <Button key="submit" type="primary" onClick={onSaveMultipleFileModal}>
            Загрузить
          </Button>]}
      >
        <Col>
          <Dragger
            showUploadList={false}
            action={null}
            multiple
            beforeUpload={(file) => {
              onMultipleFileUpload(file);
              return false;
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файлы, чтобы загрузить (не более 15мб)</p>
          </Dragger>
          {multipleFile.map((f) => (
            <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onMultipleFileRemove(f)} /></Col>
              <Col>{f.name}</Col>
            </Row>
          ))}
        </Col>
      </Modal>
      )}
    </Col>
  );
}

PersonPaymentStepDocs.propTypes = {
  originalPerson: PropTypes.shape().isRequired,
  fieldName: PropTypes.string.isRequired,
};


export default PersonPaymentStepDocs;
