/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form, Input, DatePicker, InputNumber, Checkbox,
} from 'antd';
import AuthorTabPublicationsModule from './AuthorTabPublicationsModule';
import AuthorTabEventModule from './AuthorTabEventModule';
import AuthorTabRidModule from './AuthorTabRidModule';
import AuthorTabPeopleModule from './AuthorTabPeopleModule';
import './AuthorTab.css';


const { TextArea } = Input;
function AuthorTab({
  applicationProp, disabled, updateOriginalObject, formRef,
}) {
  const [supervisorIsRussian, setSupervisorIsRussian] = useState(applicationProp.supervisorIsRussian);

  const onUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'applications2AuthorTabPeople_ids') {
      const totalNumberOfPeople = fieldValue.length + 1;
      let youngNumberOfPeople = fieldValue.filter((e) => e.birthDate && moment().diff(e.birthDate, 'years') < 39).length;
      if (applicationProp.supervisorBirthDate && moment().diff(applicationProp.supervisorBirthDate, 'years') < 39) {
        youngNumberOfPeople += 1;
      }
      const young = youngNumberOfPeople > 0 ? ((youngNumberOfPeople / totalNumberOfPeople) * 100).toFixed(0) : 0;
      formRef.current.setFieldsValue({ young });
    }
    updateOriginalObject(fieldName, fieldValue);
  };

  const onChangeHirsh = (e) => {
    let cleanedString = '';
    for (let i = 0; i < e.length; i += 1) {
      if (/[0-9,]/.test(e[i])) {
        cleanedString += e[i];
      }
    }
    formRef.current.setFieldsValue({ supervisorHirsh: cleanedString });
  };

  return (
    <div className="authorTab-wrapper">
      <div className="common-subTitle">3. Сведения о Заявителе и команде проекта</div>

      <Form.Item
        name="id"
        initialValue={applicationProp.id}
        hidden
      />

      <div className="common-field-wrapper">
        <span className="common-field-label">Перечень членов коллектива специалистов </span>
        <div className="common-table-wrapper">
          <div className="common-table-row">
            <div className="common-table-row-cell" style={{ width: '10%', borderBottom: '1px solid #d9d9d9' }}>№</div>
            <div className="common-table-row-cell" style={{ borderRight: '1px solid #d9d9d9', width: '45%', borderBottom: '1px solid #d9d9d9' }}>ФИО</div>
            <div className="common-table-row-cell" style={{ borderRight: '1px solid #d9d9d9', width: '45%', borderBottom: '1px solid #d9d9d9' }}>
              Должность
              <i style={{ display: 'block', fontSize: '12px' }}>Укажите полное наименование должности в соответствии с трудовым договором или договором ГПХ с указанием медицинского учреждения, где член коллектива специалистов осуществляет работу в указанной должности</i>
            </div>
          </div>
          {[{ name: applicationProp.supervisorFullName, position: applicationProp.supervisorPosition },
            ...applicationProp.applications2AuthorTabPeople_ids.map((e) => ({ position: e.position, name: `${e.lastName} ${e.firstName} ${e.middleName}` })),
          ].filter((el) => el.position)
            .map((e, i) => (
              <div className="common-table-row" key={i}>
                <div className="common-table-row-cell" style={{ width: '10%', borderBottom: '1px solid #d9d9d9' }}>{i + 1}</div>
                <div className="common-table-row-cell" style={{ borderRight: '1px solid #d9d9d9', width: '45%', borderBottom: '1px solid #d9d9d9' }}>{e.name}</div>
                <div className="common-table-row-cell" style={{ borderRight: '1px solid #d9d9d9', width: '45%', borderBottom: '1px solid #d9d9d9' }}>{e.position}</div>
              </div>
            ))}

        </div>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">3.1. Сведения о членстве учреждения в иностранных и российских научно-медицинских общественных объединениях, ассоциациях, союзах и федерациях (при наличии)</span>
        <Form.Item
          name="participation"
          initialValue={applicationProp.participation}
        >
          <TextArea placeholder='Введите текст. В случае отсутствия, пожалуйста, укажите слово "нет"' autoSize disabled={disabled} minrows={4} />
        </Form.Item>
      </div>

      <div className="common-subTitle">3.2. Сведения о научном руководителе Проекта</div>
      <div className="common-table-wrapper">
        <div className="common-table-row">
          <div className="common-table-row-cell">Фамилия</div>
          <div className="common-table-row-cell" style={{ borderRight: '1px solid #d9d9d9' }}>{applicationProp.supervisorFullName?.split(' ')[0] || ''}</div>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Имя</div>
          <div className="common-table-row-cell" style={{ borderRight: '1px solid #d9d9d9' }}>{applicationProp.supervisorFullName?.split(' ')[1] || ''}</div>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>Отчество</div>
          <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9', borderRight: '1px solid #d9d9d9' }}>{applicationProp.supervisorFullName?.split(' ')[2] || ''}</div>
        </div>
      </div>

      <Form.Item
        style={{ width: '100%' }}
        name="supervisorIsRussian"
        initialValue={applicationProp.supervisorIsRussian}
      >
        <Checkbox
          style={{ marginBottom: '10px' }}
          defaultChecked={supervisorIsRussian}
          onChange={(e) => {
            setSupervisorIsRussian(e.target.checked);
            formRef.current.setFieldsValue({ supervisorIsRussian: e.target.checked });
          }}
        >
          Гражданство Российской Федерации
        </Checkbox>
      </Form.Item>

      <div className="common-field-wrapper">
        <span className="common-field-label">3.2.2. Дата рождения</span>
        <Form.Item
          name="supervisorBirthDate"
          initialValue={applicationProp.supervisorBirthDate}
        >
          <DatePicker disabled={disabled} />
        </Form.Item>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">Опыт работы по специальности или направлению научно-практического проекта (лет)</span>
        <Form.Item
          name="supervisorExperience"
          initialValue={applicationProp.supervisorExperience}
        >
          <InputNumber
            disabled={disabled}
          />
        </Form.Item>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">3.2.3. Образование</span>
        <span className="common-field-hint">В данном поле можно указать информацию о высшем профессиональном образовании, а также о курсах повышения квалификации и(или) профессиональной подготовки. Информация должна содержать в себе следующее: вид образования, наименование учреждения, название специальности/направления обучения, год окончания. Например: «Высшее образование - специалитет, ФГАОУ ВО "Российский Национальный Исследовательский Медицинский Университет им. Н.И. Пирогова" Министерства здравоохранения Российской Федерации, Лечебное дело, 2022 г.»</span>
        <Form.Item
          name="supervisorEducation"
          initialValue={applicationProp.supervisorEducation}
        >
          <TextArea autoSize disabled={disabled} minrows={4} />
        </Form.Item>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">3.2.4. Ученая степень</span>
        <span className="common-field-hint">Например: кандидат биологических наук, доктор медицинских наук и т.п.</span>
        <Form.Item
          name="supervisorDegree"
          initialValue={applicationProp.supervisorDegree}
        >
          <TextArea autoSize disabled={disabled} />
        </Form.Item>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">3.2.5. Ученое звание</span>
        <span className="common-field-hint">Например: доцент, профессор, член-корреспондент РАН, академик РАН и т.д.</span>
        <Form.Item
          name="supervisorTitle"
          initialValue={applicationProp.supervisorTitle}
        >
          <TextArea autoSize disabled={disabled} />
        </Form.Item>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">3.2.6. Профессиональные достижения</span>
        <span className="common-field-hint">
          Например: внедрил инновационные программы лечебной гимнастики по развитию мелкой моторики у детей дошкольного возраста; получил высшую квалификационную категорию
          по специальности челюстно-лицевая хирургия; прошёл сертификационный цикл
          по клинической кардиологии и т.д.
        </span>
        <Form.Item
          name="supervisorAchievements"
          initialValue={applicationProp.supervisorAchievements}
        >
          <TextArea autoSize disabled={disabled} minrows={4} />
        </Form.Item>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">
          3.2.7. Ключевые научные публикации руководителя по теме Проекта за последние 5 лет
          {' '}
          <b>(максимум - 10 публикаций)</b>
          :
        </span>
        {/* <Form.Item
          name="supervisorPublicationsText"
          initialValue={applicationProp.supervisorPublicationsText}
        >
          <TextArea autoSize disabled={disabled} minrows={4} />
        </Form.Item> */}
      </div>
      <AuthorTabPublicationsModule
        elementsArrayProp={applicationProp.applications2AuthorTabPublications_ids}
        applicationId={applicationProp.id}
        disabled={disabled}
        updateOriginalObject={onUpdate}
      />

      <div className="common-field-wrapper">
        <span className="common-field-label">3.2.8. Индекс Хирша</span>
        <span className="common-field-hint">наукометрический показатель, который дает комплексную оценку одновременно числу публикаций ученого и их цитируемости. Значение Индекса Хирша нужно найти в системе размещения научных публикаций (по РИНЦ)</span>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Form.Item
            name="supervisorHirsh"
            initialValue={applicationProp.supervisorHirsh}
          >
            <Input
              onChange={(e) => onChangeHirsh(e.target.value)}
              disabled={disabled}
            />
          </Form.Item>
          <div style={{ marginLeft: '5px' }}>по РИНЦ</div>
        </div>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">3.2.9. Сведения о членстве руководителя Проекта в иностранных и российских научно-медицинских общественных объединениях, ассоциациях, союзах и федерациях (при наличии)</span>
        <Form.Item
          name="supervisorParticipation"
          initialValue={applicationProp.supervisorParticipation}
        >
          <TextArea placeholder='Введите текст. В случае отсутствия, пожалуйста, укажите слово "нет"' autoSize disabled={disabled} minrows={4} />
        </Form.Item>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">
          3.2.10. Доклады руководителя по теме Проекта на международных научно-практических конференциях  за последние 5 лет
          {' '}
          <b>(максимум - 10 мероприятий)</b>
          :
        </span>
      </div>

      <AuthorTabEventModule
        elementsArrayProp={applicationProp.applications2AuthorTabEvent_ids}
        applicationId={applicationProp.id}
        disabled={disabled}
        updateOriginalObject={onUpdate}
      />

      <div className="common-field-wrapper">
        <span className="common-field-label">
          3.2.11. Наличие патентов и (или) заявок на получение патента на изобретение
          {' '}
          <b>(максимум - 10 патентов)</b>
          :
        </span>
      </div>
      <AuthorTabRidModule
        elementsArrayProp={applicationProp.applications2AuthorTabRid_ids}
        applicationId={applicationProp.id}
        disabled={disabled}
        updateOriginalObject={onUpdate}
      />

      <div className="common-subTitle">3.3. Сведения о ключевых членах команды (от 3 до 5)</div>
      <AuthorTabPeopleModule
        elementsArrayProp={applicationProp.applications2AuthorTabPeople_ids}
        applicationId={applicationProp.id}
        disabled={disabled}
        updateOriginalObject={onUpdate}
      />

    </div>
  );
}

AuthorTab.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  updateOriginalObject: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
};

export default AuthorTab;
