import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Input, Form, Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';


class PersonStep extends Component {
  render() {
    const {
      isEdit, person, disabled, fieldNameCost, fieldNameDescription, stepName,
    } = this.props;
    return (
      <Col span={24}>
        <span style={{ color: '#212121', fontSize: '0.9rem' }}>{stepName}</span>
        <Row gutter={40} style={{ marginTop: '5px' }} align="bottom">
          <Col span={5} style={{ fontSize: '1.5vh' }}>
            Стоимость работ по этапу:
            <Tooltip title="Введите точную сумму, подлежащую перечислению указанному соисполнителю на указанный этап реализации проекта. Работы могут быть выполнены на безвозмездной основе. В таком случае в сумму средств необходимо проставить сумму, равную 0 рублей 0 копеек.">
              <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#1890FF' }} />
            </Tooltip>
          </Col>
          <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
            {isEdit
              ? (
                <Form.Item
                  name={fieldNameCost}
                  initialValue={person[fieldNameCost]}
                >
                  <Input disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              )
              : <span>{person[fieldNameCost] ? person[fieldNameCost] : 'Не заполнено'}</span>}
          </Col>
        </Row>
        <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="top">
          <Col span={5} style={{ fontSize: '1.5vh' }}>
            Краткое содержание выполняемых работ:
            <Tooltip title="Кратко опишите работы, планируемые к выполнению соисполнителем на данном этапе. Например: «Проведение генетического и протеомного анализа проб с целью выявления мутаций в генах системы комплемента на базе собственной лаборатории»">
              <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#1890FF' }} />
            </Tooltip>
          </Col>
          <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
            {isEdit
              ? (
                <Form.Item
                  name={fieldNameDescription}
                  initialValue={person[fieldNameDescription]}
                >
                  <Input disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              )
              : <span>{person[fieldNameDescription] || 'Не заполнено'}</span>}
          </Col>
        </Row>
      </Col>
    );
  }
}

PersonStep.propTypes = {
  stepName: PropTypes.string.isRequired,
  fieldNameDescription: PropTypes.string.isRequired,
  fieldNameCost: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  person: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
};


export default PersonStep;
