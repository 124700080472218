export const routes = {
  home: '/',
  login: '/login',
  homePage: '/homePage',
  application: '/application/:id',
  application2: '/application2/:id',
  project: '/project/:id',
  settings: '/settings',
  settingsPersonal: '/settings/personal',
  settingsGeneral: '/settings/general',
  registration: '/registartion',
  profile: '/profile',
};

export const routeGenerator = {
  applicationLink: (id) => `${routes.application.replace(':id', id)}`,
  projectLink: (id) => `${routes.project.replace(':id', id)}`,
  application2Link: (id) => `${routes.application2.replace(':id', id)}`,
};
