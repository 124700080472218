/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-cond-assign */
/* eslint-disable no-nested-ternary */
import prepareSmetaData from './prepareSmetaData';


const ExcelJS = require('exceljs');


const createTotalSheet = (data, worksheet) => {
  // Заполняем шапку
  data.header.forEach((header) => {
    if (header.cellsToMerge) {
      worksheet.mergeCells(header.cellsToMerge);
    }
    if (header.columnNumber) {
      worksheet.getColumn(header.columnNumber).font = { name: 'Montserrat' };
      worksheet.getColumn(header.columnNumber).width = header.width;
    }
    const cell = worksheet.getCell(header.cell);
    cell.value = header.name;
    cell.alignment = { vertical: 'middle' };
  });

  // Заполянем строчки
  let skipRows = 2;
  data.data.forEach((row) => {
    const rowXls = worksheet.getRow(skipRows + 1);
    data.header.forEach((header) => {
      if (header.field !== 'finance') {
        const fieldXls = rowXls.getCell(header.columnNumber);
        fieldXls.value = row[row.key + header.field];
      }
    });
    skipRows += 1;
  });
};

const createXLSDocument = async (data) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Admin';
  workbook.lastModifiedBy = 'Администратор';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const worksheet = workbook.addWorksheet('Смета');
  createTotalSheet(data, worksheet);

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};

const arrayBufferToBase64 = (Arraybuffer) => {
  let binary = '';
  const bytes = new Uint8Array(Arraybuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = window.btoa(binary);
  const linkSource = `data:application/xls;base64,${base64String}`;
  const downloadLink = document.createElement('a');
  const fileName = 'Отчет.xls';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const exportSmetaXLS = async (rawData, stepsNumber, projectYears) => {
  const buffer = await createXLSDocument(prepareSmetaData(rawData, stepsNumber, projectYears));
  arrayBufferToBase64(buffer);
};


export default exportSmetaXLS;
