/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Button, Table, Input, Row, Modal, Form, InputNumber, notification, Upload, Select, Checkbox, Spin,
} from 'antd';
import {
  UploadOutlined, DownloadOutlined, DeleteOutlined, LoadingOutlined,
} from '@ant-design/icons';
import {
  uploadFile, editApplicationStepReport, editApplicationSalary,
} from '../../../../../../../utils/http';
import getSalaryPaymentsColumns from './getSalaryPaymentsColumns';
import getNewSalaryPaymentsColumns from './getNewSalaryPaymentsColumns';
import calculateTotal from '../../../../../../../utils/calculateTotal';


const { Option } = Select;
class SalaryPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowAddModal: false,
      objectInEdit: null,
      dataSource: this.props.dataSource,
      loading: false,
      financialIsSalaryDone: this.props.financialIsSalaryDone,
      files: {
        financialSalaryPPFiles: this.props.financialSalaryPPFiles,
        financialSalaryFiles: this.props.financialSalaryFiles,
      },
    };
    this.formRef = React.createRef();
  }

  onAddClick = () => {
    this.setState({ isShowAddModal: true });
  }

  onEditClick = (objectInEdit) => {
    this.setState({ objectInEdit, isShowAddModal: true });
  }

  onCloseModal = () => {
    this.setState({ objectInEdit: null, isShowAddModal: false });
  }

  onDeleteClick = (object) => {
    this.setState({ loading: true });
    editApplicationSalary({ id: object.id, isDeleted: true })
      .then(() => this.setState((prevState) => ({
        loading: false,
        dataSource: prevState.dataSource.filter((d) => d.id !== object.id),
      })));
  }

  onRemoveFile = (file, fieldName) => {
    const { applicationId } = this.props;
    const { files } = this.state;
    const filteredFiles = files[fieldName].filter((f) => f.id !== file.id);
    editApplicationStepReport({ id: applicationId, [fieldName]: filteredFiles.map((f) => f.id).join(',') })
      .then(() => {
        this.setState({ files: { ...files, [fieldName]: filteredFiles } });
        notification.success({ message: 'Сохранено!' });
      });
  }

  onUploadFile = (info, fieldName) => {
    if (info.file.status === 'uploading' && !info.event) {
      if (info.file.type !== 'application/pdf') {
        notification.error({ message: 'Ошибка! Загрузите, пожалуйста, файл в формате PDF' });
        return;
      }
      this.setState({ loading: true });
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          const { applicationId } = this.props;
          const { files } = this.state;
          editApplicationStepReport({ id: applicationId, [fieldName]: [...files[fieldName].map((f) => f.id), file.id].join(',') })
            .then(() => {
              this.setState({ files: { ...files, [fieldName]: [...files[fieldName], file] }, loading: false });
              notification.success({ message: 'Сохранено!' });
            });
        });
    }
  }

  onSaveClick = () => {
    this.setState({ loading: true });
    this.formRef.current.validateFields()
      .then((values) => {
        const { applicationId, isEnpSalaryForm } = this.props;
        const finalObject = { ...values };
        const sumArray = [
          { major: values.totalPaidMajor, minor: values.totalPaidMinor },
          { major: values.pfrMajor, minor: values.pfrMinor },
          { major: values.fomsMajor, minor: values.fomsMinor },
          { major: values.fssMajor, minor: values.fssMinor },
          { major: values.fssnsMajor, minor: values.fssnsMinor },
          { major: values.enpMajor, minor: values.enpMinor },
        ];
        const totalPerson = calculateTotal(sumArray);

        finalObject.totalPersonMajor = totalPerson.major;
        finalObject.totalPersonMinor = totalPerson.minor;
        finalObject.applicationStepReport_id = applicationId;

        Promise.all([editApplicationSalary(finalObject), editApplicationStepReport({ id: applicationId, isEnpSalaryForm })])
          .then((res) => {
            this.setState((prevState) => ({
              loading: false,
              dataSource: finalObject.id
                ? prevState.dataSource.map((d) => (d.id === finalObject.id ? finalObject : d))
                : [...prevState.dataSource, { ...finalObject, id: res[0].id }],
            }));
            this.onCloseModal();
            notification.success({ message: 'Сохранено!' });
          });
      })
      .catch(() => {
        this.setState({ loading: false });
        notification.error({ message: 'Не сохранено! Заполните все обязательные поля!' });
      });
  }

  getTotalRow = (dataSource) => {
    const fields = ['totalPaid', 'ndfl', 'pfr', 'foms', 'fss', 'fssns', 'totalPerson', 'actualPaid', 'remain', 'enp'];

    const obj = {
      id: 'isTotal',
      isTotal: true,
      fio: 'ОБЩИЙ ИТОГ',
    };

    fields.forEach((f) => {
      const sumArray = dataSource.map((c) => ({ major: c[`${f}Major`], minor: c[`${f}Minor`] }));
      const total = calculateTotal(sumArray);

      obj[`${f}Major`] = total.major;
      obj[`${f}Minor`] = total.minor;
    });

    return obj;
  }

  onCheckboxChange = (e) => {
    this.setState({ loading: true });
    const { applicationId } = this.props;
    editApplicationStepReport({ id: applicationId, financialIsSalaryDone: e.target.checked })
      .then(() => {
        this.setState({ financialIsSalaryDone: e.target.checked, loading: false });
        notification.success({ message: 'Сохранено!' });
      });
  }

  render() {
    const {
      dataSource, isShowAddModal, objectInEdit, loading, financialIsSalaryDone, files,
    } = this.state;
    const { isFormDisabled, isEnpSalaryForm } = this.props;
    return (
      <Col>
        {loading && (
        <Spin
          style={{ position: 'fixed', fontSize: '60px', top: '30%' }}
          indicator={(<LoadingOutlined style={{ fontSize: '100px', margin: '-80px 0px 0px -60px' }} spin />)}
          tip="Загрузка..."
        >
          <div />
        </Spin>
        )}
        <Table
          dataSource={[...dataSource, { id: 'add', isAdd: true }, this.getTotalRow(dataSource)]}
          columns={!isEnpSalaryForm
            ? getSalaryPaymentsColumns(this.onEditClick, this.onDeleteClick, this.onAddClick, financialIsSalaryDone || isFormDisabled || loading)
            : getNewSalaryPaymentsColumns(this.onEditClick, this.onDeleteClick, this.onAddClick, financialIsSalaryDone || isFormDisabled || loading)}
          pagination={false}
          rowKey="id"
          bordered
        />
        <Checkbox defaultChecked={financialIsSalaryDone} disabled={isFormDisabled || loading} style={{ marginTop: '5px', color: '#1890ff' }} onChange={this.onCheckboxChange}>Оплата труда членов коллектива специалистов не проводилась в отчетном периоде</Checkbox>
        <Col style={{ marginTop: '20px' }}>
          <Row>
            <span style={{ color: '#212121' }}>
              Прикрепите пожалуйста скан-копию в формате pdf внутреннего приказа о создании коллектива специалистов в рамках реализации проекта с указанием ФИО сотрудников и их роли
            </span>
          </Row>
          <Row>
            <Upload
              showUploadList={false}
              onChange={(f) => this.onUploadFile(f, 'financialSalaryFiles')}
              disabled={loading || isFormDisabled}
              multiple
              action={null}
              style={{ width: '100%' }}
            >
              <Button disabled={loading || isFormDisabled} type="primary" icon={<UploadOutlined />}>Выберите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</Button>
            </Upload>
          </Row>
          {files.financialSalaryFiles.map((file) => (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(file.urlLink)} /></Col>
              {!loading && !isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveFile(file, 'financialSalaryFiles')} /></Col>}
              <Col>{file.name}</Col>
            </Row>
          ))}
        </Col>
        <Col style={{ marginTop: '20px' }}>
          <Row>
            <span style={{ color: '#212121' }}>
              Прикрепите пожалуйста скан-копии в формате pdf платежных поручений по выплате заработной платы
            </span>
          </Row>
          <Row>
            <Upload
              showUploadList={false}
              onChange={(f) => this.onUploadFile(f, 'financialSalaryPPFiles')}
              disabled={loading || isFormDisabled}
              multiple
              action={null}
              style={{ width: '100%' }}
            >
              <Button type="primary" disabled={loading || isFormDisabled} icon={<UploadOutlined />}>Выберите файлы, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</Button>
            </Upload>
          </Row>
          {files.financialSalaryPPFiles.map((filePP) => (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(filePP.urlLink)} /></Col>
              {!loading && !isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveFile(filePP, 'financialSalaryPPFiles')} /></Col>}
              <Col>{filePP.name}</Col>
            </Row>
          ))}
        </Col>
        {isShowAddModal && (
        <Modal
          title="Оплата труда членов коллектива специалистов в отчетном периоде"
          visible
          footer={[<Button type="primary" onClick={this.onSaveClick}>Сохранить</Button>]}
          onCancel={this.onCloseModal}
        >
          <Form
            initialValues={{ remember: true }}
            ref={this.formRef}
          >
            <Col>
              <Form.Item
                name="id"
                hidden
                initialValue={objectInEdit && objectInEdit.id}
              />
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Член коллектива специалистов (ФИО):</span>
                <Form.Item
                  name="fio"
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                  initialValue={objectInEdit && objectInEdit.fio}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Роль в проекте:</span>
                <Form.Item
                  name="role"
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                  initialValue={objectInEdit && objectInEdit.role}
                >
                  <Select>{['Руководитель', 'Исполнитель'].map((u) => <Option key={u} value={u}>{u}</Option>)}</Select>
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Профессия члена коллектива специалистов:</span>
                <Form.Item
                  name="position"
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                  initialValue={objectInEdit && objectInEdit.position}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Всего начислено:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="totalPaidMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.totalPaidMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="totalPaidMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.totalPaidMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">в т.ч. НДФЛ:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="ndflMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.ndflMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="ndflMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.ndflMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              {!isEnpSalaryForm && (
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">ПФР:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="pfrMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.pfrMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="pfrMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.pfrMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              )}
              {!isEnpSalaryForm && (
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">ФОМС:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="fomsMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.fomsMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="fomsMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.fomsMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              )}
              {!isEnpSalaryForm && (
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">ФСС:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="fssMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.fssMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="fssMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.fssMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              )}
              {!isEnpSalaryForm && (
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">ФСС НС:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="fssnsMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.fssnsMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="fssnsMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.fssnsMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              )}
              {isEnpSalaryForm && (
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">ЕНП:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="enpMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.enpMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="enpMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.enpMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              )}
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Фактически выплачено (кассовый  расход):</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="actualPaidMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.actualPaidMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="actualPaidMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.actualPaidMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Остаток к уплате:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="remainMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.remainMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="remainMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.remainMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
            </Col>
          </Form>
        </Modal>
        )}
      </Col>
    );
  }
}

SalaryPayments.propTypes = {
  financialIsSalaryDone: PropTypes.bool.isRequired,
  applicationId: PropTypes.string.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  financialSalaryFiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  financialSalaryPPFiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isEnpSalaryForm: PropTypes.bool.isRequired,
};


export default SalaryPayments;
