import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import {
  getApplicationByID, getSpecializationCatalogue, getScientificDirectionCatalogue, editApplicationStepReport, editApplication,
} from '../../utils/http';
import './Application.less';
import AppDZMMain from './AppDZMMain/AppDZMMain';
import AppDZMPerson from './AppDZMPerson/AppDZMPerson';
import AppNDZM from './AppNDZM/AppNDZM';
import applicationsWithSurvey from './utils/applicationsWithSurvey';


function Application({ history }) {
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState(null);
  const [specializationCatalogue, setSpecializationCatalogue] = useState([]);
  const [scientificDirectionCatalogue, setScientificDirectionCatalogue] = useState([]);
  const [isFinancialReport, setIsFinancialReport] = useState(false);
  const [isAnnotationReport, setIsAnnotationReport] = useState(false);
  const [stepNumber, setStepNumber] = useState(null);
  const [isShowSurvey, setIsShowSurvey] = useState(false);


  useEffect(() => {
    const isSurvey = get(history, 'location.search', '') === '?isShowSurvey=true';
    const id = history.location.pathname.split('/')[2];
    const isFinancial = get(history, 'location.state.isFinancialReport', false);
    const isAnnotation = get(history, 'location.state.isAnnotationReport', false);
    const step = get(history, 'location.state.stepNumber', null);

    Promise.all([getApplicationByID(id), getSpecializationCatalogue(), getScientificDirectionCatalogue()])
      .then((resp) => {
        setLoading(false);
        setIsFinancialReport(isFinancial);
        setIsAnnotationReport(isAnnotation);
        setStepNumber(step);
        setApplication(resp[0]);
        setSpecializationCatalogue(resp[1]);
        setScientificDirectionCatalogue(resp[2]);
        setIsShowSurvey(isSurvey);
      });
  }, []);

  const updateParentApplication = (newValues) => {
    setApplication({ ...application, ...newValues });
  };

  const editStepDate = (obj) => {
    editApplicationStepReport(obj);
  };

  const onAddStep = () => {
    const newStepNumbers = Number((Number(application.maxStepsNumber) + 1).toFixed(0));
    const prevStepId = application.applicationStepReports.find((e) => Number(e.stepNumber) === Number(application.maxStepsNumber))?.id;
    editApplicationStepReport({ application_id: application.id, stepNumber: newStepNumbers, prevStepApplication_id: prevStepId })
      .then((stepId) => {
        editApplication({
          id: application.id,
          maxStepsNumber: newStepNumbers,
          applicationStepReport_ids: [...application.applicationStepReports.map((e) => e.id), stepId].join(','),
        })
          .then(() => {
            setApplication({
              ...application,
              maxStepsNumber: newStepNumbers,
              applicationStepReports: [...application.applicationStepReports, { id: stepId, stepNumber: newStepNumbers, endDate: null }],
            });
          });
      });
  };

  if (loading) {
    return (
      <div className="application-wrapper">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 100, zIndex: 100 }} spin />} style={{ position: 'absolute', top: '40%', left: '40%' }} />
      </div>
    );
  }

  const isUserRole = () => {
    if (typeof application.role === 'string') {
      return application.role === 'user';
    }

    if (Array.isArray(application.role)) {
      return application.role.includes('user');
    }

    return false;
  };

  return (
    <div className="application-wrapper">
      {application && isUserRole() && application.isPersonApplication && (
      <AppDZMPerson
        application={application}
        isShowSurvey={isShowSurvey}
        isShowSurveyTab={applicationsWithSurvey.includes(application.id)}
        stepNumber={stepNumber}
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        updateParentApplication={updateParentApplication}
        editStepDate={editStepDate}
        onAddStep={onAddStep}
      />
      )}
      {application && isUserRole() && !application.isPersonApplication && (
      <AppDZMMain
        application={application}
        specializationCatalogue={specializationCatalogue}
        scientificDirectionCatalogue={scientificDirectionCatalogue}
        stepNumber={stepNumber}
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        isShowSurvey={isShowSurvey}
        isShowSurveyTab={applicationsWithSurvey.includes(application.id)}
        updateParentApplication={updateParentApplication}
        editStepDate={editStepDate}
        onAddStep={onAddStep}
      />
      )}
      {application && !isUserRole() && (
      <AppNDZM
        application={application}
        isShowSurvey={isShowSurvey}
        isShowSurveyTab={applicationsWithSurvey.includes(application.id)}
        updateParentApplication={updateParentApplication}
        editStepDate={editStepDate}
        onAddStep={onAddStep}
      />
      )}
    </div>
  );
}

Application.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(Application);
