/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined, EyeOutlined,
} from '@ant-design/icons';
import {
  Col, Row, Button, Input, Form, notification, Modal, Upload, Collapse, Popconfirm,
} from 'antd';
import { editApplication, uploadFile, editApplicationsFiles } from '../../../../../utils/http';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';
import getUserId from '../../../../../utils/localeStorage/getUserId';
import fileDownload from '../../../../../utils/fileDownload';
import fileOpenNewTab from '../../../../../utils/fileOpenNewTab';
import './Information.css';


const { Dragger } = Upload;
const { Panel } = Collapse;
function Information({ applicationProp }) {
  const [application, setApplication] = useState(null);
  const formRef = useRef(null);
  const [isShowUploadSingleFileModal, setIsShowUploadSingleFileModal] = useState(null);
  const [singleFile, setSingleFile] = useState(null);
  const [isShowUploadMultipleFileModal, setIsShowUploadMultipleFileModal] = useState(null);
  const [multipleFile, setMultipleFile] = useState([]);
  const [isShowNeDZMAdditionalFileModal, setIsShowNeDZMAdditionalFileModal] = useState(null);
  const [isShowAdditionalFileModal, setIsShowAdditionalFileModal] = useState(null);

  useEffect(() => {
    setApplication(applicationProp);
  }, []);

  const onSave = () => {
    formRef.current.validateFields()
      .then((values) => {
        editApplication({
          ...values,
          id: application.id,
        })
          .then(() => {
            setApplication({ ...application, ...values });
            notification.success({ message: 'Сохранено' });
          });
      })
      .catch(() => {
        notification.error({ message: 'Ошибка!' });
      });
  };

  const onSingleFileUpload = (info, field) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          if (isShowNeDZMAdditionalFileModal) {
            setIsShowNeDZMAdditionalFileModal({ ...isShowNeDZMAdditionalFileModal, [field]: file });
          } if (isShowAdditionalFileModal) {
            setIsShowAdditionalFileModal({ ...isShowAdditionalFileModal, [field]: file });
          } else {
            setSingleFile(file);
          }
        });
    }
  };

  const onSingleFileRemove = (field) => {
    if (isShowNeDZMAdditionalFileModal) {
      setIsShowNeDZMAdditionalFileModal({ ...isShowNeDZMAdditionalFileModal, [field]: null });
    } if (isShowAdditionalFileModal) {
      setIsShowAdditionalFileModal({ ...isShowAdditionalFileModal, [field]: null });
    } else {
      setSingleFile(null);
    }
  };

  const onSaveSingleFileModal = () => {
    editApplication({ id: application.id, [isShowUploadSingleFileModal]: singleFile.id })
      .then(() => {
        setApplication({ ...application, [isShowUploadSingleFileModal]: singleFile });
        notification.success({ message: 'Сохранено' });
        setSingleFile(null);
        setIsShowUploadSingleFileModal(null);
      });
  };


  const onMultipleFileUpload = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          setMultipleFile([...multipleFile, file]);
        });
    }
  };

  const onMultipleFileRemove = (file) => {
    setMultipleFile(multipleFile.filter((f) => f.id !== file.id));
  };

  const onSaveMultipleFileModal = () => {
    if (multipleFile.length > 0) {
      editApplication({ id: application.id, [isShowUploadMultipleFileModal]: multipleFile.map((f) => f.id).join(',') })
        .then(() => {
          setApplication({ ...application, [isShowUploadMultipleFileModal]: multipleFile });
          notification.success({ message: 'Сохранено' });
          setMultipleFile([]);
          setIsShowUploadMultipleFileModal(null);
        });
    } else {
      notification.error({ message: 'Загрузите хотя бы 1 файл' });
    }
  };


  const onSaveAdditionalFiles = () => {
    const { isNew } = isShowAdditionalFileModal;
    const objToSave = {
      id: isShowAdditionalFileModal.id,
      changeDate: moment().toISOString(),
      changeUser: getUserId(),
      file_id: isShowAdditionalFileModal?.file_id?.id || null,
      applications_id: application.id,
      field: 'applicationsFiles_ids',
    };
    editApplicationsFiles(objToSave).then(() => {
      notification.success({ message: 'Сохранено' });
      setApplication({
        ...application,
        applicationsFiles_ids: isNew
          ? [...application.applicationsFiles_ids, objToSave]
          : application.applicationsFiles_ids.map((e) => (e.id === objToSave.id ? objToSave : e)),
      });
      setIsShowNeDZMAdditionalFileModal(null);
    });
  };

  const onDeleteAdditionalFiles = () => {
    if (isShowAdditionalFileModal.isNew) {
      notification.success({ message: 'Сохранено' });
      setIsShowAdditionalFileModal(null);
    } else {
      editApplicationsFiles({
        id: isShowAdditionalFileModal.id, isDeleted: true, changeDate: moment().toISOString(), changeUser: getUserId(),
      }).then(() => {
        notification.success({ message: 'Сохранено' });
        setApplication({
          ...application,
          applicationsFiles_ids: application.applicationsFiles_ids
            .filter((f) => f.id !== isShowAdditionalFileModal.id),
        });
        setIsShowAdditionalFileModal(null);
      });
    }
  };

  const onSaveNeDZMAdditionalFiles = () => {
    const { isNew } = isShowNeDZMAdditionalFileModal;
    const objToSave = {
      id: isShowNeDZMAdditionalFileModal.id,
      changeDate: moment().toISOString(),
      changeUser: getUserId(),
      letterFile_id: isShowNeDZMAdditionalFileModal?.letterFile_id?.id || null,
      wordFile_id: isShowNeDZMAdditionalFileModal?.wordFile_id?.id || null,
      pdfFile_id: isShowNeDZMAdditionalFileModal?.pdfFile_id?.id || null,
      applications_id: application.id,
      field: 'neDZMAdditionalFilesApplications_ids',
    };
    editApplicationsFiles(objToSave).then(() => {
      notification.success({ message: 'Сохранено' });
      setApplication({
        ...application,
        neDZMAdditionalFilesApplications_ids: isNew
          ? [...application.neDZMAdditionalFilesApplications_ids, objToSave]
          : application.neDZMAdditionalFilesApplications_ids.map((e) => (e.id === objToSave.id ? objToSave : e)),
      });
      setIsShowNeDZMAdditionalFileModal(null);
    });
  };

  const onDeleteNeDZMAdditionalFiles = () => {
    if (isShowNeDZMAdditionalFileModal.isNew) {
      notification.success({ message: 'Сохранено' });
      setIsShowNeDZMAdditionalFileModal(null);
    } else {
      editApplicationsFiles({
        id: isShowNeDZMAdditionalFileModal.id, isDeleted: true, changeDate: moment().toISOString(), changeUser: getUserId(),
      }).then(() => {
        notification.success({ message: 'Сохранено' });
        setApplication({
          ...application,
          neDZMAdditionalFilesApplications_ids: application.neDZMAdditionalFilesApplications_ids
            .filter((f) => f.id !== isShowNeDZMAdditionalFileModal.id),
        });
        setIsShowNeDZMAdditionalFileModal(null);
      });
    }
  };

  return (
    <Form style={{ width: '100%' }} className="applicationReportView-information-wrapper" ref={formRef}>
      {application && (
      <Row gutter={80}>
        <Col span={16}>
          <div className="application-main-box-wrapper">
            <div className="application-main-box-mask" />
            <div className="application-main-box-content-wrapper" style={{ width: '100%' }}>
              <span className="application-main-box-label">Тема</span>
              <Form.Item
                name="theme"
                initialValue={application.theme}
              >
                <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
              </Form.Item>
            </div>
            <div className="application-main-box-content-wrapper" style={{ marginBottom: '-30px' }}>
              <div className="application-main-label">Сведения о научном руководителе проекта</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Фамилия</span>
                <Form.Item
                  name="lastName"
                  initialValue={application.lastName}
                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Имя</span>
                <Form.Item
                  name="firstName"
                  initialValue={application.firstName}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Отчество</span>
                <Form.Item
                  name="middleName"
                  initialValue={application.middleName}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Должность</span>
                <Form.Item
                  name="position"
                  initialValue={application.position}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Ученая степень</span>
                <Form.Item
                  name="degree"
                  initialValue={application.degree}
                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Ученое звание</span>
                <Form.Item
                  name="title"
                  initialValue={application.title}
                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Телефон</span>
                <Form.Item
                  name="phone"
                  initialValue={application.phone}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">E-mail</span>
                <Form.Item
                  name="email"
                  initialValue={application.email}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }} />
            </div>

            <div className="application-main-box-content-wrapper" style={{ marginBottom: '-30px', marginTop: '20px' }}>
              <div className="application-main-label">Ответственный специалист для контактов по заявке со стороны основного грантополучателя</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Фамилия</span>
                <Form.Item
                  name="specialistLastName"
                  initialValue={application.specialistLastName}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Имя</span>
                <Form.Item
                  name="specialistFirstName"
                  initialValue={application.specialistFirstName}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Отчество</span>
                <Form.Item
                  name="specialistMiddleName"
                  initialValue={application.specialistMiddleName}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Должность</span>
                <Form.Item
                  name="specialistPosition"
                  initialValue={application.specialistPosition}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Телефон</span>
                <Form.Item
                  name="specialistPhone"
                  initialValue={application.specialistPhone}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">E-mail</span>
                <Form.Item
                  name="specialistEmail"
                  initialValue={application.specialistEmail}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>

            <div className="application-main-box-content-wrapper" style={{ marginBottom: '-30px', marginTop: '20px' }}>
              <div className="application-main-label">Ответственный специалист для контактов по заявке со стороны соисполнителя</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Фамилия</span>
                <Form.Item
                  name="specialistLastNamePerson"
                  initialValue={application.specialistLastNamePerson}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Имя</span>
                <Form.Item
                  name="specialistFirstNamePerson"
                  initialValue={application.specialistFirstNamePerson}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Отчество</span>
                <Form.Item
                  name="specialistMiddleNamePerson"
                  initialValue={application.specialistMiddleNamePerson}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Должность</span>
                <Form.Item
                  name="specialistPositionPerson"
                  initialValue={application.specialistPositionPerson}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">Телефон</span>
                <Form.Item
                  name="specialistPhonePerson"
                  initialValue={application.specialistPhonePerson}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '30%' }}>
                <span className="application-main-box-label">E-mail</span>
                <Form.Item
                  name="specialistEmailPerson"
                  initialValue={application.specialistEmailPerson}

                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="application-main-box-wrapper" style={{ paddingBottom: '20px' }}>
            <div className="application-main-box-mask" style={{ background: '#fff' }} />
            {getUserRole() === 'bigAdmin' && (
            <div className="application-main-box-content-wrapper" style={{ marginBottom: '-30px' }}>
              <div className="application-main-label">Действия</div>
            </div>
            )}
            {getUserRole() === 'bigAdmin' && (
            <div className="application-main-box-content-wrapper">
              <Button type="primary" onClick={onSave}>Сохранить</Button>
            </div>
            )}

            <Col span={24} style={{ padding: '10px 20px' }}>
              <Row justify="space-between">
                <Col>
                  <span style={{ fontSize: '18px' }}>Договор на НИР</span>
                </Col>
                {getUserRole() === 'bigAdmin' && (
                <Col>
                  <Button onClick={() => setIsShowUploadSingleFileModal('nirFile_id')}>Загрузить</Button>
                </Col>
                )}
              </Row>
              {application.nirFile_id?.urlLink && (
              <Row style={{ margin: '5px 0px', width: '100%' }} align="middle">
                <Col><Button type="primary" onClick={() => fileDownload(application.nirFile_id.urlLink, application.nirFile_id.name)} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                <Col><Button type="primary" onClick={() => fileOpenNewTab(application.nirFile_id.urlLink)} style={{ marginRight: '10px' }}>Открыть</Button></Col>
                <Col>{application.nirFile_id.name}</Col>
              </Row>
              )}
            </Col>

            <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }}>
              <Row justify="space-between">
                <Col>
                  <span style={{ fontSize: '18px' }}>Дополнительные соглашения</span>
                </Col>
                {getUserRole() === 'bigAdmin' && (
                  <Col>
                    <Button onClick={() => setIsShowNeDZMAdditionalFileModal({ id: uuidv4(), isNew: true })}>
                      Добавить
                    </Button>
                  </Col>
                )}
              </Row>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                {application.neDZMAdditionalFilesApplications_ids.map((f, i) => (
                  <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '5px',
                  }}
                  >
                    <div style={{ marginRight: '10px' }}>{f?.pdfFile_id?.name || `Доп.соглашение ${i + 1}`}</div>
                    <div style={{
                      display: 'flex', flexDirection: 'row', alignItems: 'center',
                    }}
                    >
                      <Button type="primary" icon={<DownloadOutlined />} onClick={() => fileDownload(f?.pdfFile_id?.urlLink, f?.pdfFile_id?.name)} />
                      <Button type="primary" style={{ margin: '0px 10px' }} icon={<EyeOutlined />} onClick={() => fileOpenNewTab(f?.pdfFile_id?.urlLink)} />
                      <Button type="primary" onClick={() => setIsShowNeDZMAdditionalFileModal(f)}>Доп. файлы</Button>
                    </div>
                  </div>
                ))}
              </div>
            </Col>

            <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }}>
              <Row justify="space-between">
                <Col>
                  <span style={{ fontSize: '18px' }}>Дополнительные файлы</span>
                </Col>
                {getUserRole() === 'bigAdmin' && (
                  <Col>
                    <Button onClick={() => setIsShowAdditionalFileModal({ id: uuidv4(), isNew: true })}>
                      Добавить
                    </Button>
                  </Col>
                )}
              </Row>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                {application.applicationsFiles_ids.map((f, i) => (
                  <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '5px', justifyContent: 'space-between',
                  }}
                  >
                    <div style={{ marginRight: '10px' }}>{f?.file_id?.name || `Доп.файл ${i + 1}`}</div>
                    <div style={{
                      display: 'flex', flexDirection: 'row', alignItems: 'center',
                    }}
                    >
                      <Button type="primary" icon={<DownloadOutlined />} onClick={() => fileDownload(f?.file_id?.urlLink, f?.file_id?.name)} />
                      <Button type="primary" style={{ margin: '0px 10px' }} icon={<EyeOutlined />} onClick={() => fileOpenNewTab(f?.file_id?.urlLink)} />
                      <Button type="primary" onClick={() => setIsShowAdditionalFileModal(f)}>Открыть</Button>
                    </div>
                  </div>
                ))}
              </div>
            </Col>

            <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }}>
              <Row justify="space-between">
                <Col>
                  <span style={{ fontSize: '18px' }}>Сведения о денежных средствах грантополучателя</span>
                </Col>
              </Row>
              <div className="application-main-box-content-wrapper" style={{ width: '100%', marginBottom: '-20px' }}>
                <span className="application-main-box-label">Общая сумма средств гранта по Договору</span>
                <Form.Item
                  name="allStepsSum"
                  initialValue={application.allStepsSum}
                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} bordered style={{ width: '100%', borderColor: '#014f97' }} />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper" style={{ width: '100%', marginBottom: '-20px' }}>
                <span className="application-main-box-label">Объем средств, фактически полученных от АНО на текущий момент грантополучателем</span>
                <Form.Item
                  name="currentTransferedSum"
                  initialValue={application.currentTransferedSum}
                >
                  <Input disabled={getUserRole() !== 'bigAdmin'} bordered style={{ width: '100%', borderColor: '#014f97' }} />
                </Form.Item>
              </div>
              <Collapse ghost style={{ marginTop: '10px' }}>
                <Panel header={<div style={{ color: '#1890ff', textDecoration: 'underline' }}>Посмотреть распределение средств по этапам</div>} key="1">
                  {Array(application.maxStepsNumber).fill(0).map((el, index) => (
                    <div className="application-main-box-content-wrapper" style={{ width: '100%', marginBottom: '-20px' }} key={index}>
                      <span className="application-main-box-label">{`Этап ${index + 1}`}</span>
                      <Form.Item
                        name={`step${index + 1}Sum`}
                        initialValue={application[`step${index + 1}Sum`]}
                      >
                        <Input disabled={getUserRole() !== 'bigAdmin'} bordered style={{ width: '100%', borderColor: '#014f97' }} />
                      </Form.Item>
                    </div>
                  ))}
                </Panel>
              </Collapse>
            </Col>

          </div>
        </Col>
      </Row>
      )}
      {isShowUploadSingleFileModal && (
        <Modal
          visible
          width="70vw"
          maskClosable={false}
          onCancel={() => { setSingleFile(null); setIsShowUploadSingleFileModal(null); }}
          title={`Загрузка файла для ${application.displayID}`}
          footer={[
            <Button key="submit" type="primary" onClick={onSaveSingleFileModal}>
              Загрузить
            </Button>]}
        >
          <Col>
            {!singleFile && (
            <Dragger
              showUploadList={false}
              onChange={onSingleFileUpload}
              action={null}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
            </Dragger>
            )}
            {singleFile
            && (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(singleFile.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={onSingleFileRemove} /></Col>
              <Col>{singleFile.name}</Col>
            </Row>
            )}
          </Col>
        </Modal>
      )}

      {isShowUploadMultipleFileModal && (
      <Modal
        visible
        width="70vw"
        maskClosable={false}
        onCancel={() => { setMultipleFile([]); setIsShowUploadMultipleFileModal(null); }}
        title={`Загрузка файла для ${application.displayID}`}
        footer={[
          <Button key="submit" type="primary" onClick={onSaveMultipleFileModal}>
            Загрузить
          </Button>]}
      >
        <Col>
          {!singleFile && (
          <Dragger
            showUploadList={false}
            onChange={onMultipleFileUpload}
            action={null}
            multiple
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файлы, чтобы загрузить (не более 15мб)</p>
          </Dragger>
          )}
          {multipleFile.map((f) => (
            <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onMultipleFileRemove(f)} /></Col>
              <Col>{f.name}</Col>
            </Row>
          ))}
        </Col>
      </Modal>
      )}
      {isShowNeDZMAdditionalFileModal && (
      <Modal
        visible
        width="70vw"
        maskClosable={false}
        onCancel={() => setIsShowNeDZMAdditionalFileModal(false)}
        title={`Загрузка ДС для ${application.displayID}`}
        footer={[
          <Button key="save" type="primary" onClick={onSaveNeDZMAdditionalFiles}>
            Сохранить
          </Button>,
          <Popconfirm
            title="Уверены, что хотите удалить?"
            onConfirm={onDeleteNeDZMAdditionalFiles}
            okText="Да"
            cancelText="Нет"
          >
            <Button key="delete" type="danger" disabled={getUserRole() !== 'bigAdmin'}>
              Удалить
            </Button>
          </Popconfirm>]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 'bold' }}>Файл pdf</div>
          {!isShowNeDZMAdditionalFileModal.pdfFile_id && (
          <Dragger
            showUploadList={false}
            onChange={(e) => onSingleFileUpload(e, 'pdfFile_id')}
            action={null}
            accept=".pdf"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          )}
          {isShowNeDZMAdditionalFileModal.pdfFile_id
          && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 0px' }}>
              <div>{isShowNeDZMAdditionalFileModal.pdfFile_id.name}</div>
              <div>
                <Button type="primary" onClick={() => fileDownload(isShowNeDZMAdditionalFileModal.pdfFile_id.urlLink, isShowNeDZMAdditionalFileModal.pdfFile_id.name)}>Скачать</Button>
                <Button type="primary" onClick={() => fileOpenNewTab(isShowNeDZMAdditionalFileModal.pdfFile_id.urlLink)} style={{ margin: '0px 10px' }}>Открыть</Button>
                <Popconfirm
                  title="Уверены, что хотите удалить?"
                  onConfirm={() => onSingleFileRemove('pdfFile_id')}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button type="danger" disabled={getUserRole() !== 'bigAdmin'}>Удалить</Button>
                </Popconfirm>
              </div>
            </div>
          )}
          <div style={{ fontWeight: 'bold' }}>Файл word</div>
          {!isShowNeDZMAdditionalFileModal.wordFile_id && (
          <Dragger
            showUploadList={false}
            onChange={(e) => onSingleFileUpload(e, 'wordFile_id')}
            action={null}
            accept=".docx,.doc"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          )}
          {isShowNeDZMAdditionalFileModal.wordFile_id
          && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 0px' }}>
              <div>{isShowNeDZMAdditionalFileModal.wordFile_id.name}</div>
              <div>
                <Button type="primary" onClick={() => fileDownload(isShowNeDZMAdditionalFileModal.wordFile_id.urlLink, isShowNeDZMAdditionalFileModal.wordFile_id.name)}>Скачать</Button>
                <Button type="primary" onClick={() => fileOpenNewTab(isShowNeDZMAdditionalFileModal.wordFile_id.urlLink)} style={{ margin: '0px 10px' }}>Открыть</Button>
                <Popconfirm
                  title="Уверены, что хотите удалить?"
                  onConfirm={() => onSingleFileRemove('wordFile_id')}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button type="danger" disabled={getUserRole() !== 'bigAdmin'}>Удалить</Button>
                </Popconfirm>
              </div>
            </div>
          )}
          <div style={{ fontWeight: 'bold' }}>Файл письма</div>
          {!isShowNeDZMAdditionalFileModal.letterFile_id && (
          <Dragger
            showUploadList={false}
            onChange={(e) => onSingleFileUpload(e, 'letterFile_id')}
            action={null}
            accept=".docx,.doc,.pdf,.xlsx,.xlsm"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          )}
          {isShowNeDZMAdditionalFileModal.letterFile_id
          && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 0px' }}>
              <div>{isShowNeDZMAdditionalFileModal.letterFile_id.name}</div>
              <div>
                <Button type="primary" onClick={() => fileDownload(isShowNeDZMAdditionalFileModal.letterFile_id.urlLink, isShowNeDZMAdditionalFileModal.letterFile_id.name)}>Скачать</Button>
                <Button type="primary" onClick={() => fileOpenNewTab(isShowNeDZMAdditionalFileModal.letterFile_id.urlLink)} style={{ margin: '0px 10px' }}>Открыть</Button>
                <Popconfirm
                  title="Уверены, что хотите удалить?"
                  onConfirm={() => onSingleFileRemove('letterFile_id')}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button type="danger" disabled={getUserRole() !== 'bigAdmin'}>Удалить</Button>
                </Popconfirm>
              </div>
            </div>
          )}
        </div>
      </Modal>
      )}


      {isShowAdditionalFileModal && (
      <Modal
        visible
        width="70vw"
        maskClosable={false}
        onCancel={() => setIsShowAdditionalFileModal(false)}
        title={`Загрузка доп файлов для ${application.displayID}`}
        footer={[
          <Button key="save" type="primary" onClick={onSaveAdditionalFiles}>
            Сохранить
          </Button>,
          <Popconfirm
            title="Уверены, что хотите удалить?"
            onConfirm={onDeleteAdditionalFiles}
            okText="Да"
            cancelText="Нет"
          >
            <Button key="delete" type="danger" disabled={getUserRole() !== 'bigAdmin'}>
              Удалить
            </Button>
          </Popconfirm>]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 'bold' }}>Файл pdf</div>
          {!isShowAdditionalFileModal.file_id && (
          <Dragger
            showUploadList={false}
            onChange={(e) => onSingleFileUpload(e, 'file_id')}
            action={null}
            accept=".pdf"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          )}
          {isShowAdditionalFileModal.file_id
            && (
              <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 0px' }}>
                <div>{isShowAdditionalFileModal.file_id.name}</div>
                <div>
                  <Button type="primary" onClick={() => fileDownload(isShowAdditionalFileModal.file_id.urlLink, isShowAdditionalFileModal.file_id.name)}>Скачать</Button>
                  <Button type="primary" onClick={() => fileOpenNewTab(isShowAdditionalFileModal.file_id.urlLink)} style={{ margin: '0px 10px' }}>Открыть</Button>
                  <Popconfirm
                    title="Уверены, что хотите удалить?"
                    onConfirm={() => onSingleFileRemove('file_id')}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button type="danger" disabled={getUserRole() !== 'bigAdmin'}>Удалить</Button>
                  </Popconfirm>
                </div>
              </div>
            )}
        </div>
      </Modal>
      )}
    </Form>
  );
}

Information.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
};

export default Information;
