/* eslint-disable max-len */

const calculateSum = (array, fieldName) => {
  const sum = array.reduce((a, c) => a + parseFloat(`${c[`${fieldName}Major`]}.${(`${c[`${fieldName}Minor`]}`).length === 1 ? `0${c[`${fieldName}Minor`]}` : c[`${fieldName}Minor`]}`) * 100, 0);
  return {
    major: Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100),
    minor: Number((sum % 100).toFixed(0)),
  };
};

const getDataSource = (application, prevStepApplication) => {
  const dataSource = [];
  const fields = [
    {
      type: 'salary',
      orderIndex: '1',
      name: 'Оплата труда работников (с учетом страховых взносов во небюджетные фонды)',
    },
    {
      type: 'material',
      orderIndex: '2',
      name: 'Расходы на приобретение материалов и комплектующих, необходимых для реализации Проекта',
    },
    {
      type: 'equipment',
      orderIndex: '3',
      name: 'Расходы на приобретение оборудования и иного имущества, необходимых для реализации Проекта',
    },
    {
      type: 'other',
      orderIndex: '4',
      name: 'Прочие прямые расходы',
    },
    {
      type: 'paper',
      orderIndex: '5',
      name: 'Накладные и общехозяйственные расходы',
    },
  ];

  fields.forEach((f) => {
    let array = [];
    let actualSumMajor = 0;
    let actualSumMinor = 0;
    let paperSumMajor = 0;
    let paperSumMinor = 0;

    let prevStepArray = [];
    let prevStepPaperSumMajor = 0;
    let prevStepPaperSumMinor = 0;


    if (f.type === 'salary') {
      array = application.financialSalary_ids;
      actualSumMajor = calculateSum(array, 'totalPerson').major;
      actualSumMinor = calculateSum(array, 'totalPerson').minor;

      paperSumMajor = calculateSum(array, 'actualPaid').major;
      paperSumMinor = calculateSum(array, 'actualPaid').minor;

      if (prevStepApplication) {
        prevStepArray = prevStepApplication.financialSalary_ids || [];
        prevStepPaperSumMajor = calculateSum(prevStepArray, 'actualPaid').major;
        prevStepPaperSumMinor = calculateSum(prevStepArray, 'actualPaid').minor;
      }
    } else if (f.type === 'paper') {
      array = application.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]);
      actualSumMajor = calculateSum(array, 'actualCosts').major;
      actualSumMinor = calculateSum(array, 'actualCosts').minor;

      paperSumMajor = calculateSum(array, 'grantSum').major;
      paperSumMinor = calculateSum(array, 'grantSum').minor;

      if (prevStepApplication) {
        prevStepArray = prevStepApplication.financialCosts_ids ? prevStepApplication.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]) : [];
        prevStepPaperSumMajor = calculateSum(prevStepArray, 'grantSum').major;
        prevStepPaperSumMinor = calculateSum(prevStepArray, 'grantSum').minor;
      }
    } else {
      array = application.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]);
      actualSumMajor = calculateSum(array, 'actualCosts').major;
      actualSumMinor = calculateSum(array, 'actualCosts').minor;

      paperSumMajor = calculateSum(array, 'paperCosts').major;
      paperSumMinor = calculateSum(array, 'paperCosts').minor;

      if (prevStepApplication) {
        prevStepArray = prevStepApplication.financialCosts_ids ? prevStepApplication.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]) : [];
        prevStepPaperSumMajor = calculateSum(prevStepArray, 'paperCosts').major;
        prevStepPaperSumMinor = calculateSum(prevStepArray, 'paperCosts').minor;
      }
    }

    const total = application.financialReportTotal_ids.find((t) => t.type === f.type) || {};
    const prevStepTotal = prevStepApplication?.financialReportTotal_ids ? prevStepApplication.financialReportTotal_ids.find((t) => t.type === f.type) : null;
    let prevStepMoneyLeft = 0;
    if (prevStepTotal) {
      prevStepMoneyLeft = parseFloat(`${prevStepTotal.grantSumMajor}.${(`${prevStepTotal.grantSumMinor}`).length === 1 ? `0${prevStepTotal.grantSumMinor}` : prevStepTotal.grantSumMinor}`) * 100
        - parseFloat(`${prevStepTotal.prevStepPaperSumMajor}.${(`${prevStepTotal.prevStepPaperSumMinor}`).length === 1 ? `0${prevStepTotal.prevStepPaperSumMinor}` : prevStepTotal.prevStepPaperSumMinor}`) * 100;
    }
    const finalObject = {
      id: total.id,
      type: f.type,
      orderIndex: f.orderIndex,
      name: f.name,
      money2022Major: total.money2022Major || 0,
      money2022Minor: total.money2022Minor || 0,
      moneySpentLastPeriodStartMajor: prevStepPaperSumMajor,
      moneySpentLastPeriodStartMinor: prevStepPaperSumMinor,
      moneyLeftLastPeriodStartMajor: Math.trunc(prevStepMoneyLeft / 100) === 0 && prevStepMoneyLeft < 0 ? `-0${Math.trunc(prevStepMoneyLeft / 100)}` : Math.trunc(prevStepMoneyLeft / 100),
      moneyLeftLastPeriodStartMinor: Number((prevStepMoneyLeft % 100).toFixed(0)),
      moneyLeftPeriodStartMajor: total.moneyLeftPeriodStartMajor || 0,
      moneyLeftPeriodStartMinor: total.moneyLeftPeriodStartMinor || 0,
      grantSumMajor: total.grantSumMajor || 0,
      grantSumMinor: total.grantSumMinor || 0,
      actualSumMajor,
      actualSumMinor,
      paperSumMajor,
      paperSumMinor,
      comment: total.comment || '',
    };
    if (application.stepNumber === '2') {
      if (application.displayID === '0408-1/22-1С') {
        if (f.type === 'salary') { finalObject.moneyLeftLastPeriodStartMajor = 500000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
      }
      if (application.displayID === '2312-29/22-3С') {
        if (f.type === 'salary') { finalObject.moneyLeftLastPeriodStartMajor = 166005; finalObject.moneyLeftLastPeriodStartMinor = 0; }
        if (f.type === 'material') { finalObject.moneyLeftLastPeriodStartMajor = 5500; finalObject.moneyLeftLastPeriodStartMinor = 0; }
        if (f.type === 'equipment') { finalObject.moneyLeftLastPeriodStartMajor = 90000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
        if (f.type === 'other') { finalObject.moneyLeftLastPeriodStartMajor = 238495; finalObject.moneyLeftLastPeriodStartMinor = 0; }
      }
      if (application.displayID === '2412-55/22-1С') {
        if (f.type === 'salary') { finalObject.moneyLeftLastPeriodStartMajor = 210000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
        if (f.type === 'material') { finalObject.moneyLeftLastPeriodStartMajor = 130000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
        if (f.type === 'paper') { finalObject.moneyLeftLastPeriodStartMajor = 60000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
      }
      if (application.displayID === '0403-1/22-1С') {
        if (f.type === 'salary') { finalObject.moneyLeftLastPeriodStartMajor = 2055000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
        if (f.type === 'equipment') { finalObject.moneyLeftLastPeriodStartMajor = 130000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
      }
    }
    let totalSumleft = 0;
    if (!prevStepApplication) {
      totalSumleft = parseFloat(`${finalObject.grantSumMajor}.${(`${finalObject.grantSumMinor}`).length === 1 ? `0${finalObject.grantSumMinor}` : finalObject.grantSumMinor}`) * 100
        - parseFloat(`${finalObject.paperSumMajor}.${(`${finalObject.paperSumMinor}`).length === 1 ? `0${finalObject.paperSumMinor}` : finalObject.paperSumMinor}`) * 100;
    } else {
      totalSumleft = parseFloat(`${finalObject.money2022Major}.${(`${finalObject.money2022Minor}`).length === 1 ? `0${finalObject.money2022Minor}` : finalObject.money2022Minor}`) * 100
        - parseFloat(`${finalObject.moneySpentLastPeriodStartMajor}.${(`${finalObject.moneySpentLastPeriodStartMinor}`).length === 1 ? `0${finalObject.moneySpentLastPeriodStartMinor}` : finalObject.moneySpentLastPeriodStartMinor}`) * 100
        - parseFloat(`${finalObject.paperSumMajor}.${(`${finalObject.paperSumMinor}`).length === 1 ? `0${finalObject.paperSumMinor}` : finalObject.paperSumMinor}`) * 100;
    }
    finalObject.moneyLeftPeriodEndMajor = Math.trunc(totalSumleft / 100) === 0 && totalSumleft < 0 ? `-0${Math.trunc(totalSumleft / 100)}` : Math.trunc(totalSumleft / 100);
    finalObject.moneyLeftPeriodEndMinor = Number(Math.abs(totalSumleft % 100).toFixed(0));
    dataSource.push(finalObject);
  });
  return dataSource;
};


const getTotalRow = (dataSource) => {
  const fields = ['moneyLeftPeriodStart', 'grantSum', 'money2022', 'actualSum', 'paperSum', 'moneyLeftPeriodEnd', 'moneySpentLastPeriodStart', 'moneyLeftLastPeriodStart'];

  const obj = {
    id: 'isTotal',
    isTotal: true,
    name: 'ИТОГО',
  };

  fields.forEach((f) => {
    const sum = calculateSum(dataSource, f);
    obj[`${f}Major`] = sum.major;
    obj[`${f}Minor`] = sum.minor;
  });

  return obj;
};


const getTotalValue = (application, prevStepApplication) => {
  const dataSource = getDataSource(application, prevStepApplication);
  return [...dataSource, getTotalRow(dataSource)];
};


export default getTotalValue;
