/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, InputNumber, Input,
} from 'antd';


const { TextArea } = Input;
function FinanceSalaryCostTable({
  applicationProp, disabled, formRef, onChangeDetailedSum,
}) {
  const years = [];
  if (['1', '2', '3'].includes(applicationProp.projectTerm)) {
    years.push('2023');
  }
  if (['2', '3'].includes(applicationProp.projectTerm)) {
    years.push('2024');
  }
  if (['3'].includes(applicationProp.projectTerm)) {
    years.push('2025');
  }
  const rows = [{ field: 'Supervisor', label: 'Научный руководитель' }, { field: 'Doctor', label: 'Врачи' }, { field: 'Middle', label: 'Средний медицинский персонал' }, { field: 'Junior', label: 'Младший медицинский персонал' }, { field: 'Other', label: 'Прочие сотрудники' }];
  const cols = ['Total', 'AverageYear', 'AverageMonth'];

  const onSumChange = () => {
    const formValues = formRef.current.getFieldsValue();
    const updatedObj = {};

    let emptyField = false;

    // считаем средние в строчках
    years.forEach((year) => {
      rows.forEach((row) => {
        if (typeof formValues[`salary${row.field}Amount${year}`] === 'number' && formValues[`salary${row.field}Amount${year}`] > 0) {
          if (typeof formValues[`salary${row.field}Total${year}_major`] === 'object' || typeof formValues[`salary${row.field}Total${year}_minor`] === 'object') {
            emptyField = true;
          }
        }

        const totalValue = parseFloat(`${formValues[`salary${row.field}Total${year}_major`] || 0}.${(`${formValues[`salary${row.field}Total${year}_major`]}`).length === 1 ? `0${formValues[`salary${row.field}Total${year}_minor`]}` : formValues[`salary${row.field}Total${year}_minor`]}`) * 100;
        const averageYear = totalValue / (formValues[`salary${row.field}Amount${year}`] || 1);
        const averageMonth = averageYear / 12;

        updatedObj[`salary${row.field}AverageYear${year}_major`] = Math.trunc(averageYear / 100) === 0 && averageYear < 0 ? `-0${Math.trunc(averageYear / 100)}` : Math.trunc(averageYear / 100);
        updatedObj[`salary${row.field}AverageYear${year}_minor`] = Number((averageYear % 100).toFixed(0));

        updatedObj[`salary${row.field}AverageMonth${year}_major`] = Math.trunc(averageMonth / 100) === 0 && averageMonth < 0 ? `-0${Math.trunc(averageMonth / 100)}` : Math.trunc(averageMonth / 100);
        updatedObj[`salary${row.field}AverageMonth${year}_minor`] = Number((averageMonth % 100).toFixed(0));
      });
    });

    // считаем общие суммы по годам
    years.forEach((year) => {
      let numberOfPeople = 1;
      rows.forEach((row) => {
        numberOfPeople += formValues[`salary${row.field}Amount${year}`] || 0;
      });

      let sumTotal = 0;
      rows.forEach((row) => {
        sumTotal += parseFloat(`${formValues[`salary${row.field}Total${year}_major`] || 0}.${(`${formValues[`salary${row.field}Total${year}_minor`]}`).length === 1 ? `0${formValues[`salary${row.field}Total${year}_minor`]}` : formValues[`salary${row.field}Total${year}_minor`]}`) * 100;
      });
      updatedObj[`salaryTotal${year}_major`] = Math.trunc(sumTotal / 100) === 0 && sumTotal < 0 ? `-0${Math.trunc(sumTotal / 100)}` : Math.trunc(sumTotal / 100);
      updatedObj[`salaryTotal${year}_minor`] = Number((sumTotal % 100).toFixed(0));

      const sumAverageYear = sumTotal / numberOfPeople;
      updatedObj[`salaryAverageYear${year}_major`] = Math.trunc(sumAverageYear / 100) === 0 && sumAverageYear < 0 ? `-0${Math.trunc(sumAverageYear / 100)}` : Math.trunc(sumAverageYear / 100);
      updatedObj[`salaryAverageYear${year}_minor`] = Number((sumAverageYear % 100).toFixed(0));

      const AverageMonth = sumAverageYear / 12;
      updatedObj[`salaryAverageMonth${year}_major`] = Math.trunc(AverageMonth / 100) === 0 && AverageMonth < 0 ? `-0${Math.trunc(AverageMonth / 100)}` : Math.trunc(AverageMonth / 100);
      updatedObj[`salaryAverageMonth${year}_minor`] = Number((AverageMonth % 100).toFixed(0));

      // years.forEach((year) => {
      //   cols.forEach((col) => {
      //     if (col === 'Total') {
      //       let sum = 0;
      //       rows.forEach((row) => {
      //         sum += parseFloat(`${formValues[`salary${row.field}${col}${year}_major`] || 0}.${(`${formValues[`salary${row.field}${col}${year}_minor`]}`).length === 1 ? `0${formValues[`salary${row.field}${col}${year}_minor`]}` : formValues[`salary${row.field}${col}${year}_minor`]}`) * 100;
      //       });
      //       updatedObj[`salary${col}${year}_major`] = Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100);
      //       updatedObj[`salary${col}${year}_minor`] = Number((sum % 100).toFixed(0));
      //     } else {
      //       let sum = 0;
      //       rows.forEach((row) => {
      //         sum += parseFloat(`${updatedObj[`salary${row.field}${col}${year}_major`] || 0}.${(`${updatedObj[`salary${row.field}${col}${year}_minor`]}`).length === 1 ? `0${updatedObj[`salary${row.field}${col}${year}_minor`]}` : updatedObj[`salary${row.field}${col}${year}_minor`]}`) * 100;
      //       });
      //       updatedObj[`salary${col}${year}_major`] = Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100);
      //       updatedObj[`salary${col}${year}_minor`] = Number((sum % 100).toFixed(0));
      //     }
      //   });
    });

    // считаем общие суммы по всей таблице
    cols.forEach((field) => {
      let sum = 0;
      years.forEach((year) => {
        sum += parseFloat(`${updatedObj[`salary${field}${year}_major`] || 0}.${(`${updatedObj[`salary${field}${year}_minor`]}`).length === 1 ? `0${updatedObj[`salary${field}${year}_minor`]}` : updatedObj[`salary${field}${year}_minor`]}`) * 100;
      });
      updatedObj[`salary${field}_major`] = Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100);
      updatedObj[`salary${field}_minor`] = Number((sum % 100).toFixed(0));
    });

    formRef.current.setFieldsValue(updatedObj);

    const updatedObject = {
      fotTotalMajor: updatedObj.salaryTotal_major,
      fotTotalMinor: updatedObj.salaryTotal_minor,
    };

    years.forEach((year) => {
      updatedObject[`fot${year}Major`] = !emptyField ? updatedObj[`salaryTotal${year}_major`] : null;
      updatedObject[`fot${year}Minor`] = updatedObj[`salaryTotal${year}_minor`] || 0;
    });
    onChangeDetailedSum(updatedObject);
  };

  return (
    <div className="financeTab-wrapper">
      <div className="common-subTitle">5.1. Оплата труда работников (включая расходы на оплату страховых взносов на ФОТ)</div>
      <div className="common-table-wrapper" style={{ marginTop: '10px', borderRight: '1px solid #d9d9d9', borderBottom: '1px solid #d9d9d9' }}>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center', fontWeight: 'bold' }}>№ п/п</div>
          <div className="common-table-row-cell" style={{ width: '15%', textAlign: 'center', fontWeight: 'bold' }}>Группа сотрудников, непосредственно выполняющих работы по Проекту (члены научного коллектива)</div>
          <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center', fontWeight: 'bold' }}>Количество человек</div>
          <div className="common-table-row-cell" style={{ width: '30%', textAlign: 'center', fontWeight: 'bold' }}>Краткое описание функционала сотрудников указанной группы при выполнении работ по проекту</div>
          <div className="common-table-row-cell" style={{ width: '15%', textAlign: 'center', fontWeight: 'bold' }}>Годовой бюджет на оплату труда группы сотрудников, руб</div>
          <div className="common-table-row-cell" style={{ width: '15%', textAlign: 'center', fontWeight: 'bold' }}>Средняя оплата труда одного сотрудника группы в год, руб</div>
          <div className="common-table-row-cell" style={{ width: '15%', textAlign: 'center', fontWeight: 'bold' }}>Средняя оплата труда одного сотрудника группы в месяц, руб</div>
        </div>

        {years.map((year) => (
          <div style={{ display: 'flex', flexDirection: 'column' }} key={year}>
            <div className="common-table-row">
              <div className="common-table-row-cell" style={{ width: '55%', textAlign: 'center', fontWeight: 'bold' }}>{`Всего бюджет на оплату труда сотрудников за ${Number(year) + 2} год, руб:`}</div>
              <div
                className="common-table-row-cell"
                style={{
                  width: '15%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
                }}
              >
                <Form.Item
                  style={{ width: '55%' }}
                  name={`salaryTotal${year}_major`}
                  initialValue={applicationProp[`salaryTotal${year}`] ? applicationProp[`salaryTotal${year}`].split(' руб.')[0] : null}
                >
                  <InputNumber disabled />
                </Form.Item>
                <span>руб.</span>
                <Form.Item
                  style={{ width: '25%' }}
                  name={`salaryTotal${year}_minor`}
                  initialValue={applicationProp[`salaryTotal${year}`] ? applicationProp[`salaryTotal${year}`].split(' руб. ')[1].split(' коп.')[0] : null}
                >
                  <InputNumber disabled max={99} />
                </Form.Item>
                <span>коп.</span>
              </div>
              <div
                className="common-table-row-cell"
                style={{
                  width: '15%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
                }}
              >
                <Form.Item
                  style={{ width: '55%' }}
                  name={`salaryAverageYear${year}_major`}
                  initialValue={applicationProp[`salaryAverageYear${year}`] ? applicationProp[`salaryAverageYear${year}`].split(' руб.')[0] : null}
                >
                  <InputNumber disabled />
                </Form.Item>
                <span>руб.</span>
                <Form.Item
                  style={{ width: '25%' }}
                  name={`salaryAverageYear${year}_minor`}
                  initialValue={applicationProp[`salaryAverageYear${year}`] ? applicationProp[`salaryAverageYear${year}`].split(' руб. ')[1].split(' коп.')[0] : null}
                >
                  <InputNumber disabled max={99} />
                </Form.Item>
                <span>коп.</span>
              </div>
              <div
                className="common-table-row-cell"
                style={{
                  width: '15%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
                }}
              >
                <Form.Item
                  style={{ width: '55%' }}
                  name={`salaryAverageMonth${year}_major`}
                  initialValue={applicationProp[`salaryAverageMonth${year}`] ? applicationProp[`salaryAverageMonth${year}`].split(' руб.')[0] : null}
                >
                  <InputNumber disabled />
                </Form.Item>
                <span>руб.</span>
                <Form.Item
                  style={{ width: '25%' }}
                  name={`salaryAverageMonth${year}_minor`}
                  initialValue={applicationProp[`salaryAverageMonth${year}`] ? applicationProp[`salaryAverageMonth${year}`].split(' руб. ')[1].split(' коп.')[0] : null}
                >
                  <InputNumber disabled max={99} />
                </Form.Item>
                <span>коп.</span>
              </div>
            </div>

            {rows.map((row, index) => (
              <div className="common-table-row" key={row.field + year}>
                <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }}>{index + 1}</div>
                <div className="common-table-row-cell" style={{ width: '15%', textAlign: 'center' }}>{row.label}</div>
                <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }}>
                  {index === 0 ? '1' : (
                    <Form.Item
                      style={{ width: '100%' }}
                      name={`salary${row.field}Amount${year}`}
                      initialValue={applicationProp[`salary${row.field}Amount${year}`]}
                    >
                      <InputNumber disabled={disabled} onChange={onSumChange} />
                    </Form.Item>
                  )}
                </div>
                <div className="common-table-row-cell" style={{ width: '30%', textAlign: 'center' }}>
                  <Form.Item
                    style={{ width: '100%' }}
                    name={`salary${row.field}Description${year}`}
                    initialValue={applicationProp[`salary${row.field}Description${year}`]}
                  >
                    <TextArea disabled={disabled} placeholder="Введите текст" />
                  </Form.Item>
                </div>
                <div
                  className="common-table-row-cell"
                  style={{
                    width: '15%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
                  }}
                >
                  <Form.Item
                    style={{ width: '55%' }}
                    name={`salary${row.field}Total${year}_major`}
                    initialValue={applicationProp[`salary${row.field}Total${year}`] ? applicationProp[`salary${row.field}Total${year}`].split(' руб.')[0] : null}
                  >
                    <InputNumber disabled={disabled} onChange={onSumChange} />
                  </Form.Item>
                  <span>руб.</span>
                  <Form.Item
                    style={{ width: '25%' }}
                    name={`salary${row.field}Total${year}_minor`}
                    initialValue={applicationProp[`salary${row.field}Total${year}`] ? applicationProp[`salary${row.field}Total${year}`].split(' руб. ')[1].split(' коп.')[0] : null}
                  >
                    <InputNumber disabled={disabled} onChange={onSumChange} max={99} />
                  </Form.Item>
                  <span>коп.</span>
                </div>
                <div
                  className="common-table-row-cell"
                  style={{
                    width: '15%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
                  }}
                >
                  <Form.Item
                    style={{ width: '55%' }}
                    name={`salary${row.field}AverageYear${year}_major`}
                    initialValue={applicationProp[`salary${row.field}AverageYear${year}`] ? applicationProp[`salary${row.field}AverageYear${year}`].split(' руб.')[0] : null}
                  >
                    <InputNumber disabled />
                  </Form.Item>
                  <span>руб.</span>
                  <Form.Item
                    style={{ width: '25%' }}
                    name={`salary${row.field}AverageYear${year}_minor`}
                    initialValue={applicationProp[`salary${row.field}AverageYear${year}`] ? applicationProp[`salary${row.field}AverageYear${year}`].split(' руб. ')[1].split(' коп.')[0] : null}
                  >
                    <InputNumber disabled max={99} />
                  </Form.Item>
                  <span>коп.</span>
                </div>
                <div
                  className="common-table-row-cell"
                  style={{
                    width: '15%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
                  }}
                >
                  <Form.Item
                    style={{ width: '55%' }}
                    name={`salary${row.field}AverageMonth${year}_major`}
                    initialValue={applicationProp[`salary${row.field}AverageMonth${year}`] ? applicationProp[`salary${row.field}AverageMonth${year}`].split(' руб.')[0] : null}
                  >
                    <InputNumber disabled />
                  </Form.Item>
                  <span>руб.</span>
                  <Form.Item
                    style={{ width: '25%' }}
                    name={`salary${row.field}AverageMonth${year}_minor`}
                    initialValue={applicationProp[`salary${row.field}AverageMonth${year}`] ? applicationProp[`salary${row.field}AverageMonth${year}`].split(' руб. ')[1].split(' коп.')[0] : null}
                  >
                    <InputNumber disabled max={99} />
                  </Form.Item>
                  <span>коп.</span>
                </div>
              </div>
            ))}

          </div>
        ))}
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '55%', textAlign: 'center', fontWeight: 'bold' }}>Общий бюджет на оплату труда сотрудников по Проекту, руб:</div>
          <div
            className="common-table-row-cell"
            style={{
              width: '15%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
          >
            <Form.Item
              style={{ width: '55%' }}
              name="salaryTotal_major"
              initialValue={applicationProp.salaryTotal?.split(' руб.')[0] || null}
            >
              <InputNumber disabled />
            </Form.Item>
            <span>руб.</span>
            <Form.Item
              style={{ width: '25%' }}
              name="salaryTotal_minor"
              initialValue={applicationProp.salaryTotal ? applicationProp.salaryTotal.split(' руб. ')[1].split(' коп.')[0] : null}
            >
              <InputNumber disabled max={99} />
            </Form.Item>
            <span>коп.</span>
          </div>
          <div
            className="common-table-row-cell"
            style={{
              width: '15%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
          >
            <Form.Item
              style={{ width: '55%' }}
              name="salaryAverageYear_major"
              initialValue={applicationProp.salaryAverageYear?.split(' руб.')[0] || null}
            >
              <InputNumber disabled />
            </Form.Item>
            <span>руб.</span>
            <Form.Item
              style={{ width: '25%' }}
              name="salaryAverageYear_minor"
              initialValue={applicationProp.salaryAverageYear ? applicationProp.salaryAverageYear.split(' руб. ')[1].split(' коп.')[0] : null}
            >
              <InputNumber disabled max={99} />
            </Form.Item>
            <span>коп.</span>
          </div>
          <div
            className="common-table-row-cell"
            style={{
              width: '15%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
          >
            <Form.Item
              style={{ width: '55%' }}
              name="salaryAverageMonth_major"
              initialValue={applicationProp.salaryAverageMonth?.split(' руб.')[0] || null}
            >
              <InputNumber disabled />
            </Form.Item>
            <span>руб.</span>
            <Form.Item
              style={{ width: '25%' }}
              name="salaryAverageMonth_minor"
              initialValue={applicationProp.salaryAverageMonth ? applicationProp.salaryAverageMonth.split(' руб. ')[1].split(' коп.')[0] : null}
            >
              <InputNumber disabled max={99} />
            </Form.Item>
            <span>коп.</span>
          </div>
        </div>
      </div>
    </div>
  );
}

FinanceSalaryCostTable.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  formRef: PropTypes.shape().isRequired,
  onChangeDetailedSum: PropTypes.func.isRequired,
};

export default FinanceSalaryCostTable;
