/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-cond-assign */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import prepareUsersXls from './prepareUsersXls';


const ExcelJS = require('exceljs');


function toColumnName(num) {
  let ret = '';
  let a = 1;
  let b = 1;
  for (ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}


const fields = [];

const createTotalSheet = (data, worksheet) => {
  // стиль столбцов и название шапки
  data.fields.forEach((field, index) => {
    worksheet.getColumn(index + 1).font = { name: 'Montserrat' };
    worksheet.getColumn(index + 1).width = field.width;

    const cell = worksheet.getCell((`${toColumnName(index + 1)}1`));
    cell.value = field.name;
    cell.alignment = { vertical: 'middle' };
    cell.style = { font: { bold: true } };
  });
  // создаем строчки
  data.users.forEach((user) => {
    const rowXls = worksheet.getRow(user.index + 1);
    data.fields.forEach((field, index) => {
      const fieldXls = rowXls.getCell(index + 1);
      fieldXls.value = user[field.key];
      fieldXls.alignment = {
        vertical: 'middle',
        wrapText: true,
      };
    });
  });
};

const createXLSDocument = async (data) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Admin';
  workbook.lastModifiedBy = 'Администратор';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const worksheet = workbook.addWorksheet('Отчет');
  createTotalSheet(data, worksheet);

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};

const arrayBufferToBase64 = (Arraybuffer) => {
  let binary = '';
  const bytes = new Uint8Array(Arraybuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = window.btoa(binary);
  const linkSource = `data:application/xls;base64,${base64String}`;
  const downloadLink = document.createElement('a');
  const fileName = 'Отчет.xls';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const exportUsers = async (rawData) => {
  const data = prepareUsersXls(rawData);
  const buffer = await createXLSDocument(data);
  arrayBufferToBase64(buffer);
};


export default exportUsers;
