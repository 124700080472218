import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Row, InputNumber, Input, Upload, Popover,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined,
} from '@ant-design/icons';
import { uploadFile } from '../../../utils/http';


const { Dragger } = Upload;
export default function FormRow({
  label, field, survey, isDisabled, hint, onFactChange, initFields, formRef, onUpdateOriginalObject,
}) {
  const onRemoveFile = (fieldName) => {
    onUpdateOriginalObject(`${fieldName}`, null);
    formRef.current.setFieldsValue({ [`${fieldName}`]: null });
  };

  const onUploadFile = (info, fieldName) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          onUpdateOriginalObject(fieldName, file);
          formRef.current.setFieldsValue({ [fieldName]: file });
        });
    }
  };

  return (
    <Row style={{ borderBottom: '1px solid #212121' }}>
      {initFields.includes(field) && (
      <Col
        style={{
          padding: 10,
          width: '47%',
          borderLeft: '1px solid #212121',
        }}
      >
        <div>{label}</div>
        <div style={{ fontStyle: 'italic', fontSize: '12px', marginTop: '10px' }}>{hint}</div>
      </Col>
      )}
      {initFields.includes(field) && (
      <Col
        style={{
          padding: 10,
          width: '10%',
          borderLeft: '1px solid #212121',
        }}
      >
        <Form.Item
          name={`${field}Plan`}
          initialValue={survey[`${field}Plan`]}
        >
          <InputNumber disabled style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      )}
      {initFields.includes(field) && (
      <Col
        style={{
          padding: 10,
          width: '10%',
          borderLeft: '1px solid #212121',
        }}
      >
        {field === 'numberOfPublicationsTotal' && (
        <Popover title="" content="Заполняется автоматически">
          <Form.Item
            name={`${field}Fact`}
            initialValue={survey[`${field}Fact`]}
          >
            <InputNumber
              disabled
              style={{ width: '100%' }}
              onChange={(e) => onFactChange(field, e)}
            />
          </Form.Item>
        </Popover>
        )}
        {field !== 'numberOfPublicationsTotal'
        && (
        <Form.Item
          name={`${field}Fact`}
          initialValue={survey[`${field}Fact`]}
        >
          <InputNumber
            disabled={isDisabled}
            style={{ width: '100%' }}
            onChange={(e) => onFactChange(field, e)}
          />
        </Form.Item>
        )}
      </Col>
      )}
      {initFields.includes(field) && (
      <Col
        style={{
          padding: 10,
          width: '30%',
          borderLeft: '1px solid #212121',
        }}
      />
      )}
      {!initFields.includes(field) && (
      <Col
        style={{
          padding: 10,
          width: '67%',
          borderLeft: '1px solid #212121',
        }}
      />
      )}
      {!initFields.includes(field) && (
      <Col
        style={{
          padding: 10,
          width: '20%',
          borderLeft: '1px solid #212121',
        }}
      >
        <Form.Item
          name={`${field}_fileName`}
          initialValue={survey[`${field}_fileName`]}
        >
          <Input disabled={isDisabled} style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      )}
      {!initFields.includes(field) && (
      <Col
        style={{
          padding: 10,
          width: '13%',
          borderLeft: '1px solid #212121',
        }}
      >
        <Form.Item
          name={`${field}_file`}
          initialValue={survey[`${field}_file`]}
        >
          {!survey[`${field}_file`] && (
          <Dragger
            showUploadList={false}
            onChange={(e) => onUploadFile(e, `${field}_file`)}
            disabled={isDisabled}
            multiple
            action={null}
          >
            <div style={{ margin: '-12px 0px' }}>
              <InboxOutlined />
            </div>
          </Dragger>
          )}
          {survey[`${field}_file`] && (
          <Row style={{ margin: '5px 0px' }} gutter={12}>
            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(survey[`${field}_file`].urlLink)} /></Col>
            {!isDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onRemoveFile(`${field}_file`)} /></Col>}
            <Col>{survey[`${field}_file`].name}</Col>
          </Row>
          )}
        </Form.Item>
      </Col>
      )}
    </Row>
  );
}

FormRow.propTypes = {
  label: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  survey: PropTypes.shape().isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onFactChange: PropTypes.func.isRequired,
  initFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  formRef: PropTypes.shape().isRequired,
  onUpdateOriginalObject: PropTypes.shape().isRequired,
};
