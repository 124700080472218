const selectStatusOptions = [
  {
    id: 'Черновик',
    label: 'Черновик',
    value: 'Черновик',
  },
  {
    id: 'На рассмотрении',
    label: 'На рассмотрении',
    value: 'На рассмотрении',
  },
  {
    id: 'Согласован',
    label: 'Согласован',
    value: 'Согласован',
  },
  {
    id: 'Отклонен',
    label: 'Отклонен',
    value: 'Отклонен',
  },
  {
    id: 'На доработке',
    label: 'На доработке',
    value: 'На доработке',
  },
  {
    id: 'Поддержана',
    label: 'Поддержана',
    value: 'Поддержана',
  },
  {
    id: 'Не поддержана',
    label: 'Не поддержана',
    value: 'Не поддержана',
  },
  {
    id: 'Заполнена',
    label: 'Заполнена',
    value: 'Заполнена',
  },
  {
    id: 'Завершен',
    label: 'Завершен',
    value: 'Завершен',
  },
  {
    id: 'Расторгнут',
    label: 'Расторгнут',
    value: 'Расторгнут',
  },
];

export default selectStatusOptions;
