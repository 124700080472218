/* eslint-disable object-curly-newline */
const applicationFields = {
  shortTab: [
    { name: 'organisationFullName', label: 'Полное наименование организации', type: 'field' },
    { name: 'scientificDirection', label: 'Наименование научного направления Проекта', type: 'field' },
    { name: 'projectName', label: 'Название научно-практического Проекта в сфере медицины', type: 'field' },
    { name: 'projectTerm', label: 'Срок реализации Проекта', type: 'field' },
    { name: 'numberOfPublicationsVAC', label: '1.1. в научных журналах перечня ВАК с Импакт-фактором > 0,5 (на момент публикации материала)', type: 'field' },
    { name: 'numberOfPublicationsRINC', label: '1.2. в научных журналах, индексируемых РИНЦ', type: 'field' },
    { name: 'numberOfRID', label: '2. Число результатов интеллектуальной деятельности, планируемых к получению по результатам реализации Проекта', type: 'field' },
    { name: 'numberOfEvents', label: '3. Количество планируемых мероприятий по демонстрации результатов реализации Проекта', type: 'field' },
    { name: 'numberOfPerformance', label: '4. Количество планируемых выступлений с докладами на международных научно-практических конференциях, симпозиумах, форумах', type: 'field' },
    { name: 'numberOfPatienceTotal', label: '5. Общее количество пациентов, предусмотренных для выполнения Проекта', type: 'field' },
    { name: 'projectAnnotation', label: 'Аннотация Проекта', type: 'field', minLength: 600 },
    { name: 'projectAnnotation', label: 'Аннотация Проекта', type: 'field', maxLength: 800 },
    { name: 'supervisorFullName', label: 'Сведения о научном руководителе Проекта. ФИО', type: 'field' },
    { name: 'supervisorPosition', label: 'Сведения о научном руководителе Проекта. Должность', type: 'field' },
    { name: 'supervisorDegree', label: 'Сведения о научном руководителе Проекта. Ученая степень', type: 'field' },
    { name: 'supervisorPhone', label: 'Сведения о научном руководителе Проекта. Телефон', type: 'field' },
    { name: 'supervisorEmail', label: 'Сведения о научном руководителе Проекта. E-mail', type: 'field' },
    { name: 'supervisorSOPDFiles_ids', label: 'СОПД научного руководителя', type: 'array' },
    { name: 'directorFullName', label: 'Сведения о руководителе организации-Заявителя. ФИО', type: 'field' },
    { name: 'directorPosition', label: 'Сведения о руководителе организации-Заявителя. Должность', type: 'field' },
    { name: 'directorPhone', label: 'Сведения о руководителе организации-Заявителя. Телефон', type: 'field' },
    { name: 'directorEmail', label: 'Сведения о руководителе организации-Заявителя. E-mail', type: 'field' },
    { name: 'contactCommonFullName', label: 'Сведения об ответственном контактном лице по заявке и Проекту (по общим вопросам). ФИО', type: 'field' },
    { name: 'contactCommonPosition', label: 'Сведения об ответственном контактном лице по заявке и Проекту (по общим вопросам). Должность', type: 'field' },
    { name: 'contactCommonPhone', label: 'Сведения об ответственном контактном лице по заявке и Проекту (по общим вопросам). Телефон', type: 'field' },
    { name: 'contactCommonSOPDFiles_ids', label: 'СОПД ответственного контактного лица по заявке и Проекту (по общим вопросам)', type: 'array' },
    { name: 'contactFinanceFullName', label: 'Сведения об ответственном контактном лице, отвечающем за предоставление финансовых данных по заявке и Проекту (смета, финансовый отчет). ФИО', type: 'field' },
    { name: 'contactFinancePosition', label: 'Сведения об ответственном контактном лице, отвечающем за предоставление финансовых данных по заявке и Проекту (смета, финансовый отчет). Должность', type: 'field' },
    { name: 'contactFinancePhone', label: 'Сведения об ответственном контактном лице, отвечающем за предоставление финансовых данных по заявке и Проекту (смета, финансовый отчет). Телефон', type: 'field' },
    { name: 'contactFinanceEmail', label: 'Сведения об ответственном контактном лице, отвечающем за предоставление финансовых данных по заявке и Проекту (смета, финансовый отчет). E-mail', type: 'field' },
    { name: 'contactFinanceSOPDFiles_ids', label: 'СОПД ответственного контактного лица отвечающего за предоставление финансовых данных по заявке и Проекту (смета, финансовый отчет)', type: 'array' },
    { name: 'isPersonsParticipate', label: 'Предусмотрены ли в Проекте научно-исследовательские работы, которые будут выполнять организации-Соисполнители?', type: 'field' },
  ],
  infoTab: [
    { name: 'goal', label: '1.1 Цель Проекта', type: 'field' },
    { name: 'work', label: '1.3 Имеющийся у коллектива специалистов научный задел по Проекту', type: 'field', minLength: 1500 },
    { name: 'result', label: '1.4 Ожидаемые результаты', type: 'field' },
    { name: 'resultValues', label: '1.5 Вид результата', type: 'field' },
    { name: 'method', label: '1.6 Предлагаемые методы и подходы', type: 'field' },
    { name: 'risk', label: '1.9. Риски реализации Проекта и пути их минимизации', type: 'field' },
    { name: 'epidemicMoscow', label: '2.1. Какое количество пациентов нуждается в предлагаемом решении? (в Москве)', type: 'field' },
    { name: 'epidemicMoscowLink', label: '2.1. Какое количество пациентов нуждается в предлагаемом решении? (в Москве - Ссылка на источник)', type: 'field' },
    { name: 'epidemicRussia', label: '2.1. Какое количество пациентов нуждается в предлагаемом решении? (В России)', type: 'field' },
    { name: 'epidemicRussiaLink', label: '2.1. Какое количество пациентов нуждается в предлагаемом решении? (В России - Ссылка на источник)', type: 'field' },
    { name: 'epidemicWorld', label: '2.1. Какое количество пациентов нуждается в предлагаемом решении? (В мире)', type: 'field' },
    { name: 'epidemicWorldLink', label: '2.1. Какое количество пациентов нуждается в предлагаемом решении? (В мире - Ссылка на источник)', type: 'field' },
    { name: 'epidemic', label: '2.1. Дайте обоснование расчетам (от 1000 печатных знаков с пробелами)', type: 'field', minLength: 1000 },
    { name: 'state', label: '2.2. Краткий обзор состояния науки в отношении цели Проекта', type: 'field', minLength: 2000 },
    { name: 'medicalEffect', label: '2.3.a Потенциальная медицинская эффективность Проекта', type: 'field', minLength: 1000 },

    { name: 'economyMoscow', label: '2.3.б Потенциальная экономическая эффективность Проекта (Москва)', type: 'field' },
    { name: 'economyRussia', label: '2.3.б Потенциальная экономическая эффективность Проекта (Россия)', type: 'field' },
    { name: 'economyCheaperReason', label: '2.3.б Обоснование расчетов (от 1000 печатных знаков с пробелами)', type: 'field', minLength: 1000 },

    { name: 'isRegPaper', label: 'Планируется ли получение регистрационного удостоверения?', type: 'field' },

    { name: 'isMarketEntry', label: '2.6. Перспективы дальнейшего развития проекта', type: 'field' },
    { name: 'continueDescription', label: 'В этом поле укажите, будете ли вы продолжать деятельность в рамках проекта, после того как закончатся средства гранта. Планируется ли вывод результата работ на рынок/внедрения в реальный сектор экономики и какие меры будут для этого приняты', type: 'field' },
  ],

  applications2InfoTabTasks_ids: [
    { name: 'task', label: 'Задача', type: 'field' },
  ],

  authorTab: [
    { name: 'supervisorIsRussian', label: '3.2. Сведения о научном руководителе Проекта. Гражданство Российской Федерации', type: 'boolean' },
    { name: 'supervisorBirthDate', label: '3.2.2 Сведения о научном руководителе Проекта. Дата рождения', type: 'field' },
    { name: 'supervisorExperience', label: 'Опыт работы по специальности или направлению научно-практического проекта (лет)', type: 'field' },
    { name: 'supervisorEducation', label: '3.2.3 Сведения о научном руководителе Проекта. Образование', type: 'field' },
    { name: 'supervisorAchievements', label: '3.2.6 Сведения о научном руководителе Проекта. Профессиональные достижения', type: 'field' },
    { name: 'supervisorHirsh', label: '3.2.8 Сведения о научном руководителе Проекта. Индекс Хирша', type: 'field' },
    { name: 'supervisorParticipation', label: '3.2.9. Сведения о членстве руководителя Проекта в иностранных и российских научно-медицинских общественных объединениях, ассоциациях, союзах и федерациях (при наличии)', type: 'field' },
    { name: 'participation', label: '3.1. Сведения о членстве учреждения в иностранных и российских научно-медицинских общественных объединениях, ассоциациях, союзах и федерациях (при наличии)', type: 'field' },
    // { name: 'supervisorBirthDate', label: '4.2. Сведения о научном руководителе Проекта. Дата Рождения', type: 'field' },
    // { name: 'supervisorEducation', label: '4.2. Сведения о научном руководителе Проекта. Образование', type: 'field' },
    // { name: 'supervisorAchievements', label: '4.2. Сведения о научном руководителе Проекта. Профессиональные достижения', type: 'field' },
    // { name: 'supervisorHirsh', label: '4.2. Сведения о научном руководителе Проекта. Индекс Хирша', type: 'field' },
    // { name: 'supervisorPublicationsText', label: '4.2.7. Ключевые научные публикации руководителя по теме Проекта за последние 5 лет', type: 'field' },
    // { name: 'supervisorRIDText', label: '4.2.10. Доклады руководителя по теме Проекта на международных научно-практических конференциях  за последние 5 лет', type: 'field' },
    // { name: 'supervisorEventsText', label: '4.2.11. Наличие патентов и (или) заявок на получение патента на изобретение', type: 'field' },
  ],
  applications2PersonsTabPeople_ids: [
    { name: 'isMoscow', label: 'Организация-Соисполнитель подведомственна Департаменту здравоохранения г. Москвы', type: 'field' },
    { name: 'fullName', label: 'Полное наименование организации (в соответствии с учредительными документами)', type: 'field' },
    { name: 'shortName', label: 'Сокращенное наименование организации', type: 'field' },
    { name: 'ogrn', label: 'ОГРН', type: 'field' },
    { name: 'inn', label: 'ИНН', type: 'field' },
    { name: 'legalAddress', label: 'Юридический адрес', type: 'field' },
    { name: 'directorFullName', label: 'Сведения о руководителе организации-Соисполнителя. ФИО', type: 'field' },
    { name: 'directorPosition', label: 'Сведения о руководителе организации-Соисполнителя. Должность', type: 'field' },
    { name: 'directorPhone', label: 'Сведения о руководителе организации-Соисполнителя. Телефон', type: 'field' },
    { name: 'directorEmail', label: 'Сведения о руководителе организации-Соисполнителя. E-mail', type: 'field' },
    { name: 'contactCommonFullName', label: 'Сведения об ответственном контактном лице от Соисполнителя. ФИО', type: 'field' },
    { name: 'contactCommonPosition', label: 'Сведения об ответственном контактном лице от Соисполнителя. Должность', type: 'field' },
    { name: 'contactCommonPhone', label: 'Сведения об ответственном контактном лице от Соисполнителя. Телефон', type: 'field' },
    { name: 'contactCommonEmail', label: 'Сведения об ответственном контактном лице от Соисполнителя. E-mail', type: 'field' },
    { name: 'theme', label: '5.1. Тема научно-исследовательских работ (НИР) Соисполнителя', type: 'field' },
    // { name: 'task', label: '5.2. Техническое задание на выполнение НИР', type: 'field' },
    // { name: 'goal', label: '5.2.1. Цель выполнения НИР', type: 'field' },
    // { name: 'results', label: '5.2.2. Перечень результатов, подлежащих получению по окончанию выполнения НИР', type: 'field' },
    // { name: 'requirements', label: '5.2.3. Требования к выполняемым работам', type: 'field' },
    // { name: 'resultsRequirements', label: '5.2.4. Технические требования к научно-техническим результатам НИР', type: 'field' },
    // { name: 'docsRequirements', label: '5.2.5. Требования к разрабатываемой документации', type: 'field' },
  ],
  finance: [],
  coexecutors: [],
  // applications2AuthorTabEvent_ids: [
  //   { name: 'name', label: '1. Наименование мероприятия', type: 'field' },
  //   { name: 'dates', label: '2. Дата(-ты) проведения мероприятия', type: 'field' },
  //   { name: 'city', label: '3. Город проведения мероприятия', type: 'field' },
  //   { name: 'form', label: '4. Форма представления результатов, полученных при выполнении Проекта', type: 'field' },
  //   { name: 'presenterFio', label: 'В случае личного представления результатов членом научного коллектива, укажите ФИО докладчика – члена коллектива специалистов', type: 'field' },
  //   { name: 'isInternational', label: '5. Мероприятие является международным', type: 'field' },
  //   { name: 'numberOfPeople', label: '6. Укажите примерное количество участников мероприятия, имевших возможность ознакомиться с результатами выполнения Проекта, представленными в ходе данного мероприятия', type: 'field' },
  // ],
  // applications2AuthorTabRid_ids: [
  //   { name: 'ridType', label: '1. Вид охранного документа', type: 'field' },
  //   { name: 'name', label: '3. Наименование РИД', type: 'field' },
  //   { name: 'fio', label: '4. Наименование и реквизиты правообладателя (правообладателей)', type: 'field' },
  //   { name: 'applicationNumber', label: '5. Номер заявки на государственную регистрацию объекта интеллектуальной собственности', type: 'field' },
  //   { name: 'date', label: '6. Дата подачи заявки на государственную регистрацию объекта интеллектуальной собственности', type: 'field' },
  //   { name: 'documentNumber', label: '7. Номер охранного документа', type: 'field' },
  //   { name: 'regDate', label: '8. Дата регистрации охранного документа', type: 'field' },
  //   { name: 'endDate', label: '9. Дата окончания действия охранного документа', type: 'field' },
  //   { name: 'annotation', label: '10. Аннотация', type: 'field' },
  //   { name: 'documentURL', label: '11. Ссылка на документ на сайте ФИПС', type: 'field' },
  // ],
  // applications2AuthorTabPublications_ids: [
  //   { name: 'authors', label: '1. Авторы публикации', type: 'field' },
  //   { name: 'name', label: '2. Название публикации', type: 'field' },
  //   { name: 'date', label: '3. Месяц и год публикации', type: 'field' },
  //   { name: 'keyWords', label: '4. Ключевые слова', type: 'field' },
  //   { name: 'type', label: '5. Вид публикации', type: 'field' },
  //   { name: 'publisherName', label: '6. Название издания', type: 'field' },
  //   { name: 'qvartil', label: '10. Укажите квартиль (Q_) по импакт-фактору JCR Science Edition, JCR Social Sciences Edition, по SJR (квартиль издания в Scopus определяется по базе данных https://www.scimagojr.com/ или https://journalsearches.com/)', type: 'field' },
  //   { name: 'publicationParams', label: '11. Выходные данные публикации (номер, том, выпуск, страницы)', type: 'field' },
  //   { name: 'isWebOfScience', label: '16. Издание индексируется базой данных Web of Science Core Collection', type: 'field' },
  //   { name: 'impactFactor', label: '17. Импакт-фактор издания', type: 'field' },
  //   { name: 'isScopus', label: '18. Издание индексируется базой данных Scopus', type: 'field' },
  //   { name: 'isRinc', label: '19. Издание индексируется базой данных РИНЦ', type: 'field' },
  //   { name: 'isVac', label: '20. Входит в перечень научных журналов ВАК с Импакт-фактором > 0,5', type: 'field' },
  //   { name: 'isAffiliate', label: '21. Публикация аффилирована с организацией-грантополучателем', type: 'field' },
  // ],
  // applications2AuthorTabPeople_ids: [
  //   { name: 'lastName', label: '4.3.1. ФИО. Фамилия', type: 'field' },
  //   { name: 'firstName', label: '4.3.1. ФИО. Имя', type: 'field' },
  //   { name: 'middleName', label: '4.3.1. ФИО. Отчество', type: 'field' },
  //   { name: 'birthDate', label: '4.3.2. Дата рождения', type: 'field' },
  //   { name: 'education', label: '4.3.3. Образование', type: 'field' },
  //   { name: 'degree', label: '4.3.4. Ученая степень', type: 'field' },
  //   { name: 'title', label: '4.3.5. Ученое звание', type: 'field' },
  //   { name: 'achievements', label: '4.3.6. Профессиональные достижения', type: 'field' },
  // { name: 'applications2AuthorTabPublications_ids', label: '4.3.7. Научные публикации, характеризующие вклад члена команды в реализацию Проекта за последние 5 лет:', type: 'inner' },
  // { name: 'hirsh', label: '4.3.8. Индекс Хирша', type: 'field' },
  // { name: 'applications2AuthorTabEvent_ids', label: '4.3.10. Доклады на международных научно-практических конференциях за последние 5 (Пять) лет, характеризующие вклад члена команды в реализацию Проекта', type: 'inner' },
  // { name: 'applications2AuthorTabRid_ids', label: '4.3.11. Наличие патентов и (или) заявок на получение патента на изобретение) (при наличии)', type: 'field' },
  // ],
};

export default applicationFields;

//    { name: '', label: '', type: 'field' },
