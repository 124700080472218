/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row, Col, Card, Button, Popconfirm, Form, notification, Spin, Anchor, Alert, Modal, Upload,
} from 'antd';
import { LoadingOutlined, InboxOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import ShortTab from './ShortTab/ShortTab';
import AuthorTab from './AuthorTab/AuthorTab';
import InfoTab from './InfoTab/InfoTab';
import FinanceTab from './FinanceTab/FinanceTab';
import PersonsTab from './PersonsTab/PersonsTab';
import RequisitesTab from './RequisitesTab/RequisitesTab';
// import applicationFields from './applicationFields';
import getUserRole from '../../../utils/localeStorage/getUserRole';
import getUserId from '../../../utils/localeStorage/getUserId';
import { editApplications2, uploadFile, editApplications2Costs, getApplications2ById, escapeHTML, unescapeHTML } from '../../../utils/http';
import fieldsWithSums from './FinanceTab/fieldsWithSums';
import './Common.css';
import exportFiles from './exportFiles';
import CheckListModal from './CheckList/CheckListModal';
import addWorkDays from './addWorkDays';
import checkListRowFields from './CheckList/checkListRowFields';
import { approveValidation } from '../../../utils/approveValidation';
import ConclusionsTab from './ConclusionsTab/ConclusionsTab';


const { Dragger } = Upload;
const fieldsWithFiles = ['epidemicFiles_ids', 'economicEffectFiles_ids', 'medicalEffectFiles_ids', 'stateFiles_ids', 'financeFiles_ids', 'supervisorSOPDFiles_ids',
  'supervisorRIDFiles_ids', 'directorSOPDFiles_ids', 'contactCommonSOPDFiles_ids', 'contactFinanceSOPDFiles_ids', 'materials_project'];
function Common({ applicationProp, onRedirectToList }) {
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [application, setApplication] = useState(null);
  const [files, setFiles] = useState({});
  const formRef = useRef();
  const [singleFile, setSingleFile] = useState(null);
  const [isShowUploadSingleFileModal, setIsShowUploadSingleFileModal] = useState(null);
  const [isShowDeclineModla, setIsShowDeclineModal] = useState(false);
  const [smetaPercentages, setSmetaPercentages] = useState({
    fot: 0,
    material: 0,
    equipment: 0,
    other: 0,
    paper: 0,
    people: 0,
  });

  useEffect(() => {
    const filesObj = {};
    fieldsWithFiles.forEach((field) => { filesObj[field] = applicationProp[field]; });
    setFiles(filesObj);
    const data = applicationProp;
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in data) {
      if (typeof data[key] === 'string') {
        data[key] = unescapeHTML(data[key]);
      }

      if (Array.isArray(data[key])) {
        data[key].forEach((v, i) => {
          if (typeof v === 'string') {
            data[key][i] = unescapeHTML(v);
          }
        });
      }
    }
    setApplication(data);
  }, []);

  const onSaveForm = async (tab) => {
    setLoading(true);
    const values = formRef.current.getFieldsValue();
    const rowsToSave = [];

    if (tab === 'smeta') {
      const years = [];
      if (['1', '2', '3'].includes(applicationProp.projectTerm)) {
        years.push('2023');
      }
      if (['2', '3'].includes(applicationProp.projectTerm)) {
        years.push('2024');
      }
      if (['3'].includes(applicationProp.projectTerm)) {
        years.push('2025');
      }


      // Зарпланая таблица
      years.forEach((year) => {
        fieldsWithSums.forEach((sumField) => {
          if (sumField === 'salarySupervisorTotal') {
            values[`${sumField}${year}`] = `${values[`${sumField}${year}_major`] || 0} руб. ${values[`${sumField}${year}_minor`] || 0} коп.`;
          } else {
            values[`${sumField}${year}`] = typeof values[`${sumField}${year}_major`] === 'object' ? null : `${values[`${sumField}${year}_major`] || 0} руб. ${values[`${sumField}${year}_minor`] || 0} коп.`;
          }
          delete values[`${sumField}${year}_major`];
          delete values[`${sumField}${year}_minor`];
        });
      });

      values.salaryTotal = `${values.salaryTotal_major || 0} руб. ${values.salaryTotal_minor || 0} коп.`;
      delete values.salaryTotal_major;
      delete values.salaryTotal_minor;
      values.salaryAverageYear = `${values.salaryAverageYear_major || 0} руб. ${values.salaryAverageYear_minor || 0} коп.`;
      delete values.salaryAverageYear_major;
      delete values.salaryAverageYear_minor;
      values.salaryAverageMonth = `${values.salaryAverageMonth_major || 0} руб. ${values.salaryAverageMonth_minor || 0} коп.`;
      delete values.salaryAverageMonth_major;
      delete values.salaryAverageMonth_minor;

      // Другие таблицы с расходами
      if (tab === 'smeta') {
        const tables = ['material', 'equipment', 'other', 'paper'];
        tables.forEach((table) => {
          const total = {
            id: values[`${table}_id_total`],
            applications2_id: application.id,
            type: table,
            isTotal: true,
            majorSum: values[`${table}_majorSum_total`],
            minorSum: values[`${table}_minorSum_total`],
          };

          delete values[`${table}_id_total`];
          delete values[`${table}_majorSum_total`];
          delete values[`${table}_minorSum_total`];
          rowsToSave.push(total);
          years.forEach((year) => {
            rowsToSave.push(
              {
                id: values[`${table}_id_${year}`],
                applications2_id: application.id,
                type: table,
                isTotal: true,
                year,
                majorSum: values[`${table}_majorSum_${year}`],
                minorSum: values[`${table}_minorSum_${year}`],
              },
            );
            delete values[`${table}_id_${year}`];
            delete values[`${table}_majorSum_${year}`];
            delete values[`${table}_minorSum_${year}`];
            const valuesKeys = Object.keys(values);
            const yearRowsIds = valuesKeys.filter((k) => {
              const keyParts = k.split('_');
              return keyParts[0] === table && keyParts[2] === 'id' && keyParts[3] === year;
            }).map((r) => r.split('_')[1]);
            yearRowsIds.forEach((rowId) => {
              rowsToSave.push(
                {
                  id: values[`${table}_${rowId}_id_${year}`],
                  applications2_id: application.id,
                  type: table,
                  isTotal: false,
                  year,
                  name: values[`${table}_${rowId}_name_${year}`],
                  description: values[`${table}_${rowId}_description_${year}`],
                },
              );
              delete values[`${table}_${rowId}_id_${year}`];
              delete values[`${table}_${rowId}_name_${year}`];
              delete values[`${table}_${rowId}_description_${year}`];
            });
          });
        });
        application.applications2Costs_ids.forEach((oldRow) => {
          if (!rowsToSave.map((r) => r.id).includes(oldRow.id) && !oldRow.isTotal) {
            rowsToSave.push({ id: oldRow.id, isDeleted: true });
          }
        });
      }
      await editApplications2Costs(rowsToSave);
    }

    // Clear values from html entities
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in values) {
      if (typeof values[key] === 'string') {
        values[key] = escapeHTML(values[key]);
      }

      if (Array.isArray(values[key])) {
        values[key].forEach((v, i) => {
          if (typeof v === 'string') {
            values[key][i] = escapeHTML(v);
          }
        });
      }
    }


    const filesToSave = {};
    fieldsWithFiles.forEach((field) => { filesToSave[field] = files[field].map((f) => f.id).join(','); });
    editApplications2({
      ...values,
      ...filesToSave,
    })
      .then(() => {
        setLoading(false);
        setApplication({ ...application, ...values, applications2Costs_ids: tab === 'smeta' ? rowsToSave : application.applications2Costs_ids, year: '2024' });
        notification.success({ message: 'Успех', description: 'Изменения сохранены' });
      });
  };

  const onDeleteFile = (file, field) => setFiles({ ...files, [field]: files[field].filter((f) => f.id !== file.id) });

  const onAddFile = (info, field) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => setFiles({ ...files, [field]: [...files[field], file] }));
    }
  };

  const updateOriginalObject = (field, values) => {
    setApplication({ ...application, [field]: values });
  };

  const updateOriginalObjectMass = (values) => {
    setApplication({ ...application, ...values });
  };

  const onSendForApproval = async () => {
    setLoading(true);
    const newApplication = await getApplications2ById(application.id);

    const { isError, tabs } = approveValidation(newApplication);

    if (isError) {
      notification.error({
        message: 'Ошибка завершения заполнения. Заполните обязательные поля и исправьте ошибки!',
        style: { width: 1000 },
        duration: 100,
        description:
  <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll', height: '80vh' }}>
    {tabs.map((tab) => {
      if (tab.errorFields.length > 0) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{tab.label}</div>
            <ul>
              {tab.errorFields.map((f) => <li key={f}>{f}</li>)}
            </ul>
          </div>
        );
      } return null;
    })}
  </div>,
      });
      setLoading(false);
      return false;
    }
    const filesToSave = {};
    fieldsWithFiles.forEach((field) => { filesToSave[field] = files[field].map((f) => f.id).join(','); });
    // await editApplications2({ id: newApplication.id, status: 'На обработке' })
    //   .then(() => {
    //     setLoading(false);
    //     setApplication({ ...application, status: 'На обработке' });
    //     notification.success({ message: 'Успех', description: 'Изменения сохранены' });
    //   });
    // setLoading(false);
    return true;
  };

  const onTabClick = (tab) => {
    if (!selectedTab) {
      setSelectedTab(tab);
    }
    if (selectedTab && selectedTab !== tab) {
      notification.warning({ message: 'Необходимо закрыть предыдущий раздел' });
    }
  };

  const onGeneratePdf = async () => {
    const isOk = await onSendForApproval();

    if (isOk) {
      if (application.isGeneratePDF) {
        notification.warning({ message: 'Пожалуйста, дождитесь окончания генерации файла заявки' });
      } else {
        setLoading(true);
        editApplications2({ id: application.id, isGeneratePDF: true, printFormFull: '' })
          .then(() => {
            setLoading(false);
            setApplication({ ...application, isGeneratePDF: true, printFormFull: '' });
            notification.success({ message: 'Файл заявки формируется. Обновите страницу через пару минут, чтобы появилась кнопка для скачивания заявки' });
          });
      }
    }
  };

  const onSingleFileUpload = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          setSingleFile(file);
        });
    }
  };

  const onSingleFileRemove = () => {
    setSingleFile(null);
  };

  const onSaveSingleFileModal = () => {
    editApplications2({ id: application.id, [isShowUploadSingleFileModal]: singleFile.id, status: 'На обработке', reviewTillDate: addWorkDays(new Date(), 5) })
      .then(() => {
        setApplication({ ...application, [isShowUploadSingleFileModal]: singleFile, status: 'На обработке', sendOnReviewDate: moment().toISOString() });
        notification.success({ message: 'Сохранено' });
        setSingleFile(null);
        setIsShowUploadSingleFileModal(null);
      });
  };

  const onDeclineModal = (e, comments) => {
    const declineObj = {
      ...e,
      reviewAdmin_id: getUserId(),
      reviewDate: moment(),
      fixTillDate: comments ? addWorkDays(new Date(), 3) : null,
      id: applicationProp.id,
      declineComment: comments ? `<ol>${comments.map((l) => `<li>${l}</li>`).join('')}</ol>` : null,
    };
    editApplications2(declineObj)
      .then(() => {
        setLoading(false);
        setIsShowDeclineModal(false);
        setApplication({ ...application, ...declineObj });
        notification.success({ message: 'Письмо-уведомление успешно направлено Заявителю!' });
      });
  };

  const downloadAllDocs = () => {
    exportFiles(application);
  };

  const onDelete = () => {
    setLoading(true);
    editApplications2({ id: application.id, isDeleted: true })
      .then(() => onRedirectToList());
  };

  const onDraft = async () => {
    setLoading(true);
    editApplications2({
      id: application.id,
      reviewAdmin_id: null,
      reviewDate: null,
      fixTillDate: null,
      declineComment: null,
      status: 'Черновик',
      sendOnReviewDate: null,
      printFormFull: null,
      sendOnFirstReviewDate: null,
      isDeadline: null,
      reviewTillDate: null,
    })
      .then(() => onRedirectToList());
  };

  const onArchive = async () => {
    setLoading(true);
    editApplications2({
      id: application.id,
      reviewAdmin_id: null,
      reviewDate: null,
      fixTillDate: null,
      declineComment: null,
      status: 'В архиве',
      sendOnReviewDate: null,
      printFormFull: null,
      sendOnFirstReviewDate: null,
      isDeadline: null,
      reviewTillDate: null,
    })
      .then(() => onRedirectToList());
  };

  let isApplicationDisabled = application?.status === 'В архиве' || application?.status === 'На обработке' || application?.status === 'Принята к рассмотрению';
  if (getUserRole() === 'bigAdmin') {
    isApplicationDisabled = false;
  }

  const comments = [];
  if (application) {
    checkListRowFields.forEach((field) => {
      if (application[`${field.fieldName}Comment`]) {
        comments.push(application[`${field.fieldName}Comment`]);
      }
    });
  }

  // const showGenerateBtn = () => {
  //   if (getUserRole() === 'bigAdmin') {
  //     return true;
  //   }

  //   return !selectedTab && application?.status !== 'На обработке' && application?.status !== 'В архиве' && application?.status !== 'Принята к рассмотрению';
  // };

  return (
    <div className="common-wrapper">
      <Col>
        <Row>
          <Button disabled={loading} onClick={onGeneratePdf} type="primary" style={{ backgroundColor: 'green', marginRight: '10px', borderColor: 'green' }}>Сформировать файл заявки для подписи</Button>
          {application?.printFormFull && !selectedTab && application?.status !== 'На обработке' && application?.status !== 'В архиве' && application?.status !== 'Принята к рассмотрению' && <Button disabled={loading} onClick={() => window.open(application.printFormFull, '_blank')} style={{ marginRight: '10px' }}>Скачать файл заявки</Button>}
          {getUserRole() === 'bigAdmin' && application?.printFormFull
          && !selectedTab && application?.status !== 'На обработке'
          && application?.status !== 'В архиве'
          && application?.status !== 'Принята к рассмотрению'
          && <Button disabled={loading} type="primary" onClick={() => setIsShowUploadSingleFileModal('printFormFullSigned')} style={{ marginRight: '10px' }}>Загрузить подписанную заявку и направить на рассмотрение</Button>}
          {application?.printFormFullSigned && !selectedTab && <Button disabled={loading} onClick={() => window.open(application.printFormFullSigned, '_blank')} style={{ marginRight: '10px' }}>Скачать подписанный PDF</Button>}
          {/* {application?.status === 'На обработке' && getUserRole() === 'bigAdmin' && !selectedTab && <Button type="primary" disabled={loading} onClick={() => setIsShowDeclineModal(true)} style={{ marginRight: '10px' }}>Отклонить</Button>} */}
          {/* {application?.status === 'На обработке' && getUserRole() === 'bigAdmin' && !selectedTab
          && (
          <Popconfirm okText="Согласовать" cancelText="Отмена" onConfirm={() => onChangeStatus('Принята к рассмотрению')} title="Уверены, что хотите согласовать?">
            <Button type="primary" style={{ marginRight: '10px' }} disabled={loading}>Согласовать</Button>
          </Popconfirm>
          )} */}
          {(application?.status === 'На обработке' || application?.status === 'В архиве' || application?.status === 'Принята к рассмотрению') && getUserRole() === 'bigAdmin' && !selectedTab && <Button type="primary" disabled={loading} onClick={downloadAllDocs} style={{ marginRight: '10px' }}>Скачать приложения</Button>}
          {(application?.status === 'На обработке' || application?.status === 'В архиве') && getUserRole() === 'bigAdmin' && !selectedTab && <Button type="primary" style={{ marginRight: '10px', backgroundColor: 'green', borderColor: 'green' }} disabled={loading} onClick={() => setIsShowDeclineModal(true)}>Чек-лист</Button>}
          {application?.id && getUserRole() === 'bigAdmin' && !selectedTab && <Popconfirm okText="Удалить" cancelText="Отмена" onConfirm={onDelete} title="Вы уверены, что хотите удалить? Заявку можно будет восстановить через поддержку"><Button type="danger" style={{ marginRight: '10px' }} disabled={loading}>Удалить</Button></Popconfirm>}
          {application?.id && getUserRole() === 'bigAdmin' && !selectedTab && <Popconfirm okText="В черновик" cancelText="Отмена" onConfirm={onDraft} title="Вы уверены, что хотите вернуть заявку в черновик?"><Button type="danger" style={{ marginRight: '10px' }} disabled={loading}>В черновик</Button></Popconfirm>}
          {/* {application?.id && getUserRole() === 'bigAdmin' && !selectedTab && <Popconfirm okText="В архив" cancelText="Отмена" onConfirm={onArchive} title="Вы уверены, что хотите отправить заявку в архив?"><Button type="danger" style={{ marginRight: '10px' }} disabled={loading}>В архив</Button></Popconfirm>} */}
        </Row>

        {getUserRole() === 'bigAdmin' && application?.status === 'Черновик' && (
        <Alert
          type="error"
          style={{ margin: '10px 0px' }}
          message="Причина отклонения заявки"
          description={(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>{`Заявка №${application.displayID} от ${application.sendOnReviewDate} направлена на доработку по следующим причине(-ам):`}</div>
              {comments.map((comment, index) => (
                <div
                  key={comment}
                  style={{
                    whiteSpace: 'break-spaces', width: '100%', display: 'flex', flexDirection: 'row',
                  }}
                >
                  <div style={{ marginRight: '5px' }}>{`${index + 1}.`}</div>
                  <div>{comment}</div>
                </div>
              ))}
              <div style={{ fontWeight: 'bold' }}>{`Важно: Внести изменения в зявку №${application.displayID} возможно только один раз. Заявку №${application.displayID} от ${application.sendOnReviewDate} необходимо исправить до 23:59 10 октября 2024 года.`}</div>
            </div>
)}
        />
        )}

        {application?.status === 'На доработке' && (
        <Alert
          type="error"
          style={{ margin: '10px 0px' }}
          message="Причина отклонения заявки"
          description={(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>{`Заявка №${application.displayID} от ${application.sendOnReviewDate} направлена на доработку по следующим причине(-ам):`}</div>
              {comments.map((comment, index) => (
                <div
                  key={comment}
                  style={{
                    whiteSpace: 'break-spaces', width: '100%', display: 'flex', flexDirection: 'row',
                  }}
                >
                  <div style={{ marginRight: '5px' }}>{`${index + 1}.`}</div>
                  <div>{comment}</div>
                </div>
              ))}
              <div style={{ fontWeight: 'bold' }}>{`Важно: Внести изменения в зявку №${application.displayID} возможно только один раз. Заявку №${application.displayID} от ${application.sendOnReviewDate} необходимо исправить до 23:59 10 октября 2024 года.`}</div>
            </div>
)}
        />
        )}
        {application?.status === 'В архиве' && application?.isDeadline && (
        <Alert
          type="error"
          style={{ margin: '10px 0px' }}
          message="Причина отклонения заявки"
          description={`Заявка №${application.displayID} от ${application.sendOnReviewDate} была направлена в архив без права на редактирование по причине несоблюдения сроков по ее исправлению и доработке. Исправления необходимо было направить до 23:59 ${application.fixTillDate?.format('DD MMMM YYYY')} года.`}
        />
        )}
        {application?.status === 'В архиве' && !application?.isDeadline && (
        <Alert
          type="error"
          style={{ margin: '10px 0px' }}
          message="Причина отклонения заявки"
          description={(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>Заявка была повторно рассмотрена и направлена в архив в виду неустранения ранее направленных замечаний.</div>
              <div>Перечень неустраненных замечаний:</div>
              {comments.map((comment, index) => (
                <div
                  key={comment}
                  style={{
                    whiteSpace: 'break-spaces', width: '100%', display: 'flex', flexDirection: 'row',
                  }}
                >
                  <div style={{ marginRight: '5px' }}>{`${index + 1}.`}</div>
                  <div>{comment}</div>
                </div>
              ))}
              <div>
                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Важно:</span>
                <span>{`Повторные правки Заявки №${application.displayID} от ${application.sendOnReviewDate} более недопустимы!`}</span>
              </div>
            </div>
)}
        />
        )}
      </Col>

      {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 100, zIndex: 100 }} spin />} style={{ position: 'absolute', top: '40%', left: '40%' }} />}
      <Card
        style={{
          width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
        }}
        onClick={() => onTabClick('short')}
      >
        <Row justify="space-between" align="middle">
          <Col style={{ fontSize: '20px', color: '#212121' }}>Краткая информация о Проекте</Col>
          <Col>
            <Row gutter={10}>
              <Col style={{ cursor: 'pointer' }}>
                <Anchor offsetTop={50}>
                  {selectedTab === 'short' && <Button type="primary" disabled={isApplicationDisabled} onClick={onSaveForm} style={{ marginRight: '15px' }}>Сохранить</Button>}
                  {selectedTab === 'short' && <Popconfirm okText="Закрыть" cancelText="Отмена" onConfirm={() => setSelectedTab(null)} title="Если вы закроете без сохранения, все изменения будут утеряны"><Button>Закрыть</Button></Popconfirm>}
                  {/* {!selectedTab && <Button onClick={() => setSelectedTab('short')}>Открыть</Button>} */}
                </Anchor>
              </Col>
            </Row>
          </Col>
        </Row>
        {selectedTab === 'short' && application && (
        <Row>
          <Col span={24}>
            <Form ref={formRef}>
              <ShortTab
                applicationProp={application}
                disabled={loading || isApplicationDisabled}
                onAddFile={onAddFile}
                onDeleteFile={onDeleteFile}
                files={files}
                formRef={formRef}
                // updateOriginalObject={updateOriginalObject}
              />
            </Form>
          </Col>
        </Row>
        )}
      </Card>

      <Card
        style={{
          width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
        }}
        onClick={() => onTabClick('info')}
      >
        <Row justify="space-between" align="middle">
          <Col style={{ fontSize: '20px', color: '#212121' }}>Сведения о проекте</Col>
          <Col>
            <Row gutter={10}>
              <Col style={{ cursor: 'pointer' }}>
                <Anchor offsetTop={50}>
                  {selectedTab === 'info' && <Button type="primary" disabled={isApplicationDisabled} onClick={() => onSaveForm('infoTab')} style={{ marginRight: '15px' }}>Сохранить</Button>}
                  {selectedTab === 'info' && <Popconfirm okText="Закрыть" cancelText="Отмена" onConfirm={() => setSelectedTab(null)} title="Если вы закроете без сохранения, все изменения будут утеряны"><Button>Закрыть</Button></Popconfirm>}
                  {/* {!selectedTab && <Button onClick={() => setSelectedTab('info')}>Открыть</Button>} */}
                </Anchor>
              </Col>
            </Row>
          </Col>
        </Row>
        {selectedTab === 'info' && (
        <Row>
          <Col span={24}>
            <Form ref={formRef}>
              <InfoTab
                applicationProp={application}
                disabled={loading || isApplicationDisabled}
                onAddFile={onAddFile}
                onDeleteFile={onDeleteFile}
                files={files}
                updateOriginalObject={updateOriginalObject}
                formRef={formRef}
              />
            </Form>
          </Col>
        </Row>
        )}
      </Card>

      <Card
        style={{
          width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
        }}
        onClick={() => onTabClick('author')}
      >
        <Row justify="space-between" align="middle">
          <Col style={{ fontSize: '20px', color: '#212121' }}>Сведения о Заявителе и команде Проекта</Col>
          <Col>
            <Row gutter={10}>
              <Col style={{ cursor: 'pointer' }}>
                <Anchor offsetTop={50}>
                  {selectedTab === 'author' && <Button type="primary" disabled={isApplicationDisabled} onClick={onSaveForm} style={{ marginRight: '15px' }}>Сохранить</Button>}
                  {selectedTab === 'author' && <Popconfirm okText="Закрыть" cancelText="Отмена" onConfirm={() => setSelectedTab(null)} title="Если вы закроете без сохранения, все изменения будут утеряны"><Button>Закрыть</Button></Popconfirm>}
                  {/* {!selectedTab && <Button onClick={() => setSelectedTab('author')}>Открыть</Button>} */}
                </Anchor>
              </Col>
            </Row>
          </Col>
        </Row>
        {selectedTab === 'author' && (
        <Row>
          <Col span={24}>
            <Form ref={formRef}>
              <AuthorTab
                applicationProp={application}
                disabled={loading || isApplicationDisabled}
                updateOriginalObject={updateOriginalObject}
                formRef={formRef}
                onAddFile={onAddFile}
                onDeleteFile={onDeleteFile}
                files={files}
              />
            </Form>
          </Col>
        </Row>
        )}
      </Card>

      <Card
        style={{
          width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
        }}
        onClick={() => onTabClick('persons')}
      >
        <Row justify="space-between" align="middle">
          <Col style={{ fontSize: '20px', color: '#212121' }}>Сведения о Соисполнителях</Col>
          <Col>
            <Row gutter={10}>
              <Col style={{ cursor: 'pointer' }}>
                <Anchor offsetTop={50}>
                  {selectedTab === 'persons' && <Popconfirm okText="Закрыть" cancelText="Отмена" onConfirm={() => setSelectedTab(null)} title="Если вы закроете без сохранения, все изменения будут утеряны"><Button>Закрыть</Button></Popconfirm>}
                  {/* {!selectedTab && <Button onClick={() => setSelectedTab('persons')}>Открыть</Button>} */}
                </Anchor>
              </Col>
            </Row>
          </Col>
        </Row>
        {selectedTab === 'persons' && (
        <Row>
          <Col span={24}>
            <Form ref={formRef}>
              <PersonsTab
                applicationProp={application}
                disabled={loading || isApplicationDisabled}
                updateOriginalObject={updateOriginalObject}
                updateOriginalObjectMass={updateOriginalObjectMass}
                onAddFile={onAddFile}
                onDeleteFile={onDeleteFile}
                files={files}
              />
            </Form>
          </Col>
        </Row>
        )}
      </Card>

      <Card
        style={{
          width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
        }}
        onClick={() => onTabClick('finance')}
      >
        <Row justify="space-between" align="middle">
          <Col style={{ fontSize: '20px', color: '#212121' }}>Смета и план-график реализации проекта</Col>
          <Col>
            <Row gutter={10}>
              <Col style={{ cursor: 'pointer' }}>
                <Anchor offsetTop={50}>
                  {selectedTab === 'finance' && <Button type="primary" disabled={isApplicationDisabled} onClick={() => onSaveForm('smeta')} style={{ marginRight: '15px' }}>Сохранить</Button>}
                  {selectedTab === 'finance' && <Popconfirm okText="Закрыть" cancelText="Отмена" onConfirm={() => setSelectedTab(null)} title="Если вы закроете без сохранения, все изменения будут утеряны"><Button>Закрыть</Button></Popconfirm>}
                  {/* {!selectedTab && <Button onClick={() => setSelectedTab('finance')}>Открыть</Button>} */}
                </Anchor>
              </Col>
            </Row>
          </Col>
        </Row>
        {selectedTab === 'finance' && (
        <Row>
          <Col span={24}>
            <Form ref={formRef}>
              <FinanceTab
                applicationProp={application}
                disabled={loading || isApplicationDisabled}
                formRef={formRef}
                onAddFile={onAddFile}
                onDeleteFile={onDeleteFile}
                files={files}
                onPercentagesUpdate={setSmetaPercentages}
              />
            </Form>
          </Col>
        </Row>
        )}
      </Card>

      {getUserRole() === 'bigAdmin' && (
      <Card
        style={{
          width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
        }}
        onClick={() => onTabClick('requisites')}
      >
        <Row justify="space-between" align="middle">
          <Col style={{ fontSize: '20px', color: '#212121' }}>Реквизиты Грантополучателя</Col>
          <Col>
            <Row gutter={10}>
              <Col style={{ cursor: 'pointer' }}>
                <Anchor offsetTop={50}>
                  {selectedTab === 'requisites' && <Button type="primary" disabled={isApplicationDisabled} onClick={onSaveForm} style={{ marginRight: '15px' }}>Сохранить</Button>}
                  {selectedTab === 'requisites' && <Popconfirm okText="Закрыть" cancelText="Отмена" onConfirm={() => setSelectedTab(null)} title="Если вы закроете без сохранения, все изменения будут утеряны"><Button onClick={() => setSelectedTab(null)}>Закрыть</Button></Popconfirm>}
                </Anchor>
              </Col>
            </Row>
          </Col>
        </Row>
        {selectedTab === 'requisites' && (
        <Row>
          <Col span={24}>
            <Form ref={formRef}>
              <RequisitesTab
                applicationProp={application}
                disabled={loading || isApplicationDisabled}
                updateOriginalObject={updateOriginalObject}
                formRef={formRef}
                onAddFile={onAddFile}
                onDeleteFile={onDeleteFile}
                files={files}
              />
            </Form>
          </Col>
        </Row>
        )}
      </Card>
      )}

      <Card
        style={{
          width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
        }}
        onClick={() => onTabClick('conclusion')}
      >
        <Row justify="space-between" align="middle">
          <Col style={{ fontSize: '20px', color: '#212121' }}>Заключения по Заявке</Col>
          <Col>
            <Row gutter={10}>
              <Col style={{ cursor: 'pointer' }}>
                <Anchor offsetTop={50}>
                  {selectedTab === 'conclusion' && <Button type="primary" disabled={isApplicationDisabled} onClick={onSaveForm} style={{ marginRight: '15px' }}>Сохранить</Button>}
                  {selectedTab === 'conclusion' && <Popconfirm okText="Закрыть" cancelText="Отмена" onConfirm={() => setSelectedTab(null)} title="Если вы закроете без сохранения, все изменения будут утеряны"><Button onClick={() => setSelectedTab(null)}>Закрыть</Button></Popconfirm>}
                </Anchor>
              </Col>
            </Row>
          </Col>
        </Row>
        {selectedTab === 'conclusion' && (
        <Row>
          <Col span={24}>
            <Form ref={formRef}>
              <ConclusionsTab
                applicationProp={application}
                disabled={loading || isApplicationDisabled}
                updateOriginalObject={updateOriginalObject}
                formRef={formRef}
                onAddFile={onAddFile}
                onDeleteFile={onDeleteFile}
                files={files}
              />
            </Form>
          </Col>
        </Row>
        )}
      </Card>

      {isShowUploadSingleFileModal && (
        <Modal
          visible
          width="70vw"
          maskClosable={false}
          onCancel={() => { setSingleFile(null); setIsShowUploadSingleFileModal(null); }}
          title="Загрузка подписанного файла заявки"
          footer={[
            <Button key="submit" type="primary" onClick={onSaveSingleFileModal}>
              Загрузить подписанную заявку и направить на рассмотрение
            </Button>]}
        >
          <Col>
            {!singleFile && (
            <Dragger
              showUploadList={false}
              onChange={onSingleFileUpload}
              action={null}
              accept=".pdf"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Выберите или перенесите файл в формате .pdf, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
            </Dragger>
            )}
            {singleFile
            && (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(singleFile.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={onSingleFileRemove} /></Col>
              <Col>{singleFile.name}</Col>
            </Row>
            )}
          </Col>
        </Modal>
      )}

      {isShowDeclineModla && (
      <CheckListModal
        onSave={(e, c) => onDeclineModal(e, c)}
        onCancel={() => setIsShowDeclineModal(false)}
        application={application}
      />
      )}
    </div>
  );
}

Common.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  onRedirectToList: PropTypes.func.isRequired,
};

export default Common;
