/* eslint-disable no-nested-ternary */
import React from 'react';
import { Form, InputNumber } from 'antd';


const fields = [
  { name: 'fot', label: 'Оплата труда работников (включая расходы на оплату страховых взносов на ФОТ)' },
  { name: 'material', label: 'Расходы на приобретение материалов и комплектующих, необходимых для реализации проекта' },
  { name: 'equipment', label: 'Расходы, на приобретение оборудования и иного имущества, необходимых для реализации Проекта' },
  { name: 'other', label: 'Прочие прямые расходы' },
  { name: 'paper', label: 'Накладные и общехозяйственные расходы, предусмотренные учетной политикой организации' },
  { name: 'people', label: 'Оплата работ, выполняемых Соисполнителями' },
];


const getRow = (index, applicationProp, disabled, onSumChange, projectYears) => {
  const row = fields[index - 1];
  return (
    <div className="common-table-row">
      <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }}>{index}</div>
      <div className="common-table-row-cell" style={{ width: '25%', textAlign: 'left' }}>{row.label}</div>
      <div className="common-table-row" style={{ width: '70%' }}>
        <div
          className="common-table-row-cell"
          style={{
            width: '30%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
          }}
        >
          <Form.Item
            style={{ width: '55%' }}
            name={`${row.name}TotalMajor`}
            initialValue={applicationProp[`${row.name}TotalMajor`]}
          >
            <InputNumber disabled />
          </Form.Item>
          <span>руб.</span>
          <Form.Item
            style={{ width: '25%' }}
            name={`${row.name}TotalMinor`}
            initialValue={applicationProp[`${row.name}TotalMinor`]}
          >
            <InputNumber disabled />
          </Form.Item>
          <span>коп.</span>
        </div>
        {projectYears.map((year) => (
          <div
            key={year}
            className="common-table-row-cell"
            style={{
              width: `${60 / projectYears.length}%`, textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
          >
            <Form.Item
              style={{ width: '55%' }}
              name={`${row.name}${year}Major`}
              initialValue={applicationProp[`${row.name}${year}Major`]}
            >
              <InputNumber disabled={disabled} min={0} onChange={() => onSumChange()} />
            </Form.Item>
            <span>руб.</span>
            <Form.Item
              style={{ width: '25%' }}
              name={`${row.name}${year}Minor`}
              initialValue={applicationProp[`${row.name}${year}Minor`]}
            >
              <InputNumber disabled={disabled} max={99} min={0} onChange={() => onSumChange()} />
            </Form.Item>
            <span>коп.</span>
          </div>
        ))}
        <Form.Item
          style={{ width: '10%' }}
          name={`${row.name}Percent`}
          initialValue={applicationProp[`${row.name}Percent`]}
        >
          <InputNumber disabled />
        </Form.Item>
      </div>
    </div>
  );
};

export default getRow;
