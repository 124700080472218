/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Button, Form, Input, Row, Card, Alert, Upload, notification, Popconfirm, Modal, Radio, Select, DatePicker, InputNumber, Spin, Menu,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined, LoadingOutlined,
} from '@ant-design/icons';
import {
  editApplicationStepReport, uploadFile, getApplicationStepReportById, generateFinancialReport, generateAnnotationReport, editApplicationStepReportStepSevenRows, editApplication,
} from '../../../../../../utils/http';
import organisationsCatalogue from '../../../../utils/organisationsCatalogue';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';
import ReportPublicationsModule from './ReportPublicationsModule';
import ReportEventsModule from './ReportEventsModule';
import ReportRidModule from './ReportRidModule';
import FinancialReport from '../FinancialReportComponents/FinancialReport';
import NewAnnotationReport from '../NewAnnotationReport/NewAnnotationReport';
import SectionSevenTable from './SectionSevenTable';
import PlanFactLeft from '../PlanFactLeft/PlanFactLeft';
import exportFinanceFiles from '../../../../utils/exportFinanceFiles';


const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;
const customAnnotationReportIds = ['TEST/22', '2312-44/22', '2312-29/22', '2212-12/22', '2312-32/22', '1912-1/22', '0403-1/22', '2708-1/22'];
const customFormFields = ['projectVAC', 'projectRINC', 'projectRID', 'projectEvents', 'projectPublications', 'projectPatients', 'projectPatientsMain', 'projectPatientsControl',
  'projectAudience', 'projectEventsDemonstration', 'projectPatientsCompare', 'projectActs', 'projectExamples', 'projectProtocols'];
const hiddenFinancialReportIds = ['f9e4f6c6-df88-4000-98df-a90ad11b29ca', 'e3cf0459-c3cb-45ac-9dc7-fa4d5e0096a6'];
class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepsCatalogue: [],
      application: null,
      disabled: this.props.disabled,
      isShowCommonBlock: false,
      annotationReport: this.props.annotationReport,
      financialReport: this.props.financialReport,
      stepFiles: [],
      loading: true,
      annotationIsPresented: null,
      annotationIsIntellectualProperty: null,
      annotationIsResultsAchieved: null,
      annotationIsPublications: null,
      isShowDeclineModal: false,
      annotationComment: '',
      annotationTasksDone: null,
      isShowUploadActModal: false,
      uploadActFile: null,
      projectActsFiles_ids: [],
      projectResultsDescriptionFiles_ids: [],
      projectShortAnnotationFiles: [],
      isShowUploadSingleFileModal: null,
      singleFile: null,
      planFactLeft: false,
    };
    this.formRef = React.createRef();
    this.commonFormRef = React.createRef();
    this.annotationFormRef = React.createRef();
  }

  componentDidMount() {
    const { initialApplication } = this.props;
    this.setState({
      loading: false,
      application: initialApplication,
      stepFiles: initialApplication.annotationTasksDoneAttachments,
      annotationIsPresented: initialApplication.annotationIsPresented,
      annotationIsIntellectualProperty: initialApplication.annotationIsIntellectualProperty,
      annotationIsResultsAchieved: initialApplication.annotationIsResultsAchieved,
      annotationIsPublications: initialApplication.annotationIsPublications,
      annotationTasksDone: initialApplication.annotationTasksDone,
      projectActsFiles_ids: initialApplication.projectActsFiles_ids,
      projectResultsDescriptionFiles_ids: initialApplication.projectResultsDescriptionFiles_ids,
      projectShortAnnotationFiles: initialApplication.projectShortAnnotationFiles,
      stepsCatalogue: [
        {
          ano: initialApplication.ano1date,
          fin: initialApplication.fin1date,
        },
        { fin: initialApplication.fin2date },
        { fin: initialApplication.fin3date },
        { fin: initialApplication.fin4date },
        { fin: initialApplication.fin5date },
        { fin: initialApplication.fin6date },
      ],
    });
  }

  onDeleteSignedAnnotation = () => {
    const { application } = this.state;
    this.setState({ loading: true });
    editApplicationStepReport({ id: application.id, annotationReportPDFSigned: null })
      .then(() => {
        this.setState({
          loading: false,
          application: { ...application, annotationReportPDFSigned: null },
        });
        notification.success({ message: 'Успешно' });
      });
  }

  onDeleteSignedFinancial = () => {
    const { application } = this.state;
    this.setState({ loading: true });
    editApplicationStepReport({ id: application.id, financialReportPDFSigned: null })
      .then(() => {
        this.setState({
          loading: false,
          application: { ...application, financialReportPDFSigned: null },
        });
        notification.success({ message: 'Успешно' });
      });
  }

  onSaveForm = () => {
    const { application } = this.state;
    this.setState({ disabled: true, loading: true });
    const values = this.formRef.current.getFieldsValue();
    const keys = Object.keys(values);
    let stepSevenRows = [];
    keys.forEach((k) => {
      if (k.split('_')[1]) {
        const obj = {
          id: k.split('_')[0],
          [k.split('_')[1]]: values[k],
          applicationStepReport_id: application.id,
        };
        const isExists = stepSevenRows.find((r) => r.id === obj.id);
        if (isExists) {
          stepSevenRows = stepSevenRows.map((r) => (r.id === obj.id ? { ...r, ...obj } : r));
        } else {
          stepSevenRows.push(obj);
        }
        delete values[k];
      }
    });
    Promise.all([editApplicationStepReportStepSevenRows(stepSevenRows), editApplicationStepReport({ id: application.id, ...values, stepSevenRows: stepSevenRows.map((s) => s.id) })])
      .then(() => {
        this.setState({ disabled: false, application: { id: application.id, ...application, ...values }, loading: false });
        notification.success({ message: 'Успешно' });
      });
  }

  onSaveAnnotationForm = () => {
    const {
      application, annotationIsPresented, annotationIsIntellectualProperty, annotationIsResultsAchieved, stepFiles,
      projectActsFiles_ids, projectResultsDescriptionFiles_ids, projectShortAnnotationFiles, stepsCatalogue,
    } = this.state;
    const { step } = this.props;
    this.setState({ loading: true });
    const values = this.annotationFormRef.current.getFieldsValue();

    const keys = Object.keys(values);
    let stepSevenRows = [];
    keys.forEach((k) => {
      if (k.split('_')[1]) {
        const obj = {
          id: k.split('_')[0],
          [k.split('_')[1]]: values[k],
          applicationStepReport_id: application.id,
        };
        const isExists = stepSevenRows.find((r) => r.id === obj.id);
        if (isExists) {
          stepSevenRows = stepSevenRows.map((r) => (r.id === obj.id ? { ...r, ...obj } : r));
        } else {
          stepSevenRows.push(obj);
        }
        delete values[k];
      }
    });

    const annotationTasksDoneAttachments = stepFiles.map((f) => f.id).join(',');
    const projectActsFiles = projectActsFiles_ids.map((f) => f.id).join(',');
    const projectResultsDescriptionFiles = projectResultsDescriptionFiles_ids.map((f) => f.id).join(',');
    const objToSave = {
      id: application.id,
      ...values,
      annotationIsPresented,
      annotationIsIntellectualProperty,
      annotationIsResultsAchieved,
      annotationTasksDoneAttachments,
      projectActsFiles_ids: projectActsFiles,
      projectResultsDescriptionFiles_ids: projectResultsDescriptionFiles,
      projectShortAnnotationFiles: projectShortAnnotationFiles.map((f) => f.id).join(','),
    };
    // const isCustomAnnotationReport = customAnnotationReportIds.includes(application?.displayID) || application?.isGrants2023;
    const isCustomAnnotationReport = step === application?.maxStepsNumber || customAnnotationReportIds.includes(application?.displayID) || application?.isGrants2023;
    if (isCustomAnnotationReport) {
      customFormFields.forEach((f) => {
        objToSave[`${f}Percent`] = values[`${f}Plan`] && values[`${f}Plan`] > 0 ? ((values[`${f}Fact`] / values[`${f}Plan`]) * 100).toFixed(0) : '-';
      });
      const steps = Array(application.maxStepsNumber).fill(0);
      steps.forEach((_, index) => {
        const fin = stepsCatalogue[index].fin.diff(values[`step${index + 1}ReportDateFinance`], 'days');
        objToSave[`step${index + 1}ReportDelayFinance`] = fin < 0 ? -fin : 0;
        if (index === 0) {
          const ano = stepsCatalogue[index].ano.diff(values[`step${index + 1}ReportDateAnnotation`], 'days');
          objToSave[`step${index + 1}ReportDelayAnnotation`] = ano < 0 ? -ano : 0;
        }
      });
    }
    Promise.all([editApplicationStepReportStepSevenRows(stepSevenRows), editApplicationStepReport(objToSave)])
      .then(() => {
        this.setState({
          loading: false,
          application: {
            id: application.id, ...application, ...values, annotationIsPresented, annotationIsIntellectualProperty, annotationIsResultsAchieved, annotationTasksDoneAttachments,
          },
        });
        notification.success({ message: 'Успешно сохранено' });
      });
  }

  onChangeAnnotationStatus = (annotationStatus) => {
    const { application, annotationComment } = this.state;
    this.setState({ loading: true });
    if (annotationStatus === 'Отклонено' || annotationStatus === 'Согласовано') {
      editApplicationStepReport({ id: application.id, annotationStatus, annotationComment })
        .then(() => {
          this.setState({
            loading: false,
            application: { ...application, annotationStatus, annotationComment },
            isShowDeclineModal: false,
            annotationComment: '',
          });
          notification.success({ message: 'Успешно изменен статус' });
        });
      return;
    }

    this.onSaveAnnotationForm();

    this.annotationFormRef.current.validateFields()
      .then(() => {
        this.setState({ loading: true });
        getApplicationStepReportById(application.id)
          .then(() => {
            this.setState({ loading: true });
            editApplicationStepReport({ id: application.id, annotationStatus, annotationComment })
              .then(() => {
                this.setState({
                  loading: false,
                  application: { ...application, annotationStatus, annotationComment },
                  isShowDeclineModal: false,
                  annotationComment: '',
                });
                notification.success({ message: 'Успешно изменен статус' });
                notification.warning({ message: 'Уважаемые грантополучатели! Отчет направлен на проверку, после ее завершения мы направим вам файл Аннотированного отчета с комментарием по дальнейшим действиям' });
              });
            // }
          });
      })
      .catch(() => {
        this.setState({ loading: false });
        notification.error({ message: 'Статус не изменен! Заполните все поля аннотированного отчета!' });
      });
  }

  onRemoveFile = (file, field) => {
    this.setState((prevState) => ({ [field]: prevState[field].filter((f) => f.id !== file.id) }));
  }

  onUploadFile = (info, field) => {
    this.setState({ loading: true });
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => this.setState((prevState) => ({
          [field]: [...prevState[field], file],
          loading: false,
        })));
    }
  }

  onSaveUploadActFile = () => {
    const { application, uploadActFile } = this.state;
    editApplicationStepReport({ id: application.id, actReport_id: uploadActFile.id })
      .then(() => this.setState({
        application: { ...application, actReport_id: uploadActFile },
        uploadActFile: null,
        isShowUploadActModal: false,
      }));
  }

  onActFileUpload = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          this.setState({ uploadActFile: file });
        });
    }
  }

  onActFileRemove = () => {
    this.setState({ uploadActFile: null });
  }

  onGenerateFinancialReport = () => {
    this.setState({ loading: true });
    const { application } = this.state;
    generateFinancialReport({ id: application.id })
      .then(() => this.setState({ loading: false }));
  }

  onGenerateAnnotationReport = () => {
    this.setState({ loading: true });
    const { application } = this.state;
    generateAnnotationReport({ id: application.id })
      .then(() => this.setState({ loading: false }));
  }

  onSingleFileUpload = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          this.setState({ singleFile: file });
        });
    }
  };

  onSingleFileRemove = () => {
    this.setState({ singleFile: null });
  };

  onSaveSingleFileModal = () => {
    const { application, isShowUploadSingleFileModal, singleFile } = this.state;
    editApplicationStepReport({ id: application.id, [isShowUploadSingleFileModal]: singleFile.id })
      .then(() => {
        notification.success({ message: 'Сохранено' });
        this.setState({ singleFile: null, isShowUploadSingleFileModal: null, application: { ...application, [isShowUploadSingleFileModal]: singleFile } });
      });
  };

  downloadAllDocs = () => {
    const { application } = this.state;
    exportFinanceFiles(application);
  }

  isChangeAdminEditFlag = (obj) => {
    editApplicationStepReport(obj)
      .then(() => {
        const { application } = this.state;
        notification.success({ message: 'Сохранено' });
        this.setState({ application: { ...application, ...obj } });
      });
  }

  onMenuClick = (key) => {
    const { onDownloadXls } = this.props;
    const { application } = this.state;
    switch (key) {
      case 'finEdit':
        if (!application?.isAllowFinanceEdit) {
          this.isChangeAdminEditFlag({ id: application?.id, isAllowFinanceEdit: true });
        } else {
          this.isChangeAdminEditFlag({ id: application?.id, isAllowFinanceEdit: false });
        }
        break;
      case 'finOnDownloadXls':
        onDownloadXls();
        break;
      case 'anoEdit':
        if (!application?.isAllowAnnotationEdit) {
          this.isChangeAdminEditFlag({ id: application?.id, isAllowAnnotationEdit: true });
        } else {
          this.isChangeAdminEditFlag({ id: application?.id, isAllowAnnotationEdit: false });
        }
        break;
      default:
        break;
    }
  }

  onSaveCommonForm = () => {
    const values = this.commonFormRef.current.getFieldsValue();
    editApplicationStepReport(values)
      .then(() => {
        const { updateParentApplication } = this.props;
        const { application } = this.state;
        notification.success({ message: 'Сохранено' });

        this.setState({ application: { ...application, ...values } });
        updateParentApplication({ applicationStepReports: application.applicationStepReports.map((step) => (step.id === values.id ? { ...step, endDate: values.endDate } : step)) });
      });
  }

  onDeleteStep = () => {
    const { application } = this.state;
    const newStepNumbers = Number((Number(application.maxStepsNumber) - 1).toFixed(0));
    const newApplicationStepReports = application.applicationStepReports.filter((step) => step.id !== application.id);
    Promise.all([
      editApplicationStepReport({ id: application.id, isDeleted: true }),
      editApplication({ id: application.parentAppId, maxStepsNumber: newStepNumbers, applicationStepReport_ids: newApplicationStepReports.map((e) => e.id).join(',') }),
    ]).then(() => {
      const { updateParentApplication } = this.props;
      notification.success({ message: 'Сохранено' });
      this.setState({ application: { ...application, maxStepsNumber: newStepNumbers, applicationStepReports: newApplicationStepReports } });
      updateParentApplication({ applicationStepReports: newApplicationStepReports, maxStepsNumber: newStepNumbers });
    });
  }

  render() {
    const {
      application, disabled, isShowCommonBlock, annotationReport, stepFiles, loading, annotationIsPresented, annotationIsIntellectualProperty, annotationIsResultsAchieved,
      isShowDeclineModal, annotationTasksDone, financialReport, annotationIsPublications, isShowUploadActModal, uploadActFile, projectActsFiles_ids, projectResultsDescriptionFiles_ids,
      projectShortAnnotationFiles, singleFile, isShowUploadSingleFileModal, planFactLeft,
    } = this.state;
    const {
      isShowAnnotationCard, step, prevStepApplications,
    } = this.props;
    const isCustomAnnotationReport = step === application?.maxStepsNumber || customAnnotationReportIds.includes(application?.displayID) || application?.isGrants2023;
    // const isFormDisabled = application?.status === 'Завершен' || application?.status === 'Расторгнут' || loading || ((application?.annotationStatus === 'На согласовании' || application?.annotationStatus === 'Согласовано') && getUserRole() !== 'bigAdmin');
    let isFormDisabled = loading || ((application?.annotationStatus === 'На согласовании' || application?.annotationStatus === 'Согласовано') && getUserRole() !== 'bigAdmin');
    const isFinalStep = step === application?.maxStepsNumber && !application.displayID.includes('С');
    const isHideFinancialReport = hiddenFinancialReportIds.includes(application?.parentAppId) && getUserRole() !== 'bigAdmin';
    const isOnlyFinancialReport = application?.id === '4c5ca6fe-5f66-4ccd-ab03-10678e375f9c';

    if (application?.isAllowAnnotationEdit) {
      isFormDisabled = false;
    }

    return (
      <div className="application-reports">
        {loading && (
          <Spin
            style={{ position: 'fixed', fontSize: '60px', top: '30%' }}
            indicator={(<LoadingOutlined style={{ fontSize: '100px', margin: '-80px 0px 0px -60px' }} spin />)}
            tip="Загрузка..."
          >
            <div />
          </Spin>
        )}
        {!application && !loading
                  && (
                  <Alert
                    message="Уважаемые Грантополучатели!"
                    description="Что-то пошло не так. Просьба обратиться к ответственному сотруднику оператора конкурсного отбора: Звонарева Елена Сергеевна +7(916)104-57-96"
                    type="warning"
                  />
                  )}
        {application && (
        <Col>
          {getUserRole() === 'bigAdmin' && (
          <Card style={{ width: '100%', borderRadius: '15px', marginTop: '20px' }}>
            <div className="application-main-wrapper" style={{ display: 'flex', padding: 0 }}>
              <Col>
                <Form
                  initialValues={{ remember: true }}
                  ref={this.commonFormRef}
                >
                  <Form.Item
                    name="id"
                    initialValue={application.id}
                    hidden
                  />
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">Дата окончания этапа</span>
                    <Form.Item
                      name="endDate"
                      initialValue={application.endDate}
                    >
                      <DatePicker style={{ width: '100%' }} placeholder="Заполняется автоматически" disabled={disabled} format="DD.MM.YYYY" />
                    </Form.Item>
                  </div>
                </Form>
                <Row style={{ marginTop: '20px' }} gutter={10}>
                  <Col><Button type="primary" onClick={this.onSaveCommonForm}>Сохранить</Button></Col>
                  {Number(application.stepNumber) === application.maxStepsNumber && (
                  <Col>
                    <Popconfirm
                      title="Вы действительно удалить этап?"
                      onConfirm={this.onDeleteStep}
                      okText="Да"
                      cancelText="Нет"
                    >
                      <Button type="danger">Удалить этап</Button>
                    </Popconfirm>
                  </Col>
                  )}
                </Row>
              </Col>
            </div>
          </Card>
          )}

          <Card
            style={{ width: '100%', borderRadius: '15px', marginTop: '20px' }}
          >
            <Row justify="space-between" align="middle">
              <Col style={{ fontSize: '20px', color: '#212121' }}>Основные сведения о проекте (заполняется автоматически)</Col>
              <Col style={{ cursor: 'pointer' }} onClick={() => this.setState((prevState) => ({ isShowCommonBlock: !prevState.isShowCommonBlock }))}>
                {isShowCommonBlock ? <Button>Закрыть</Button> : <Button>Посмотреть</Button>}
              </Col>
            </Row>
            {isShowCommonBlock && (
            <Row>
              <Alert
                style={{ marginBottom: '20px' }}
                description="Основные сведения о научно-практическом проекте в сфере медицины согласно заключенному грантовому соглашению"
                type="warning"
              />
              <Form
                initialValues={{ remember: true }}
                ref={this.formRef}
              >
                <Col>
                  {/* <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">Дата завершения этапа</span>
                    <Form.Item
                      name="endDate"
                      initialValue={application.endDate}
                    >
                      <DatePicker style={{ width: '100%' }} placeholder="Заполняется автоматически" disabled={disabled} />
                    </Form.Item>
                  </div> */}
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">1. Организационно-правовая форма и полное наименование медицинской организации государственной системы здравоохранения</span>
                    <Form.Item
                      name="formAndName"
                      initialValue={application.formAndName}
                    >
                      <Select
                        placeholder="Заполняется автоматически"
                        disabled={disabled}
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                      >
                        {organisationsCatalogue.map((u) => <Option key={u} value={u}>{u}</Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">2. Название темы научно-практического проекта в области медицины</span>
                    <Form.Item
                      name="themeName"
                      initialValue={application.themeName}
                    >
                      <TextArea disabled={disabled} placeholder="Заполняется автоматически" autoSize={{ minRows: 2 }} />
                    </Form.Item>
                  </div>
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">3. Дата окончания реализации научно-практического проекта</span>
                    <Form.Item
                      name="monthAndYearEnd"
                      initialValue={application.monthAndYearEnd}
                    >
                      <DatePicker style={{ width: '100%' }} placeholder="Заполняется автоматически" disabled={disabled} />
                    </Form.Item>
                  </div>
                  {!isHideFinancialReport && (
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">4. Полный объем одобренных средств для реализации научно-практического проекта</span>
                    <Row align="bottom" gutter={8}>
                      <Col>
                        <Form.Item
                          name="fullAgreementSumMajor"
                          initialValue={application.fullAgreementSumMajor}
                        >
                          <InputNumber disabled={disabled} style={{ width: '200px' }} placeholder="Заполняется автоматически" />
                        </Form.Item>
                      </Col>
                      <Col>руб.</Col>
                      <Col>
                        <Form.Item
                          name="fullAgreementSumMinor"
                          initialValue={application.fullAgreementSumMinor}
                        >
                          <InputNumber disabled={disabled} placeholder="Заполняется автоматически" />
                        </Form.Item>
                      </Col>
                      <Col>коп.</Col>
                    </Row>
                  </div>
                  )}
                  {!isHideFinancialReport && (
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">
                      {isFinalStep
                        ? '5. Стоимость выполнения работ в рамках научно-практического проекта (с учетом Дополнительных Соглашений, при наличии)'
                        : `5. Стоимость выполнения работ в рамках научно-практического проекта в рамках ${step} этапа (с учетом Дополнительных Соглашений, при наличии)`}
                    </span>
                    <Row align="bottom" gutter={8}>
                      <Col>
                        <Form.Item
                          name="stepSumTotalMajor"
                          initialValue={application.stepSumTotalMajor}
                        >
                          <InputNumber disabled={disabled} style={{ width: '200px' }} placeholder="Заполняется автоматически" />
                        </Form.Item>
                      </Col>
                      <Col>руб.</Col>
                      <Col>
                        <Form.Item
                          name="stepSumTotalMinor"
                          initialValue={application.stepSumTotalMinor}
                        >
                          <InputNumber disabled={disabled} placeholder="Заполняется автоматически" />
                        </Form.Item>
                      </Col>
                      <Col>коп.</Col>
                    </Row>
                  </div>
                  )}
                  {!isHideFinancialReport && (
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">
                      {isFinalStep
                        ? '6. Объем средств, фактически полученных от Автономной некоммерческой организации «Московский центр инновационных технологий в здравоохранении»'
                        : `6. Объем средств, фактически полученных от Автономной некоммерческой организации «Московский центр инновационных технологий в здравоохранении» на дату окончания ${step} этапа работ`}
                    </span>
                    <Row align="bottom" gutter={8}>
                      <Col>
                        <Form.Item
                          name="stepSumGainedMajor"
                          initialValue={application.stepSumGainedMajor}
                        >
                          <InputNumber disabled={disabled} style={{ width: '200px' }} placeholder="Заполняется автоматически" />
                        </Form.Item>
                      </Col>
                      <Col>руб.</Col>
                      <Col>
                        <Form.Item
                          name="stepSumGainedMinor"
                          initialValue={application.stepSumGainedMinor}
                        >
                          <InputNumber disabled={disabled} placeholder="Заполняется автоматически" />
                        </Form.Item>
                      </Col>
                      <Col>коп.</Col>
                    </Row>
                  </div>
                  )}
                  {/* <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">
                      7. Запланированные к выполнению работы по этапу
                      {' '}
                      {step}
                      {' '}
                      реализации Проекта в соответствии с заключенным Соглашением о предоставлении гранта
                    </span>
                    <Form.Item
                      name="stepWork"
                      initialValue={application.stepWork}
                    >
                      <TextArea disabled={disabled} placeholder="Заполняется автоматически" autoSize={{ minRows: 2 }} />
                    </Form.Item>
                  </div> */}
                  {!isFinalStep && (
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">
                      {isFinalStep
                        ? '7. Ожидаемые результаты, запланированные к получению в рамках реализации Проекта (в соответствии с Соглашением о предоставлении гранта)'
                        : `7. Запланированные к выполнению работы по этапу ${step} реализации Проекта в соответствии с заключенным Соглашением о предоставлении гранта`}
                    </span>
                    <SectionSevenTable values={application.stepSevenRows} disabled={getUserRole() !== 'bigAdmin'} />
                  </div>
                  )}
                  {!application?.isGrants2023 && !isFinalStep && (
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">
                      {isFinalStep
                        ? '8. Запланированные к достижению результаты на конец реализации Проекта в соответствии с заключенным Соглашением о предоставлении гранта'
                        : `8. Запланированные к достижению результаты на конец этапа ${step} реализации Проекта в соответствии с заключенным Соглашением о предоставлении гранта`}
                    </span>
                    <Form.Item
                      name="stepResults"
                      initialValue={application.stepResults}
                    >
                      <TextArea disabled={disabled} placeholder="Заполняется автоматически" autoSize={{ minRows: 2 }} />
                    </Form.Item>
                  </div>
                  )}
                  <Button type="primary" onClick={this.onSaveForm}>Сохранить</Button>
                </Col>
              </Form>
            </Row>
            )}
          </Card>

          {isShowAnnotationCard && !isOnlyFinancialReport && (
          <Card
            style={{ width: '100%', borderRadius: '15px', marginTop: '20px' }}
          >
            <Row justify="space-between" align="middle">
              <Col style={{ fontSize: '20px', color: '#212121' }}>Аннотированный отчет</Col>
              <Col>
                <Row gutter={10} style={{ alignItems: 'center' }}>
                  <Col style={{ cursor: 'pointer' }} onClick={() => this.setState((prevState) => ({ annotationReport: !prevState.annotationReport }))}>
                    {annotationReport ? <Button>Закрыть</Button> : <Button>Открыть</Button>}
                  </Col>
                  {getUserRole() === 'bigAdmin' && (
                  <Col>
                    <Button type="primary" onClick={this.onGenerateAnnotationReport} shape="round">
                      Сформировать
                    </Button>
                  </Col>
                  )}
                  {((application.annotationReportPDF && application.annotationStatus === 'Согласовано') || getUserRole() === 'bigAdmin') && (
                  <Col>
                    <Button type="primary" onClick={() => window.open(application.annotationReportPDF)} shape="round">
                      Скачать
                    </Button>
                  </Col>
                  )}
                  {application.annotationReportPDFSigned && (
                  <Col>
                    <Button type="primary" onClick={() => window.open(application.annotationReportPDFSigned)} shape="round" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}>
                      Скачать подписанный файл
                    </Button>
                  </Col>
                  )}
                  {application.annotationReportPDFSigned && (
                  <Col>
                    <Popconfirm
                      title="Вы действительно удалить"
                      onConfirm={this.onDeleteSignedAnnotation}
                      okText="Да"
                      cancelText="Нет"
                    >
                      <Button type="danger" shape="round">
                        Удалить подписанный файл
                      </Button>
                    </Popconfirm>
                  </Col>
                  )}
                  {((application.annotationReportPDF && application.annotationStatus === 'Согласовано') || getUserRole() === 'bigAdmin') && (
                    <Col>
                      <Button type="primary" onClick={() => this.setState({ isShowUploadSingleFileModal: 'annotationReportPDFSigned' })} shape="round">
                        Загрузить подписанный
                      </Button>
                    </Col>
                  )}
                  {getUserRole() === 'bigAdmin' && (
                  <Col style={{ width: '130px' }}>
                    <Menu
                      onClick={(e) => this.onMenuClick(e.key)}
                      selectedKeys={[]}
                      mode="horizontal"
                      style={{ border: 'none' }}
                      items={[{
                        label: 'Действия',
                        key: 'SubMenu',
                        children: [
                          {
                            label: !application?.isAllowFinanceEdit ? 'Принудительно открыть на редактирование' : 'Отменить принудительное редактирование',
                            key: 'anoEdit',
                          },
                        ],
                      }]}
                    />
                  </Col>
                  )}
                </Row>
              </Col>
            </Row>
            {annotationReport && (
            <Row>
              <Form
                initialValues={{ remember: true }}
                ref={this.annotationFormRef}
                style={{ width: '100%' }}
              >
                <Col>
                  {!isCustomAnnotationReport && (
                  <Col>
                    <Alert
                      style={{ margin: '20px 0px' }}
                      description="Уважаемые грантополучатели! Просим проверить корректность внесенных ниже сведений и при необходимости внести правки"
                      type="warning"
                    />
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">1. Фамилия Руководителя коллектива специалистов</span>
                      <Form.Item
                        name="agreementLastNameSpecialist"
                        initialValue={application.agreementLastNameSpecialist}
                      >
                        <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">2. Имя Руководителя коллектива специалистов</span>
                      <Form.Item
                        name="agreementFirstNameSpecialist"
                        initialValue={application.agreementFirstNameSpecialist}
                      >
                        <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">3. Отчество Руководителя коллектива специалистов (при наличии)</span>
                      <Form.Item
                        name="agreementMiddleNameSpecialist"
                        initialValue={application.agreementMiddleNameSpecialist}
                      >
                        <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">4. Контактный телефон Руководителя коллектива специалистов</span>
                      <Form.Item
                        name="agreementPhoneSpecialist"
                        initialValue={application.agreementPhoneSpecialist}
                      >
                        <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">5. Контактный адрес электронной почты Руководителя коллектива специалистов</span>
                      <Form.Item
                        name="agreementEmailSpecialist"
                        initialValue={application.agreementEmailSpecialist}
                      >
                        <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">6. Фамилия Руководителя организации-грантополучателя</span>
                      <Form.Item
                        name="agreementLastNameDirector"
                        initialValue={application.agreementLastNameDirector}
                      >
                        <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">7. Имя Руководителя организации-грантополучателя</span>
                      <Form.Item
                        name="agreementFirstNameDirector"
                        initialValue={application.agreementFirstNameDirector}
                      >
                        <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">8. Отчество Руководителя организации-грантополучателя (при наличии)</span>
                      <Form.Item
                        name="agreementMiddleNameDirector"
                        initialValue={application.agreementMiddleNameDirector}
                      >
                        <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">9. Должность Руководителя организации-грантополучателя</span>
                      <Form.Item
                        name="agreementPositionDirector"
                        initialValue={application.agreementPositionDirector}
                      >
                        <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
                      </Form.Item>
                    </div>
                    <Alert
                      style={{ margin: '20px 0px' }}
                      description={`Уважаемые грантополучатели! Внесите информацию для формирования аннотированного отчета по этапу ${step} работ`}
                      type="warning"
                    />
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">
                        {isFinalStep
                          ? '10. Запланированные к выполнению работы по реализации Проекта (заполняется автоматически в соответствии с заключенным Соглашением о предоставлении гранта)'
                          : `10. Запланированные к выполнению работы по этапу ${step} реализации Проекта (заполняется автоматически в соответствии с заключенным Соглашением о предоставлении гранта)`}
                      </span>
                      <Form.Item
                        name="stepWork"
                        initialValue={application.stepWork}
                      >
                        <TextArea disabled placeholder="Заполняется автоматически" autoSize={{ minRows: 2 }} />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">
                        {isFinalStep
                          ? '11. Запланированные к достижению результаты на конец реализации Проекта (заполняется автоматически в соответствии с заключенным Соглашением о предоставлении гранта)'
                          : `11. Запланированные к достижению результаты на конец этапа ${step} реализации Проекта (заполняется автоматически в соответствии с заключенным Соглашением о предоставлении гранта)`}
                      </span>
                      <Form.Item
                        name="stepResults"
                        initialValue={application.stepResults}
                      >
                        <TextArea disabled placeholder="Заполняется автоматически" autoSize={{ minRows: 2 }} />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label" style={{ marginBotom: 0 }}>
                        {isFinalStep
                          ? '12. Задачи, запланированные к выполнению в рамках реализации проекта'
                          : `12. Задачи, запланированные к выполнению в рамках ${step} этапа реализации проекта`}
                      </span>
                      <span style={{ fontStyle: 'italic' }}>
                        {isFinalStep
                          ? 'Необходимо сформулировать задачи исходя из запланированных к реализации в рамках работ и мероприятий (см. пункт 10 настоящей формы)'
                          : `Необходимо сформулировать задачи исходя из запланированных к реализации в рамках ${step} этапа работ и мероприятий (см. пункт 10 настоящей формы)`}
                      </span>
                      <Form.Item
                        name="annotationTasks"
                        initialValue={application.annotationTasks}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <TextArea disabled={isFormDisabled} placeholder="Введите текст" autoSize={{ minRows: 2 }} />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label" style={{ marginBotom: 0 }}>
                        {isFinalStep
                          ? '13. Сведения о фактически выполненных работах на конец реализации проекта в соответствии с поставленными задачами'
                          : `13. Сведения о фактически выполненных работах на конец Этапа ${step} реализации проекта в соответствии с поставленными задачами`}
                      </span>
                      <span style={{ fontStyle: 'italic' }}>
                        Указать краткое описание предварительных результатов реализации Проекта. Форма изложения должна дать возможность провести экспертизу результатов и оценить степень выполнения заявленного по данному этапу плана работ и мероприятий. Объем описания результатов должен составлять НЕ МЕНЕЕ
                        {' '}
                        <u>2 500 печатных знаков.</u>
                      </span>
                      <Form.Item
                        name="annotationTasksDone"
                        initialValue={application.annotationTasksDone}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <TextArea
                          autoSize={{ minRows: 2 }}
                          disabled={isFormDisabled}
                          placeholder="Введите текст"
                          onChange={(e) => this.setState({ annotationTasksDone: e.target.value })}
                        />
                      </Form.Item>
                      <span>{`${annotationTasksDone.length} из 2500`}</span>
                      <span style={{ fontStyle: 'italic' }}>При необходимости представления дополнительных материалов по проекту загрузите файлы</span>
                      <Dragger
                        showUploadList={false}
                        onChange={(e) => this.onUploadFile(e, 'stepFiles')}
                        disabled={isFormDisabled}
                        multiple
                        action={null}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб)</p>
                      </Dragger>
                      {stepFiles.map((f) => (
                        <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                          <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
                          {!loading && !isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveFile(f, 'stepFiles')} /></Col>}
                          <Col>{f.name}</Col>
                        </Row>
                      ))}
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">14. Для выполнения вышеуказанных работ и мероприятий были использованы следующие методы</span>
                      <Form.Item
                        name="annotationMethods"
                        initialValue={application.annotationMethods}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <TextArea disabled={isFormDisabled} placeholder="Введите текст" autoSize={{ minRows: 2 }} />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">15. В ходе реализации этапа Проекта была использована следующая материально-техническая база</span>
                      <Form.Item
                        name="annotationMaterials"
                        initialValue={application.annotationMaterials}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <TextArea disabled={isFormDisabled} placeholder="Введите текст" autoSize={{ minRows: 2 }} />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">
                        {isFinalStep
                          ? '16. Во время выполнения работ коллективом специалистов были опубликованы научные статьи по теме Проекта'
                          : `16. На этапе ${step} выполнения работ коллективом специалистов были опубликованы научные статьи по теме Проекта`}
                      </span>
                      <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="annotationIsPublications"
                        initialValue={application.annotationIsPublications}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          disabled={isFormDisabled}
                          optionType="button"
                          buttonStyle="solid"
                          onChange={(e) => this.setState({ annotationIsPublications: e.target.value })}
                        />
                      </Form.Item>
                    </div>
                    {annotationIsPublications === 'Да' && <ReportPublicationsModule publications={application.applicationStepPublications_ids} applicationId={application.id} />}
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17. Научные результаты, полученные при выполнении работ по Проекту, были представлены на научно-практических конференциях, симпозиумах, форумах (в том числе, международных)</span>
                      <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="annotationIsPresented"
                        initialValue={application.annotationIsPresented}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          onChange={(e) => this.setState({ annotationIsPresented: e.target.value })}
                          disabled={isFormDisabled}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    {annotationIsPresented === 'Да' && <ReportEventsModule publications={application.applicationStepEvents_ids} applicationId={application.id} />}
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18. За отчетный период возникли исключительные права на результаты интеллектуальной деятельности (РИД), созданные при выполнении проекта</span>
                      <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="annotationIsIntellectualProperty"
                        initialValue={application.annotationIsIntellectualProperty}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          onChange={(e) => this.setState({ annotationIsIntellectualProperty: e.target.value })}
                          disabled={isFormDisabled}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    {annotationIsIntellectualProperty === 'Да' && <ReportRidModule publications={application.applicationStepRid_ids} applicationId={application.id} />}
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">
                        {isFinalStep
                          ? '19. Таким образом, по итогам выполнения работ по Проекту, ожидаемые результаты, указанные в Плане-графике реализации проекта (Приложение № 1 к Соглашению):'
                          : `19. Таким образом, по итогам выполнения работ по этапу ${step} Проекта, ожидаемые результаты, указанные в Плане-графике реализации проекта (Приложение № 1 к Соглашению):`}
                      </span>
                      <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="annotationIsResultsAchieved"
                        initialValue={application.annotationIsResultsAchieved}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Достигнуты в полном объеме', value: 'Достигнуты в полном объеме' },
                            { label: 'Не достигнуты в полном объеме', value: 'Не достигнуты в полном объеме' },
                          ]}
                          onChange={(e) => this.setState({ annotationIsResultsAchieved: e.target.value })}
                          disabled={isFormDisabled}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    {annotationIsResultsAchieved === 'Не достигнуты в полном объеме' && (
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label" style={{ marginBotom: 0 }}>19.1. Перечень работ из Плана-графика реализации проекта, которые не были выполнены в связи с объективными обстоятельствами</span>
                      <span style={{ fontStyle: 'italic' }}>Привести описание работ и подробное пояснение о приведших к неисполнению обстоятельствах</span>
                      <Form.Item
                        name="annotationResultsWorksNotDone"
                        initialValue={application.annotationResultsWorksNotDone}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <TextArea disabled={isFormDisabled} placeholder="Введите текст" autoSize={{ minRows: 2 }} />
                      </Form.Item>
                    </div>
                    )}
                    {annotationIsResultsAchieved === 'Не достигнуты в полном объеме' && (
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label" style={{ marginBotom: 0 }}>19.2. Перечень работ, которые были выполнены досрочно взамен невыполненных в связи с объективными обстоятельствами</span>
                      <span style={{ fontStyle: 'italic' }}>При наличии, привести описание работ из Плана-графика реализации проекта</span>
                      <Form.Item
                        name="annotationResultsWorksDone"
                        initialValue={application.annotationResultsWorksDone}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <TextArea disabled={isFormDisabled} placeholder="Введите текст" autoSize={{ minRows: 2 }} />
                      </Form.Item>
                    </div>
                    )}
                    {annotationIsResultsAchieved === 'Не достигнуты в полном объеме' && (
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label" style={{ marginBotom: 0 }}>19.3. Информация о замене работ из Плана-графика реализации проекта, которые не были выполнены в связи с объективными обстоятельствами, на иные выполненные работы, которые соответствовали цели выполняемого исследования с обоснованием такого соответствия и равнозначности замены</span>
                      <span style={{ fontStyle: 'italic' }}>При наличии</span>
                      <Form.Item
                        name="annotationResultsWorksReplaced"
                        initialValue={application.annotationResultsWorksReplaced}
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                      >
                        <TextArea disabled={isFormDisabled} placeholder="Введите текст" autoSize={{ minRows: 2 }} />
                      </Form.Item>
                    </div>
                    )}
                  </Col>
                  )}
                  {isCustomAnnotationReport && (
                  <NewAnnotationReport
                    application={application}
                    isFormDisabled={isFormDisabled}
                    disabled={disabled}
                    projectActsFiles_ids={projectActsFiles_ids}
                    projectResultsDescriptionFiles_ids={projectResultsDescriptionFiles_ids}
                    onUploadFile={this.onUploadFile}
                    onRemoveFile={this.onRemoveFile}
                    projectShortAnnotationFiles={projectShortAnnotationFiles}
                    annotationFormRef={this.annotationFormRef}
                  />
                  )}
                  {/* {application?.status !== 'Завершен' && application?.status !== 'Расторгнут' && ( */}
                  <Row gutter={10} style={{ marginTop: '20px' }}>
                    {!isFormDisabled
                     && <Col><Button onClick={this.onSaveAnnotationForm}>Сохранить</Button></Col>}
                    {getUserRole() !== 'bigAdmin' && application.annotationStatus !== 'На согласовании' && application.annotationStatus !== 'Согласовано' && (
                    <Col>
                      <Popconfirm
                        title="Вы действительно хотите завершить заполнение Аннотированного отчета? После подачи отчета редактирование внесенных данных невозможно"
                        onConfirm={() => this.onChangeAnnotationStatus('На согласовании')}
                        okText="Да, подать отчет"
                        cancelText="Нет, продолжить редактирование"
                      >
                        <Button type="primary">Подать Аннотированный отчет</Button>
                      </Popconfirm>
                    </Col>
                    )}
                    {getUserRole() === 'bigAdmin' && application.annotationStatus === 'На согласовании' && (
                    <Col>
                      <Button type="primary" onClick={() => this.setState({ isShowDeclineModal: true })}>Отклонить Аннотированный отчет</Button>
                    </Col>
                    )}
                    {getUserRole() === 'bigAdmin' && application.annotationStatus === 'На согласовании' && (
                    <Col>
                      <Popconfirm
                        title="Вы действительно хотите Согласовать"
                        onConfirm={() => this.onChangeAnnotationStatus('Согласовано')}
                        okText="Да, согласовать"
                        cancelText="Нет, продолжить редактирование"
                      >
                        <Button type="primary">Согласовать Аннотированный отчет</Button>
                      </Popconfirm>
                    </Col>
                    )}
                  </Row>

                </Col>
              </Form>
            </Row>
            )}
          </Card>
          )}

          {!isHideFinancialReport && (
          <Card
            style={{
              width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
            }}
          >
            <Row justify="space-between" align="middle">
              <Col style={{ fontSize: '20px', color: '#212121' }}>Финансовый отчет</Col>
              <Col>
                <Row gutter={10} style={{ alignItems: 'center' }}>
                  <Col style={{ cursor: 'pointer' }} onClick={() => this.setState((prevState) => ({ financialReport: !prevState.financialReport }))}>
                    {financialReport ? <Button>Закрыть</Button> : <Button>Открыть</Button>}
                  </Col>
                  {getUserRole() === 'bigAdmin' && (
                  <Col>
                    <Button type="primary" onClick={this.downloadAllDocs} shape="round" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}>Скачать приложения</Button>
                  </Col>
                  )}
                  {getUserRole() === 'bigAdmin' && (
                  <Col>
                    <Button type="primary" onClick={this.onGenerateFinancialReport} shape="round">
                      Сформировать
                    </Button>
                  </Col>
                  )}
                  {application.financialReportPDF && (getUserRole() === 'bigAdmin' || application.financialReportStatus === 'Согласован') && (
                  <Col>
                    <Button type="primary" onClick={() => window.open(application.financialReportPDF)} shape="round">
                      Скачать
                    </Button>
                  </Col>
                  )}
                  {application.financialReportPDFSigned && (
                  <Col>
                    <Button type="primary" onClick={() => window.open(application.financialReportPDFSigned)} shape="round" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}>
                      Скачать подписанный файл
                    </Button>
                  </Col>
                  )}
                  {application.financialReportPDFSigned && (
                  <Col>
                    <Popconfirm
                      title="Вы действительно удалить"
                      onConfirm={this.onDeleteSignedFinancial}
                      okText="Да"
                      cancelText="Нет"
                    >
                      <Button type="danger" shape="round">
                        Удалить подписанный файл
                      </Button>
                    </Popconfirm>
                  </Col>
                  )}
                  {((application.financialReportPDF && application.financialReportStatus === 'Согласовано') || getUserRole() === 'bigAdmin') && (
                  <Col>
                    <Button type="primary" onClick={() => this.setState({ isShowUploadSingleFileModal: 'financialReportPDFSigned' })} shape="round">
                      Загрузить подписанный
                    </Button>
                  </Col>
                  )}
                  {getUserRole() === 'bigAdmin' && (
                  <Col style={{ width: '130px' }}>
                    <Menu
                      onClick={(e) => this.onMenuClick(e.key)}
                      selectedKeys={[]}
                      mode="horizontal"
                      style={{ border: 'none' }}
                      items={[{
                        label: 'Действия',
                        key: 'SubMenu',
                        children: [
                          {
                            label: !application?.isAllowFinanceEdit ? 'Принудительно открыть на редактирование' : 'Отменить принудительное редактирование',
                            key: 'finEdit',
                          },
                          {
                            label: 'Скачать XLS',
                            key: 'finOnDownloadXls',
                          },
                        ],
                      }]}
                    />
                  </Col>
                  )}
                </Row>
              </Col>
            </Row>
            {financialReport && (
            <Row>
              <Col span={24}>
                <FinancialReport application={application} prevStepApplications={prevStepApplications} step={step} />
              </Col>
            </Row>
            )}
          </Card>
          )}

          {!application?.isGrants2023 && !isOnlyFinancialReport && !isHideFinancialReport && (
          <Card
            style={{
              width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
            }}
          >
            <Row justify="space-between" align="middle">
              <Col style={{ fontSize: '20px', color: '#212121' }}>Акт сдачи-приемки выполненных работ по этапу</Col>
              <Col>
                <Row gutter={10}>
                  {getUserRole() === 'bigAdmin' && (
                  <Col>
                    <Button type="primary" onClick={() => this.setState({ isShowUploadActModal: true })} shape="round">
                      Загрузить
                    </Button>
                  </Col>
                  )}
                  {application.actReport_id && (
                  <Col>
                    <Button type="primary" onClick={() => window.open(application.actReport_id.urlLink)} shape="round" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}>
                      Скачать
                    </Button>
                  </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
          )}

          {getUserRole() === 'bigAdmin' && !isOnlyFinancialReport && (
          <Card
            style={{
              width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
            }}
          >
            <Row justify="space-between" align="middle">
              <Col style={{ fontSize: '20px', color: '#212121' }}>Файл презентации</Col>
              <Col>
                <Row gutter={10}>
                  <Col>
                    <Button type="primary" onClick={() => this.setState({ isShowUploadSingleFileModal: 'grantAgreementPresentation_id' })} shape="round">
                      Загрузить
                    </Button>
                  </Col>
                  {application.grantAgreementPresentation_id?.urlLink && (
                  <Col>
                    <Button type="primary" onClick={() => window.open(application.grantAgreementPresentation_id.urlLink)} shape="round" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}>
                      Скачать
                    </Button>
                  </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
          )}

          {getUserRole() === 'bigAdmin' && !isOnlyFinancialReport && (
          <Card
            style={{
              width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
            }}
          >
            <Row justify="space-between" align="middle">
              <Col style={{ fontSize: '20px', color: '#212121' }}>Файл справки</Col>
              <Col>
                <Row gutter={10}>
                  <Col>
                    <Button type="primary" onClick={() => this.setState({ isShowUploadSingleFileModal: 'grantAgreementCertificate_id' })} shape="round">
                      Загрузить
                    </Button>
                  </Col>
                  {application.grantAgreementCertificate_id?.urlLink && (
                  <Col>
                    <Button type="primary" onClick={() => window.open(application.grantAgreementCertificate_id.urlLink)} shape="round" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}>
                      Скачать
                    </Button>
                  </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
          )}

          {getUserRole() === 'bigAdmin' && !isOnlyFinancialReport && (
          <Card
            style={{
              width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
            }}
          >
            <Row justify="space-between" align="middle">
              <Col style={{ fontSize: '20px', color: '#212121' }}>Показатели эффективности</Col>
              <Col style={{ cursor: 'pointer' }} onClick={() => this.setState((prevState) => ({ planFactLeft: !prevState.planFactLeft }))}>
                {planFactLeft ? <Button>Закрыть</Button> : <Button>Открыть</Button>}
              </Col>
            </Row>
            {planFactLeft && (
            <Row>
              <Col span={24}>
                <PlanFactLeft application={application} />
              </Col>
            </Row>
            )}
          </Card>
          )}

        </Col>
        )}

        {isShowDeclineModal && (
        <Modal
          title="Отклонение"
          visible
          onCancel={() => this.setState({ isShowDeclineModal: false, annotationComment: '' })}
          footer={null}
        >
          <Col>
            <Row>Комментарий:</Row>
            <Row>
              <TextArea rows={3} style={{ width: '100%' }} onChange={(e) => this.setState({ annotationComment: e.target.value })} />
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Popconfirm
                title="Вы действительно хотите Отклонить"
                onConfirm={() => this.onChangeAnnotationStatus('Отклонено')}
                okText="Да, отклонить"
                cancelText="Нет, продолжить редактирование"
              >
                <Button danger>Отклонить</Button>
              </Popconfirm>
            </Row>
          </Col>
        </Modal>
        )}

        {isShowUploadSingleFileModal && (
        <Modal
          visible
          width="70vw"
          maskClosable={false}
          onCancel={() => this.setState({ isShowUploadSingleFileModal: null, singleFile: null })}
          title={`Загрузка файла для ${application.displayID}`}
          footer={[
            <Button key="submit" type="primary" onClick={this.onSaveSingleFileModal}>
              Загрузить
            </Button>]}
        >
          <Col>
            {!singleFile && (
            <Dragger
              showUploadList={false}
              onChange={this.onSingleFileUpload}
              action={null}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
            </Dragger>
            )}
            {singleFile
            && (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(singleFile.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={this.onSingleFileRemove} /></Col>
              <Col>{singleFile.name}</Col>
            </Row>
            )}
          </Col>
        </Modal>
        )}

        {isShowUploadActModal
        && (
        <Modal
          visible
          width="70vw"
          maskClosable={false}
          onCancel={() => this.setState({ isShowUploadActModal: false, uploadActFile: null })}
          title={`Загрузка файла для ${application.displayID}`}
          footer={[
            <Button key="submit" type="primary" onClick={this.onSaveUploadActFile}>
              Загрузить
            </Button>]}
        >
          <Col>
            {!uploadActFile && (
            <Dragger
              showUploadList={false}
              onChange={this.onActFileUpload}
              action={null}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
            </Dragger>
            )}
            {uploadActFile
            && (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(uploadActFile.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={this.onActFileRemove} /></Col>
              <Col>{uploadActFile.name}</Col>
            </Row>
            )}
          </Col>
        </Modal>
        )}
      </div>
    );
  }
}

Reports.propTypes = {
  disabled: PropTypes.bool.isRequired,
  annotationReport: PropTypes.bool.isRequired,
  financialReport: PropTypes.bool.isRequired,
  initialApplication: PropTypes.shape().isRequired,
  isShowAnnotationCard: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  prevStepApplications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDownloadXls: PropTypes.func.isRequired,
  updateParentApplication: PropTypes.func.isRequired,
};

export default Reports;
