import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { getApplicationStepReportById, getApplicationStepReportByIds } from '../../../../../utils/http';
import Reports from './AnnotationReportComponents/Reports';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';
import onExportXLS from '../../../../../utils/createXLSDocument';


function Step({
  applicationProp, step, isShowAnnotationCard, isFinancialReport, isAnnotationReport, updateParentApplication,
}) {
  const [application, setApplication] = useState(null);
  const [prevStepApplications, setPrevStepApplications] = useState([]);

  useEffect(() => {
    const stepId = applicationProp.applicationStepReports.find((s) => Number(s.stepNumber) === step)?.id;
    let prevStepId = applicationProp.applicationStepReports.find((s) => Number(s.stepNumber) === step - 1)?.id;
    if (applicationProp.id === '77acb6a6-5497-40ff-ab58-cc4db19ddcab' && step === 4) {
      prevStepId = null;
    }
    if (stepId && prevStepId) {
      Promise.all([getApplicationStepReportById(stepId),
        getApplicationStepReportByIds({ ids: applicationProp.applicationStepReports.filter((a) => Number(a.stepNumber) < step).map((a) => a.id).join(',') })])
        .then((resp) => {
          setApplication(resp[0]);
          setPrevStepApplications(resp[1]);
        });
    } else if (stepId) {
      getApplicationStepReportById(stepId)
        .then((resp) => setApplication(resp));
    }
  }, []);

  const onDownloadXls = () => {
    onExportXLS(application, prevStepApplications);
  };
  return (
    <div style={{ overflowY: 'scroll', height: '79vh' }}>
      {application ? (
        <Reports
          initialApplication={{
            ...application, ...applicationProp, id: application.id, parentAppId: applicationProp.id,
          }}
          annotationReport={isAnnotationReport}
          financialReport={isFinancialReport}
          disabled={getUserRole() !== 'bigAdmin'}
          isShowAnnotationCard={isShowAnnotationCard}
          step={step}
          prevStepApplications={prevStepApplications}
          onDownloadXls={onDownloadXls}
          updateParentApplication={updateParentApplication}
        />
      ) : ''}
    </div>
  );
}

Step.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  step: PropTypes.number.isRequired,
  isShowAnnotationCard: PropTypes.bool.isRequired,
  isAnnotationReport: PropTypes.bool.isRequired,
  isFinancialReport: PropTypes.bool.isRequired,
  updateParentApplication: PropTypes.func.isRequired,
};

export default withRouter(Step);
