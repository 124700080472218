/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-cond-assign */
/* eslint-disable no-nested-ternary */
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


const ExcelJS = require('exceljs');


const addFilesToFolder = async (files, folder) => {
  for (const [index, fileObj] of files.entries()) {
    if (fileObj.urlLink) {
      const name = `${fileObj.name ? `${fileObj.name.substring(0, 100).replace(/\//g, ':')} (${index + 1})` : `без названия (${index + 1})`}`;
      const file = await fetch(fileObj.urlLink).then((r) => r.blob()).then((blobFile) => new File([blobFile], name, { type: blobFile.type }));
      folder.file(`${name}.pdf`, file, { base64: true });
    }
  }
};

function toColumnName(num) {
  let ret = '';
  let a = 1;
  let b = 1;
  for (ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}

const fields = [
  {
    name: '1. Число планируемых публикаций по результатам реализации Проекта',
    field: 'numberOfPublicationsTotal',
  },
  {
    name: '1.1. в научных журналах перечня ВАК с Импакт-фактором > 0,5 (на момент публикации материала, включая публикации в научных журналах,индексируемых в базах данных Scopus и (или) Web of Science, в том числе, входящих в первый или второй квартили (Q1 или Q2))',
    field: 'publicationsQ1Q2',
  },
  {
    name: '1.2. в научных журналах, индексируемых РИНЦ',
    field: 'publications',
  },
  {
    name: '2. Число результатов интеллектуальной деятельности, планируемых к получению по результатам реализации Проекта',
    field: 'intellectualProperty',
  },
  {
    name: '2.1. Количество планируемых мероприятий по демонстрации результатов реализации Проекта',
    field: 'events',
  },
  {
    name: '2.2. Ожидаемая аудитория мероприятий по демонстрации результатов реализации Проекта из числа профессионального сообщества и представителей здравоохранения города Москвы',
    field: 'people',
  },
  {
    name: '3. Количество планируемых выступлений с докладами на международных научно-практических конференциях, симпозиумах, форумах',
    field: 'otherEvents',
  },
  {
    name: '4. Общее количество пациентов, предусмотренных для выполнения Проекта',
    field: 'numberOfPatienceTotal',
  },
  {
    name: '4.1. Пациенты основной группы',
    field: 'numberOfPatienceMain',
  },
  {
    name: '4.2. Пациенты контрольной группы',
    field: 'numberOfPatienceControl',
  },
  {
    name: '4.3. Пациенты группы сравнения',
    field: 'numberOfPatienceCompare',
  },
  {
    name: '5. Протоколы испытаний',
    field: 'numberOfActs',
  },
  {
    name: '6. Наличие опытных образцов',
    field: 'numberOfExamples',
  },
];


const prepareSurveyData = (rawData) => {
  const data = [];
  const files = [];
  const header = [
    {
      cellsToMerge: 'A1:A2',
      cell: 'A1',
      name: '№ проекта',
      columnNumber: 1,
      field: 'displayId',
    },
    {
      cellsToMerge: 'B1:B2',
      cell: 'B1',
      name: 'Название проекта',
      columnNumber: 2,
      field: 'theme',
    },
    {
      cellsToMerge: 'C1:C2',
      cell: 'C1',
      name: 'Наименование организации- грантополучателя',
      columnNumber: 3,
      field: 'orgName',
    },
    {
      cellsToMerge: 'D1:D2',
      cell: 'D1',
      name: 'Руководитель проекта',
      columnNumber: 4,
      field: 'fio',
    },
    {
      cellsToMerge: 'E1:E2',
      cell: 'E1',
      name: 'Зaвершен?',
      columnNumber: 5,
      field: 'isDone',
    },
  ];

  fields.forEach((field, i) => {
    header.push({
      cellsToMerge: `${toColumnName(6 + (i * 3))}1:${toColumnName(6 + (i * 3))}1`,
      cell: `${toColumnName(6 + (i * 3))}1`,
      name: field.name,
      columnNumber: 6 + (i * 3),
    });
    header.push({
      cell: `${toColumnName(5 + (i * 3))}2`,
      name: 'План',
      columnNumber: 6 + (i * 3),
      field: `${field.field}Plan`,
    });
    header.push({
      cell: `${toColumnName(6 + (i * 3) + 1)}2`,
      name: 'Факт',
      columnNumber: 6 + (i * 3) + 1,
      field: `${field.field}Fact`,
    });
    header.push({
      cell: `${toColumnName(6 + (i * 3) + 2)}2`,
      name: 'Расхождение',
      columnNumber: 6 + (i * 3) + 2,
      field: `${field.field}Diff`,
    });
  });

  rawData.forEach((s) => {
    const obj = {
      displayId: s.displayId,
      theme: s.theme,
      orgName: s.orgName,
      fio: s.fio,
      isDone: s.isDone,
    };
    fields.forEach((field) => {
      obj[`${field.field}Plan`] = s[`${field.field}Plan`];
      obj[`${field.field}Fact`] = s[`${field.field}Fact`];
      obj[`${field.field}Diff`] = Number((s[`${field.field}Fact`] - s[`${field.field}Plan`]).toFixed(0));
    });
    data.push(obj);

    // файлы
    files.push({
      displayId: s.displayId,
      files: s.applicationSurveyFiles_ids.map((file) => ({ name: file?.fileName, urlLink: file?.file?.urlLink })),
    });
  });

  return {
    header,
    data,
    files,
  };
};


const createTotalSheet = (data, worksheet) => {
  // Заполняем шапку
  data.header.forEach((header) => {
    if (header.cellsToMerge) {
      worksheet.mergeCells(header.cellsToMerge);
    }
    if (header.columnNumber) {
      worksheet.getColumn(header.columnNumber).font = { name: 'Montserrat' };
      worksheet.getColumn(header.columnNumber).width = 20;
    }
    const cell = worksheet.getCell(header.cell);
    cell.value = header.name;
    cell.alignment = { vertical: 'middle' };
  });

  // Заполянем строчки
  let skipRows = 2;
  data.data.forEach((row) => {
    const rowXls = worksheet.getRow(skipRows + 1);
    data.header.forEach((header) => {
      const fieldXls = rowXls.getCell(header.columnNumber);
      fieldXls.value = row[header.field];
    });
    skipRows += 1;
  });
};

const createXLSDocument = async (data) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Admin';
  workbook.lastModifiedBy = 'Администратор';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const worksheet = workbook.addWorksheet('Опрос');
  createTotalSheet(data, worksheet);

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};

const arrayBufferToBase64 = (Arraybuffer) => {
  let binary = '';
  const bytes = new Uint8Array(Arraybuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = window.btoa(binary);
  const linkSource = `data:application/xls;base64,${base64String}`;
  const downloadLink = document.createElement('a');
  const fileName = 'Опрос.xls';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const exportZip = async (data) => {
  const zip = new JSZip();

  for (const app of data) {
    const appFolder = zip.folder(app.displayId.replace(/\//g, ':'));

    if (app.files.length > 0) {
      await addFilesToFolder(app.files, appFolder);
    }
  }
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, 'Файлы опроса.zip');
  });
};

const exportSurveyXLS = async (rawData) => {
  const parsedData = prepareSurveyData(rawData);
  const zipApps = ['0912-1/22', '0408-1/22', '1403-8/23', '1612-2/22', '1712-5/22', '2312-45/22', '2412-28/22', '2412-35/22', '2412-36/22', '2412-45/22', '2412-55/22', '2707-2/22'];
  // exportZip(parsedData.files.filter((e) => zipApps.includes(e.displayId)));
  const buffer = await createXLSDocument(parsedData);
  arrayBufferToBase64(buffer);
};


export default exportSurveyXLS;
