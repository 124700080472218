/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Tabs, Row, Button, Popconfirm, notification, Modal, Input,
} from 'antd';
import SalaryPayments from './Salary/SalaryPayments';
import Materials from './Materials/Materials';
import Total from './Total/Total';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';
import { editApplicationStepReport, getApplicationStepReportById } from '../../../../../../utils/http';


const { TabPane } = Tabs;
const { TextArea } = Input;
class FinancialReport extends Component {
  state = {
    view: '1',
    financialReportComment: '',
    isShowDeclineFinancialReportModal: false,
    financialReportStatus: this.props.application.financialReportStatus,
    financialReportPosition: this.props.application.financialReportPosition,
    financialReportFIO: this.props.application.financialReportFIO,
  }

  onSendForApproval = () => {
    const { application } = this.props;

    getApplicationStepReportById(application.id)
      .then((app) => {
        const { financialReportPosition, financialReportFIO } = this.state;
        if (!financialReportPosition || !financialReportFIO) {
          notification.error({ message: 'Статус не изменен! Введете ФИО и должность работника организации, уполномоченного на представление отчета о целевом использовании средств гранта' });
          return;
        }
        if (!app.isFinancialTotalChecked) {
          notification.error({ message: 'Статус не изменен! Подтвердите, что вы проверили отчет, установив галочку в поле под таблицей' });
          return;
        }
        if (!app.financialIsSalaryDone && app.financialSalary_ids.length === 0) {
          notification.error({ message: 'Ошибка! Заполните раздел "Оплата труда членов коллектива специалистов"' });
          return;
        }
        if (!app.financialSalaryFiles || app.financialSalaryFiles.length === 0) {
          notification.error({ message: 'Ошибка! Прикрепите cкан-копию в формате pdf внутреннего приказа о создании коллектива специалистов в разделе "Оплата труда членов коллектива специалистов"' });
          return;
        }
        if (!app.financialIsSalaryDone && (!app.financialSalaryPPFiles || app.financialSalaryPPFiles.length === 0)) {
          notification.error({ message: 'Ошибка! Прикрепите скан-копии в формате pdf платежных поручений по выплате заработной платы в разделе "Оплата труда членов коллектива специалистов"' });
          return;
        }
        if (!app.financialIsPaperDone && (!app.financialPaperPolicyFile || !app.financialPaperAccountFile)) {
          notification.error({ message: 'Ошибка! Прикрепите файлы в разделе "Накладные и общехозяйственные расходы"' });
          return;
        }


        if (!app.financialIsMaterialDone && app.financialCosts_ids.filter((c) => c.isMaterial).length === 0) {
          notification.error({ message: 'Ошибка! Заполните раздел "Расходы на приобретение материалов и комплектующих"' });
          return;
        }

        if (!app.financialIsEquipmentDone && app.financialCosts_ids.filter((c) => c.isEquipment).length === 0) {
          notification.error({ message: 'Ошибка! Заполните раздел "Расходы на приобретение оборудования и иного имущества"' });
          return;
        }

        if (!app.financialIsOtherDone && app.financialCosts_ids.filter((c) => c.isOther).length === 0) {
          notification.error({ message: 'Ошибка! Заполните раздел "Прочие прямые расходы"' });
          return;
        }

        if (!app.financialIsPaperDone && app.financialCosts_ids.filter((c) => c.isPaper).length === 0) {
          notification.error({ message: 'Ошибка! Заполните раздел "Накладные и общехозяйственные расходы"' });
          return;
        }

        editApplicationStepReport({
          id: application.id,
          financialReportStatus: 'На согласовании',
          financialReportPosition,
          financialReportFIO,
        })
          .then(() => {
            this.setState({ financialReportStatus: 'На согласовании' });
            notification.success({ message: 'Статус изменен!' });
            notification.warning({ duration: 10, message: 'Уважаемые грантополучатели! Отчет направлен на проверку, после ее завершения мы направим вам Акт сдачи-приемки выполненных работ по этапу с комментарием по дальнейшим действиям' });
          });
      });
  }

  onChangeTextFiled = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue });
  }

  onChangeFinancialReportStatus = (financialReportStatus) => {
    const { application } = this.props;
    const { financialReportComment } = this.state;
    editApplicationStepReport({ id: application.id, financialReportStatus, financialReportComment })
      .then(() => {
        this.setState({
          isShowDeclineFinancialReportModal: false,
          financialReportComment: '',
        });
        notification.success({ message: 'Успешно изменен статус' });
      });
  }

  render() {
    const {
      view, financialReportStatus, isShowDeclineFinancialReportModal, financialReportPosition, financialReportFIO,
    } = this.state;
    const { application, prevStepApplications, step } = this.props;
    let isFormDisabled = !((application.isGrants2023 && step === 1)
     || (!application.isGrants2023 && step === 3)
     || (application.id === '5a8c0f36-46aa-4f21-a5de-2784ea754c2c')
     || (application.id === '67c3dc32-e90c-456c-98f1-43d288a3c613'))
     || financialReportStatus === 'Согласовано';
    if (getUserRole() === 'bigAdmin') {
      isFormDisabled = false;
    }
    //  || application?.status === 'Завершен'
    //  || application?.status === 'Расторгнут';
    // это ID шага 2 для заявки 2312-27/22-1С
    // const isFormDisabled = application.displayID !== '1409-1' && application.displayID !== '2412-49';
    console.log(isFormDisabled, 'isFormDisabled');
    return (
      <Col>
        <Tabs defaultActiveKey={view} activeKey={view} onChange={(e) => this.setState({ view: e })}>
          <TabPane tab="Оплата труда членов коллектива специалистов" key="1">
            <SalaryPayments
              financialIsSalaryDone={application.financialIsSalaryDone}
              applicationId={application.id}
              dataSource={application.financialSalary_ids}
              financialSalaryFiles={application.financialSalaryFiles}
              financialSalaryPPFiles={application.financialSalaryPPFiles}
              isFormDisabled={isFormDisabled}
              isEnpSalaryForm={application.isEnpSalaryForm || application.financialSalary_ids.length === 0}
            />
            <Row justify="end" style={{ marginTop: '20px' }}><Button type="primary" onClick={() => this.setState({ view: '2' })}>Продолжить</Button></Row>
          </TabPane>
          <TabPane tab="Расходы на приобретение материалов и комплектующих" key="2">
            <Materials
              applicationId={application.id}
              isMaterial
              title="Расходы на приобретение материалов и комплектующих не проводились в отчетном периоде"
              dataSource={application.financialCosts_ids.filter((c) => c.isMaterial)}
              checkboxName="financialIsMaterialDone"
              checkboxValue={application.financialIsMaterialDone}
              isFormDisabled={isFormDisabled}
            />
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              <Col><Button onClick={() => this.setState({ view: '1' })}>Назад</Button></Col>
              <Col><Button type="primary" onClick={() => this.setState({ view: '3' })}>Продолжить</Button></Col>
            </Row>
          </TabPane>
          <TabPane tab="Расходы на приобретение оборудования и иного имущества" key="3">
            <Materials
              applicationId={application.id}
              isEquipment
              title="Расходы на приобретение оборудования и иного имущества не проводилась в отчетном периоде"
              dataSource={application.financialCosts_ids.filter((c) => c.isEquipment)}
              checkboxName="financialIsEquipmentDone"
              checkboxValue={application.financialIsEquipmentDone}
              isFormDisabled={isFormDisabled}
            />
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              <Col><Button onClick={() => this.setState({ view: '2' })}>Назад</Button></Col>
              <Col><Button type="primary" onClick={() => this.setState({ view: '4' })}>Продолжить</Button></Col>
            </Row>
          </TabPane>
          <TabPane tab="Прочие прямые расходы" key="4">
            <Materials
              applicationId={application.id}
              isOther
              title="Расходы по статье «Прочие прямые расходы» не проводились в отчетном периоде"
              dataSource={application.financialCosts_ids.filter((c) => c.isOther)}
              checkboxName="financialIsOtherDone"
              checkboxValue={application.financialIsOtherDone}
              isFormDisabled={isFormDisabled}
            />
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              <Col><Button onClick={() => this.setState({ view: '3' })}>Назад</Button></Col>
              <Col><Button type="primary" onClick={() => this.setState({ view: '5' })}>Продолжить</Button></Col>
            </Row>
          </TabPane>
          <TabPane tab="Накладные и общехозяйственные расходы" key="5">
            <Materials
              applicationId={application.id}
              isPaper
              title="Расходы по статье «Накладные и общехозяйственные расходы» не проводились в отчетном периоде"
              dataSource={application.financialCosts_ids.filter((c) => c.isPaper)}
              checkboxName="financialIsPaperDone"
              checkboxValue={application.financialIsPaperDone}
              isFormDisabled={isFormDisabled}
              financialPaperPolicyFile={application.financialPaperPolicyFile}
              financialPaperAccountFile={application.financialPaperAccountFile}
            />
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              <Col><Button onClick={() => this.setState({ view: '4' })}>Назад</Button></Col>
              <Col><Button type="primary" onClick={() => this.setState({ view: '6' })}>Продолжить</Button></Col>
            </Row>

          </TabPane>
          <TabPane tab="Сведения о фактических расходах по Проекту" key="6">
            {view === '6' && (
            <Total
              applicationId={application.id}
              isFormDisabled={isFormDisabled}
              financialReportPosition={financialReportPosition}
              financialReportFIO={financialReportFIO}
              onChangeTextFiled={this.onChangeTextFiled}
              prevStepApplications={prevStepApplications}
            />
            )}
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              <Col><Button onClick={() => this.setState({ view: '5' })}>Назад</Button></Col>
              {financialReportStatus !== 'На согласовании' && financialReportStatus !== 'Согласовано' && !isFormDisabled && (
              <Col>
                <Popconfirm
                  title="Уверены, что хотите подать отчет?"
                  onConfirm={this.onSendForApproval}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button type="primary" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}> Подать отчет</Button>
                </Popconfirm>
              </Col>
              )}
              {getUserRole() === 'bigAdmin' && application.financialReportStatus === 'На согласовании' && (
              <Row gutter={10}>
                <Col><Button onClick={() => this.setState({ isShowDeclineFinancialReportModal: true })}>Отклонить отчет</Button></Col>
                <Col><Button type="primary" onClick={() => this.onChangeFinancialReportStatus('Согласовано')}>Согласовать отчет</Button></Col>
              </Row>
              )}
            </Row>
          </TabPane>
        </Tabs>
        {isShowDeclineFinancialReportModal && (
        <Modal
          title="Отклонение"
          visible
          onCancel={() => this.setState({ isShowDeclineFinancialReportModal: false, financialReportComment: '' })}
          footer={null}
        >
          <Col>
            <Row>Комментарий:</Row>
            <Row>
              <TextArea rows={3} style={{ width: '100%' }} onChange={(e) => this.setState({ financialReportComment: e.target.value })} />
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Popconfirm
                title="Вы действительно хотите Отклонить"
                onConfirm={() => this.onChangeFinancialReportStatus('Отклонено')}
                okText="Да, отклонить"
                cancelText="Нет, продолжить редактирование"
              >
                <Button danger>Отклонить</Button>
              </Popconfirm>
            </Row>
          </Col>
        </Modal>
        )}
      </Col>
    );
  }
}

FinancialReport.propTypes = {
  application: PropTypes.shape().isRequired,
  prevStepApplications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  step: PropTypes.number.isRequired,
};


export default FinancialReport;
