/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import {
  Modal, Input, notification, Button, Alert,
} from 'antd';
import moment from 'moment';
// import { LogoutOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import logout from '../../utils/logout';
import getUserShortName from '../../utils/localeStorage/getUserShortName';
import { routes } from '../../utils/routes';
import { addUserFeedback } from '../../utils/http';
import logo from '../../assets/images/logoGood.svg';
import getUserId from '../../utils/localeStorage/getUserId';
import './Header.less';
// import getUserRole from '../../utils/localeStorage/getUserRole';


const { TextArea } = Input;
// Больнички с заявками "С"
// const dzmUsers = ['9be2a9a8-c067-43cb-b6c1-042637817be6', '97d3f88b-61b5-49a2-bd60-ede70f23e92a', '77d918b8-fb20-4ce6-8c36-8e86fadcd844', 'dd9560bf-9bd1-4333-a97f-a58007e8bb9d',
//   '3bc9f4b4-2c7b-4aaa-a0cf-2e56c615304c', 'ec9e8ff7-0940-445c-928b-c63a06ef313e', 'db0b6675-331c-457b-ab9c-d23c938410d4', '957bc9f8-244c-4bbe-b4f4-56e4b0ebb5f7',
//   '165ebd9c-565a-4126-ba7b-2a34409204e0', 'be341e0c-22fd-4614-a639-a5ab8f10b880', 'b809aac9-cb11-416d-8a94-1c98a2e250b3', 'b046ebeb-a1f5-46b1-ae01-a2ee23b34092',
// ];
@withRouter
class Header extends Component {
  state = {
    isShowModal: false,
    comment: '',
    authorName: '',
    contacts: '',
    project: '',
  }

  onClose = () => {
    this.setState({ isShowModal: false, comment: '' });
  }

  onSave = () => {
    const {
      comment, authorName, contacts, project,
    } = this.state;
    addUserFeedback({
      user_id: getUserId(),
      date: moment().toISOString(),
      comment,
      dateText: moment().format('DD MMMM YYYY'),
      authorName,
      contacts,
      project,
    })
      .then(() => {
        notification.success({ message: 'Запрос успешно отправлен' });
        this.setState({
          isShowModal: false, comment: '', authorName: '', contacts: '', project: '',
        });
      });
  }

  onShow = () => {
    this.setState({ isShowModal: true });
  }

  render() {
    const { isShowModal } = this.state;
    const { history } = this.props;
    const pathname = get(history, 'location.pathname', '');
    const isVisible = pathname !== routes.home && pathname !== routes.registration && pathname !== routes.login;
    if (!isVisible) {
      return null;
    }

    // const isNIRUser = getUserRole() === 'noDSM';
    // const isCUser = dzmUsers.includes(getUserId());
    return (
      <div className="header-wrapper">
        <div className="header-top">
          <img src={logo} alt="logo" className="header-logo" onClick={() => history.push(routes.homePage)} />
          {/* <div style={{ fontSize: '22px', color: 'red' }}>Приём заявок завершён</div> */}
          {/* {(isNIRUser || isCUser) && (
          <div style={{ width: '100%', textAlign: 'center', fontSize: '30px' }}>
            {isCUser && <a href="https://api.directual.com/fileUploaded/medtechgrants/8d32711b-015c-4d13-b62b-03888dcca726.docx">Памятка по подаче отчетности</a>}
            {isNIRUser && <a href="https://api.directual.com/fileUploaded/medtechgrants/b88984f8-1b87-4277-a1f0-9fa4bc6d6852.docx">Памятка по подаче отчетности</a>}
          </div>
          )} */}
          <div className="header-top-icons">
            <div style={{ marginRight: '20px' }}>{getUserShortName()}</div>
            <div style={{
              display: 'flex', flexDirection: 'row', fontSize: '28px', color: '#5e5e5e', gap: '10px',
            }}
            >
              <Button onClick={this.onShow} type="primary">Задать вопрос</Button>
              <Button onClick={() => logout(history)}>Выход</Button>
              {/* <Tooltip title="Помощь"><QuestionCircleOutlined onClick={this.onShow} style={{ marginLeft: '10px' }} /></Tooltip> */}
              {/* <Tooltip title="Выход"><LogoutOutlined onClick={() => logout(history)} style={{ marginLeft: '10px' }} /></Tooltip> */}
            </div>
          </div>
        </div>

        {isShowModal && (
        <Modal title="Форма обратной связи" visible onOk={this.onSave} onCancel={this.onClose} okText="Отправить">
          <Alert message="Также вы можете связаться с нами по телефону и через мессенджеры +7(915)-313-54-13" type="info" showIcon />
          <div style={{ marginTop: '10px' }}>Текст вашего обращения</div>
          <TextArea rows={2} placeholder="введите текст" onChange={(e) => this.setState({ comment: e.target.value })} />
          <div style={{ marginTop: '10px' }}>Ваше ФИО</div>
          <Input onChange={(e) => this.setState({ authorName: e.target.value })} />
          <div style={{ marginTop: '10px' }}>Ваши контактные данные</div>
          <Input onChange={(e) => this.setState({ contacts: e.target.value })} />
          <div style={{ marginTop: '10px' }}>Проект</div>
          <Input onChange={(e) => this.setState({ project: e.target.value })} />
        </Modal>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  history: PropTypes.shape(),
};

Header.defaultProps = {
  history: {},
};

export default Header;
