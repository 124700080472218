import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form, Button, Input, DatePicker, notification, Col, Row, Alert,
} from 'antd';
import md5 from 'md5';
import { uniq } from 'lodash';
import FormRow from './FormRow';
import { getApplicationSurvey, editApplicationSurvey, editApplicationSurveyFiles } from '../../../utils/http';


const fieldsWithoutFiles = ['numberOfPublicationsTotal', 'people', 'numberOfPatienceTotal', 'numberOfPatienceMain', 'numberOfPatienceControl', 'numberOfPatienceCompare', 'numberOfActs', 'numberOfExamples'];

const initFields = [
  {
    name: 'numberOfPublicationsTotal',
    label: '1. Число планируемых публикаций по результатам реализации Проекта',
    hint: '',
  },
  {
    name: 'publicationsQ1Q2',
    label: '1.1. в научных журналах перечня ВАК с Импакт-фактором > 0,5 (на момент публикации материала, включая публикации в научных журналах,индексируемых в базах данных Scopus и (или) Web of Science, в том числе, входящих в первый или второй квартили (Q1 или Q2))',
    hint: 'Для выполнения данного показателя эффективности также приветствуются публикации в зарубежных научных журналах, индексируемых в базах данных Scopus и (или) Web of Science. В публикациях необходимо указание на источник финансирования и номер гранта, полученного от Правительства Москвы',
  },
  {
    name: 'publications',
    label: '1.2. в научных журналах, индексируемых РИНЦ',
    hint: 'Для проектов, по которым соглашения о предоставлении гранта были заключены в 2022 году, для выполнения данного показателя эффективности возможно также использовать публикации по результатам проектов в научных журналах, индексируемых в базах данных Scopus и (или) Web of Science» (третьего и четвертого квартиля (Q3 или Q4)), если они уже не были указаны в пункте 1.1. В публикациях необходимо указание на источник финансирования и номер гранта, полученного от Правительства Москвы.',
  },
  {
    name: 'intellectualProperty',
    label: '2. Число результатов интеллектуальной деятельности, планируемых к получению по результатам реализации Проекта',
    hint: 'Для проектов, по которым соглашения о предоставлении гранта были заключены в 2022 и в 2023 году, для выполнения данного показателя эффективности необходимо предоставить соответствующие патенты (или заявку на патент с подтверждением прохождения формальной экспертизы) на изобретение и (или) патент на полезную модель и (или) промышленный образец и (или) свидетельства о государственной регистрации программы для ЭВМ или базы данных.',
  },
  {
    name: 'events',
    label: '2.1. Количество планируемых мероприятий по демонстрации результатов реализации Проекта',
    hint: 'Для проектов, по которым соглашения о предоставлении гранта были заключены в 2022 и в 2023 году, для выполнения показателей эффективности необходимо предоставить подтверждение участия минимум одного из членов коллектива специалистов в конференциях, симпозиумах, форумах и выставках регионального и всероссийского уровня путем предоставления программы мероприятия.',
  },
  { name: 'people', label: '2.2. Ожидаемая аудитория мероприятий по демонстрации результатов реализации Проекта из числа профессионального сообщества и представителей здравоохранения города Москвы', hint: '' },
  {
    name: 'otherEvents',
    label: '3. Количество планируемых выступлений с докладами на международных научно-практических конференциях, симпозиумах, форумах',
    hint: 'Для проектов, по которым соглашения о предоставлении гранта были заключены в 2022 и в 2023 году, для выполнения данного показателя необходимо предоставить подтверждение участия минимум одного из членов коллектива специалистов в таких мероприятиях. Международный уровень мероприятия подтверждается программой мероприятия, среди участников которой должны быть представители не менее двух государств.',
  },
  { name: 'numberOfPatienceTotal', label: '4. Общее количество пациентов, предусмотренных для выполнения Проекта', hint: '' },
  { name: 'numberOfPatienceMain', label: '4.1. Пациенты основной группы', hint: '' },
  { name: 'numberOfPatienceControl', label: '4.2. Пациенты контрольной группы', hint: '' },
  { name: 'numberOfPatienceCompare', label: '4.3. Пациенты группы сравнения', hint: '' },
  { name: 'numberOfActs', label: '5. Протоколы испытаний', hint: '' },
  { name: 'numberOfExamples', label: '6. Наличие опытных образцов', hint: '' },
];
function Survey({ application }) {
  const [survey, setSurvey] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const formRef = useRef();
  const [isInitLoading, setIsInitLoading] = useState(true);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    getApplicationSurvey({ id: application.id })
      .then((res) => {
        const obj = {
          ...res[0],
        };
        const fieldsToSet = [];
        initFields.forEach((initField) => {
          fieldsToSet.push(initField);
          if (!fieldsWithoutFiles.includes(initField.name) && obj[`${initField.name}Fact`] > 0) {
            const filesPerField = res[0].applicationSurveyFiles_ids.filter((f) => f.fieldName === initField.name);
            filesPerField.forEach((file) => {
              fieldsToSet.push({
                name: `${file.id}_${initField.name}`,
                label: '',
              });
              obj[`${file.id}_${initField.name}_fileName`] = file.fileName;
              obj[`${file.id}_${initField.name}_file`] = file.file;
            });
          }
        });
        setSurvey(obj);
        setFields(fieldsToSet);
        setIsLoading(false);
        setIsInitLoading(false);
      });
  }, []);


  const onSave = async () => {
    setIsLoading(true);
    await formRef.current.validateFields()
      .then(async (values) => {
        const applicationSurveyFiles_ids = [];
        const filesToSave = [];
        const objToSave = {
          id: values.id,
          date: values.date.toISOString(),
          fio: values.fio,
          orgName: values.orgName,
          displayId: values.displayId,
          webUser_id: values.webUser_id,
        };
        const valuesKeys = Object.keys(values);
        initFields.forEach((f) => {
          objToSave[`${f.name}Plan`] = values[`${f.name}Plan`];
          objToSave[`${f.name}Fact`] = values[`${f.name}Fact`];
          if (values[`${f.name}Fact`] && values[`${f.name}Fact`] > 0 && f.name !== 'numberOfPublicationsTotal') {
            const fieldFileKeys = valuesKeys.filter((key) => key.split('_')[1] === f.name);
            let fileIds = [];
            fieldFileKeys.forEach((ffk) => fileIds.push(ffk.split('_')[0]));
            fileIds = uniq(fileIds);
            applicationSurveyFiles_ids.push(...fileIds);
            fileIds.forEach((fileId) => {
              filesToSave.push({
                id: fileId,
                survey_id: objToSave.id,
                fileName: values[`${fileId}_${f.name}_fileName`],
                file_id: values[`${fileId}_${f.name}_file`] ? values[`${fileId}_${f.name}_file`].id : null,
                fieldName: f.name,
              });
            });
          }
        });
        objToSave.applicationSurveyFiles_ids = applicationSurveyFiles_ids.join(',');
        await Promise.all([editApplicationSurveyFiles(filesToSave), editApplicationSurvey(objToSave)])
          .then(() => {
            setIsLoading(false);
            notification.success({ message: 'Сохранено' });
          });
      })
      .catch(() => {
        notification.error({ message: 'Ошибка!' });
      });
  };

  const onFactChange = (field, value) => {
    const filteredFields = fields.filter((f) => f.name.split('_')[1] !== field);
    const newFields = [];

    if (field === 'publicationsQ1Q2') {
      const sum = (survey.publicationsFact || 0) + (value || 0);
      setSurvey({
        ...survey,
        numberOfPublicationsTotalFact: sum,
        publicationsQ1Q2Fact: value,
      });
      formRef.current.setFieldsValue({ numberOfPublicationsTotalFact: sum });
    } else if (field === 'publications') {
      const sum = (survey.publicationsQ1Q2Fact || 0) + (value || 0);
      setSurvey({
        ...survey,
        numberOfPublicationsTotalFact: sum,
        publicationsFact: value,
      });
      formRef.current.setFieldsValue({ numberOfPublicationsTotalFact: sum });
    } else {
      setSurvey({ ...survey, [`${field}Fact`]: value });
    }

    filteredFields.forEach((f) => {
      if (f.name === field && value && !fieldsWithoutFiles.includes(f.name)) {
        newFields.push(f);
        Array(value).fill(0).forEach(() => {
          newFields.push({
            name: `${md5(new Date() + Math.random())}_${field}`,
            label: '',
          });
        });
      } else {
        newFields.push(f);
      }
    });
    setFields(newFields);
  };

  const onUpdateOriginalObject = (field, value) => {
    setSurvey({
      ...survey,
      [field]: value,
    });
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await onSave();
    editApplicationSurvey({
      id: survey.id,
      isDone: true,
    })
      .then(() => {
        setSurvey({ ...survey, isDone: true });
        setIsLoading(false);
      });
  };

  return (
    <div className="application-main-wrapper" style={{ width: '100%', overflowY: 'scroll', height: '79vh' }}>
      <div className="application-main-box-wrapper" style={{ marginBottom: '20px', width: '100%' }}>
        <div className="application-main-box-mask" />
        {survey.isDone && (
        <Alert
          style={{ margin: '20px' }}
          message="Уважаемые Грантополучатели!"
          description="Заполнение опроса завершено. В случае необходимости внесения изменений в уже поданый опрос, воспользуйтесь синей кнопкой Задать Вопрос в правой верхней части экрана"
          type="warning"
        />
        )}
        {!isInitLoading && (
        <div className="application-main-box-content-wrapper" style={{ width: '100%' }}>
          <Form ref={formRef} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Form.Item
              style={{ width: '100%' }}
              name="displayId"
              initialValue={application.displayID}
              label="Номер"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              name="fio"
              initialValue={application.supervisorFullName}
              label="ФИО руководителя"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              name="orgName"
              initialValue={application.shortName}
              hidden
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              name="id"
              initialValue={survey.id}
              hidden
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              name="webUser_id"
              initialValue={application.webUser_id}
              hidden
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              name="date"
              initialValue={moment()}
              hidden
            >
              <DatePicker disabled />
            </Form.Item>


            <Col
              style={{
                marginBottom: '30px',
                border: '1px solid #212121',
                borderBottom: 0,
              }}
            >
              <Row
                style={{ borderBottom: '1px solid #212121', fontSize: 17 }}
              >
                {/* <Col
                  style={{
                    padding: 10,
                    width: '3%',
                  }}
                >
                  №
                </Col> */}
                <Col
                  style={{
                    padding: 10,
                    width: '47%',
                    borderLeft: '1px solid #212121',
                  }}
                >
                  Наименование показателя
                </Col>
                <Col
                  style={{
                    padding: 10,
                    width: '10%',
                    borderLeft: '1px solid #212121',
                  }}
                >
                  План
                </Col>
                <Col
                  style={{
                    padding: 10,
                    width: '10%',
                    borderLeft: '1px solid #212121',
                  }}
                >
                  Факт
                </Col>
                <Col
                  style={{
                    padding: 10,
                    width: '20%',
                    borderLeft: '1px solid #212121',
                  }}
                >
                  Название публикации
                </Col>
                <Col
                  style={{
                    padding: 10,
                    width: '13%',
                    borderLeft: '1px solid #212121',
                  }}
                >
                  PDF публикации
                </Col>
              </Row>
              {fields.map((field) => (
                <FormRow
                  label={field.label}
                  hint={field.hint}
                  field={field.name}
                  survey={survey}
                  isDisabled={isLoading || survey.isDone}
                  onFactChange={onFactChange}
                  initFields={initFields.map((f) => f.name)}
                  formRef={formRef}
                  onUpdateOriginalObject={onUpdateOriginalObject}
                />
              ))}

            </Col>

          </Form>
          {!survey.isDone && (
          <div style={{
            display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '10px',
          }}
          >
            <Button disabled={isLoading} onClick={onSave}>Сохранить</Button>
            <Button type="primary" disabled={isLoading} onClick={onSubmit}>Отправить</Button>
          </div>
          )}
        </div>
        )}
      </div>
    </div>
  );
}

Survey.propTypes = {
  application: PropTypes.shape().isRequired,
};

export default Survey;
