import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Navigation from './components/Navigation/Navigation';
import CommonInfo from './components/CommonInfo/CommonInfo';
import Step from './components/Step/Step';
import People from './components/Persons/People';
import Survey from '../Survey/Survey';
import Smeta from './components/Smeta/Smeta';
import SurveyPostMonitor from '../../../components/Survey/Survey';


const hideAnnotationStep2 = [];
function AppDZMMain({
  application, specializationCatalogue, scientificDirectionCatalogue, stepNumber, isFinancialReport, isAnnotationReport, isShowSurvey,
  isShowSurveyTab, updateParentApplication, editStepDate, onAddStep,
}) {
  const [view, setView] = useState('common');
  const onChangeView = (updatedView) => setView(updatedView);

  useEffect(() => {
    if (isShowSurvey) {
      setView('survey');
    } else if (stepNumber) {
      setView(stepNumber);
    }
  }, []);

  // const isAppDisabled = application.status === 'Завершен' || application.status === 'Расторгнут';
  const isAppDisabled = false;
  const surveyApps = [
    '2312-44/22',
    '2212-12/22',
    '2312-29/22',
    '1912-1/22',
    '0403-1/22',
    '2708-1/22',
    '2312-32/22',
    '1409-1/22',
    '2412-28/22',
    '2312-45/22',
    '1612-2/22',
    '0912-1/22',
    '1712-5/22',
    '1403-8/23',
    '2412-55/22',
    '2412-11/22',
    '2707-2/22',
    '2412-36/22',
    '2212-7/22',
    '2412-66/22',
    '2212-5/22',
    '2807-1/22',
    '2312-34/22',
    '0409-2/22',
    '2212-19/22',
    '2212-19/22',
    '2212-19/22',
    '2212-19/22',
    '2412-45/22',
    '1210-2/22',
    '1003-1-2/22',
    '3333',
  ];
  const isShowSurveyPostMonitorTab = surveyApps.includes(application?.displayID);

  const isHidden = ['f9e4f6c6-df88-4000-98df-a90ad11b29ca', 'e3cf0459-c3cb-45ac-9dc7-fa4d5e0096a6'].includes(application?.id);
  return (
    <>
      <Navigation
        application={application}
        view={view}
        onChangeView={onChangeView}
        isShowSurveyTab={isShowSurveyTab}
        isShowPaymentTab={!isHidden}
        editStepDate={editStepDate}
        onAddStep={onAddStep}
        isShowSurveyPostMonitorTab={isShowSurveyPostMonitorTab}
      />
      {view === 'common' && (
      <CommonInfo
        applicationProp={application}
        specializationCatalogue={specializationCatalogue}
        scientificDirectionCatalogue={scientificDirectionCatalogue}
      />
      )}
      {view === 'persons' && (
      <People
        application={application}
        disabled={isAppDisabled}
      />
      )}
      {/* {view === 'reports' && (
      <Reports
        application={application}
        disabled={isAppDisabled}
      />
      )} */}
      {view === '0' && (
        <Step
          applicationProp={application}
          step={1}
          isShowAnnotationCard={!application.isGrants2023 || application.maxStepsNumber === 1}
          isAnnotationReport={isAnnotationReport}
          updateParentApplication={updateParentApplication}
        />
      )}
      {view === '1' && (
      <Step
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        applicationProp={application}
        step={2}
        updateParentApplication={updateParentApplication}
        isShowAnnotationCard={!hideAnnotationStep2.includes(application.displayID) && (!application.isGrants2023 || application.displayID === '2112-10')}
      />
      )}
      {view === '2' && (
      <Step
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        applicationProp={application}
        step={3}
        updateParentApplication={updateParentApplication}
        isShowAnnotationCard={!application.isGrants2023 || application.displayID === '2112-10' || application.id === '77acb6a6-5497-40ff-ab58-cc4db19ddcab'}
      />
      )}
      {view === '3' && (
      <Step
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        applicationProp={application}
        step={4}
        updateParentApplication={updateParentApplication}
        isShowAnnotationCard={!application.isGrants2023}
      />
      )}
      {view === '4' && (
      <Step
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        applicationProp={application}
        step={5}
        updateParentApplication={updateParentApplication}
        isShowAnnotationCard={!application.isGrants2023}
      />
      )}
      {view === '5' && (
      <Step
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        applicationProp={application}
        step={6}
        updateParentApplication={updateParentApplication}
        isShowAnnotationCard={!application.isGrants2023}
      />
      )}
      {view === 'survey' && (
      <Survey application={application} />
      )}
      {view === 'smeta' && (
      <Smeta applicationId={application.id} updateParentApplication={updateParentApplication} isAppDisabled={isAppDisabled} />
      )}
      {view === 'surveyPostMonitor' && (
      <SurveyPostMonitor applicationId={application.id} />
      )}
    </>
  );
}

AppDZMMain.propTypes = {
  application: PropTypes.shape().isRequired,
  specializationCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  scientificDirectionCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  stepNumber: PropTypes.string,
  isFinancialReport: PropTypes.bool.isRequired,
  isAnnotationReport: PropTypes.bool.isRequired,
  isShowSurvey: PropTypes.bool,
  isShowSurveyTab: PropTypes.bool.isRequired,
  updateParentApplication: PropTypes.func.isRequired,
  editStepDate: PropTypes.func.isRequired,
  onAddStep: PropTypes.func.isRequired,
};

AppDZMMain.defaultProps = {
  stepNumber: null,
  isShowSurvey: false,
};

export default withRouter(AppDZMMain);
