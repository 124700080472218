const exportSmetaHeader = [
  {
    cellsToMerge: 'A1:A2',
    cell: 'A1',
    name: '№ П/П',
    width: 15,
    columnNumber: 1,
    field: 'index',
  },
  {
    cellsToMerge: 'B1:B2',
    cell: 'B1',
    name: 'НАПРАВЛЕНИЯ РАСХОДОВАНИЯ',
    width: 60,
    columnNumber: 2,
    field: 'direction',
  },
  {
    cell: 'C2',
    name: 'ВСЕГО',
    width: 30,
    columnNumber: 3,
    field: 'total',
  },
];

export default exportSmetaHeader;

