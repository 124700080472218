const calculateTotal = (sumArray) => {
  // [{ major: 0, minor: 0 }]
  let sum = 0;
  sumArray.forEach((el) => {
    const major = Math.abs(el.major || 0);
    const minor = Math.abs(el.minor || 0);
    const isMinus = el.major < 0 || el.minor < 0;
    let valueInMinors = parseFloat((parseFloat(`${major}.${(`${minor}`).length === 1 ? `0${minor}` : minor}`) * 100).toFixed(0));
    if (isMinus) {
      valueInMinors *= (-1);
    }
    sum += valueInMinors;
  });
  const resultMajor = Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100);
  const resultMinor = Number(Math.abs(sum % 100).toFixed(0));

  return {
    major: resultMajor || 0,
    minor: resultMinor || 0,
  };
};

export default calculateTotal;
