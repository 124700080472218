/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-cond-assign */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import { getHeaderSingleColumn, getPersonColumn } from './exportFinanceHeader';
import prepareFinanceData from './prepareFinanceData';


const ExcelJS = require('exceljs');


function toColumnName(num) {
  let ret = '';
  let a = 1;
  let b = 1;
  for (ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}

const createTotalSheet = (data, worksheet, isGrants2023) => {
  const commonHeaderLength = getHeaderSingleColumn(isGrants2023).length;

  // Заполняем шапку
  getHeaderSingleColumn(isGrants2023).forEach((header, index) => {
    if (header.isVerticalMerge) {
      worksheet.mergeCells(`${toColumnName(index + 1)}1:${toColumnName(index + 1)}3`);
    }
    worksheet.getColumn(index + 1).font = { name: 'Montserrat' };
    worksheet.getColumn(index + 1).width = header.width;

    if (header.parentName) {
      if (header.isMergeFirstTwo || isGrants2023) {
        worksheet.mergeCells(`${toColumnName(index + 1)}1:${toColumnName(index + 4)}2`);
      } else {
        worksheet.mergeCells(`${toColumnName(index + 1)}2:${toColumnName(index + 4)}2`);
      }
      const cell = worksheet.getCell(`${toColumnName(index + 1)}2`);
      cell.value = header.parentName;
      cell.alignment = { vertical: 'middle' };
    }

    if (header.stepName) {
      worksheet.mergeCells(`${toColumnName(index + 1)}1:${toColumnName(index + 8)}1`);
      const cell = worksheet.getCell(`${toColumnName(index + 1)}1`);
      cell.value = header.stepName;
      cell.alignment = { vertical: 'middle' };
    }

    const cell = worksheet.getCell(`${toColumnName(index + 1)}${header.isBottomRow ? 3 : 1}`);
    cell.value = header.name;
    cell.alignment = { vertical: 'middle' };
  });

  getPersonColumn(isGrants2023).forEach((header, index) => {
    const columnNumber = commonHeaderLength + index + 1;
    if (header.isVerticalMerge) {
      worksheet.mergeCells(`${toColumnName(columnNumber)}1:${toColumnName(columnNumber)}3`);
    }
    if (header.parentName) {
      if (header.isMergeFirstTwo || isGrants2023) {
        worksheet.mergeCells(`${toColumnName(columnNumber)}1:${toColumnName(columnNumber + 3)}2`);
      } else {
        worksheet.mergeCells(`${toColumnName(columnNumber)}2:${toColumnName(columnNumber + 3)}2`);
      }
      const cell = worksheet.getCell(`${toColumnName(columnNumber)}2`);
      cell.value = header.parentName;
      cell.alignment = { vertical: 'middle' };
    }
    if (header.stepName) {
      worksheet.mergeCells(`${toColumnName(columnNumber)}1:${toColumnName(columnNumber + 7)}1`);
      const cell = worksheet.getCell(`${toColumnName(columnNumber)}1`);
      cell.value = header.stepName;
      cell.alignment = { vertical: 'middle' };
    }

    worksheet.getColumn(columnNumber).font = { name: 'Montserrat' };
    worksheet.getColumn(columnNumber).width = header.width;

    const cell = worksheet.getCell(`${toColumnName(columnNumber)}3`);
    cell.value = header.name;
    cell.alignment = { vertical: 'middle' };
  });


  let currentRow = 3;
  data.forEach((org) => {
    currentRow += 1;
    const rowXls = worksheet.getRow(currentRow);
    const fieldXls = rowXls.getCell(1);
    worksheet.mergeCells(`A${currentRow}:${toColumnName(commonHeaderLength)}${currentRow}`);
    fieldXls.value = org.orgName;

    org.applications.forEach((application) => {
      currentRow += 1;
      getHeaderSingleColumn(isGrants2023).forEach((cell, i) => {
        const rowXlsApp = worksheet.getRow(currentRow);
        const fieldXlsApp = rowXlsApp.getCell(i + 1);
        fieldXlsApp.value = application[cell.field];
      });
      if (application.persons.length !== 0) {
        getHeaderSingleColumn(isGrants2023).forEach((cell, i) => {
          worksheet.mergeCells(`${toColumnName(i + 1)}${currentRow}:${toColumnName(i + 1)}${currentRow + application.persons.length - 1}`);
        });

        application.persons.forEach((person, personIndex) => {
          getHeaderSingleColumn(isGrants2023).forEach((cell, i) => {
            const rowXlsApp = worksheet.getRow(currentRow);
            const fieldXlsApp = rowXlsApp.getCell(i + 1);
            fieldXlsApp.value = application[cell.field];
          });

          getPersonColumn(isGrants2023).forEach((cell, i) => {
            const columnNumber = commonHeaderLength + i + 1;
            const rowXlsPerson = worksheet.getRow(currentRow);
            const fieldXlsPerson = rowXlsPerson.getCell(columnNumber);
            fieldXlsPerson.value = person[cell.field];
          });
          if (personIndex < application.persons.length - 1) {
            currentRow += 1;
          }
        });
      }
    });
  });
};

const createXLSDocument = async (data) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Admin';
  workbook.lastModifiedBy = 'Администратор';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const applications2022 = data.grants2022;
  const applications2023 = data.grants2023;

  const worksheet2022 = workbook.addWorksheet('2022');
  const worksheet2023 = workbook.addWorksheet('2023');
  createTotalSheet(applications2022, worksheet2022, false);
  createTotalSheet(applications2023, worksheet2023, true);

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};

const arrayBufferToBase64 = (Arraybuffer) => {
  let binary = '';
  const bytes = new Uint8Array(Arraybuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = window.btoa(binary);
  const linkSource = `data:application/xls;base64,${base64String}`;
  const downloadLink = document.createElement('a');
  const fileName = 'Отчет.xls';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const exportFinanceXLS = async (rawData) => {
  const buffer = await createXLSDocument(prepareFinanceData(rawData));
  arrayBufferToBase64(buffer);
};


export default exportFinanceXLS;
