import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Modal, Form,
} from 'antd';
import md5 from 'md5';
import { editUser } from '../../../../../utils/http';
import UserCard from './UserCard/UserCard';


const { Search } = Input;
class UserMobile extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  onOk = () => {
    this.formRef.current.submit();
  }

  onSaveModal = (values) => {
    const { onAddUser } = this.props;
    editUser({
      ...values, password: md5(values.password), passwordView: values.password, isOrg: true, role: 'user',
    })
      .then((user) => onAddUser(user));
  }

  render() {
    const {
      users, isShowAddUserModal, onCancel, onSearch,
    } = this.props;
    return (
      <div>
        <div className="userCard-wrapper">
          <Search
            placeholder="Введите название..."
            onSearch={onSearch}
          />
        </div>
        {users.map((u) => <UserCard user={u} key={u.id} />)}
        {isShowAddUserModal && (
        <Modal
          title="Добавить учреждение"
          visible
          onOk={this.onOk}
          onCancel={onCancel}
          cancelText="Отмена"
          okText="Добавить"
        >
          <Form layout="vertical" name="addUserForm" ref={this.formRef} onFinish={this.onSaveModal}>
            <Form.Item
              label="Название учреждения"
              name="shortName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Пароль"
              name="password"
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        )}
      </div>
    );
  }
}

UserMobile.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isShowAddUserModal: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default UserMobile;
