/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
// import getStatus from './getStatus';
import './UserCard.less';


const UserCard = ({ user }) => (
  <div className="userCard-wrapper">
    <div style={{ fontWeight: 'bold' }}>{user.shortName}</div>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <span>На рассмотрении</span>
      <span>{user.onApproval}</span>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <span>Черновики</span>
      <span>{user.drafts}</span>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <span>Согласовано</span>
      <span>{user.approved}</span>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <span>Отклонено</span>
      <span>{user.declined}</span>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <span>Всего</span>
      <span>{user.total}</span>
    </div>
  </div>
);

UserCard.propTypes = {
  user: PropTypes.shape().isRequired,
//   onAppClick: PropTypes.func.isRequired,
};

export default UserCard;
