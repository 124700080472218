import React from 'react';
import { Tag } from 'antd';


const getRegApplicationsTag = (status) => {
  switch (status) {
    case 'Согласовано':
      return <Tag color="green">{status}</Tag>;
    case 'Отклонено':
      return <Tag color="red">{status}</Tag>;
    case 'На согласовании':
      return <Tag color="blue">{status}</Tag>;
    default:
      return status;
  }
};

export default getRegApplicationsTag;
