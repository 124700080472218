const colors = {
  light: {
    primaryColor: 'linear-gradient(128.14deg, #6E54F5 26.29%, #08B6F2 83.5%)', // red
    mainTextColor: '#212121', // very black
    mainBackgroundColor: '#f5f4f4', // light grey
    elementBackgroundColor: '#ffffff', // white
    secondaryTextColor: '#5a5a5a', //
  },
  dark: {
    primaryColor: '#921010', // dark red
    mainTextColor: '#f5f4f4', // light grey
    mainBackgroundColor: '#212121', // very black
    elementBackgroundColor: '#2f2f2f', // dark grey
    secondaryTextColor: '#b4c4de', //
  },
  white: '#ffffff',
  black: '#212121',
};


export default colors;

