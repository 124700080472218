import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { getApplicationStepReportById } from '../../../../../utils/http';
import Reports from './AnnotationReportComponents/Reports';


function Step({ applicationProp, step, updateParentApplication }) {
  const [application, setApplication] = useState(null);

  useEffect(() => {
    const stepId = applicationProp.applicationStepReports.find((s) => Number(s.stepNumber) === step)?.id;
    if (stepId) {
      getApplicationStepReportById(stepId)
        .then((resp) => setApplication(resp));
    }
  }, []);

  return (
    <div>
      {application ? (
        <Reports
          // initialApplication={{ ...application, ...applicationProp, id: application.id }}
          initialApplication={{
            ...application, ...applicationProp, id: application.id, parentAppId: applicationProp.id,
          }}
          step={step}
          updateParentApplication={updateParentApplication}
        />
      ) : ''}
    </div>
  );
}

Step.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  step: PropTypes.number.isRequired,
  updateParentApplication: PropTypes.func.isRequired,
};

export default withRouter(Step);
