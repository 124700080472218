/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Card, Col, Row, Popconfirm, notification, Modal, Select,
  InputNumber,
} from 'antd';
import { editApplications2InfoTabAnalog } from '../../../../utils/http';


const labels = [
  {
    label: 'Организация/научная группа',
    key: 'organisation',
  },
  {
    label: 'Суть проводимых исследований/разработок',
    key: 'workDescription',
  },
  {
    label: 'Преимущество предлагаемого решения',
    key: 'advantage',
  },
  {
    label: 'Наименование аналога',
    key: 'analogName',
  },
  {
    label: 'Описание',
    key: 'analogDescription',
  },
  {
    label: 'Стоимость 1 шт, руб',
    key: 'cost',
  },
  {
    label: 'Доступно ли в России',
    key: 'isAvaliableRussia',
  },
];

const { Option } = Select;
const { TextArea } = Input;
function InfoTabAnalogModule({
  elementsArrayProp, applicationId, disabled, updateOriginalObject, additionalFieldName,
}) {
  const [elementsArray, setElementsArray] = useState([]);
  const [objectInEditId, setObjectInEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const formRef = useRef(null);
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  useEffect(() => {
    setElementsArray(elementsArrayProp);
    setIsFormDisabled(disabled);
  }, []);

  const onEditClick = (id) => setObjectInEditId(id);

  const onAddClick = () => {
    setLoading(true);
    const objToSave = {};
    objToSave.applications2_id = applicationId;
    editApplications2InfoTabAnalog(objToSave)
      .then((id) => {
        setObjectInEditId(id);
        const updatedArray = [...elementsArray, { id }];
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject(updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onDeleteClick = (id) => {
    setLoading(true);
    editApplications2InfoTabAnalog({ id, isDeleted: true })
      .then(() => {
        setObjectInEditId(null);
        const updatedArray = elementsArray.filter((p) => p.id !== id);
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject(updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onCancelClick = () => {
    const values = formRef.current.getFieldsValue();
    const valuesKeys = Object.keys(values);
    let isEmpty = true;
    valuesKeys.forEach((k) => {
      if (values[k]) {
        isEmpty = false;
      }
    });
    if (isEmpty) {
      onDeleteClick(objectInEditId);
    } else {
      setObjectInEditId(null);
    }
  };

  const onSaveClick = () => {
    const values = formRef.current.getFieldsValue();
    let error = false;

    Object.entries(values).forEach(([key, value]) => {
      if (!value && !error) {
        const { label } = labels.find((l) => l.key === key);
        error = true;
        notification.error({ message: `Не заполнено поле: ${label}` });
      }
    });

    if (error) return;
    setLoading(true);
    editApplications2InfoTabAnalog({ id: objectInEditId, ...values })
      .then(() => {
        const updatedArray = elementsArray.map((p) => (p.id === objectInEditId ? {
          id: objectInEditId, ...values,
        } : p));
        setObjectInEditId(null);
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject(updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onExpand = (id) => {
    setObjectInEditId(id);
    setIsExpanded(true);
    setIsFormDisabled(true);
  };

  const onCollapse = () => {
    setObjectInEditId(null);
    setIsExpanded(false);
    setIsFormDisabled(disabled);
  };

  return (
    <div style={{
      margin: '20px', padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
    }}
    >
      <span style={{ fontSize: '24px', color: '#212121' }}>Аналоги</span>
      {elementsArray.map((p, i) => (
        <Card
          key={p.id}
          style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
          bodyStyle={{
            borderLeft: objectInEditId === p.id ? '4px solid #1890FF' : '',
            borderRadius: objectInEditId === p.id ? '5px' : '',
          }}
        >
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                {p.organisation || `Аналог №${i + 1}`}
              </Col>
              <Col>
                <Row align="middle" gutter={10}>
                  {!objectInEditId && (<Col><Button type="primary" onClick={() => onEditClick(p.id)} disabled={isFormDisabled}>Редактировать</Button></Col>)}
                  {!objectInEditId && (
                    <Col>
                      <Popconfirm
                        title="Уверены, что хотите удалить аналог?"
                        onConfirm={() => onDeleteClick(p.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button type="danger" disabled={isFormDisabled}>Удалить</Button>
                      </Popconfirm>
                    </Col>
                  )}
                  {!objectInEditId && (<Col><Button onClick={() => onExpand(p.id)}>Показать</Button></Col>)}
                </Row>
              </Col>
            </Row>
            {objectInEditId === p.id
                && (
                  <Modal title="Редактирование аналога" visible footer={null} width="90vw" maskClosable={false} closable={false}>
                    <Form initialValues={{ remember: true }} ref={formRef} style={{ marginTop: '20px' }}>
                      {!additionalFieldName && (
                      <Col>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Организация/научная группа</span>
                          <Form.Item
                            name="organisation"
                            initialValue={p.organisation}
                          >
                            <TextArea autoSize disabled={isFormDisabled || loading} />
                          </Form.Item>
                        </div>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Суть проводимых исследований/разработок</span>
                          <Form.Item
                            name="workDescription"
                            initialValue={p.workDescription}
                          >
                            <TextArea autoSize disabled={isFormDisabled || loading} />
                          </Form.Item>
                        </div>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Преимущество предлагаемого решения</span>
                          <Form.Item
                            name="advantage"
                            initialValue={p.advantage}
                          >
                            <TextArea autoSize disabled={isFormDisabled || loading} />
                          </Form.Item>
                        </div>
                      </Col>
                      )}

                      {additionalFieldName && (
                      <Col>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">{`Наименование аналога ${additionalFieldName}`}</span>
                          <Form.Item
                            name="analogName"
                            initialValue={p.analogName}
                          >
                            <TextArea autoSize disabled={isFormDisabled || loading} />
                          </Form.Item>
                        </div>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Описание</span>
                          <Form.Item
                            name="analogDescription"
                            initialValue={p.analogDescription}
                          >
                            <TextArea autoSize disabled={isFormDisabled || loading} />
                          </Form.Item>
                        </div>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Стоимость 1 шт, руб</span>
                          <Form.Item
                            name="cost"
                            initialValue={p.cost}
                          >
                            <InputNumber disabled={isFormDisabled || loading} style={{ width: '100%' }} />
                          </Form.Item>
                        </div>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Доступно ли в России</span>
                          <Form.Item
                            style={{ width: '100%' }}
                            name="isAvaliableRussia"
                            initialValue={p.isAvaliableRussia}
                          >
                            <Select disabled={disabled}>
                              {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      )}
                    </Form>
                    <Row justify="end">
                      {objectInEditId === p.id && isExpanded && (<Col><Button type="primary" onClick={() => onCollapse()}>Закрыть</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button onClick={onCancelClick}>Отменить</Button></Col>)}
                    </Row>
                  </Modal>
                )}
          </Col>
        </Card>
      ))}
      {!objectInEditId && <Button type="primary" onClick={onAddClick} style={{ marginTop: '20px', width: '200px', fontSize: '16px' }} disabled={isFormDisabled}>Добавить аналог</Button>}
    </div>
  );
}

InfoTabAnalogModule.propTypes = {
  elementsArrayProp: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  applicationId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  updateOriginalObject: PropTypes.func.isRequired,
  additionalFieldName: PropTypes.string,
};

InfoTabAnalogModule.defaultProps = {
  additionalFieldName: null,
};

export default InfoTabAnalogModule;
