/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';
import {
  Col,
  Button,
  Form,
  Row,
  Input,
  notification,
} from 'antd';
import getUserRole from '../../../../utils/localeStorage/getUserRole';


const { TextArea } = Input;
function Sopd({
  application, onSaveSopd,
}) {
  const formRef = useRef();
  const isDZM = getUserRole() === 'expertDZM';
  const [isShowDeclineReason, setIsShowDeclineReason] = useState(false);

  const onAgree = () => {
    onSaveSopd({ id: application.id, sopdStatus: 'yes', sopdDeclineReason: '' });
  };

  const onDecline = () => {
    const values = formRef.current.getFieldsValue();
    if (!values.sopdDeclineReason) {
      notification.error({ message: 'Заполните причину' });
    } else {
      onSaveSopd({ ...values, sopdStatus: 'no' });
    }
  };

  // { person: "", directors: [""]}
  let persons = [];
  application.persons.forEach((person) => {
    const existingPerson = persons.find((p) => p.person === person.form);
    if (existingPerson) {
      persons = persons.map((p) => (p.person === person.form
        ? { ...p, directors: uniq([...p.directors, person.directorFullName]) }
        : p));
    } else {
      persons.push({ person: person.form, directors: [person.directorFullName] });
    }
  });

  return (
    <div style={{ zIndex: 1 }}>
      <Form ref={formRef}>

        <div className="application-main-wrapper" style={{ height: '82vh' }}>

          <div className="application-main-box-wrapper" style={{ marginBottom: '20px', paddingBottom: '40px' }}>
            <div className="application-main-box-mask" />

            <Form.Item
              name="id"
              initialValue={application.id}
              hidden
            />

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Тема проекта:</span>
              <Col>
                <Input disabled defaultValue={application.theme} />
              </Col>
            </div>

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Руководитель проекта:</span>
              <Col>
                <Input disabled defaultValue={application.directorFirstName} />
              </Col>
            </div>

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Организация грантополучатель:</span>
              <Col>
                <Input disabled defaultValue={application.webUserShortName} />
              </Col>
            </div>

            {!isDZM && persons.map((p) => (
              <div key={p.person} style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="application-main-box-content-wrapper">
                  <span className="application-main-box-label">Организация соисполнитель:</span>
                  <Col>
                    <Input disabled defaultValue={p.person} style={{ margin: '2px 0px' }} />
                  </Col>
                </div>
                <div className="application-main-box-content-wrapper" style={{ marginTop: '-20px' }}>
                  <span className="application-main-box-label">Научный(-ые) руководитель(-и) организации-соисполнителя:</span>
                  <Col>
                    {p.directors.map((d) => <Input disabled defaultValue={d} style={{ margin: '2px 0px' }} />)}
                  </Col>
                </div>
              </div>
            ))}

            {isDZM && (
            <div className="application-main-box-content-wrapper">
              <p style={{ zIndex: 1 }}>Не допускается проведение экспертизы по данному Проекту, в случае если Вы:</p>
              <ul style={{ zIndex: 1 }}>
                <li>Являетесь руководителем и/или членом коллектива научно-практического Проекта, и/или главным врачом медицинской организации, в которой осуществляют трудовую деятельность члены научно-практического Проекта, подлежащего экспертизе;</li>
                <li>Имеете трудовые или гражданско-правовые отношения с организацией-заявителем Проекта;</li>
                <li>Имеете совместные научные работы (научные публикации) или совместные научные проекты с руководителем Проекта за 2 (Два) года, предшествующих дате направления на экспертизу Проекта;</li>
                <li>Являетесь в настоящем или в прошлом научным руководителем Проекта;</li>
                <li>Находитесь в зависимости в части получения вознаграждения в материальной или нематериальной форме от руководителя Проекта;</li>
                <li>Имеете по иным причинам личную заинтересованность в результатах экспертизы, которая может повлиять на объективность рассмотрения результатов Проекта.</li>
              </ul>
            </div>
            )}

            {!isDZM && (
            <div className="application-main-box-content-wrapper">
              <p style={{ zIndex: 1 }}>Не допускается проведение экспертизы по данному Проекту, в случае если Вы:</p>
              <ul style={{ zIndex: 1 }}>
                <li>Входите в состав научного коллектива Проекта или в состав научного коллектива проекта, участвующего в том же конкурсе по той же отрасли науки (области знания), что и рассматриваемый Проект в рамках грантовой программы Мэра Москвы в соответствии с ПП-1636 от «19» октября 2021 года;</li>
                <li>Имеете трудовые или гражданско-правовые отношения с организацией-заявителем Проекта;</li>
                <li>Имеете совместные научные работы (научные публикации) или совместные научные проекты с руководителем проекта организации-заявителя и/или научным(-ыми) руководителем(-ями) проекта организации-соисполнителя за 2 (Два) года, предшествующих дате направления на экспертизу Проекта;</li>
                <li>Являетесь в настоящем или в прошлом научным руководителем, исполнителем Проекта;</li>
                <li>Находитесь в зависимости в части получения вознаграждения в материальной или нематериальной форме хотя бы от одного исполнителя или руководителя Проекта;</li>
                <li>Имеете по иным причинам личную заинтересованность в результатах экспертизы, которая может повлиять на объективность рассмотрения результатов Проекта.</li>
              </ul>
            </div>
            )}


            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Пожалуйста, подтвердите отсутствие конфликта интересов исходя из вышеуказанных критериев аффилированности:</span>
              {!isShowDeclineReason && (
              <Row style={{ zIndex: 1 }} gutter={10}>
                <Col>
                  <Button onClick={onAgree} type="primary" style={{ backgroundColor: '#237804', borderColor: '#237804' }}>
                    Подтвердить
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => setIsShowDeclineReason(true)} danger type="primary">
                    Отклонить
                  </Button>
                </Col>
              </Row>
              )}

              {isShowDeclineReason && (<span className="application-main-box-label">Пожалуйста, укажите причину:</span>)}
              {isShowDeclineReason && (
              <Form.Item
                name="sopdDeclineReason"
                initialValue={application.sopdDeclineReason}
              >
                <TextArea style={{ marginBottom: '20px' }} />
              </Form.Item>
              )}

              {isShowDeclineReason && (
              <Row style={{ zIndex: 1 }} gutter={10}>
                <Col>
                  <Button onClick={() => setIsShowDeclineReason(false)} type="primary" danger>
                    Отмена
                  </Button>
                </Col>
                <Col>
                  <Button onClick={onDecline} type="primary">
                    Отправить ответ
                  </Button>
                </Col>
              </Row>
              )}

            </div>

          </div>
        </div>
      </Form>
    </div>
  );
}

Sopd.propTypes = {
  application: PropTypes.shape().isRequired,
  onSaveSopd: PropTypes.func.isRequired,
};

export default Sopd;
