import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Navigation from './components/Navigation/Navigation';
import Information from './components/Information/Information';
import Step from './components/Step/Step';
import Smeta from './components/PaymentModule/Smeta';


function AppDZMPerson({
  application, isShowSurvey, isShowSurveyTab, stepNumber, isFinancialReport, isAnnotationReport, editStepDate, updateParentApplication, onAddStep,
}) {
  const [view, setView] = useState('common');

  useEffect(() => {
    if (isShowSurvey) {
      setView('survey');
    } else if (stepNumber) {
      setView(`${stepNumber}`);
    }
  }, []);

  const onChangeView = (updatedView) => setView(updatedView);

  // const isAppDisabled = application.status === 'Завершен' || application.status === 'Расторгнут';
  const isAppDisabled = false;
  return (
    <div>
      <Navigation
        application={application}
        view={view}
        onChangeView={onChangeView}
        isShowSurveyTab={isShowSurveyTab}
        isShowPaymentTab={!!application.parentApplicationPerson}
        editStepDate={editStepDate}
        onAddStep={onAddStep}
      />
      {view === 'common' && (
      <Information
        applicationProp={application}
      />
      )}
      {view === '0' && (
        <Step
          applicationProp={application}
          step={1}
          isShowAnnotationCard
          isAnnotationReport={isAnnotationReport}
          updateParentApplication={updateParentApplication}
        />
      )}
      {view === '1' && (
      <Step
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        applicationProp={application}
        step={2}
        isShowAnnotationCard={application.maxStepsNumber === 2}
        updateParentApplication={updateParentApplication}
      />
      )}
      {view === '2' && (
      <Step
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        applicationProp={application}
        step={3}
        isShowAnnotationCard={application.maxStepsNumber === 3}
        updateParentApplication={updateParentApplication}
      />
      )}
      {view === '3' && (
      <Step
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        applicationProp={application}
        step={4}
        isShowAnnotationCard={application.maxStepsNumber === 4}
        updateParentApplication={updateParentApplication}
      />
      )}
      {view === '4' && (
      <Step
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        applicationProp={application}
        step={5}
        isShowAnnotationCard={application.maxStepsNumber === 5}
        updateParentApplication={updateParentApplication}
      />
      )}
      {view === '5' && (
      <Step
        isFinancialReport={isFinancialReport}
        isAnnotationReport={isAnnotationReport}
        applicationProp={application}
        step={6}
        isShowAnnotationCard={application.maxStepsNumber === 6}
        updateParentApplication={updateParentApplication}
      />
      )}
      {view === 'payment' && (
      <Smeta payment={application.parentApplicationPerson} applicationId={application.id} isAppDisabled={isAppDisabled} />
      )}
    </div>
  );
}

AppDZMPerson.propTypes = {
  application: PropTypes.shape().isRequired,
  isShowSurveyTab: PropTypes.bool.isRequired,
  isShowSurvey: PropTypes.bool,
  isFinancialReport: PropTypes.bool.isRequired,
  isAnnotationReport: PropTypes.bool.isRequired,
  stepNumber: PropTypes.string,
  editStepDate: PropTypes.func.isRequired,
  updateParentApplication: PropTypes.func.isRequired,
  onAddStep: PropTypes.func.isRequired,
};

AppDZMPerson.defaultProps = {
  isShowSurvey: false,
  stepNumber: null,
};

export default withRouter(AppDZMPerson);
