const applicationsWithSurvey = [
  '2fd91448-d05e-43d4-ba27-4a2d69e0b3a9',
  '4c05e55f-2a85-4e0b-aef8-a3bf1041d83f',
  '638e5380-f55f-480e-a459-50e3d127a606',
  'c4166637-dcf7-4c08-998d-64bd45f37ce9',
  'f2f91775-d140-4a74-a4f1-5ec0174c455b',
  '0810c94d-b4c0-4a3e-9bcf-a648d8d0194e',
  '07cfed81-8841-49e4-9480-1caf0e2d46ab',
  '0b521766-3249-41dc-a0c5-77f155f6e1f0',
  '566dd31c-3f30-4c21-9cbc-660fdddb6140',
  '01ad25c2-c139-4ba1-b347-ba65fb37db9e',
  '74e527a1-a33b-4eb7-b46e-c6cca00971a2',
  'e6c71db8-5a17-4a97-852e-9e9edde76cb5',
  '41e10309-fa0c-49b2-8f4f-ff0062944c43',
  'aeebe47e-d449-4e15-9fb4-0f55ef8b4152',
  '20f282ca-2933-4c5a-8c60-349fef588973',
  '0ea2c519-d82b-4216-901e-3efb28462041',
  'b345a7bb-bee9-4e16-8f5c-9f611132e429',
  '4376276d-e444-45b7-bf2d-b9464a5dffa7',
  'd47e3ad8-7bbd-4634-9314-f9de748f3c43',
  '5fe32d3c-c432-4a0f-924f-bf6353d19159',
  '706f4399-2431-4ee5-a8f4-2107cec8255e',
  '06670dee-7b9e-4237-b1ac-e2ac9cb5e9e8',
  '2ec1ebf3-6b3d-4f8e-8021-b2d11575bb63',
  '23cc96c1-8764-4fc4-a42a-2bc54348de9b',
  '27399354-8920-41e9-af3f-81f5019ce4d9',
  'd4e03207-9307-4123-bd7a-def3e96d73a0',
  '8155e1df-ef0c-4012-8d9b-5216d5dfa402',
  '3c634db7-e2eb-4f0d-8cd2-3da1f4955e8c',
  '8126d08a-f646-46af-a71e-571f1f6b48ef',
  '141058a8-6488-4c8f-b836-ba58258ff482',
  '8068cb6a-ea6c-4440-a327-6a7a4f8f6ae6',
  'e6d3182c-4f30-49c2-834e-bdc931af52e5',
  '2adb94e7-ac3f-45b1-b05a-7d6d02cbeb9e',
  'cb8c36fe-e5d7-46c3-935a-52ec7b553068',
  '27bbe8ef-e7d4-4e1e-9c02-294ab63682a4',
  '0186418a-7b15-48e8-a0f0-49c68eec1eaf',
  '4083d614-f2f8-4aae-9dd8-8065868b0ce4',
  'ee43a580-4be6-4e82-961b-98870cdbe2ad',
  '604fe15c-4e53-4f4a-835d-e5f83b1a0ed8',
  'c925f814-a094-4d2e-8ace-45c6ed41ef25',
  '2c0c70d7-82fa-4dc3-8a50-7980dbf7337a',
  '56d55d68-ef77-4f6f-919e-75e90fdf1f04',
  '1ef78b66-4a8b-4dae-86af-4fae97835eba',
  'abcd310b-7bf2-47d5-9bbd-ef750b9d706d',
  '6e8e3def-c42c-46b1-8611-0b09202cda2b',
  '17d6448c-88b3-4e64-b745-9a7cbd99eddf',
  '0d0c9dba-7cf8-4d9e-8fb4-dc29518991a3',
  '09bffffd-59b5-4c9c-93bf-3e036680c343',
  'e4790c65-5432-4ad1-ab2d-feaad82a2e8a',
  'f11851c2-0ed2-40e7-93f2-2e757aefc95f',
  '7adfb0b1-a4cd-469a-9161-b8c046adb707',
  'cb27e3f7-a427-4295-adaf-b91c948a4d65',
  'd4db8ec5-9ea3-4173-b586-93a5f277794e',
  '61ed9b2c-ad7e-441b-a03a-74e32e961f33',
  'b04f7e47-4c9f-44d5-b418-c436d9de9372',
  '9e556e59-478f-4bd7-a883-912bebf9bd10',
  '9b24cdde-da8d-4275-8b2f-5d4924e797df',
  '3cae253e-8926-46a6-93d0-c95ccda1f98d',
  '82b601f4-70dd-49e1-8903-679945169fae',
  '93d9ad7c-d65e-46ad-a101-0c3d15622851',
  '10202050-6a3a-496d-9994-0842266d55d8',
  '8a4218e5-8319-498a-8479-b0b5ef3d9b27',
  '03352509-c537-451a-9cec-6fa72342275f',
  'bf300ade-e8fd-47cc-b2fa-b969474722c9',
  '20daed62-9a23-4169-bd4f-7a4c8b7f5d32',
  '49c2e87b-45f2-4883-b88c-9830842046af',
  '7422d7c3-318e-434d-9ba3-7bb5c14d3a7c',
  'fb407f34-6ff6-4d3c-ae77-552fac47ea99',
  '0c66cbdc-1474-4f62-b412-d50e0a4861f6',
  '60403244-1978-407d-bcfd-b299588c4efe',
  '77acb6a6-5497-40ff-ab58-cc4db19ddcab',
  '1b798e9d-0c9d-406f-9729-774330b627e8',
  'b5ef5237-a442-41db-9026-15c91254d270',
  'cb1dcf83-87e4-47d7-8c4b-c836d0027495',
  'a7fb58e1-93f5-44a6-bc61-c9e0b5535e8c',
  '083bc369-c1a6-464b-8da2-e2a6adc2f6db',
  'c6b5a71a-146a-4140-af50-a9569703881c',
  '237e363f-067f-4def-8750-baba9d7cdb4d',
  '1cdd1414-2e68-4fb6-bf2a-3e3258c6527b',
  '941d5571-869f-4728-b844-1ed71aea053c',
  '19e71764-6c3b-461f-a60f-d52eb5217acf',
  'e61f7ecd-f32a-4131-801f-db65d31e2957',
  '1ccd7b60-4a39-452c-b42e-6b155afd131b',
  '8a016f53-0163-4baa-81dc-baf529e6d3e6',
  '1d477b7d-26f0-42ea-9511-2d153d2d6e60',
  'c242fe60-0167-4ae5-9b8f-32aab0c202f7',
  '9d5c4005-766e-4156-9ecb-cd86bc3da341',
  'e0ba2aea-5c3e-4758-aa62-dace57bea0e8',
  '4444d788-0515-4f2f-b082-ac70fd4ed0af',
  'f943c037-1f79-4b54-a7db-c3d581464e4d',
  '229fb7e0-e024-4782-98e2-94bc6f4b8b16',
  'e4cf88a3-2380-4f99-81bb-31c609ed4dde',
  '6cc391b9-4519-440c-a071-6642aaa89241',
  'a5396bcf-0df1-4e51-96b1-c45659bf8d08',
  '581383c8-4b03-47af-a562-ee58607b88ba',
  '15f9a782-bcc7-4ab4-abfe-a5c0184eca65',
  'b3f20979-da81-4a93-97f5-9e68a2fa3ab1',
  '7e4281bf-f171-4d4a-a20e-8ecc0fecba60',
  '4d41a2d5-ef15-4c84-a3f4-a8a8be51fbba',
  'ceee10e8-960b-42e8-ac5d-f372fbd5b09a',
  '86e1556e-b02f-41da-9718-9870e94539b7',
  '076fddbe-da06-41b5-823b-c9f278bee8c8',
  '90b57c93-14b0-478e-a3af-692ad5f6d4d6',
  '1731a4ff-49ed-4bca-9b1b-328b800e514c',
  '356c3afc-357a-4062-a492-283835afd7ce',
  '42d8ab9f-e78c-4b4b-ad82-99416fe7c8df',
  '1d070a0f-cb96-4539-9b52-876234202036',
  'b32cff7a-6ba7-4c80-9e4f-9ec42b5912b1',
  '0ac811c5-cbfa-4fcb-b70e-1427b6ecbc02',
  '6d824806-1c67-4bd6-8537-417664878114',
  'c96d98ef-4b76-46dd-8c60-8e8d464fa0b6',
  '52cb2024-8b54-4204-b875-14694308aff2',
  '520aabf1-e221-457f-af4e-3459b163a8e6',
  '45b750e5-dcbc-4e3f-91e9-590381a9cde2',
  '3364f084-595b-4d14-81d8-39755f24a388',
  '12d3a9e7-6609-4995-a8a4-7847e52d905d',
  '2d63b8ae-0ae2-45c6-bcca-c040f7c721f5',
  '1209f604-86a5-409b-8b5f-ccc62125933d',
  'a5d3d365-b178-4671-9a3d-4026f512885e',
  'fabac295-e1ee-49d5-a30a-36e86cea8ed5',
  'db077494-7fb8-4144-b058-e9b8f203b29b',
  '761200b7-9583-459e-ad37-8371f15b3794',
  '53d7d084-e421-4cec-a56b-3cbeb81ce2bd',
  'fe2d532a-238f-474d-818a-f2569cd044c9',
  '0e4ab90a-ac38-4e3d-83c4-e69335ced9cc',
  '701bf929-0c91-46ac-905c-f9280e92797c',
  '93947b3f-bc21-4ad9-aa11-6c0775593dec',
  'e663657e-396d-4748-89fd-9fc50bb5226a',
  '3d44d6f3-8ded-4f7e-9e16-c9604e1cac44',
  'eda0b47d-17ef-41de-9a42-53459826186b',
  'e503af52-f673-41d7-bd01-0463e07f9262',
  '1c9c9dc5-f2a5-4a5f-9a75-28e56fe0d743',
  '29672d33-ecd3-4af2-85f1-c1a73d12a5f6',
];

export default applicationsWithSurvey;
