/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import {
  Input, Button, Select, notification, Card, Popconfirm, Modal,
} from 'antd';
import './Survey.css';
import {
  barrierOptions, levelOptions, analysOptions, placeOptions, helpOptions, addictionOptions, revenueOptions,
} from './fieldOptions';
import SurveyField from './SurveyField';
import { editApplicationSurveyPostmonitoringResult } from '../../utils/http';


const { Option } = Select;
const customResultFields = ['использование в нескольких медицинских учреждениях одного региона (перечислить)', 'использование в нескольких медицинских учреждениях в разных регионах (перечислить)', 'свой вариант'];
function SurveyResult({ elementsArrayProp, surveyId }) {
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [values, setValues] = useState(null);
  const [elementsArray, setElementsArray] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setElementsArray(elementsArrayProp);
    setIsFormDisabled(false);
  }, []);

  const onSelect = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const onInput = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const onCheckBox = (field, value, option) => {
    const valArray = values[field] || [];
    if (value) {
      setValues({ ...values, [field]: [...valArray, option] });
    } else {
      setValues({ ...values, [field]: valArray.filter((e) => e !== option) });
    }
  };

  const onAddTableRow = (field) => {
    const currentRows = values[field] || [];
    setValues({ ...values, [field]: [...currentRows, { id: uuidv4(), value: '' }] });
  };

  const onRemoveTableRow = (field, rowId) => {
    setValues({ ...values, [field]: values[field].filter((e) => e.id !== rowId) });
  };

  const onTableRowChange = (field, rowId, value) => {
    setValues({ ...values, [field]: values[field].map((e) => (e.id === rowId ? { id: rowId, value } : e)) });
  };

  const onEditClick = (object) => setValues(object);

  const onAddClick = () => {
    setIsFormDisabled(true);
    const objToSave = {};
    objToSave.applicationSurveyPostmonitoring_id = surveyId;
    editApplicationSurveyPostmonitoringResult(objToSave)
      .then((res) => {
        setValues({ id: res.id });
        const updatedArray = [...elementsArray, { id: res.id }];
        setElementsArray(updatedArray);
        setIsFormDisabled(false);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onDeleteClick = (id) => {
    setIsFormDisabled(true);
    editApplicationSurveyPostmonitoringResult({ id, isDeleted: true })
      .then(() => {
        setValues(null);
        const updatedArray = elementsArray.filter((p) => p.id !== id);
        setElementsArray(updatedArray);
        setIsFormDisabled(false);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onExpand = (object) => {
    setValues(object);
    setIsExpanded(true);
    setIsFormDisabled(true);
  };

  const onCollapse = () => {
    setValues(null);
    setIsExpanded(false);
    setIsFormDisabled(false);
  };

  const onCancelClick = () => {
    const valuesKeys = Object.keys(values);
    let isEmpty = true;
    valuesKeys.forEach((k) => {
      if (values[k] && k !== 'id') {
        isEmpty = false;
      }
    });
    if (isEmpty) {
      onDeleteClick(values.id);
    } else {
      setValues(null);
    }
  };

  const onSaveClick = () => {
    setIsFormDisabled(true);
    const objToSave = { ...values };
    objToSave.foreignAnalogues = values.foreignAnalogues?.map((e) => e.value);
    objToSave.russianAnalogues = values.russianAnalogues?.map((e) => e.value);
    editApplicationSurveyPostmonitoringResult({ id: objToSave.id, ...objToSave })
      .then(() => {
        const updatedArray = elementsArray.map((p) => (p.id === objToSave.id ? values : p));
        setValues(null);
        setElementsArray(updatedArray);
        setIsFormDisabled(false);
        notification.success({ message: 'Сохранено!' });
      });
  };

  return (
    <div style={{
      padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
    }}
    >
      <span style={{ fontSize: '24px', color: '#212121' }}>Результаты</span>

      {elementsArray.map((p, i) => (
        <Card
          key={p.id}
          style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
          bodyStyle={{
            borderLeft: values?.id === p.id ? '4px solid #1890FF' : '',
            borderRadius: values?.id === p.id ? '5px' : '',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px',
            }}
            >
              <div>
                {p.resultName || `Результат №${i + 1}`}
              </div>
              <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px',
              }}
              >
                {!values && (<Button type="primary" onClick={() => onEditClick(p)} disabled={isFormDisabled}>Редактировать</Button>)}
                {!values && (
                  <Popconfirm
                    title="Уверены, что хотите удалить результат?"
                    onConfirm={() => onDeleteClick(p.id)}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button type="danger" disabled={isFormDisabled}>Удалить</Button>
                  </Popconfirm>
                )}
                {!values && (<Button onClick={() => onExpand(p)}>Показать</Button>)}
              </div>
            </div>

            {values?.id === p.id
                && (
                  <Modal title="Редактирование результат" visible footer={null} width="90vw" maskClosable={false} closable={false}>

                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                      {values?.id === p.id && isExpanded && (<Button type="primary" onClick={() => onCollapse()}>Закрыть</Button>)}
                      {values?.id === p.id && !isExpanded && (<Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button>)}
                      {values?.id === p.id && !isExpanded && (<Button onClick={onCancelClick}>Отменить</Button>)}
                    </div>
                    <div>Введите результат вашего научно-технического проекта</div>
                    <Input disabled={isFormDisabled} style={{ margin: '5px 0px' }} onChange={(e) => onInput('resultName', e.target.value)} defaultValue={values.resultName} />

                    <div>1. Результат реализации Вашего научно-технического проекта и возможность масштабирования:</div>
                    <Select disabled={isFormDisabled} onChange={(e) => onSelect('result', e)} defaultValue={values.result} style={{ width: '100%' }}>
                      {['использование в пределах своего медицинского учреждения',
                        'использование в нескольких медицинских учреждениях одного региона (перечислить)',
                        'использование в нескольких медицинских учреждениях в разных регионах (перечислить)',
                        'свой вариант'].map((u) => <Option key={u} value={u}>{u}</Option>)}
                    </Select>
                    {customResultFields.includes(values.result) && <Input disabled={isFormDisabled} defaultValue={values.resultAdditional1} style={{ marginTop: '5px', marginLeft: '20px' }} onChange={(e) => onInput('resultAdditional1', e.target.value)} />}

                    <div style={{ marginTop: '10px' }}>2. Ориентировочное количество пользователей разработки на текущий момент (работники системы здравоохранения):</div>
                    <Select disabled={isFormDisabled} onChange={(e) => onSelect('usersOrg', e)} defaultValue={values.usersOrg} style={{ width: '100%' }}>
                      {['до 50',
                        'от 50 до 100',
                        'от 100 до 500',
                        'от 500 до 1 000',
                        'более 1 000'].map((u) => <Option key={u} value={u}>{u}</Option>)}
                    </Select>

                    <div style={{ marginTop: '10px' }}>3. Ориентировочное количество пользователей разработки на текущий момент (пациенты):</div>
                    <Select disabled={isFormDisabled} onChange={(e) => onSelect('usersPatience', e)} defaultValue={values.usersPatience} style={{ width: '100%' }}>
                      {['до 500',
                        'от 500 до 1 000',
                        'от 1 000 до 5 000',
                        'от 5 000 до 10 000',
                        'более 10 000'].map((u) => <Option key={u} value={u}>{u}</Option>)}
                    </Select>

                    <div style={{ marginTop: '10px' }}>4. Уровень технологической готовности по Вашей оценке:</div>
                    <Select disabled={isFormDisabled} onChange={(e) => onSelect('techLevel', e)} defaultValue={values.techLevel} style={{ width: '100%' }}>
                      {['Изготовлен экспериментальный образец продукта, ведется разработка прототипа',
                        'Прототип продукта продемонстрирован, необходимо пилотное внедрение',
                        'Полнофункциональный образец испытан, необходима доработка',
                        'Продукт фактически применяется в окончательном виде и в реальных условиях',
                      ].map((u) => <Option key={u} value={u}>{u}</Option>)}
                    </Select>

                    <SurveyField
                      label="5. Барьеры (на текущий момент), препятствующие масштабированию разработанного Вами решения в другие медицинские учреждения или другие региональные системы здравоохранения?"
                      field="barrier"
                      isFormDisabled={isFormDisabled}
                      options={barrierOptions}
                      onInput={onInput}
                      onCheckBox={onCheckBox}
                      onSelect={onSelect}
                      values={values}
                      type="checkbox"
                    />

                    <SurveyField
                      label="6. Уровень подготовки к масштабированию Вашего продукта и анализ рынка (по Вашей оценке, выбрать совпадающие)"
                      field="level"
                      isFormDisabled={isFormDisabled}
                      options={levelOptions}
                      onInput={onInput}
                      onCheckBox={onCheckBox}
                      onSelect={onSelect}
                      values={values}
                      type="checkbox"
                    />

                    <SurveyField
                      label="7. Анализ рынка для Вашего продукта по Вашей оценке"
                      field="analys"
                      isFormDisabled={isFormDisabled}
                      options={analysOptions}
                      onInput={onInput}
                      onCheckBox={onCheckBox}
                      onSelect={onSelect}
                      values={values}
                      type="checkbox"
                    />

                    <SurveyField
                      label="8. Если для производства необходима производственная площадка, опишите статус"
                      field="place"
                      isFormDisabled={isFormDisabled}
                      options={placeOptions}
                      onInput={onInput}
                      onCheckBox={onCheckBox}
                      onSelect={onSelect}
                      values={values}
                      type="select"
                    />

                    <SurveyField
                      label="9. Какая помощь Вам требуется для дальнейшей коммерциализации продукта:"
                      field="help"
                      isFormDisabled={isFormDisabled}
                      options={helpOptions}
                      onInput={onInput}
                      onCheckBox={onCheckBox}
                      onSelect={onSelect}
                      values={values}
                      type="select"
                    />

                    <SurveyField
                      label="10. Зависимость продукта в рамках проекта от импортных технологий:"
                      field="addiction"
                      isFormDisabled={isFormDisabled}
                      options={addictionOptions}
                      onInput={onInput}
                      onCheckBox={onCheckBox}
                      onSelect={onSelect}
                      values={values}
                      type="select"
                    />

                    <div style={{ marginTop: '10px' }}>11. Перечислите зарубежные аналоги (конкурентные решения):</div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {values.foreignAnalogues?.map((a, ii) => (
                        <div
                          key={a.id}
                          style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                          }}
                        >
                          <div style={{ width: '20px' }}>{ii + 1}</div>
                          <Input disabled={isFormDisabled} style={{ width: '100%', margin: '5px 10px' }} defaultValue={a.value} onChange={(e) => onTableRowChange('foreignAnalogues', a.id, e.target.value)} />
                          <Button style={{ width: '100px' }} type="danger" onClick={() => onRemoveTableRow('foreignAnalogues', a.id)}>Удалить</Button>
                        </div>
                      ))}
                      <Button onClick={() => onAddTableRow('foreignAnalogues')} style={{ marginTop: '10px' }}>Добавить</Button>
                    </div>

                    <div style={{ marginTop: '10px' }}>12. Перечислите российские аналоги (конкурентные решения):</div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {values.russianAnalogues?.map((a, iii) => (
                        <div
                          key={a.id}
                          style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                          }}
                        >
                          <div style={{ width: '20px' }}>{iii + 1}</div>
                          <Input disabled={isFormDisabled} style={{ width: '100%', margin: '5px 10px' }} defaultValue={a.value} onChange={(e) => onTableRowChange('russianAnalogues', a.id, e.target.value)} />
                          <Button style={{ width: '100px' }} type="danger" onClick={() => onRemoveTableRow('russianAnalogues', a.id)}>Удалить</Button>
                        </div>
                      ))}
                      <Button onClick={() => onAddTableRow('russianAnalogues')} style={{ marginTop: '10px' }}>Добавить</Button>
                    </div>

                    <SurveyField
                      label="13. Объем уже полученной выручки или экономический эффект от внедрения решения в рамках проекта:"
                      field="revenue"
                      isFormDisabled={isFormDisabled}
                      options={revenueOptions}
                      onInput={onInput}
                      onCheckBox={onCheckBox}
                      onSelect={onSelect}
                      values={values}
                      type="select"
                    />

                  </Modal>
                )}


          </div>

        </Card>
      ))}
      {!values && <Button type="primary" onClick={onAddClick} style={{ marginTop: '20px', width: '200px', fontSize: '16px' }} disabled={isFormDisabled}>Добавить результат</Button>}
    </div>


  );
}

SurveyResult.propTypes = {
  elementsArrayProp: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  surveyId: PropTypes.string.isRequired,
};

export default SurveyResult;
