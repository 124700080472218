import React from 'react';
import PropTypes from 'prop-types';
import SmetaModule from './SmetaModule';
import PaymentModule from './PaymentModule';


function Smeta({ applicationId, payment, isAppDisabled }) {
  return (
    <div style={{ overflowY: 'scroll', height: '79vh' }}>
      <SmetaModule applicationId={applicationId} isAppDisabled={isAppDisabled} />
      <PaymentModule payment={payment} isAppDisabled={isAppDisabled} />
    </div>
  );
}

Smeta.propTypes = {
  applicationId: PropTypes.string.isRequired,
  payment: PropTypes.shape().isRequired,
  isAppDisabled: PropTypes.bool.isRequired,
};

export default Smeta;
