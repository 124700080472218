const fileDownload = (urlLink, name = 'FileName.pdf') => {
  fetch(urlLink).then((t) => t.blob().then((b) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(b);
    a.setAttribute('download', name);
    a.click();
  }));
};

export default fileDownload;
