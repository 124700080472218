/* eslint-disable no-useless-escape */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { flatten } from 'lodash';


const addFilesToFolder = async (files, folder, isNoIndex) => {
  // eslint-disable-next-line no-unused-vars
  for (const [index, fileObj] of files.entries()) {
    const file = await fetch(fileObj.file.urlLink)
      .then((r) => r.blob()).then((blobFile) => new File([blobFile], fileObj.name, { type: blobFile.type }));
    let finalFileName = isNoIndex ? fileObj.name : `(${index + 1}) ${fileObj.name}`;
    const finalFileExtention = finalFileName.split('.')[1];
    if (!finalFileExtention) { finalFileName += '.pdf'; }
    folder.file(finalFileName, file, { base64: true });
  }
};

const exportFiles = async (application) => {
  const parentZip = new JSZip();
  const zip = parentZip.folder(`Приложения заявки №${application.displayID} от ${application.sendOnReviewDate}.zip`);
  if (application.printFormFull) {
    await addFilesToFolder([{ file: { urlLink: application.printFormFull }, name: `Заявка №${application.displayID} от ${application.sendOnReviewDate}` }], parentZip, true);
  }

  const peopleFolder = zip.folder('Сведения о контактных лицах');
  const peopleFiles = [
    ...application.supervisorSOPDFiles_ids,
    ...application.directorSOPDFiles_ids,
    ...application.contactCommonSOPDFiles_ids,
    ...application.contactFinanceSOPDFiles_ids,
    ...flatten(application.applications2AuthorTabPeople_ids.map((e) => e.SOPDFiles_ids)),
  ].filter((f) => !!f).map((f) => ({ file: f, name: f.name }));
  if (peopleFiles.length > 0) {
    await addFilesToFolder(peopleFiles, peopleFolder);
  }

  const infoFolder = zip.folder('Сведения о проекте');
  const infoFiles = [
    ...application.epidemicFiles_ids,
    ...application.medicalEffectFiles_ids,
    ...application.economicEffectFiles_ids,
  ].filter((f) => !!f).map((f) => ({ file: f, name: f.name }));
  if (infoFiles.length > 0) {
    await addFilesToFolder(infoFiles, infoFolder);
  }

  // eslint-disable-next-line no-unused-vars
  const authorFolder = zip.folder('Сведения о Заявителе и команде Проекта');

  const personsFolder = zip.folder('Сведения о Соисполнителях');
  const personFiles = [
    ...flatten(application.applications2PersonsTabPeople_ids.map((e) => e.directorSOPDFiles_ids)),
    ...flatten(application.applications2PersonsTabPeople_ids.map((e) => e.contactCommonSOPDFiles_ids)),
    ...application.applications2PersonsTabPeople_ids.map((e) => e.confirmEmail_id),
  ].filter((f) => !!f).map((f) => ({ file: f, name: f.name }));
  if (personFiles.length > 0) {
    await addFilesToFolder(personFiles, personsFolder);
  }

  const financeFolder = zip.folder('Смета и план-график реализации проекта');
  const financeFiles = [...application.financeFiles_ids, ...application.materials_project].filter((f) => !!f).map((f) => ({ file: f, name: f.name }));
  if (financeFiles.length > 0) {
    await addFilesToFolder(financeFiles, financeFolder);
  }

  parentZip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `Приложения заявки №${application.displayID} от ${application.sendOnReviewDate}.zip`);
  });
};

export default exportFiles;
