/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Card, Col, Row, Popconfirm, notification, Modal,
} from 'antd';
import { editApplications2InfoTabTask } from '../../../../utils/http';


const labels = [
  {
    label: 'Задача',
    key: 'task',
  },
];

const { TextArea } = Input;
function InfoTabTaskModule({
  elementsArrayProp, applicationId, disabled, updateOriginalObject,
}) {
  const [elementsArray, setElementsArray] = useState([]);
  const [objectInEditId, setObjectInEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const formRef = useRef(null);
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  useEffect(() => {
    setElementsArray(elementsArrayProp);
    setIsFormDisabled(disabled);
  }, []);

  const onEditClick = (id) => setObjectInEditId(id);

  const onAddClick = () => {
    setLoading(true);
    const objToSave = {};
    objToSave.applications2_id = applicationId;
    editApplications2InfoTabTask(objToSave)
      .then((id) => {
        setObjectInEditId(id);
        const updatedArray = [...elementsArray, { id }];
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject(updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onDeleteClick = (id) => {
    setLoading(true);
    editApplications2InfoTabTask({ id, isDeleted: true })
      .then(() => {
        setObjectInEditId(null);
        const updatedArray = elementsArray.filter((p) => p.id !== id);
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject(updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onCancelClick = () => {
    const values = formRef.current.getFieldsValue();
    const valuesKeys = Object.keys(values);

    let isEmpty = true;
    valuesKeys.forEach((k) => {
      if (values[k]) {
        isEmpty = false;
      }
    });
    if (isEmpty) {
      onDeleteClick(objectInEditId);
    } else {
      setObjectInEditId(null);
    }
  };

  const onSaveClick = () => {
    const values = formRef.current.getFieldsValue();

    let error = false;

    Object.entries(values).forEach(([key, value]) => {
      if (!value && !error) {
        const { label } = labels.find((l) => l.key === key);
        error = true;
        notification.error({ message: `Не заполнено поле: ${label}` });
      }
    });

    if (error) return;

    setLoading(true);
    editApplications2InfoTabTask({ id: objectInEditId, ...values })
      .then(() => {
        const updatedArray = elementsArray.map((p) => (p.id === objectInEditId ? {
          id: objectInEditId, ...values,
        } : p));
        setObjectInEditId(null);
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject(updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onExpand = (id) => {
    setObjectInEditId(id);
    setIsExpanded(true);
    setIsFormDisabled(true);
  };

  const onCollapse = () => {
    setObjectInEditId(null);
    setIsExpanded(false);
    setIsFormDisabled(disabled);
  };

  return (
    <div style={{
      margin: '20px', padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
    }}
    >
      <span style={{ fontSize: '24px', color: '#212121' }}>Задачи</span>
      {elementsArray.map((p, i) => (
        <Card
          key={p.id}
          style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
          bodyStyle={{
            borderLeft: objectInEditId === p.id ? '4px solid #1890FF' : '',
            borderRadius: objectInEditId === p.id ? '5px' : '',
          }}
        >
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                {p.task || `Задача №${i + 1}`}
              </Col>
              <Col>
                <Row align="middle" gutter={10}>
                  {!objectInEditId && (<Col><Button type="primary" onClick={() => onEditClick(p.id)} disabled={isFormDisabled}>Редактировать</Button></Col>)}
                  {!objectInEditId && (
                    <Col>
                      <Popconfirm
                        title="Уверены, что хотите удалить задачу?"
                        onConfirm={() => onDeleteClick(p.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button type="danger" disabled={isFormDisabled}>Удалить</Button>
                      </Popconfirm>
                    </Col>
                  )}
                  {!objectInEditId && (<Col><Button onClick={() => onExpand(p.id)}>Показать</Button></Col>)}
                </Row>
              </Col>
            </Row>
            {objectInEditId === p.id
                && (
                  <Modal title="Редактирование задачи" visible footer={null} width="90vw" maskClosable={false} closable={false}>
                    <Form initialValues={{ remember: true }} ref={formRef} style={{ marginTop: '20px' }}>
                      <Col>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Задача</span>
                          <Form.Item
                            name="task"
                            initialValue={p.task}
                          >
                            <TextArea autoSize disabled={isFormDisabled || loading} />
                          </Form.Item>
                        </div>
                      </Col>
                    </Form>
                    <Row justify="end">
                      {objectInEditId === p.id && isExpanded && (<Col><Button type="primary" onClick={() => onCollapse()}>Закрыть</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button onClick={onCancelClick}>Отменить</Button></Col>)}
                    </Row>
                  </Modal>
                )}
          </Col>
        </Card>
      ))}
      {!objectInEditId && <Button type="primary" onClick={onAddClick} style={{ marginTop: '20px', width: '200px', fontSize: '16px' }} disabled={isFormDisabled}>Добавить задачу</Button>}
    </div>
  );
}

InfoTabTaskModule.propTypes = {
  elementsArrayProp: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  applicationId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  updateOriginalObject: PropTypes.func.isRequired,
};

export default InfoTabTaskModule;
