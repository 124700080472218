/* eslint-disable no-unused-vars */
import texts from './texts';
import { LOCALES } from '../locales';


export default function messages(inputLocale) {
  const result = {};
  Object.entries(LOCALES)
    .forEach(([key, locale]) => {
      if (locale === inputLocale) {
        Object.entries(texts)
          .forEach(([id, translations]) => { result[id] = translations[locale]; });
      }
    });
  return result;
}
