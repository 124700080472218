import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Input, Form, DatePicker,
} from 'antd';


const { TextArea } = Input;
class paymentPaymentStep extends Component {
  render() {
    const {
      payment, disabled, fieldNameDate, fieldNameRequisites, fieldNameTransfered, fieldNameComment, isShort, isGrants2023,
      fieldNameDateClose, fieldNameRequisitesClose, fieldNameTransferedClose, fieldNameCommentClose, fieldNameTransferedTotal,
    } = this.props;
    return (
      <Col>
        {!isShort && !isGrants2023 && (
        <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
          <Col span={3} style={{ fontSize: '1.5vh' }}>
            Доведено средств ИТОГО
          </Col>
          <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
            <Form.Item
              name={fieldNameTransferedTotal}
              initialValue={payment[fieldNameTransferedTotal]}
            >
              <Input disabled={disabled} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        )}
        <Row>
          {!isGrants2023 && (
          <Col span={12}>
            <Row style={{ color: '#212121', fontSize: '1.3rem', marginBottom: '10px' }}>{isShort ? 'Платеж' : 'Аванс'}</Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Дата платежа
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                <Form.Item
                  name={fieldNameDate}
                  initialValue={payment[fieldNameDate]}
                >
                  <DatePicker disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Реквизиты ПП
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                <Form.Item
                  name={fieldNameRequisites}
                  initialValue={payment[fieldNameRequisites]}
                >
                  <TextArea disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Объем перечисленных средств
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                <Form.Item
                  name={fieldNameTransfered}
                  initialValue={payment[fieldNameTransfered]}
                >
                  <Input disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Комментарий
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                <Form.Item
                  name={fieldNameComment}
                  initialValue={payment[fieldNameComment]}
                >
                  <TextArea disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          )}

          {!isShort && (
          <Col span={12}>
            <Row style={{ color: '#212121', fontSize: '1.3rem', marginBottom: '10px' }}>Закрывающий платеж</Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Дата платежа
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                <Form.Item
                  name={fieldNameDateClose}
                  initialValue={payment[fieldNameDateClose]}
                >
                  <DatePicker disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Реквизиты ПП
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                <Form.Item
                  name={fieldNameRequisitesClose}
                  initialValue={payment[fieldNameRequisitesClose]}
                >
                  <TextArea disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Объем перечисленных средств
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                <Form.Item
                  name={fieldNameTransferedClose}
                  initialValue={payment[fieldNameTransferedClose]}
                >
                  <Input disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
              <Col span={5} style={{ fontSize: '1.5vh' }}>
                Комментарий
              </Col>
              <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                <Form.Item
                  name={fieldNameCommentClose}
                  initialValue={payment[fieldNameCommentClose]}
                >
                  <TextArea disabled={disabled} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          )}
        </Row>
      </Col>
    );
  }
}

paymentPaymentStep.propTypes = {
  payment: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  fieldNameDate: PropTypes.string.isRequired,
  fieldNameRequisites: PropTypes.string.isRequired,
  fieldNameTransfered: PropTypes.string.isRequired,
  fieldNameComment: PropTypes.string.isRequired,
  fieldNameDateClose: PropTypes.string.isRequired,
  fieldNameRequisitesClose: PropTypes.string.isRequired,
  fieldNameTransferedClose: PropTypes.string.isRequired,
  fieldNameCommentClose: PropTypes.string.isRequired,
  fieldNameTransferedTotal: PropTypes.string.isRequired,
  isShort: PropTypes.bool.isRequired,
  isGrants2023: PropTypes.bool.isRequired,
};


export default paymentPaymentStep;
