/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import getStatus from './getStatus';
import './ApplicationCard.less';


const ApplicationCard = ({ application, onAppClick }) => (
  <div className="applicationCard-wrapper" onClick={onAppClick}>
    <div className="applicationCard-row" style={{ marginBottom: '10px' }}>
      <div style={{ fontWeight: 'bold' }}>{`№ ${application.displayID}`}</div>
      <div>
        {getStatus(application.status)}
      </div>
    </div>
    <div className="applicationCard-row">
      <div>{application.theme}</div>
    </div>
    <div className="applicationCard-row" style={{ marginTop: '10px' }}>
      <div style={{ width: '60%', fontSize: '12px' }}>{application.scientificDirection}</div>
      <div style={{ width: '38%', textAlign: 'end' }}>{`${Number(application.projectFinance).toLocaleString('ru-RU')} ₽`}</div>
    </div>
  </div>
);

ApplicationCard.propTypes = {
  application: PropTypes.shape().isRequired,
  onAppClick: PropTypes.func.isRequired,
};

export default ApplicationCard;
