import React from 'react';
import {
  Row, Col, Popconfirm, Button,
} from 'antd';


const getNewSalaryPaymentsColumns = (onEditClick, onDeleteClick, onAddClick, financialIsSalaryDone) => [
  {
    title: 'Член коллектива специалистов (ФИО)',
    dataIndex: 'fio',
    key: 'fio',
    render: (text, object) => (object.isAdd && !financialIsSalaryDone ? <Button type="primary" onClick={onAddClick}>Добавить</Button> : text),
  },
  {
    title: 'Роль в проекте/профессия',
    dataIndex: 'rolePosition',
    key: 'rolePosition',
    render: (_, object) => (object.isAdd || object.isTotal ? '' : <div>{`${object.role}/${object.position}`}</div>),
  },
  {
    title: 'Всего начислено',
    dataIndex: 'totalPaid',
    key: 'totalPaid',
    render: (_, object) => (object.isAdd ? '' : <div>{`${object.totalPaidMajor.toString().length === 1 ? `0${object.totalPaidMajor}` : object.totalPaidMajor} руб. ${object.totalPaidMinor.toString().length === 1 ? `0${object.totalPaidMinor}` : object.totalPaidMinor} коп. `}</div>),
  },
  {
    title: 'в т.ч. НДФЛ',
    dataIndex: 'ndfl',
    key: 'ndfl',
    render: (_, object) => (object.isAdd ? '' : <div>{`${object.ndflMajor.toString().length === 1 ? `0${object.ndflMajor}` : object.ndflMajor} руб. ${object.ndflMinor.toString().length === 1 ? `0${object.ndflMinor}` : object.ndflMinor} коп. `}</div>),
  },
  {
    title: 'ЕНП',
    dataIndex: 'enp',
    key: 'enp',
    render: (_, object) => (object.isAdd ? '' : <div>{`${object.enpMajor.toString().length === 1 ? `0${object.enpMajor}` : object.enpMajor} руб. ${object.enpMinor.toString().length === 1 ? `0${object.enpMinor}` : object.enpMinor} коп. `}</div>),
  },
  {
    title: 'ИТОГО по сотруднику',
    dataIndex: 'totalPerson',
    key: 'totalPerson',
    render: (_, object) => (object.isAdd ? '' : <div>{`${object.totalPersonMajor.toString().length === 1 ? `0${object.totalPersonMajor}` : object.totalPersonMajor} руб. ${object.totalPersonMinor.toString().length === 1 ? `0${object.totalPersonMinor}` : object.totalPersonMinor} коп. `}</div>),
  },
  {
    title:
  <Col>
    <Row>Кассовый  расход</Row>
  </Col>,
    dataIndex: 'actualPaid',
    key: 'actualPaid',
    render: (_, object) => (object.isAdd ? '' : <div>{`${object.actualPaidMajor.toString().length === 1 ? `0${object.actualPaidMajor}` : object.actualPaidMajor} руб. ${object.actualPaidMinor.toString().length === 1 ? `0${object.actualPaidMinor}` : object.actualPaidMinor} коп. `}</div>),
  },
  {
    title: 'Остаток к уплате',
    dataIndex: 'remain',
    key: 'remain',
    render: (_, object) => (object.isAdd ? '' : <div>{`${object.remainMajor.toString().length === 1 ? `0${object.remainMajor}` : object.remainMajor} руб. ${object.remainMinor.toString().length === 1 ? `0${object.remainMinor}` : object.remainMinor} коп. `}</div>),
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: '5%',
    render: (_, object) => (
      <div>
        {!object.isTotal && !object.isAdd && !financialIsSalaryDone && (
        <Col gutter={5}>
          <Row style={{ cursor: 'pointer' }}><Button type="link" style={{ padding: 0 }} onClick={() => onEditClick(object)}>изменить</Button></Row>
          <Row style={{ cursor: 'pointer' }}>
            <Popconfirm
              title="Вы уверены, что хотите удалить?"
              onConfirm={() => onDeleteClick(object)}
              okText="Да"
              cancelText="Нет"
            >
              <Button type="link" style={{ padding: 0 }}>удалить</Button>
            </Popconfirm>
          </Row>
        </Col>
        )}
      </div>
    ),
  },
];

export default getNewSalaryPaymentsColumns;
