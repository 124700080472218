import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
} from 'antd';


function ProfilePage({
  profile, disabled, onSave, onRedirectMain,
}) {
  const formRef = useRef();

  const save = () => {
    const values = formRef.current.getFieldsValue();
    onSave(values);
  };

  return (
    <div style={{ zIndex: 1, height: '100%' }}>

      <Button onClick={onRedirectMain} type="primary" style={{ margin: '20px 0px' }}>
        Вернуться на главную
      </Button>

      <Form ref={formRef}>

        <div className="application-main-wrapper">

          <div className="application-main-box-wrapper" style={{ marginBottom: '20px', paddingBottom: '10px' }}>
            <div className="application-main-box-mask" />

            <Form.Item
              name="id"
              initialValue={profile.id}
              hidden
            />

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">ФИО:</span>
              <Form.Item
                name="shortName"
                initialValue={profile.shortName}
              >
                <Input disabled />
              </Form.Item>
            </div>

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Должность:</span>
              <Form.Item
                name="position"
                initialValue={profile.position}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </div>

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Организация:</span>
              <Form.Item
                name="company"
                initialValue={profile.company}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </div>


            <div className="application-main-box-content-wrapper">
              <Button onClick={save} type="primary" style={{ backgroundColor: '#237804', borderColor: '#237804' }}>
                Сохранить
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

ProfilePage.propTypes = {
  profile: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onRedirectMain: PropTypes.func.isRequired,
};

export default ProfilePage;
