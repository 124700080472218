/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-loop-func */
import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import {
  Button, Popconfirm, Modal, Col, Upload, notification, Spin, Row, Input,
} from 'antd';
import {
  ArrowRightOutlined, InboxOutlined, DownloadOutlined, DeleteOutlined, LoadingOutlined,
} from '@ant-design/icons';
import ArchiveRow from './components/ArchiveRow/ArchiveRow';
import { uploadFile, editGreatArchive, getGreatArchive } from '../../../../../../utils/http';
import getUserId from '../../../../../../utils/localeStorage/getUserId';
import fileDownload from '../../../../../../utils/fileDownload';


const { Dragger } = Upload;
function GreatArchive() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isShowUploadSingleFileModal, setIsShowUploadSingleFileModal] = useState(false);
  const [singleFile, setSingleFile] = useState(null);
  const [data, setData] = useState([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [isShowCreateNewFolder, setIsShowCreateNewFolder] = useState(false);

  const orderData = (array) => {
    const newArray = array.map((a) => ({ ...a, index: Number(a.name.split('.')[0]) }));
    return orderBy(newArray, ['index'], ['asc']);
  };

  const fetchData = (id, newNavigation) => {
    setIsLoading(true);
    getGreatArchive({ id })
      .then((result) => {
        if (result.total > 30) {
          getGreatArchive({ id, pageSize: result.total })
            .then((res) => {
              setData(orderData(res.data));
              setIsLoading(false);
              setSelectedFile(null);
              setSelectedFolder(newNavigation);
            });
        } else {
          setData(orderData(result.data));
          setIsLoading(false);
          setSelectedFile(null);
          setSelectedFolder(newNavigation);
        }
      });
  };

  useEffect(() => {
    fetchData(null, []);
  }, []);

  const onSelectRow = (row, isNew) => {
    if (row.isFolder) {
      let newNavigation = [];
      if (isNew) {
        newNavigation = [...selectedFolder, {
          id: row.id, name: row.name, isFolder: true, parentId: row.parentId,
        }];
      } else {
        const index = selectedFolder.findIndex((x) => x.id === row.id) || 0;
        newNavigation = selectedFolder.slice(0, index + 1);
      }
      fetchData(row.id, newNavigation);
    } else if (selectedFile && selectedFile.id === row.id) {
      setSelectedFile(null);
    } else {
      setSelectedFile(row);
    }
  };

  const onDeleteFile = () => {
    setIsLoading(true);
    editGreatArchive({ id: selectedFile.id, isDeleted: true })
      .then(() => {
        setData(data.filter((d) => d.id !== selectedFile.id));
        setSelectedFile(null);
        setIsLoading(false);
      });
  };

  const onSaveSingleFileModal = () => {
    setIsLoading(true);
    let parent_id = null;
    if (selectedFolder.length > 0) {
      parent_id = selectedFolder[selectedFolder.length - 1].id;
    }
    editGreatArchive({
      file_id: singleFile.id, parent_id, createDate: new Date().toISOString(), author_id: getUserId(),
    })
      .then((newFile) => {
        notification.success({ message: 'Сохранено' });
        setData([...data, newFile]);
        setIsLoading(false);
        setSingleFile(null);
        setIsShowUploadSingleFileModal(false);
      });
  };

  const onSingleFileUpload = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          setSingleFile(file);
        });
    }
  };

  const onSingleFileRemove = () => {
    setSingleFile(null);
  };

  const onSaveNewFolder = () => {
    setIsLoading(true);
    let parent_id = null;
    if (selectedFolder.length > 0) {
      parent_id = selectedFolder[selectedFolder.length - 1].id;
    }
    editGreatArchive({
      parent_id, createDate: new Date().toISOString(), author_id: getUserId(), isFolder: true, name: newFolderName,
    })
      .then((newFolder) => {
        notification.success({ message: 'Сохранено' });
        setData([...data, newFolder]);
        setIsLoading(false);
        setIsShowCreateNewFolder(false);
        setNewFolderName('');
      });
  };

  const onCancelNewFolder = () => {
    setIsShowCreateNewFolder(false);
    setNewFolderName('');
  };

  const onDeleteFolder = () => {
    const folderId = selectedFolder[selectedFolder.length - 1].id;
    const { parentId } = selectedFolder[selectedFolder.length - 1];
    const index = selectedFolder.findIndex((x) => x.id === folderId) || 0;
    const newNavigation = selectedFolder.slice(0, index);
    setIsLoading(true);
    editGreatArchive({
      id: folderId, isDeleted: true,
    })
      .then(() => {
        fetchData(parentId, newNavigation);
        notification.success({ message: 'Сохранено' });
      });
  };

  return (
    <>
      {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 100, zIndex: 100 }} spin />} style={{ position: 'absolute', top: '40%', left: '50%' }} />}
      <div style={{
        backgroundColor: '#fff', borderRadius: '15px', marginRight: '30px', marginTop: '10px',
      }}
      >
        <div style={{
          display: 'flex', flexDirection: 'row', padding: '20px', justifyContent: 'space-between', alignItems: 'center',
        }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {[{ name: 'Все файлы', isAll: true, isFolder: true }, ...selectedFolder].map((e, i) => (
              <div onClick={() => onSelectRow(e, false)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row' }} key={e.name}>
                <div>{e.name}</div>
                {selectedFolder.length !== 0 && selectedFolder.length !== i && <div style={{ margin: '0px 10px' }}><ArrowRightOutlined /></div>}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Button type="primary" disabled={isLoading} onClick={() => setIsShowUploadSingleFileModal(true)}>Загрузить файл</Button>
            <Button disabled={isLoading} onClick={() => setIsShowCreateNewFolder(true)}>Создать папку</Button>
            {selectedFolder.length !== 0 && (
            <Popconfirm
              title="Уверены, что хотите удалить папку?"
              onConfirm={onDeleteFolder}
              okText="Да"
              cancelText="Нет"
            >
              <Button type="danger" disabled={isLoading}>Удалить папку</Button>
            </Popconfirm>
            )}
          </div>
        </div>
        {selectedFile && (
        <div style={{
          display: 'flex', flexDirection: 'row', padding: '10px 20px', alignItems: 'center', gap: '20px', backgroundColor: '#f0f1f1',
        }}
        >
          <div>{selectedFile.name}</div>
          <Button type="primary" disabled={isLoading} onClick={() => fileDownload(selectedFile.urlLink, selectedFile.name)}>Скачать</Button>
          <Popconfirm
            title="Уверены, что хотите удалить файл?"
            onConfirm={onDeleteFile}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="danger" disabled={isLoading}>Удалить</Button>
          </Popconfirm>
        </div>
        )}
        <div style={{
          height: '75vh', overflowY: 'scroll', display: 'flex', flexDirection: 'column',
        }}
        >
          {data.map((e) => (
            <ArchiveRow
              key={e.id}
              rawData={e}
              onSelectRow={onSelectRow}
              selectedFileId={selectedFile?.id}
            />
          ))}
        </div>
      </div>

      {isShowUploadSingleFileModal && (
        <Modal
          visible
          width="70vw"
          maskClosable={false}
          onCancel={() => { setSingleFile(null); setIsShowUploadSingleFileModal(false); }}
          title="Загрузка файла"
          footer={[
            <Button key="submit" type="primary" onClick={onSaveSingleFileModal} disabled={isLoading}>
              Загрузить
            </Button>]}
        >
          <Col>
            {!singleFile && (
            <Dragger
              showUploadList={false}
              onChange={onSingleFileUpload}
              action={null}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
            </Dragger>
            )}
            {singleFile
            && (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(singleFile.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={onSingleFileRemove} /></Col>
              <Col>{singleFile.name}</Col>
            </Row>
            )}
          </Col>
        </Modal>
      )}

      {isShowCreateNewFolder && (
      <Modal
        visible
        width="30vw"
        maskClosable={false}
        onCancel={onCancelNewFolder}
        title="Создать папку"
        footer={[
          <Button key="submit" type="primary" onClick={onSaveNewFolder} disabled={isLoading}>
            Создать
          </Button>]}
      >
        <Col>
          <Input onChange={(e) => setNewFolderName(e.target.value)} />
        </Col>
      </Modal>
      )}
    </>
  );
}

export default GreatArchive;
