/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import usersRoles from './usersRoles';
import customAppNumbers from '../../utils/customAppNumbers';


const getUsersColumns = (onSelect) => {
  const columns = [
    {
      title: 'Название',
      dataIndex: 'shortName',
      key: 'shortName',
      width: '20%',
      render: (text, object) => {
        const number = customAppNumbers.find((e) => e.name === text)?.number;
        let orgName = text;
        if (number) {
          orgName = `${text} (том ${number})`;
        }
        return <a onClick={() => onSelect(object)}>{orgName}</a>;
      },
    },
    {
      title: 'Заявки 2022',
      dataIndex: 'applications2022',
      key: 'applications2022',
      width: '25%',
      render: (text) => text.map((e) => e.displayID)?.join(', '),
    },
    {
      title: 'Заявки 2023',
      dataIndex: 'applications2023',
      key: 'applications2023',
      width: '25%',
      render: (text) => text.map((e) => e.displayID)?.join(', '),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
    },
    {
      title: 'Пароль',
      dataIndex: 'passwordView',
      key: 'passwordView',
      width: '10%',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      width: '10%',
      render: (text) => usersRoles.find((t) => t.value === text)?.label,
    },
  ];
  return columns;
};

export default getUsersColumns;
