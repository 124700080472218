import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form,
} from 'antd';


const { TextArea } = Input;
class Organisation extends Component {
  render() {
    const {
      loading, visible, application,
    } = this.props;
    return (
      <div className="application-main-wrapper" style={{ display: visible ? 'flex' : 'none' }}>
        <div className="application-main-box-wrapper">
          <div className="application-main-box-mask" />


          <div className="application-main-box-content-wrapper">
            <div className="application-subTitle">Сведения об организации-Заявителе</div>
            <div className="application-table-wrapper">
              <div className="application-table-row">
                <div className="application-table-row-cell">Полное наименование организации</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="fullName"
                  initialValue={application.fullName}
                >
                  <TextArea autoSize disabled={loading} minrows={2} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Сокращенное наименование организации</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="shortName"
                  initialValue={application.shortName}
                >
                  <TextArea autoSize disabled={loading} minrows={2} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>ИНН</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="INN"
                  initialValue={application.INN}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
            </div>
          </div>


          <div className="application-main-box-content-wrapper">
            <div className="application-subTitle">Сведения о руководителе организации-Заявителя</div>
            <div className="application-table-wrapper">
              <div className="application-table-row">
                <div className="application-table-row-cell">ФИО</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="directorFullName"
                  initialValue={application.directorFullName}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Должность</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="directorPosition"
                  initialValue={application.directorPosition}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Ученая степень</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="directorDegree"
                  initialValue={application.directorDegree}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Ученое звание</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="directorTitle"
                  initialValue={application.directorTitle}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">Телефон</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="directorPhone"
                  initialValue={application.directorPhone}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>E-mail</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="directorEmail"
                  initialValue={application.directorEmail}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Organisation.propTypes = {
  loading: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  application: PropTypes.shape().isRequired,
};

export default Organisation;
