/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Tag, Col, Row,
} from 'antd';
import { routeGenerator } from '../../../../../../utils/routes';
import getApplicationStatusTag from '../../utils/getApplicationStatusTag';


const getColumns = () => [
  {
    title: '№',
    dataIndex: 'displayID',
    key: 'displayID',
    render: (_, object) => {
      if (object.isPersonApplication) {
        return (
          <Col>
            <Row justify="center">
              <a onClick={() => window.open(routeGenerator.application2Link(object.id), '_blank')}>{object.displayID}</a>
            </Row>
            <Row justify="center">
              <Tag color="gold">Соисполнитель</Tag>
            </Row>
          </Col>
        );
      }
      return (
        <Col>
          <Row justify="center">
            <a onClick={() => window.open(routeGenerator.application2Link(object.id), '_blank')}>{object.displayID}</a>
          </Row>
          <Row justify="center">
            {getApplicationStatusTag(object.status)}
          </Row>
        </Col>
      );
    },
    width: '12%',
  },
  {
    title: 'Тема',
    dataIndex: 'projectName',
    key: 'projectName',
    width: '25%',
    render: (_, object) => (
      <Row justify="center">
        <a onClick={() => window.open(routeGenerator.application2Link(object.id), '_blank')}>{object.projectName}</a>
      </Row>
    ),
  },
  {
    title: 'Руководитель Проекта',
    dataIndex: 'supervisorFullName',
    key: 'supervisorFullName',
  },
  {
    title: 'Запрашиваемая сумма',
    dataIndex: 'financeTotal',
    key: 'financeTotal',
    render: (_, object) => `${object.financeTotalMajor || 0} руб. ${object.financeTotalMinor || 0} коп.`,
  },
  {
    title: 'Направление',
    dataIndex: 'scientificDirection',
    key: 'scientificDirection',
  },
  {
    title: 'Соисполнители',
    dataIndex: 'people',
    key: 'people',
    render: (_, object) => object.applications2PersonsTabPeople_ids.map((p) => p.fullName).join(', '),
  },
];

export default getColumns;
