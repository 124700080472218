/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Card, Col, Row, Popconfirm, notification, Radio, DatePicker, Select,
} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { DeleteOutlined, DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { editApplicationStep1Rid, editApplicationStepReport, uploadFile } from '../../../../../../utils/http';


const { TextArea } = Input;
const { Option } = Select;
class ReportRidModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportInEditID: null,
      publications: this.props.publications,
      applicationId: this.props.applicationId,
      materials_files_field: [],
      loading: false,
      isInView: false,
      ridType: '',
    };
    this.formRef = React.createRef();
  }

  onChageDocumentType = (p, value) => {
    this.setState((prevState) => ({
      publications: prevState.publications.map((pub) => (pub.id === p.id ? { ...p, documentType: value } : pub)),
    }));
  }

  onEditClick = (p) => {
    this.setState((prevState) => ({
      reportInEditID: p.id,
      ridType: prevState.publications.find((pub) => pub.id === p.id).certificateType,
      materials_files_field: p.materials_files_field || [],
    }));
  }

  onAddClick = () => {
    const { applicationId, publications } = this.state;
    const { fieldToSave } = this.props;
    editApplicationStepReport({ id: applicationId, annotationIsIntellectualProperty: 'Да' });
    editApplicationStep1Rid({ applicationStepReport_id: applicationId, field: fieldToSave })
      .then((res) => {
        this.props.onChangeAmount(publications.length + 1);
        this.setState((prevState) => ({
          reportInEditID: res.result[0].id,
          publications: [...prevState.publications, { id: res.result[0].id }],
        }));
      });
  }

  onDeleteClick = (id) => {
    const { publications } = this.state;
    editApplicationStep1Rid({ id, isDeleted: true })
      .then(() => {
        this.props.onChangeAmount(publications.length - 1);
        this.setState((prevState) => ({
          reportInEditID: null,
          ridType: '',
          publications: prevState.publications.filter((p) => p.id !== id),
        }));
      });
  }

  onCancelClick = () => {
    this.setState({ reportInEditID: null, ridType: '' });
  }

  onSaveClick = () => {
    const { reportInEditID, materials_files_field } = this.state;
    this.setState({ loading: true });
    const values = this.formRef.current.getFieldsValue();
    const materials_files = materials_files_field.map((f) => f.id).join(',');
    editApplicationStep1Rid({
      id: reportInEditID, ...values, materials_files_field: materials_files,
    })
      .then(() => {
        this.setState((prevState) => ({
          loading: false,
          reportInEditID: null,
          ridType: '',
          materials_files_field: [],
          publications: prevState.publications.map((p) => (p.id === reportInEditID ? {
            id: reportInEditID, ...values, materials_files_field,
          } : p)),
        }));
        notification.success({ message: 'Успешно' });
      });
  }

  onRemoveFile = (field, file) => {
    this.setState((prevState) => ({ [field]: prevState[field]?.filter((f) => f.id !== file.id) }));
  }

  onUploadFile = (info, field) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => this.setState((prevState) => ({
          [field]: [...prevState[field], file],
        })));
    }
  }


  render() {
    const {
      reportInEditID, publications, loading, isInView, ridType, materials_files_field,
    } = this.state;
    const { label } = this.props;
    return (
      <div style={{
        margin: '20px', padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
      }}
      >
        <span style={{ fontSize: '24px', color: '#212121' }}>{label}</span>
        {publications.map((p, i) => (
          <Card
            key={p.id}
            style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
            bodyStyle={{
              borderLeft: reportInEditID === p.id ? '4px solid #1890FF' : '',
              borderRadius: reportInEditID === p.id ? '5px' : '',
            }}
          >
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  {`РИД №${i + 1}`}
                </Col>
                <Col>
                  <Row align="middle" gutter={10}>
                    {!reportInEditID && (<Col><Button type="primary" onClick={() => this.onEditClick(p)}>Редактировать</Button></Col>)}
                    {!reportInEditID && (
                    <Col>
                      <Popconfirm
                        title="Уверены, что хотите удалить РИД?"
                        onConfirm={() => this.onDeleteClick(p.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button type="danger">Удалить</Button>
                      </Popconfirm>
                    </Col>
                    )}
                    {!reportInEditID && (<Col><Button onClick={() => this.setState({ reportInEditID: p.id, isInView: true, materials_files_field: p.materials_files_field })}>Развернуть</Button></Col>)}
                    {reportInEditID === p.id && isInView && (<Col><Button onClick={() => this.setState({ reportInEditID: null, isInView: false, materials_files_field: null })}>Свернуть</Button></Col>)}
                    {reportInEditID === p.id && !isInView && (<Col><Button type="primary" onClick={this.onSaveClick}>Сохранить</Button></Col>)}
                    {reportInEditID === p.id && !isInView && (<Col><Button onClick={this.onCancelClick}>Отменить</Button></Col>)}
                  </Row>
                </Col>
              </Row>
              {reportInEditID === p.id
                && (
                <Form
                  initialValues={{ remember: true }}
                  ref={this.formRef}
                >
                  <Col>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18.1 Вид охранного документа</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите вид охранного документа</span>
                      <Form.Item
                        name="documentType"
                        initialValue={p.documentType}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Свидетельство', value: 'Свидетельство' },
                            { label: 'Патент', value: 'Патент' },
                          ]}
                          disabled={loading}
                          optionType="button"
                          buttonStyle="solid"
                          onChange={(e) => this.onChageDocumentType(p, e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    {p.documentType === 'Свидетельство' && (
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Тип свидетельства</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите из выпадающего списка</span>
                      <Form.Item
                        name="certificateType"
                        initialValue={p.certificateType}
                      >
                        <Select
                          disabled={loading}
                          onChange={(e) => this.setState({ ridType: e })}
                          placeholder="Необходимо заполнить"
                        >
                          {[
                            'На товарный знак (знак обслуживания)',
                            'На право пользования наименованием места происхождения товара',
                            'О государственной регистрации базы данных',
                            'О государственной регистрации топологии интегральной микросхемы',
                            'О государственной регистрации программы ЭВМ',
                          ].map((u) => <Option key={u} value={u}>{u}</Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                    )}
                    {p.documentType === 'Патент' && (
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Тип патента</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите из выпадающего списка</span>
                      <Form.Item
                        name="patentType"
                        initialValue={p.patentType}
                      >
                        <Select
                          disabled={loading}
                          placeholder="Необходимо заполнить"
                        >
                          {[
                            'На изобретение',
                            'На полезную модель',
                            'На промышленный образец',
                            'На селекционное достижение',
                          ].map((u) => <Option key={u} value={u}>{u}</Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                    )}
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18.2 Наименование РИД</span>
                      <Form.Item
                        name="name"
                        initialValue={p.name}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18.3 Наименование и реквизиты правообладателя (правообладателей)</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Указано в охранном документе</span>
                      <Form.Item
                        name="fio"
                        initialValue={p.fio}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18.4 Номер заявки на государственную регистрацию объекта интеллектуальной собственности</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Номер заявки указан на первой странице охранного документа</span>
                      <Form.Item
                        name="applicationNumber"
                        initialValue={p.applicationNumber}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18.5 Дата подачи заявки на государственную регистрацию объекта интеллектуальной собственности</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Дата заявки указана на первой странице охранного документа</span>
                      <Form.Item
                        name="date"
                        initialValue={p.date}
                      >
                        <DatePicker style={{ width: '100%' }} disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18.6 Номер охранного документа</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Указан на первой странице охранного документа</span>
                      <Form.Item
                        name="documentNumber"
                        initialValue={p.documentNumber}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18.7 Дата регистрации охранного документа</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Указана на первой странице охранного документа</span>
                      <Form.Item
                        name="regDate"
                        initialValue={p.regDate}
                      >
                        <DatePicker style={{ width: '100%' }} disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    {ridType !== 'О государственной регистрации базы данных' && (
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18.8 Дата окончания действия охранного документа</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Указана на первой странице охранного документа</span>
                      <Form.Item
                        name="endDate"
                        initialValue={p.endDate}
                      >
                        <DatePicker style={{ width: '100%' }} disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    )}
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18.9 Аннотация</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Приводится краткое описание объекта интеллектуальной собственности</span>
                      <Form.Item
                        name="annotation"
                        initialValue={p.annotation}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">18.10 Ссылка на документ на сайте ФИПС</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Указывается ссылка (URL адрес) на документ на сайте ФИПС (http://www1.fips.ru)</span>
                      <Form.Item
                        name="documentURL"
                        initialValue={p.documentURL}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">
                        Загрузите подтверждающие материалы (заявку в ФИПС, копию охранного документа). 
                      </span>
                      <Dragger
                        showUploadList={false}
                        onChange={(file) => this.onUploadFile(file, 'materials_files_field')}
                        disabled={loading}
                        multiple
                        action={null}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
                      </Dragger>
                      {materials_files_field.map((f) => (
                        <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                          <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
                          {!loading && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveFile('materials_files_field', f)} /></Col>}
                          <Col>{f.name}</Col>
                        </Row>
                      ))}
                    </div>
                  </Col>
                </Form>
                )}
            </Col>
          </Card>
        ))}
        {!reportInEditID && <Button type="primary" onClick={this.onAddClick} style={{ marginTop: '20px' }}>Добавить РИД</Button>}
      </div>
    );
  }
}

ReportRidModule.propTypes = {
  publications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  applicationId: PropTypes.string.isRequired,
  fieldToSave: PropTypes.string,
  onChangeAmount: PropTypes.func.isRequired,
  label: PropTypes.func,
};

ReportRidModule.defaultProps = {
  fieldToSave: '',
  label: 'Результаты интеллектуальной деятельности',
};

export default ReportRidModule;
