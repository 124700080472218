import React from 'react';
import PropTypes from 'prop-types';
import SmetaModule from './components/Smeta/SmetaModule';
import PaymentModule from './components/PaymentModule';
import './Smeta.css';


function Smeta({ applicationId, updateParentApplication, isAppDisabled }) {
  return (
    <div style={{ overflowY: 'scroll', height: '79vh' }}>
      <SmetaModule applicationId={applicationId} isAppDisabled={isAppDisabled} />
      <PaymentModule applicationId={applicationId} updateParentApplication={updateParentApplication} isAppDisabled={isAppDisabled} />
    </div>
  );
}

Smeta.propTypes = {
  applicationId: PropTypes.string.isRequired,
  updateParentApplication: PropTypes.func.isRequired,
  isAppDisabled: PropTypes.bool.isRequired,
};

export default Smeta;
