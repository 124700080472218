export const planFactFields = [
  {
    name: 'projectVAC',
    label: 'Число публикаций по результатам реализации Проекта в научных журналах перечня ВАК с Импакт-фактором > 0,5',
    title: 'Статья №',
    hint: 'Оценка уровня научного издания, в котором опубликованы полученные результаты в отчетном периоде. Оценка соответствия представленной за отчетный период публикации результатам, полученным в рамках Проекта.',
  },
  {
    name: 'projectRINC',
    label: 'Число публикаций по результатам реализации Проекта в научных журналах, индексируемых РИНЦ',
    title: 'Статья №',
    hint: 'Оценка уровня научного издания, в котором опубликованы полученные результаты в отчетном периоде. Оценка соответствия представленной за отчетный период публикации результатам, полученным в рамках Проекта.',
  },
  {
    name: 'projectRID',
    label: 'Число результатов интеллектуальной деятельности, полученных по результатам реализации Проекта',
    title: 'РИД №',
    hint: 'Оценка заявленных к результату РИД на соответствие тематике и составу работ Проекта',
  },
  {
    name: 'projectEventsDemonstration',
    label: 'Количество мероприятий по демонстрации результатов реализации Проекта',
    title: 'Мероприятие №',
    hint: 'Оценка степени представления (обнародования) результатов реализации Проекта в отчетном периоде. Оценка уровня научного мероприятия, на котором представлялись результаты Проекта в отчетном периоде',
  },
  {
    name: 'projectEvents',
    label: 'Количество выступлений с докладами на международных научно-практических конференциях, симпозиумах, форумах',
    title: 'Выступление №',
    hint: 'Оценка степени представления (обнародования) результатов реализации Проекта в отчетном периоде, оценка уровня научного мероприятия, на котором представлялись результаты Проекта в отчетном периоде',
  },
];
