import React, { useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {
  Form, Modal, Spin, Input, Select, Button, notification, InputNumber, Radio,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getUsersAdmin, editApplication, editApplicationStepReport } from '../../../../../../utils/http';
import { routeGenerator } from '../../../../../../utils/routes';


const { Option } = Select;
function CreateAppAdmin({ history, onCloseModal }) {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [usersCatalogue, setUsersCatalogue] = useState([]);

  useEffect(() => {
    getUsersAdmin()
      .then((usersCatalogueResponse) => {
        setUsersCatalogue(usersCatalogueResponse);
        setLoading(false);
      });
  }, []);

  const onCreateApp = () => {
    formRef.current.validateFields()
      .then((values) => {
        editApplication(values)
          .then((app) => editApplicationStepReport({ application_id: app.id, stepNumber: 1 })
            .then(() => history.push(routeGenerator.applicationLink(app.id))));
      })
      .catch(() => {
        notification.error({ message: 'Ошибка!' });
      });
  };

  return (
    <Modal
      title="Новая заявка"
      visible
      onCancel={onCloseModal}
      footer={null}
      bodyStyle={{ minHeight: '30vh' }}
      width="40vw"
    >
      <div>
        {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 100, zIndex: 100 }} spin />} style={{ position: 'absolute', top: '40%', left: '40%' }} />}

        {!loading && (
        <Form ref={formRef} labelCol={{ span: 6 }}>
          <Form.Item
            label="Пользователь"
            name="webUser_id"
            required
          >
            <Select
              defaultValue="all"
              style={{ width: '100%' }}
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {usersCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.shortName}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ marginTop: '20px' }}
            label="Номер заявки"
            name="displayID"
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ marginTop: '20px' }}
            label="Кол-во этапов"
            name="maxStepsNumber"
            required
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            style={{ marginTop: '20px' }}
            label="Соисполнительская?"
            name="isPersonApplication"
            required
          >
            <Radio.Group
              options={[{ label: 'Да', value: true }, { label: 'Нет', value: false }]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          <Button type="primary" style={{ marginTop: '40px' }} onClick={onCreateApp}>Создать заявку</Button>
        </Form>
        )}
      </div>
    </Modal>
  );
}

CreateAppAdmin.propTypes = {
  history: PropTypes.shape().isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default withRouter(CreateAppAdmin);

