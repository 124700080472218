import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { routes } from '../../utils/routes';
import {
  editUser, getUser,
} from '../../utils/http';
import ProfilePage from './Desktop/ProfilePage';
import getUserId from '../../utils/localeStorage/getUserId';
import getSessionId from '../../utils/localeStorage/getSessionId';


function Application({ history }) {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    getUser(getUserId(), getSessionId())
      .then((user) => {
        setLoading(false);
        setProfile(user);
      });
  }, []);

  const onSave = (values) => {
    setLoading(true);
    editUser(values)
      .then(() => {
        setLoading(false);
        setProfile({ ...profile, ...values });
        notification.success({ message: 'Сохранено' });
      });
  };

  const onRedirectMain = () => {
    history.push(routes.home);
  };

  return (
    <div className="application-wrapper" style={{ backgroundSize: 'unset', backgroundPosition: 'unset' }}>
      {profile && (
      <ProfilePage
        profile={profile}
        onSave={onSave}
        onRedirectMain={onRedirectMain}
        disabled={loading}
      />
      )}
    </div>
  );
}

Application.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(Application);
