/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Select, Form, DatePicker, InputNumber, Button, Upload, Col, Row,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';


const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
class Project extends Component {
  render() {
    const {
      loading, scientificDirectionCatalogue, visible, application, files, removeFile, onFileUpload,
    } = this.props;

    return (
      <div className="application-main-wrapper" style={{ display: visible ? 'flex' : 'none' }}>
        <div className="application-main-box-wrapper">
          <div className="application-main-box-mask" />
          <div className="application-main-box-content-wrapper">
            <div className="application-main-box-label" style={{ fontSize: '20px' }}>
              Документы по заявке
            </div>
            {getUserRole() === 'bigAdmin' && (
            <Dragger
              showUploadList={false}
              onChange={onFileUpload}
              disabled={loading}
              multiple
              action={null}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
            </Dragger>
            )}
            {files.map((f) => (
              <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                <Col><Button type="primary" onClick={() => window.open(f.urlLink)}>Скачать</Button></Col>
                {!loading && <Col><Button onClick={() => removeFile(f)}>Удалить</Button></Col>}
                <Col style={{ color: '#212121', fontSize: '20px' }}>{f.name}</Col>
              </Row>
            ))}
          </div>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">Название проекта</span>
            <Form.Item
              name="theme"
              initialValue={application.theme}

            >
              <Input placeholder="Напишите тему проекта" disabled={loading} />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className="application-main-box-content-wrapper" style={{ width: '100%' }}>
              <span className="application-main-box-label">Научное направление</span>
              <Form.Item
                name="scientificDirection_id"
                initialValue={application.scientificDirectionID}

              >
                <Select
                  disabled={loading}
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {scientificDirectionCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                </Select>
              </Form.Item>
            </div>
          </div>
          {/* <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">Число планируемых публикаций по результатам Проекта в научных журналах, индексируемых в базах данных Scopus или и (или) Web of Science</span>
            <Form.Item
              name="publications"
              initialValue={application.publications}

            >
              <Select disabled={loading}>
                {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((u) => <Option key={u} value={u}>{u}</Option>)}
              </Select>
            </Form.Item>
          </div>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">Число планируемых публикаций по результатам Проекта в научных журналах, индексируемых в базах данных Scopus и (или) Web of Science, входящих в первый или второй квартиль (Q1 или Q2) по импакт-фактору JCR Science Edition</span>
            <Form.Item
              name="publicationsQ1Q2"
              initialValue={application.publicationsQ1Q2}

            >
              <Select disabled={loading}>
                {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((u) => <Option key={u} value={u}>{u}</Option>)}
              </Select>
            </Form.Item>
          </div>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">Число результатов интеллектуальной деятельности, планируемых к получению при выполнении Проекта</span>
            <Form.Item
              name="intellectualProperty"
              initialValue={application.intellectualProperty}

            >
              <Select disabled={loading}>
                {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((u) => <Option key={u} value={u}>{u}</Option>)}
              </Select>
            </Form.Item>
          </div>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">Количество планируемых мероприятий по демонстрации результатов Проекта</span>
            <Form.Item
              name="events"
              initialValue={application.events}

            >
              <Select disabled={loading}>
                {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((u) => <Option key={u} value={u}>{u}</Option>)}
              </Select>
            </Form.Item>
          </div>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">Ожидаемая аудитория мероприятий по демонстрации результатов Проекта из числа профессионального сообщества и представителей столичного здравоохранения</span>
            <Form.Item
              name="people"
              initialValue={application.people}

            >
              <InputNumber disabled={loading} bordered={false} />
            </Form.Item>
          </div>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">Количество планируемых выступлений с докладами на международных научно-практических конференциях, симпозиумах, форумах</span>
            <Form.Item
              name="otherEvents"
              initialValue={application.otherEvents}

            >
              <Select disabled={loading}>
                {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((u) => <Option key={u} value={u}>{u}</Option>)}
              </Select>
            </Form.Item>
          </div> */}
          <div className="application-main-box-content-wrapper">
            <div className="application-table-wrapper">
              <div className="application-table-row">
                <div className="application-table-row-cell">1. Число планируемых публикаций по результатам реализации Проекта</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="numberOfPublicationsTotal"
                  initialValue={application.numberOfPublicationsTotal}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">1.1. в научных журналах перечня ВАК с Импакт-фактором > 0,5 (на момент публикации материала)</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="publicationsQ1Q2"
                  initialValue={application.publicationsQ1Q2}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">1.2. в научных журналах, индексируемых РИНЦ</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="publications"
                  initialValue={application.publications}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">2. Число результатов интеллектуальной деятельности, планируемых к получению по результатам реализации Проекта</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="intellectualProperty"
                  initialValue={application.intellectualProperty}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">2.1. Количество планируемых мероприятий по демонстрации результатов реализации Проекта</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="events"
                  initialValue={application.events}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell">2.2. Ожидаемая аудитория мероприятий по демонстрации результатов реализации Проекта из числа профессионального сообщества и представителей здравоохранения города Москвы</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="people"
                  initialValue={application.people}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>3. Количество планируемых выступлений с докладами на международных научно-практических конференциях, симпозиумах, форумах</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="otherEvents"
                  initialValue={application.otherEvents}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>4. Общее количество пациентов, предусмотренных для выполнения Проекта</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="numberOfPatienceTotal"
                  initialValue={application.numberOfPatienceTotal}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>4.1. Пациенты основной группы</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="numberOfPatienceMain"
                  initialValue={application.numberOfPatienceMain}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>4.2. Пациенты контрольной группы</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="numberOfPatienceControl"
                  initialValue={application.numberOfPatienceControl}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>4.3. Пациенты группы сравнения</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="numberOfPatienceCompare"
                  initialValue={application.numberOfPatienceCompare}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <span>5. Протоколы испытаний</span>
                  <br />
                  <span className="application-field-hint">Указать количество планируемых к разработке протоколов, например: протоколов клинического исследования, протоколов определения токсичности и т.п.</span>
                </div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="numberOfActs"
                  initialValue={application.numberOfActs}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
              <div className="application-table-row">
                <div className="application-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <span>6. Наличие опытных образцов</span>
                  <br />
                  <span className="application-field-hint">Указать планируемое количество опытных образцов</span>
                </div>
                <Form.Item
                  style={{ width: '100%' }}
                  name="numberOfExamples"
                  initialValue={application.numberOfExamples}
                >
                  <InputNumber disabled={loading} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className="application-main-box-content-wrapper" style={{ width: '24%' }}>
              <span className="application-main-box-label">Дата начала проекта</span>
              <Form.Item
                name="projectStart"
                initialValue={application.projectStart}

              >
                <DatePicker disabled={loading} />
              </Form.Item>
            </div>
            <div className="application-main-box-content-wrapper" style={{ width: '24%' }}>
              <span className="application-main-box-label">Дата окончания проекта</span>
              <Form.Item
                name="projectDeadline"
                initialValue={application.projectDeadline}

              >
                <DatePicker disabled={loading} />
              </Form.Item>
            </div>
            <div className="application-main-box-content-wrapper" style={{ width: '24%' }}>
              <span className="application-main-box-label">Требуемое финансирование (руб.)</span>
              <Form.Item
                name="projectFinance"
                initialValue={application.projectFinance}

              >
                <InputNumber disabled={loading} bordered={false} />
              </Form.Item>
            </div>
            <div className="application-main-box-content-wrapper" style={{ width: '24%' }}>
              <span className="application-main-box-label">Количество этапов</span>
              <Form.Item
                name="projectTerm"
                initialValue={application.projectTerm}

              >
                <Input disabled={loading} />
              </Form.Item>
            </div>
          </div>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">Цель проекта</span>
            <Form.Item
              name="projectGoalExpert"
              initialValue={application.projectGoalExpert}
            >
              <TextArea disabled={loading} autoSize />
            </Form.Item>
          </div>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">Задачи проекта</span>
            <Form.Item
              name="projectTasksExpert"
              initialValue={application.projectTasksExpert}
            >
              <TextArea disabled={loading} autoSize />
            </Form.Item>
          </div>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">Потенциальное влияние на существующую клиническую практику в г. Москве</span>
            <Form.Item
              name="practice"
              initialValue={application.practice}
            >
              <TextArea disabled={loading} autoSize />
            </Form.Item>
          </div>
        </div>
      </div>
    );
  }
}

Project.propTypes = {
  loading: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  scientificDirectionCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  application: PropTypes.shape().isRequired,
  files: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  removeFile: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
};

export default Project;
