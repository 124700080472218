// Название / Проекты 2022 (т.е заявки которые взяли в работу)/ Проекты 2023 / ФИО ответственного / E-mail / Роль (но сами роли думаю поправим)
// А в выгрузке еще добавлялись столбцы Заявки 2022, Заявки 2023
import usersRoles from './usersRoles';


const fields = [
  {
    key: 'id',
    name: 'ID',
    width: 100,
  },
  {
    key: 'name',
    name: 'Название',
    width: 200,
  },
  {
    key: 'projects2022',
    name: 'Заявки 2022 (Поддержана)',
    width: 80,
  },
  {
    key: 'projects2023',
    name: 'Заявки 2023 (Поддержана)',
    width: 80,
  },
  {
    key: 'fio',
    name: 'ФИО',
    width: 80,
  },
  {
    key: 'email',
    name: 'E-mail',
    width: 80,
  },
  {
    key: 'phone',
    name: 'Телефон',
    width: 80,
  },
  {
    key: 'role',
    name: 'Роль',
    width: 50,
  },
  // {
  //   key: 'allProjects2022',
  //   name: 'Заявки 2022 (все)',
  //   width: 150,
  // },
  // {
  //   key: 'allProjects2023',
  //   name: 'Заявки 2023 (все)',
  //   width: 150,
  // },
];


const prepareUsersXls = (rawData) => {
  const result = {
    fields,
    users: [],
  };
  rawData.forEach((user, index) => {
    const obj = {
      id: user.id,
      index: index + 1,
      name: user.shortName,
      projects2022: user.applications2022.map((a) => a.displayID).join(', '),
      projects2023: user.applications2023.map((a) => a.displayID).join(', '),
      fio: `${user.lastName} ${user.firstName} ${user.middleName}`,
      email: user.email,
      phone: user.phone,
      role: usersRoles.find((t) => t.value === user.role)?.label,
      allProjects2022: [],
      allProjects2023: [],
      // allProjects2022: user.applicationsFull.filter((a) => !a.isGrants2023 && !a.displayID.includes('НИР') && !a.displayID.includes('С')).map((a) => a.displayID).join(', '),
      // allProjects2023: user.applications2.join(', '),
    };

    result.users.push(obj);
  });
  return result;
};

export default prepareUsersXls;
