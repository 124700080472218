const fieldsWithSums = [
  'salarySupervisorTotal',
  'salaryDoctorTotal',
  'salaryMiddleTotal',
  'salaryJuniorTotal',
  'salaryOtherTotal',
  'salarySupervisorAverageYear',
  'salaryDoctorAverageYear',
  'salaryMiddleAverageYear',
  'salaryJuniorAverageYear',
  'salaryOtherAverageYear',
  'salarySupervisorAverageMonth',
  'salaryDoctorAverageMonth',
  'salaryMiddleAverageMonth',
  'salaryJuniorAverageMonth',
  'salaryOtherAverageMonth',
  'salaryTotal',
  'salaryAverageYear',
  'salaryAverageMonth',
];
export default fieldsWithSums;
