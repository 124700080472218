/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, InputNumber,
} from 'antd';
import { planFactFields } from './planFactFields';


const { TextArea } = Input;
function PlanFactTable({ loading, application }) {
  return (
    <div className="application-main-wrapper" style={{ display: 'flex' }}>
      <div className="application-main-box-wrapper">
        <div className="application-main-box-mask" />

        <div className="application-main-box-content-wrapper">
          <div className="application-subTitle">2. Экспертная оценка достижения показателей эффективности реализации проекта, установленных приложением № 3 к Соглашению.</div>

          <div className="application-main-box-content-wrapper" style={{ width: '100%', padding: 0 }}>
            <div className="application-table-row">
              <div className="application-table-row-cell" style={{ width: '10%' }}>№ п/п</div>
              <div className="application-table-row-cell" style={{ width: '15%' }}>Наименование показателя</div>
              <div className="application-table-row-cell" style={{ width: '15%' }}>Плановое значение показателя, установленное Соглашением</div>
              <div className="application-table-row-cell" style={{ width: '15%' }}>Фактическое достижение показателя, указанное в отчетности грантополучателем</div>
              <div className="application-table-row-cell" style={{ width: '15%' }}>Фактическое достижение показателя, подтвержденное по версии эксперта</div>
              <div className="application-table-row-cell" style={{ borderRight: '1px solid #d9d9d9', width: '30%' }}>Комментарий</div>
            </div>

            {planFactFields.map((e, i) => (
              <div className="application-table-row" key={e.name}>
                <div className="application-table-row-cell" style={{ width: '10%' }}>{i + 1}</div>
                <div className="application-table-row-cell" style={{ width: '15%' }}>{e.label}</div>
                <div className="application-table-row-cell" style={{ width: '15%' }}>{application[`${e.name}Plan`]}</div>
                <div className="application-table-row-cell" style={{ width: '15%' }}>{application[`${e.name}Fact`]}</div>
                <div className="application-table-row-cell" style={{ width: '15%' }}>
                  <Form.Item
                    style={{ width: '100%' }}
                    name={`${e.name}Left`}
                    initialValue={application[`${e.name}Left`]}
                  >
                    <InputNumber disabled={loading} placeholder="Введите число" />
                  </Form.Item>
                </div>
                <div className="application-table-row-cell" style={{ borderRight: '1px solid #d9d9d9', width: '30%' }}>
                  {Array(application[`${e.name}Fact`] || 1).fill(0).map((el, index) => {
                    const elements = application[`${e.name}Comment`].split('$');
                    const text = elements[index];
                    return (
                      <div>
                        <span>{`${e.title} ${index + 1}`}</span>
                        <Form.Item
                          style={{ width: '100%' }}
                          name={`${e.name}Comment_${index + 1}`}
                          initialValue={text}
                        >
                          <TextArea disabled={loading} rows={4} placeholder={e.hint} />
                        </Form.Item>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}

            <div className="application-table-row" style={{ borderBottom: '1px solid #d9d9d9' }}>
              <div className="application-table-row-cell" style={{ width: '10%' }}>6</div>
              <div className="application-table-row-cell" style={{ width: '15%' }}>Общее количество пациентов в рамках выполнения Проекта</div>
              <div className="application-table-row-cell" style={{ width: '15%' }}>{application.projectPatientsPlan}</div>
              <div className="application-table-row-cell" style={{ width: '15%' }}>{application.projectPatientsFact}</div>
              <div className="application-table-row-cell" style={{ width: '15%' }}>
                <Form.Item
                  style={{ width: '100%' }}
                  name="projectPatientsLeft"
                  initialValue={application.projectPatientsLeft}
                >
                  <InputNumber disabled={loading} placeholder="Введите число" />
                </Form.Item>
              </div>
              <div className="application-table-row-cell" style={{ borderRight: '1px solid #d9d9d9', width: '30%' }}>
                <Form.Item
                  style={{ width: '100%' }}
                  name="projectPatientsComment"
                  initialValue={application.projectPatientsComment}
                >
                  <TextArea disabled={loading} rows={4} placeholder="Оценивается адекватность выборки пациентов, задействованных в рамках работ по Проекту, а также соответствие степени их участия в Проекте требованиям Соглашения" />
                </Form.Item>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

PlanFactTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  application: PropTypes.shape().isRequired,
};

export default PlanFactTable;
