const customAppNumbers = [
  { number: 1, name: 'Государственное бюджетное учреждение здравоохранения города Москвы "Городская клиническая онкологическая больница № 1" Департамента здравоохранения города Москвы' },
  { number: 2, name: 'ГАУЗ МНПЦ МРВСМ ДЗМ' },
  { number: 3, name: 'ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ ГОРОДА МОСКВЫ "СТАНЦИЯ СКОРОЙ И НЕОТЛОЖНОЙ МЕДИЦИНСКОЙ ПОМОЩИ ИМ. А.С. ПУЧКОВА" ДЕПАРТАМЕНТА ЗДРАВООХРАНЕНИЯ ГОРОДА МОСКВЫ' },
  { number: 4, name: 'ГБУЗ ГКБ им. В.В. Вересаева' },
  { number: 5, name: 'ГБУЗ "Городская клиническая больница им. И.В. Давыдовского ДЗМ"' },
  { number: 6, name: 'ГБУЗ "ГКБ им. М.П.Кончаловского ДЗМ"' },
  { number: 7, name: 'ГБУЗ "Городская клиническая больница №13 ДЗМ"' },
  { number: 8, name: 'ГБУЗ ГКБ № 15 им.О.М.Филатова ДЗМ' },
  { number: 9, name: 'ГБУЗ "ГКБ № 29 им. Н.Э. Баумана"' },
  { number: 10, name: 'ГБУЗ ГКБ №52 ДЗМ' },
  { number: 11, name: 'ПКБ № 4 им. П.Б.Ганнушкина' },
  { number: 12, name: 'Государственное бюджетное учреждение здравоохранения города Москвы «Детская городская клиническая больница имени З.А. Башляевой Департамента здравоохранения города Москвы»' },
  { number: 13, name: 'ДГКБ им. Н.Ф. Филатова' },
  { number: 14, name: 'ГБУЗ "ДГКБ №9 им Г.Н.Сперанского ДЗМ"' },
  { number: 15, name: 'ГБУЗ "МГОБ №62 ДЗМ"' },
  { number: 16, name: 'Московский клинический НПЦ им. А.С.Логинова' },
  { number: 17, name: 'ГБУЗ "Морозовская ДГКБ ДЗМ"' },
  { number: 18, name: 'ГБУЗ "НИИСП им. Н.В. Склифосовского ДЗМ"' },
  { number: 19, name: 'ГБУЗ НИКИО им. Л.И. Свержевского ДЗМ' },
  { number: 20, name: 'ГБУЗ "ГКБ им А.К. Ерамишанцева ДЗМ"' },
  { number: 21, name: 'ГБУЗ "ГКБ им. В.В. Виноградова ДЗМ"' },
  { number: 22, name: 'ГБУЗ " Городская клиническая больница им. С. И. Спасокукоцкого" ДЗМ' },
  { number: 23, name: 'ГБУЗ "ГКБ им. С.С. Юдина ДЗМ"' },
  { number: 24, name: 'ГКБ им. В.М.Буянова' },
  { number: 25, name: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 17 Департамента здравоохранения города Москвы» (ГБУЗ "ГКБ № 17 ДЗМ")' },
  { number: 26, name: 'ГКБ № 31' },
  { number: 27, name: 'ГБУЗ "НПКЦ ДиТ ДЗМ"' },
  { number: 28, name: 'ПКБ № 1 им. Н.А.Алексеева' },
  { number: 29, name: 'ГБУЗ ГКБ им. С.П. Боткина ДЗМ' },
  { number: 30, name: 'ГБУЗ ММКЦ "Коммунарка ДЗМ"' },
  { number: 30, name: 'ГБУЗ "ГКБ № 40 ДЗМ"' },
  { number: 31, name: 'Научно-практический психоневрологический центр им. З.П.Соловьева' },
  { number: 32, name: 'ГКБ № 67 им. Л.А.Ворохобова' },
  { number: 33, name: 'ГКБ № 1 им.Н.И.Пирогова' },
  { number: 34, name: 'Московский НПЦ дерматовенерологии и косметологии' },
  { number: 34, name: 'гбуз мнпцдк дзм' },
  { number: 35, name: 'ГБУЗ г.Москвы " Научно-исследовательский институт неотложной детской хирургии и травматологии"ДЗМ' },
  { number: 36, name: 'ФГБУ "НМИЦ ССХ им. А.Н. Бакулева" Минздрава России' },
  { number: 37, name: 'ГБУЗ «КДП № 121 ДЗМ»' },
  { number: 38, name: 'Центр патологии речи и нейрореабилитации' },
  { number: 38, name: 'ГБУЗ "ЦПРИН ДЗМ"' },
  { number: 39, name: 'ГБУЗ "ДЦЛИ ДЗМ"' },
  { number: 39, name: 'Государственное бюджетное учреждение здравоохранения города Москвы "Московский научно-практический центр лабораторных исследований Департамента здравоохранения города Москвы"' },
  { number: 40, name: 'Государственное бюджетное учреждение здравоохранения "Диагностический клинический центр №1 Депаратмента здравоохранения Москвы"' },
  { number: 41, name: 'ГБУЗ "ИКБ №1 ДЗМ"' },
  { number: 41, name: 'ГБУЗ "Инфекционная клиническая больница №1 ДЗМ"' },
  { number: 42, name: 'ГКБ им. В.П.Демихова' },
  { number: 43, name: 'Госпиталь для ветеранов войн № 3' },
];

export default customAppNumbers;
