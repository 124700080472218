export const barrierOptions = [
  { value: 'необходимость доработки продукта (по итогам пилотирования или до более высокого уровня технологической готовности)', additionalFieldLabel: '', additionalFieldName: '' },
  { value: 'необходимость увеличения выборки пациентов для более точной апробации', additionalFieldLabel: '', additionalFieldName: '' },
  { value: 'получение регистрационного удостоверения Росздравнадзора', additionalFieldLabel: '', additionalFieldName: '' },
  {
    value: 'получение другой разрешительной документации', additionalFieldLabel: 'Указать формат - патент, иное', additionalFieldName: 'barrierAdditional1', type: 'input',
  },
  { value: 'необходимость проведения клинических исследований', additionalFieldLabel: '', additionalFieldName: '' },
  { value: 'необходимость инвестиций для масштабирования продукта (или для перехода на более высокую стадию уровня технологической готовности TRL)', additionalFieldLabel: '', additionalFieldName: '' },
  { value: 'необходимость организации передачи технологии (обучение персонала, разработка и внедрение в клиническую практику дополнительных стандартов/другие мероприятия)', additionalFieldLabel: '', additionalFieldName: '' },
  {
    value: 'технические доработки для интеграции', additionalFieldLabel: 'Указать - например, ЕМИАС', additionalFieldName: 'barrierAdditional2', type: 'input',
  },
  {
    value: 'необходимость PR-сопровождения', additionalFieldLabel: 'Укажите свой запрос', additionalFieldName: 'barrierAdditional3', type: 'input',
  },
  {
    value: 'необходимость GR-поддержки для масштабирования в регионы', additionalFieldLabel: 'Перечислите регионы', additionalFieldName: 'barrierAdditional4', type: 'input',
  },
  {
    value: 'необходимость внедрения разработанного продукта в образовательные стандарты и программы', additionalFieldLabel: 'Укажите программы', additionalFieldName: 'barrierAdditional5', type: 'input',
  },
  { value: 'поиск и привлечение в команду специалистов, продвигающих продукт на рынок', additionalFieldLabel: '', additionalFieldName: '' },
  {
    value: 'свой вариант', additionalFieldLabel: 'Введите свой вариант', additionalFieldName: 'barrierAdditional6', type: 'input',
  },
];

export const levelOptions = [
  { value: 'сформирована дорожная карта развития/создана стратегия развития', additionalFieldLabel: '', additionalFieldName: '' },
  { value: 'укомплектована профессиональная команда', additionalFieldLabel: '', additionalFieldName: '' },
  { value: 'ведется работа по получению инвестиций для вывода продукта на рынок', additionalFieldLabel: '', additionalFieldName: '' },
  {
    value: 'определены стратегические партнеры в коммерциализации продукта', additionalFieldLabel: 'Перечислите стратегических партнеров', additionalFieldName: 'levelAdditional1', type: 'input',
  },
];

export const analysOptions = [
  {
    value: 'проведена оценка затрат необходимых для вывода продукта на рынок', additionalFieldLabel: 'указать, млн. руб', additionalFieldName: 'analysAdditional1', type: 'input',
  },
  {
    value: 'рассчитана прогнозируемая доля рынка', additionalFieldLabel: 'указать, млн. руб', additionalFieldName: 'analysAdditional2', type: 'input',
  },
  {
    value: 'определены потенциальные рынки сбыта',
    additionalFieldLabel: 'Выберите рынки сбыта',
    additionalFieldName: 'analysAdditional3',
    type: 'checkbox',
    options: [
      { value: 'государственные медицинские учреждения', additionalFieldLabel: '', additionalFieldName: '' },
      { value: 'частные медицинские учреждения', additionalFieldLabel: '', additionalFieldName: '' },
      { value: 'клинико-диагностические лаборатории', additionalFieldLabel: '', additionalFieldName: '' },
      { value: 'медицинские специалисты без привязки к учреждению', additionalFieldLabel: '', additionalFieldName: '' },
      {
        value: 'свой вариант', additionalFieldLabel: 'Введите свой вариант', additionalFieldName: 'analysAdditional3Additional1', type: 'input',
      },
    ],
  },
  {
    value: 'модель коммерциализации',
    additionalFieldLabel: 'Выберите модель',
    additionalFieldName: 'analysAdditional4',
    type: 'select',
    options: [
      { value: 'покупка лицензий на право использования продукта ограниченных по времени', additionalFieldLabel: '', additionalFieldName: '' },
      { value: 'разовая закупка продукта', additionalFieldLabel: '', additionalFieldName: '' },
      {
        value: 'свой вариант', additionalFieldLabel: 'Введите свой вариант', additionalFieldName: 'analysAdditional4Additional1', type: 'input',
      },
    ],
  },
];

export const placeOptions = [
  {
    value: 'продукт в рамках проекта нематериальный, площадка не требуется', additionalFieldLabel: '', additionalFieldName: '', type: '',
  },
  {
    value: 'площадка полностью подготовлена', additionalFieldLabel: '', additionalFieldName: '', type: '',
  },
  {
    value: 'необходим поиск площадки для дальнейшей реализации проекта', additionalFieldLabel: '', additionalFieldName: '', type: '',
  },
  {
    value: 'уже проработан поиск, но необходимо проведение дополнительных работ (застройка чистых комнат/прохождение сертификации/ иных разрешительных процедур и т.д., указать)',
    additionalFieldLabel: 'укажите',
    additionalFieldName: 'placeAdditional1',
    type: 'checkbox',
    options: [
      { value: 'застройка чистых комнат', additionalFieldLabel: '', additionalFieldName: '' },
      { value: 'прохождение сертификации', additionalFieldLabel: '', additionalFieldName: '' },
      {
        value: 'иных разрешительных процедур', additionalFieldLabel: 'Укажите', additionalFieldName: 'placeAdditional1Additional1', type: 'input',
      },
    ],
  },
];

export const helpOptions = [
  {
    value: 'дополнительные инвестиции для масштабирования', additionalFieldLabel: '', additionalFieldName: '', type: '',
  },
  {
    value: 'маркетинговое продвижение продукта', additionalFieldLabel: '', additionalFieldName: '', type: '',
  },
  {
    value: 'помощь в получении разрешительной документации',
    additionalFieldLabel: '',
    additionalFieldName: 'helpAdditional1',
    type: 'select',
    options: [
      { value: 'патент', additionalFieldLabel: '', additionalFieldName: '' },
      { value: 'регистрационное удостоверение', additionalFieldLabel: '', additionalFieldName: '' },
      {
        value: 'свой вариант', additionalFieldLabel: 'Укажите', additionalFieldName: 'helpAdditional1Additional1', type: 'input',
      },
    ],
  },
  {
    value: 'свой вариант', additionalFieldLabel: 'Укажите', additionalFieldName: 'helpAdditional2', type: 'input',
  },
];

export const addictionOptions = [
  {
    value: 'используются иностранные реагенты или комплектующие для его производства', additionalFieldLabel: '', additionalFieldName: '', type: '',
  },
  {
    value: 'необходимо иностранное оборудование', additionalFieldLabel: '', additionalFieldName: '', type: '',
  },
  {
    value: 'полная независимость от иностранных технологий', additionalFieldLabel: '', additionalFieldName: '', type: '',
  },
  {
    value: 'свой вариант', additionalFieldLabel: 'Укажите', additionalFieldName: 'addictionAdditional1', type: 'input',
  },
];

export const revenueOptions = [
  {
    value: 'Рассчитывали', additionalFieldLabel: 'Укажите', additionalFieldName: 'revenueAdditional1', type: 'input',
  },
  {
    value: 'Не рассчитывали', additionalFieldLabel: '', additionalFieldName: '', type: '',
  },
];
