import React, { useState, useEffect } from 'react';
import {
  Table, message, Input, Pagination, Select,
} from 'antd';
import moment from 'moment';
import ExcelJS from 'exceljs';
import { getPendingUsers, editUser } from '../../../../../../utils/http';
import './RegApplications.css';
import getRegApplicationsColumns from '../../utils/getRegApplicationsColumns';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';


const { Search } = Input;
const { Option } = Select;
function RegApplications() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDatasource] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 0, tableSize: 0 });
  const [searchData, setSearchData] = useState({});


  const onGetData = (newSearchData) => {
    getPendingUsers(newSearchData)
      .then((resp) => {
        setPagination(resp.pageInfo);
        setDatasource(resp.data);
        setSearchData(newSearchData);
        setLoading(false);
      });
  };

  const generateXLS = async (data) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Admin';
    workbook.lastModifiedBy = 'Администратор';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    const worksheet = workbook.addWorksheet('Отклоненные заявки');

    worksheet.columns = [
      { header: '№', key: 'index', width: 10 },
      { header: 'Наименование учреждения', key: 'name', width: 42 },
      {
        header: 'Дата подачи заявки на регистрацию', key: 'date', width: 15, outlineLevel: 1,
      },
      {
        header: 'ФИО', key: 'fio', width: 50, outlineLevel: 1,
      },
      {
        header: 'Эл. почта', key: 'email', width: 25, outlineLevel: 1,
      },
      {
        header: 'Телефон', key: 'tel', width: 30, outlineLevel: 1,
      },
      {
        header: 'ДЗМ', key: 'dzm', width: 10, outlineLevel: 1,
      },
      {
        header: 'Должность', key: 'position', width: 25, outlineLevel: 1,
      },
      {
        header: 'Cтатус', key: 'status', width: 15, outlineLevel: 1,
      },
    ];

    data.forEach((el, index) => {
      worksheet.addRow({
        index,
        name: el.shortName,
        date: moment(el.regDate).format('DD.MM.YYYY'),
        status: el.status,
        fio: `${el.lastName} ${el.firstName} ${el.middleName}`,
        email: el.email,
        tel: el.phone,
        dzm: el.isDzm ? 'Да' : 'Нет',
        position: el.position,
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
  };

  const exportPending = () => {
    setLoading(true);
    getPendingUsers({
      pageSize: 2000, page: 0, name: null, isPending: null, isBlocked: null,
    })
      .then(async (resp) => {
        const currentYearPending = resp.data.filter((el) => moment(el.regDate).year() === 2024);
        const buffer = await generateXLS(currentYearPending);
        setLoading(false);

        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Заявки на регистрацию 2024.xlsx';
        link.click();
        URL.revokeObjectURL(link.href);
      });
  };

  useEffect(() => {
    onGetData({
      pageSize: 30, page: 0, name: null, isPending: null, isBlocked: null,
    });
  }, []);

  const onDecide = (isApproved, id, isDuplicate, isDzm, isRevert) => {
    setLoading(true);
    const obj = {
      id,
      isPending: false,
      isBlocked: !isApproved,
      isDuplicate,
      isDzm,
      isRevert,
    };
    editUser(obj)
      .then(() => {
        if (!isApproved) {
          message.success('Отказное письмо успешно направлено на электронный адрес заявителя');
        }
        if (isApproved) {
          message.success('Письмо-согласование успешно направлено на электронный адрес заявителя');
        }
        setLoading(false);
        setDatasource(dataSource.map((d) => (d.id === id ? ({
          ...d, isPending: false, isBlocked: !isApproved, isDzm,
        }) : d)));
      });
  };

  const onChangePage = (e) => {
    onGetData({ ...searchData, page: e });
  };

  const onSearch = (e) => {
    onGetData({ ...searchData, name: e, page: 0 });
  };

  const onFilter = (value) => {
    if (value === 'На согласовании') {
      onGetData({
        ...searchData, isPending: true, isBlocked: null, page: 0,
      });
    } else if (value === 'Отклонено') {
      onGetData({
        ...searchData, isPending: false, isBlocked: true, page: 0,
      });
    } else if (value === 'Согласовано') {
      onGetData({
        ...searchData, isPending: false, isBlocked: false, page: 0,
      });
    } else {
      onGetData({
        ...searchData, isPending: null, isBlocked: null, page: 0,
      });
    }
  };

  return (
    <div className="regApplications-wrapper">
      <div
        className="regApplications-box-wrapper"
        style={{
          height: '5vh', display: 'flex', flexDirection: 'row', gap: '20px',
        }}
      >
        <Search
          placeholder="Поиск по организации"
          style={{ width: '70%' }}
          onSearch={(e) => onSearch(e)}
        />
        <Select
          allowClear
          style={{ width: '30%' }}
          disabled={loading}
          onChange={onFilter}
          placeholder="Выберите статус..."
        >
          {['На согласовании', 'Отклонено', 'Согласовано'].map((u) => <Option key={u} value={u}>{u}</Option>)}
        </Select>
      </div>
      <div className="regApplications-box-wrapper">
        {getUserRole() === 'bigAdmin' && (
          <button disabled={loading} type="button" className="ant-btn ant-btn-primary" onClick={exportPending}>Экспортировать все заявки на регистрацию за 2024 год</button>
        )}
        <Table
          dataSource={dataSource}
          columns={getRegApplicationsColumns(onDecide)}
          loading={loading}
          scroll={{ y: '50vh' }}
          pagination={false}
          rowKey="id"
        />
      </div>
      <Pagination
        defaultCurrent={pagination.currentPage + 1}
        current={searchData.page + 1}
        total={pagination.tableSize}
        defaultPageSize={30}
        hideOnSinglePage
        showSizeChanger={false}
        style={{ marginTop: '20px', marginLeft: '20px' }}
        onChange={onChangePage}
      />
    </div>
  );
}

export default RegApplications;
