/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Form, Row, Button, notification, Col, Modal, Upload, Input, Collapse, Popconfirm,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined, EyeOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import Project from './Project';
import Organisation from './Organisation';
import Director from './Director';
import ApplicationsPlan from '../../../Common/ApplicationsPlan';
import Files from './Files';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';
import getUserId from '../../../../../utils/localeStorage/getUserId';
import {
  editApplication, uploadFile, editApplicationsFiles, editApplicationsPlan, getApplicationStepReportById,
} from '../../../../../utils/http';
import fileDownload from '../../../../../utils/fileDownload';
import fileOpenNewTab from '../../../../../utils/fileOpenNewTab';
import { getAnnotationFiles } from '../../../../../components/AnnotationFiles/AnnotationFiles';


const { Panel } = Collapse;
const { Dragger } = Upload;
function CommonInfo({ applicationProp, specializationCatalogue, scientificDirectionCatalogue }) {
  const [application, setApplication] = useState(null);
  const formRef = useRef(null);
  const [isShowUploadSingleFileModal, setIsShowUploadSingleFileModal] = useState(null);
  const [singleFile, setSingleFile] = useState(null);
  const [isShowUploadMultipleFileModal, setIsShowUploadMultipleFileModal] = useState(null);
  const [multipleFile, setMultipleFile] = useState([]);
  const [isShowNeDZMAdditionalFileModal, setIsShowNeDZMAdditionalFileModal] = useState(null);
  const [isShowAdditionalFileModal, setIsShowAdditionalFileModal] = useState(null);

  useEffect(() => {
    setApplication(applicationProp);
  }, []);

  const fixApplicationsPlan = (values) => {
    const result = {
      values,
      applicationsPlan: { id: application.applicationsPlan.id, steps: values.steps },
    };
    Array(Number(values.steps) || 1).fill(0).forEach((el, index) => {
      const field1 = `n${index + 1}`;
      const field2 = `d${index + 1}`;

      result.applicationsPlan[field1] = values[field1];
      result.applicationsPlan[field2] = values[field2];

      delete result.values[field1];
      delete result.values[field2];
    });
    delete result.values.steps;
    return result;
  };

  const onSave = () => {
    formRef.current.validateFields()
      .then((values) => {
        const result = fixApplicationsPlan(values);
        editApplicationsPlan(result.applicationsPlan);
        editApplication({
          ...result.values,
          id: application.id,
          applicationAndSopd_ids: application.applicationAndSopd_ids.length === 0 ? '' : application.applicationAndSopd_ids.map((f) => f.id).join(','),
          additionalFiles_ids: application.additionalFiles.length === 0 ? '' : application.additionalFiles.map((f) => f.id).join(','),
        })
          .then(() => {
            setApplication({ ...application, ...result.values, applicationsPlan: result.applicationsPlan });
            notification.success({ message: 'Сохранено' });
          });
      })
      .catch(() => {
        notification.error({ message: 'Ошибка!' });
      });
  };

  const onAppFileUpload = (info, fieldName) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          if (fieldName === 'appFiles') {
            setApplication({ ...application, applicationAndSopd_ids: [...application.applicationAndSopd_ids, file] });
          }
          if (fieldName === 'files') {
            setApplication({ ...application, additionalFiles: [...application.additionalFiles, file] });
          }
        });
    }
  };

  const onAppFileRemove = (file, fieldName) => {
    if (fieldName === 'appFiles') {
      setApplication({ ...application, applicationAndSopd_ids: application.applicationAndSopd_ids.filter((f) => f.id !== file.id) });
    }
    if (fieldName === 'files') {
      setApplication({ ...application, additionalFiles: application.additionalFiles.filter((f) => f.id !== file.id) });
    }
  };

  const onSingleFileUpload = (info, field) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          if (isShowNeDZMAdditionalFileModal) {
            setIsShowNeDZMAdditionalFileModal({ ...isShowNeDZMAdditionalFileModal, [field]: file });
          } if (isShowAdditionalFileModal) {
            setIsShowAdditionalFileModal({ ...isShowAdditionalFileModal, [field]: file });
          } else {
            setSingleFile(file);
          }
        });
    }
  };

  const onSingleFileRemove = (field) => {
    if (isShowNeDZMAdditionalFileModal) {
      setIsShowNeDZMAdditionalFileModal({ ...isShowNeDZMAdditionalFileModal, [field]: null });
    } if (isShowAdditionalFileModal) {
      setIsShowAdditionalFileModal({ ...isShowAdditionalFileModal, [field]: null });
    } else {
      setSingleFile(null);
    }
  };

  const onSaveSingleFileModal = () => {
    editApplication({ id: application.id, [isShowUploadSingleFileModal]: singleFile.id })
      .then(() => {
        setApplication({ ...application, [isShowUploadSingleFileModal]: singleFile });
        notification.success({ message: 'Сохранено' });
        setSingleFile(null);
        setIsShowUploadSingleFileModal(null);
      });
  };

  const onMultipleFileUpload = async (originalFile) => {
    const form = new FormData();
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    form.append('file', originalFile);
    const file = await uploadFile(form, headers);
    setMultipleFile([...multipleFile, file]);
  };

  const onMultipleFileRemove = (file) => {
    setMultipleFile(multipleFile.filter((f) => f.id !== file.id));
  };

  const onSaveMultipleFileModal = () => {
    if (multipleFile.length > 0) {
      editApplication({ id: application.id, [isShowUploadMultipleFileModal]: multipleFile.map((f) => f.id).join(',') })
        .then(() => {
          setApplication({ ...application, [isShowUploadMultipleFileModal]: multipleFile });
          notification.success({ message: 'Сохранено' });
          setMultipleFile([]);
          setIsShowUploadMultipleFileModal(null);
        });
    } else {
      notification.error({ message: 'Загрузите хотя бы 1 файл' });
    }
  };

  const isHidden = ['f9e4f6c6-df88-4000-98df-a90ad11b29ca', 'e3cf0459-c3cb-45ac-9dc7-fa4d5e0096a6'].includes(application?.id);

  const sideTabs = [];

  if (!isHidden) {
    sideTabs.push({ name: 'Презентация проекта', field: 'presentation_id' },
      { name: 'План-график реализации проекта', field: 'additionalFile1_id' },
      { name: 'Смета расходов на реализацию проекта', field: 'additionalFile3_id' });
  }
  sideTabs.push(
    { name: 'Меры поддержки соисполнителей', field: 'additionalFile6_id' },
    { name: 'Итоговый аннотированный отчет', field: 'finalAnnotation_id' },
  );

  const onSaveNeDZMAdditionalFiles = () => {
    const { isNew } = isShowNeDZMAdditionalFileModal;
    const objToSave = {
      id: isShowNeDZMAdditionalFileModal.id,
      changeDate: moment().toISOString(),
      changeUser: getUserId(),
      letterFile_id: isShowNeDZMAdditionalFileModal?.letterFile_id?.id || null,
      wordFile_id: isShowNeDZMAdditionalFileModal?.wordFile_id?.id || null,
      pdfFile_id: isShowNeDZMAdditionalFileModal?.pdfFile_id?.id || null,
      applications_id: application.id,
      field: 'neDZMAdditionalFilesApplications_ids',
    };
    editApplicationsFiles(objToSave).then(() => {
      notification.success({ message: 'Сохранено' });
      setApplication({
        ...application,
        neDZMAdditionalFilesApplications_ids: isNew
          ? [...application.neDZMAdditionalFilesApplications_ids, objToSave]
          : application.neDZMAdditionalFilesApplications_ids.map((e) => (e.id === objToSave.id ? objToSave : e)),
      });
      setIsShowNeDZMAdditionalFileModal(null);
    });
  };

  const onSaveAdditionalFiles = () => {
    const { isNew } = isShowAdditionalFileModal;
    const objToSave = {
      id: isShowAdditionalFileModal.id,
      changeDate: moment().toISOString(),
      changeUser: getUserId(),
      file_id: isShowAdditionalFileModal?.file_id?.id || null,
      applications_id: application.id,
      field: 'applicationsFiles_ids',
    };
    editApplicationsFiles(objToSave).then(() => {
      notification.success({ message: 'Сохранено' });
      setApplication({
        ...application,
        applicationsFiles_ids: isNew
          ? [...application.applicationsFiles_ids, objToSave]
          : application.applicationsFiles_ids.map((e) => (e.id === objToSave.id ? objToSave : e)),
      });
      setIsShowNeDZMAdditionalFileModal(null);
    });
  };

  const onDeleteAdditionalFiles = () => {
    if (isShowAdditionalFileModal.isNew) {
      notification.success({ message: 'Сохранено' });
      setIsShowAdditionalFileModal(null);
    } else {
      editApplicationsFiles({
        id: isShowAdditionalFileModal.id, isDeleted: true, changeDate: moment().toISOString(), changeUser: getUserId(),
      }).then(() => {
        notification.success({ message: 'Сохранено' });
        setApplication({
          ...application,
          applicationsFiles_ids: application.applicationsFiles_ids
            .filter((f) => f.id !== isShowAdditionalFileModal.id),
        });
        setIsShowAdditionalFileModal(null);
      });
    }
  };

  const onDeleteNeDZMAdditionalFiles = () => {
    if (isShowNeDZMAdditionalFileModal.isNew) {
      notification.success({ message: 'Сохранено' });
      setIsShowNeDZMAdditionalFileModal(null);
    } else {
      editApplicationsFiles({
        id: isShowNeDZMAdditionalFileModal.id, isDeleted: true, changeDate: moment().toISOString(), changeUser: getUserId(),
      }).then(() => {
        notification.success({ message: 'Сохранено' });
        setApplication({
          ...application,
          neDZMAdditionalFilesApplications_ids: application.neDZMAdditionalFilesApplications_ids
            .filter((f) => f.id !== isShowNeDZMAdditionalFileModal.id),
        });
        setIsShowNeDZMAdditionalFileModal(null);
      });
    }
  };

  const downloadAnnotationFiles = () => {
    const applicationStepReport = application.applicationStepReports.find((e) => Number(e.stepNumber) === application.maxStepsNumber);
    getApplicationStepReportById(applicationStepReport.id)
      .then((asr) => getAnnotationFiles(asr));
  };

  return (
    <Form
      ref={formRef}
      className="application-form-wrapper"
    >
      {application && (
        <Row gutter={80}>
          <Col span={16}>

            <div className="application-main-wrapper">
              <div className="application-main-box-wrapper" style={{ marginBottom: '20px' }}>
                <div className="application-main-box-mask" />
                <div className="application-main-box-content-wrapper">
                  <Col span={24} style={{ padding: '10px 0px' }}>
                    <Row justify="space-between">
                      <Col>
                        <span style={{ fontSize: '20px' }}>Заявка на получение гранта</span>
                      </Col>
                      {getUserRole() === 'bigAdmin' && (
                      <Col>
                        <Button onClick={() => setIsShowUploadMultipleFileModal('takeGrantRequest_id')}>Загрузить</Button>
                      </Col>
                      )}
                    </Row>
                    {application.takeGrantRequest_id && (
                    <Row style={{ margin: '5px 0px', width: '100%' }} align="middle">
                      <Col><Button type="primary" onClick={() => window.open(application.takeGrantRequest_id.urlLink)} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                      <Col>{application.takeGrantRequest_id.name}</Col>
                    </Row>
                    )}
                  </Col>
                </div>
              </div>
            </div>

            <Project
              loading={getUserRole() !== 'bigAdmin'}
              visible
              specializationCatalogue={specializationCatalogue}
              scientificDirectionCatalogue={scientificDirectionCatalogue}
              application={application}
              onButtonClick={() => {}}
              files={application.applicationAndSopd_ids || []}
              removeFile={(f) => onAppFileRemove(f, 'appFiles')}
              onFileUpload={(f) => onAppFileUpload(f, 'appFiles')}
            />
            <Organisation
              loading={getUserRole() !== 'bigAdmin'}
              visible
              application={application}
              onButtonClick={() => {}}
            />
            <Director
              loading={getUserRole() !== 'bigAdmin'}
              visible
              application={application}
              onButtonClick={() => {}}
            />
            <Files
              loading={getUserRole() !== 'bigAdmin'}
              visible
              application={application}
              onButtonClick={() => {}}
              onUploadFile={(f) => onAppFileUpload(f, 'appFiles')}
              onRemoveFile={(f) => onAppFileRemove(f, 'appFiles')}
              files={application.additionalFiles || []}
            />
            <ApplicationsPlan
              loading={getUserRole() !== 'bigAdmin'}
              visible
              applicationsPlan={application?.applicationsPlan || {}}
            />
          </Col>

          <Col span={8}>
            <div className="application-main-box-wrapper" style={{ paddingBottom: '20px' }}>
              <div className="application-main-box-mask" style={{ background: '#fff' }} />
              {getUserRole() === 'bigAdmin' && (
              <div className="application-main-box-content-wrapper" style={{ marginBottom: '-30px' }}>
                <div className="application-main-label">Действия</div>
              </div>
              )}
              {getUserRole() === 'bigAdmin' && (
              <div className="application-main-box-content-wrapper">
                <Button type="primary" onClick={onSave}>Сохранить</Button>
              </div>
              )}

              {!isHidden && (
              <Col span={24} style={{ padding: '10px 20px' }}>
                <Row justify="space-between">
                  <Col>
                    <span style={{ fontSize: '18px' }}>Грантовые соглашения</span>
                  </Col>
                  {getUserRole() === 'bigAdmin' && (
                  <Col>
                    <Button onClick={() => {
                      setIsShowUploadMultipleFileModal('grantAgreementScan_ids');
                      setMultipleFile(application.grantAgreementScan_ids);
                    }}
                    >
                      Загрузить
                    </Button>
                  </Col>
                  )}
                </Row>
                <Col span={24} style={{ padding: 0 }}>
                  {application.grantAgreementScan_ids.map((f) => (
                    <Row key={f.id} style={{ margin: '5px 0px', width: '100%' }} align="middle">
                      <Col><Button type="primary" onClick={() => fileDownload(f.urlLink, f.name)} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                      <Col><Button type="primary" onClick={() => fileOpenNewTab(f.urlLink)} style={{ marginRight: '10px' }}>Открыть</Button></Col>
                      <Col>{f.name}</Col>
                    </Row>
                  ))}
                </Col>
                {/* {application.grantAgreementScan_id?.urlLink && (
                <Row style={{ margin: '5px 0px', width: '100%' }} align="middle">
                  <Col><Button type="primary" onClick={() => window.open(application.grantAgreementScan_id.urlLink)} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                  <Col>{application.grantAgreementScan_id.name}</Col>
                </Row>
                )} */}
              </Col>
              )}

              {!isHidden && (
              <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }}>
                <Row justify="space-between">
                  <Col>
                    <span style={{ fontSize: '18px' }}>Дополнительные соглашения</span>
                  </Col>
                  {getUserRole() === 'bigAdmin' && (
                  <Col>
                    <Button onClick={() => setIsShowNeDZMAdditionalFileModal({ id: uuidv4(), isNew: true })}>
                      Добавить
                    </Button>
                  </Col>
                  )}
                </Row>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  {application.neDZMAdditionalFilesApplications_ids.map((f, i) => (
                    <div style={{
                      display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '5px', justifyContent: 'space-between',
                    }}
                    >
                      <div style={{ marginRight: '10px' }}>{f?.pdfFile_id?.name || `Доп.соглашение ${i + 1}`}</div>
                      <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                      }}
                      >
                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => fileDownload(f?.pdfFile_id?.urlLink, f?.pdfFile_id?.name)} />
                        <Button type="primary" style={{ margin: '0px 10px' }} icon={<EyeOutlined />} onClick={() => fileOpenNewTab(f?.pdfFile_id?.urlLink)} />
                        <Button type="primary" onClick={() => setIsShowNeDZMAdditionalFileModal(f)}>Доп. файлы</Button>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
              )}

              <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }}>
                <Row justify="space-between">
                  <Col>
                    <span style={{ fontSize: '18px' }}>Дополнительные файлы</span>
                  </Col>
                  {getUserRole() === 'bigAdmin' && (
                  <Col>
                    <Button onClick={() => setIsShowAdditionalFileModal({ id: uuidv4(), isNew: true })}>
                      Добавить
                    </Button>
                  </Col>
                  )}
                </Row>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  {application.applicationsFiles_ids.map((f, i) => (
                    <div style={{
                      display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '5px', justifyContent: 'space-between',
                    }}
                    >
                      <div style={{ marginRight: '10px' }}>{f?.file_id?.name || `Доп.файл ${i + 1}`}</div>
                      <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                      }}
                      >
                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => fileDownload(f?.file_id?.urlLink, f?.file_id?.name)} />
                        <Button type="primary" style={{ margin: '0px 10px' }} icon={<EyeOutlined />} onClick={() => fileOpenNewTab(f?.file_id?.urlLink)} />
                        <Button type="primary" onClick={() => setIsShowAdditionalFileModal(f)}>Открыть</Button>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>

              <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }}>
                <Row justify="space-between">
                  <Col>
                    <span style={{ fontSize: '18px' }}>Отчеты соисполнителей с приложениями</span>
                  </Col>
                </Row>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  {application.linkedApplicationFiles.map((f, i) => (
                    <div style={{
                      display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '5px', justifyContent: 'space-between',
                    }}
                    >
                      <div style={{ marginRight: '10px' }}>{f?.originalFileName || `Файл ${i + 1}`}</div>
                      <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                      }}
                      >
                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => fileDownload(f?.urlLink, f?.name)} />
                        <Button type="primary" style={{ margin: '0px 10px' }} icon={<EyeOutlined />} onClick={() => fileOpenNewTab(f?.urlLink)} />
                      </div>
                    </div>
                  ))}
                </div>
              </Col>

              {sideTabs.map((f) => (
                <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }} key={f.name}>
                  <Row justify="space-between">
                    <Col>
                      <span style={{ fontSize: '18px' }}>{f.name}</span>
                    </Col>
                    {getUserRole() === 'bigAdmin' && (
                    <Col>
                      <Button onClick={() => {
                        setIsShowUploadSingleFileModal(f.field);
                        setSingleFile(application[f.field]);
                      }}
                      >
                        Загрузить
                      </Button>
                    </Col>
                    )}
                  </Row>
                  <Col span={24} style={{ padding: 0 }}>
                    {application[f.field] && (
                      <Row key={f.id} style={{ margin: '5px 0px', width: '100%' }} align="middle">
                        <Col><Button type="primary" onClick={() => fileDownload(application[f.field].urlLink, application[f.field].name)} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                        <Col><Button type="primary" onClick={() => fileOpenNewTab(application[f.field].urlLink)} style={{ marginRight: '10px' }}>Открыть</Button></Col>
                        <Col>{application[f.field].name}</Col>
                      </Row>
                    )}
                  </Col>
                </Col>
              ))}

              <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }}>
                <Row justify="space-between">
                  <Col>
                    <span style={{ fontSize: '18px' }}>Приложения к итоговому аннотированному отчету</span>
                  </Col>
                  <Col>
                    <Button onClick={downloadAnnotationFiles}>
                      Скачать
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }}>
                <Row justify="space-between">
                  <Col>
                    <span style={{ fontSize: '18px' }}>Экспертные заключения по результатам проекта</span>
                  </Col>
                </Row>
                <Col span={24} style={{ padding: 0 }}>
                  {application.applicationStepReportExpert.map((f) => (
                    <Row key={f.id} style={{ margin: '5px 0px', width: '100%' }} align="middle">
                      <Col><Button type="primary" onClick={() => fileDownload(f.urlLink, f.name)} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                      <Col><Button type="primary" onClick={() => fileOpenNewTab(f.urlLink)} style={{ marginRight: '10px' }}>Открыть</Button></Col>
                      <Col>{f.name}</Col>
                    </Row>
                  ))}
                </Col>
              </Col>

              {!isHidden && (
              <Col span={24} style={{ padding: '10px 20px', borderTop: '1px solid #212121' }}>
                <Row justify="space-between">
                  <Col>
                    <span style={{ fontSize: '18px' }}>Сведения о денежных средствах грантополучателя</span>
                  </Col>
                </Row>
                <div className="application-main-box-content-wrapper" style={{ width: '100%', marginBottom: '-20px' }}>
                  <span className="application-main-box-label">Общая сумма средств гранта по Соглашению</span>
                  <Form.Item
                    name="allStepsSum"
                    initialValue={application.allStepsSum}
                  >
                    <Input disabled={getUserRole() !== 'bigAdmin'} bordered style={{ width: '100%', borderColor: '#014f97' }} />
                  </Form.Item>
                </div>
                <div className="application-main-box-content-wrapper" style={{ width: '100%', marginBottom: '-20px' }}>
                  <span className="application-main-box-label">Объем средств, фактически полученных от АНО на текущий момент грантополучателем</span>
                  <Form.Item
                    name="currentTransferedSum"
                    initialValue={application.currentTransferedSum}
                  >
                    <Input disabled={getUserRole() !== 'bigAdmin'} bordered style={{ width: '100%', borderColor: '#014f97' }} />
                  </Form.Item>
                </div>
                <Collapse ghost style={{ marginTop: '10px' }}>
                  <Panel header={<div style={{ color: '#1890ff', textDecoration: 'underline' }}>Посмотреть распределение средств по этапам</div>} key="1">
                    {Array(application.maxStepsNumber).fill(0).map((el, index) => (
                      <div className="application-main-box-content-wrapper" style={{ width: '100%', marginBottom: '-20px' }} key={index}>
                        <span className="application-main-box-label">{`Этап ${index + 1}`}</span>
                        <Form.Item
                          name={`step${index + 1}Sum`}
                          initialValue={application[`step${index + 1}Sum`]}
                        >
                          <Input disabled={getUserRole() !== 'bigAdmin'} bordered style={{ width: '100%', borderColor: '#014f97' }} />
                        </Form.Item>
                      </div>
                    ))}
                  </Panel>
                </Collapse>
              </Col>
              )}
            </div>
          </Col>
        </Row>
      )}

      {isShowUploadSingleFileModal && (
        <Modal
          visible
          width="70vw"
          maskClosable={false}
          onCancel={() => { setSingleFile(null); setIsShowUploadSingleFileModal(null); }}
          title={`Загрузка файла для ${application.displayID}`}
          footer={[
            <Button key="submit" type="primary" onClick={onSaveSingleFileModal}>
              Загрузить
            </Button>]}
        >
          <Col>
            {!singleFile && (
            <Dragger
              showUploadList={false}
              onChange={onSingleFileUpload}
              action={null}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
            </Dragger>
            )}
            {singleFile
            && (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(singleFile.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={onSingleFileRemove} /></Col>
              <Col>{singleFile.name}</Col>
            </Row>
            )}
          </Col>
        </Modal>
      )}

      {isShowUploadMultipleFileModal && (
      <Modal
        visible
        width="70vw"
        maskClosable={false}
        onCancel={() => { setMultipleFile([]); setIsShowUploadMultipleFileModal(null); }}
        title={`Загрузка файла для ${application.displayID}`}
        footer={[
          <Button key="submit" type="primary" onClick={onSaveMultipleFileModal}>
            Загрузить
          </Button>]}
      >
        <Col>
          <Dragger
            showUploadList={false}
            action={null}
            multiple
            beforeUpload={(file) => {
              onMultipleFileUpload(file);
              return false;
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файлы, чтобы загрузить (не более 15мб)</p>
          </Dragger>
          {multipleFile.map((f) => (
            <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onMultipleFileRemove(f)} /></Col>
              <Col>{f.name}</Col>
            </Row>
          ))}
        </Col>
      </Modal>
      )}
      {isShowNeDZMAdditionalFileModal && (
      <Modal
        visible
        width="70vw"
        maskClosable={false}
        onCancel={() => setIsShowNeDZMAdditionalFileModal(false)}
        title={`Загрузка ДС для ${application.displayID}`}
        footer={[
          <Button key="save" type="primary" onClick={onSaveNeDZMAdditionalFiles}>
            Сохранить
          </Button>,
          <Popconfirm
            title="Уверены, что хотите удалить?"
            onConfirm={onDeleteNeDZMAdditionalFiles}
            okText="Да"
            cancelText="Нет"
          >
            <Button key="delete" type="danger" disabled={getUserRole() !== 'bigAdmin'}>
              Удалить
            </Button>
          </Popconfirm>]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 'bold' }}>Файл pdf</div>
          {!isShowNeDZMAdditionalFileModal.pdfFile_id && (
          <Dragger
            showUploadList={false}
            onChange={(e) => onSingleFileUpload(e, 'pdfFile_id')}
            action={null}
            accept=".pdf"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          )}
          {isShowNeDZMAdditionalFileModal.pdfFile_id
            && (
              <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 0px' }}>
                <div>{isShowNeDZMAdditionalFileModal.pdfFile_id.name}</div>
                <div>
                  <Button type="primary" onClick={() => fileDownload(isShowNeDZMAdditionalFileModal.pdfFile_id.urlLink, isShowNeDZMAdditionalFileModal.pdfFile_id.name)}>Скачать</Button>
                  <Button type="primary" onClick={() => fileOpenNewTab(isShowNeDZMAdditionalFileModal.pdfFile_id.urlLink)} style={{ margin: '0px 10px' }}>Открыть</Button>
                  <Popconfirm
                    title="Уверены, что хотите удалить?"
                    onConfirm={() => onSingleFileRemove('pdfFile_id')}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button type="danger" disabled={getUserRole() !== 'bigAdmin'}>Удалить</Button>
                  </Popconfirm>
                </div>
              </div>
            )}
          <div style={{ fontWeight: 'bold' }}>Файл word</div>
          {!isShowNeDZMAdditionalFileModal.wordFile_id && (
          <Dragger
            showUploadList={false}
            onChange={(e) => onSingleFileUpload(e, 'wordFile_id')}
            action={null}
            accept=".docx,.doc"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          )}
          {isShowNeDZMAdditionalFileModal.wordFile_id
            && (
              <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 0px' }}>
                <div>{isShowNeDZMAdditionalFileModal.wordFile_id.name}</div>
                <div>
                  <Button type="primary" onClick={() => fileDownload(isShowNeDZMAdditionalFileModal.wordFile_id.urlLink, isShowNeDZMAdditionalFileModal.wordFile_id.name)}>Скачать</Button>
                  <Button type="primary" onClick={() => fileOpenNewTab(isShowNeDZMAdditionalFileModal.wordFile_id.urlLink)} style={{ margin: '0px 10px' }}>Открыть</Button>
                  <Popconfirm
                    title="Уверены, что хотите удалить?"
                    onConfirm={() => onSingleFileRemove('wordFile_id')}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button type="danger" disabled={getUserRole() !== 'bigAdmin'}>Удалить</Button>
                  </Popconfirm>
                </div>
              </div>
            )}
          <div style={{ fontWeight: 'bold' }}>Файл письма</div>
          {!isShowNeDZMAdditionalFileModal.letterFile_id && (
          <Dragger
            showUploadList={false}
            onChange={(e) => onSingleFileUpload(e, 'letterFile_id')}
            action={null}
            accept=".docx,.doc,.pdf,.xlsx,.xlsm"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          )}
          {isShowNeDZMAdditionalFileModal.letterFile_id
            && (
              <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 0px' }}>
                <div>{isShowNeDZMAdditionalFileModal.letterFile_id.name}</div>
                <div>
                  <Button type="primary" onClick={() => fileDownload(isShowNeDZMAdditionalFileModal.letterFile_id.urlLink, isShowNeDZMAdditionalFileModal.letterFile_id.name)}>Скачать</Button>
                  <Button type="primary" onClick={() => fileOpenNewTab(isShowNeDZMAdditionalFileModal.letterFile_id.urlLink)} style={{ margin: '0px 10px' }}>Открыть</Button>
                  <Popconfirm
                    title="Уверены, что хотите удалить?"
                    onConfirm={() => onSingleFileRemove('letterFile_id')}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button type="danger" disabled={getUserRole() !== 'bigAdmin'}>Удалить</Button>
                  </Popconfirm>
                </div>
              </div>
            )}
        </div>
      </Modal>
      )}

      {isShowAdditionalFileModal && (
      <Modal
        visible
        width="70vw"
        maskClosable={false}
        onCancel={() => setIsShowAdditionalFileModal(false)}
        title={`Загрузка доп файлов для ${application.displayID}`}
        footer={[
          <Button key="save" type="primary" onClick={onSaveAdditionalFiles}>
            Сохранить
          </Button>,
          <Popconfirm
            title="Уверены, что хотите удалить?"
            onConfirm={onDeleteAdditionalFiles}
            okText="Да"
            cancelText="Нет"
          >
            <Button key="delete" type="danger" disabled={getUserRole() !== 'bigAdmin'}>
              Удалить
            </Button>
          </Popconfirm>]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 'bold' }}>Файл pdf</div>
          {!isShowAdditionalFileModal.file_id && (
          <Dragger
            showUploadList={false}
            onChange={(e) => onSingleFileUpload(e, 'file_id')}
            action={null}
            accept=".pdf"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          )}
          {isShowAdditionalFileModal.file_id
            && (
              <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 0px' }}>
                <div>{isShowAdditionalFileModal.file_id.name}</div>
                <div>
                  <Button type="primary" onClick={() => fileDownload(isShowAdditionalFileModal.file_id.urlLink, isShowAdditionalFileModal.file_id.name)}>Скачать</Button>
                  <Button type="primary" onClick={() => fileOpenNewTab(isShowAdditionalFileModal.file_id.urlLink)} style={{ margin: '0px 10px' }}>Открыть</Button>
                  <Popconfirm
                    title="Уверены, что хотите удалить?"
                    onConfirm={() => onSingleFileRemove('file_id')}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button type="danger" disabled={getUserRole() !== 'bigAdmin'}>Удалить</Button>
                  </Popconfirm>
                </div>
              </div>
            )}
        </div>
      </Modal>
      )}
    </Form>
  );
}

CommonInfo.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  specializationCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  scientificDirectionCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CommonInfo;
