/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Form, InputNumber, Upload, Row, Col,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined,
} from '@ant-design/icons';
import PropTypes, { number } from 'prop-types';
import getRow from './getRow';
import getPlanRow from './getPlanRow';
import FinanceCostTable from './FinanceCostTable';
import FinanceSalaryCostTable from './FinanceSalaryCostTable';
import './FinanceTab.css';


const { Dragger } = Upload;
const fields = ['fot', 'material', 'equipment', 'other', 'paper', 'people'];
function FinanceTab({
  applicationProp, disabled, formRef, files, onAddFile, onDeleteFile, onPercentagesUpdate,
}) {
  // const calculateSum = (array, fieldName) => {
  //   const sum = array.reduce((a, c) => a + parseFloat(`${c[`${fieldName}Major`]}.${(`${c[`${fieldName}Minor`]}`).length === 1 ? `0${c[`${fieldName}Minor`]}` : c[`${fieldName}Minor`]}`) * 100, 0);
  //   return {
  //     major: Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100),
  //     minor: Number((sum % 100).toFixed(0)),
  //   };
  // };

  const [totalPercentByRow, setTotalPercentByRow] = useState({
    fot: 0,
    material: 0,
    equipment: 0,
    other: 0,
    paper: 0,
    people: 0,
  });

  useEffect(() => {
    onPercentagesUpdate(totalPercentByRow);
  }, [totalPercentByRow]);

  const onSumChange = () => {
    const values = formRef.current.getFieldsValue();
    const objToSave = {
      financeTotalPercent: 100,
    };
    fields.forEach((f) => {
      const year23Major = typeof values[`${f}2023Major`] === 'number' ? Math.round(values[`${f}2023Major`]) : 0;
      const year23Minor = typeof values[`${f}2023Minor`] === 'number' ? Math.round(values[`${f}2023Minor`]) : 0;
      const year23 = parseFloat(`${year23Major}.${year23Minor.toString().length === 1 ? `0${year23Minor}` : year23Minor}`) * 100;
      const year24Major = typeof values[`${f}2024Major`] === 'number' ? Math.round(values[`${f}2024Major`]) : 0;
      const year24Minor = typeof values[`${f}2024Minor`] === 'number' ? Math.round(values[`${f}2024Minor`]) : 0;
      const year24 = parseFloat(`${year24Major}.${year24Minor.toString().length === 1 ? `0${year24Minor}` : year24Minor}`) * 100;
      const year25Major = typeof values[`${f}2025Major`] === 'number' ? Math.round(values[`${f}2025Major`]) : 0;
      const year25Minor = typeof values[`${f}2025Minor`] === 'number' ? Math.round(values[`${f}2025Minor`]) : 0;
      const year25 = parseFloat(`${year25Major}.${year25Minor.toString().length === 1 ? `0${year25Minor}` : year25Minor}`) * 100;

      const sum = year23 + year24 + year25;

      objToSave[`${f}TotalMajor`] = Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100);
      objToSave[`${f}TotalMinor`] = Number((sum % 100).toFixed(0));
    });

    const financeSum = parseFloat(`${objToSave.fotTotalMajor || 0}.${(`${objToSave.fotTotalMinor}`).length === 1 ? `0${objToSave.fotTotalMinor}` : objToSave.fotTotalMinor}`) * 100
    + parseFloat(`${objToSave.materialTotalMajor || 0}.${(`${objToSave.materialTotalMinor}`).length === 1 ? `0${objToSave.materialTotalMinor}` : objToSave.materialTotalMinor}`) * 100
    + parseFloat(`${objToSave.equipmentTotalMajor || 0}.${(`${objToSave.equipmentTotalMinor}`).length === 1 ? `0${objToSave.equipmentTotalMinor}` : objToSave.equipmentTotalMinor}`) * 100
    + parseFloat(`${objToSave.otherTotalMajor || 0}.${(`${objToSave.otherTotalMinor}`).length === 1 ? `0${objToSave.otherTotalMinor}` : objToSave.otherTotalMinor}`) * 100
    + parseFloat(`${objToSave.paperTotalMajor || 0}.${(`${objToSave.paperTotalMinor}`).length === 1 ? `0${objToSave.paperTotalMinor}` : objToSave.paperTotalMinor}`) * 100
    + parseFloat(`${objToSave.peopleTotalMajor || 0}.${(`${objToSave.peopleTotalMinor}`).length === 1 ? `0${objToSave.peopleTotalMinor}` : objToSave.peopleTotalMinor}`) * 100;

    objToSave.financeTotalMajor = Math.trunc(financeSum / 100) === 0 && financeSum < 0 ? `-0${Math.trunc(financeSum / 100)}` : Math.trunc(financeSum / 100);
    objToSave.financeTotalMinor = Number((financeSum % 100).toFixed(0));

    fields.forEach((f) => {
      const percent = (objToSave[`${f}TotalMajor`] / objToSave.financeTotalMajor) * 100;
      if (!Number.isNaN(percent)) {
        objToSave[`${f}Percent`] = percent.toFixed(2);
      } else {
        objToSave[`${f}Percent`] = '0';
      }
    });

    const years = ['2023', '2024', '2025'];
    years.forEach((y) => {
      let financeTotalYearSum = 0;
      fields.forEach((f) => {
        financeTotalYearSum += parseFloat(`${values[`${f}${y}Major`] || 0}.${(`${values[`${f}${y}Minor`]}`).length === 1 ? `0${values[`${f}${y}Minor`]}` : values[`${f}${y}Minor`]}`) * 100;
      });
      objToSave[`financeTotal${y}Major`] = Math.trunc(financeTotalYearSum / 100) === 0 && financeTotalYearSum < 0 ? `-0${Math.trunc(financeTotalYearSum / 100)}` : Math.trunc(financeTotalYearSum / 100);
      objToSave[`financeTotal${y}Minor`] = Number((financeTotalYearSum % 100).toFixed(0));
    });

    const obj = {};
    fields.forEach((f) => {
      obj[f] = Number(objToSave[`${f}Percent`]);
    });
    setTotalPercentByRow(obj);

    formRef.current.setFieldsValue(objToSave);
  };

  const onChangeDetailedSum = (updatedObject) => {
    formRef.current.setFieldsValue(updatedObject);
    onSumChange();
  };

  useEffect(() => {
    const obj = {};
    fields.forEach((f) => {
      obj[f] = applicationProp[`${f}Percent`];
    });
    setTotalPercentByRow(obj);
  }, []);

  const isShowFileUpload = totalPercentByRow.fot > 70 || ((totalPercentByRow.material + totalPercentByRow.equipment) > 80)
  || totalPercentByRow.other > 40 || totalPercentByRow.paper > 20 || totalPercentByRow.people > 60;
  return (
    <div className="financeTab-wrapper">
      <Form.Item
        name="id"
        initialValue={applicationProp.id}
        hidden
      />

      <div>
        Формируйте бюджет, после того как запланируете все мероприятия проекта, а не наоборот — так бюджет получится честным и реалистичным. Помните, что бюджет проекта —
        <b> это ответственность руководителя проекта </b>
        , а не бухгалтера, поэтому составляет бюджет и отвечает за расходование средств именно руководитель.
      </div>

      <div style={{ margin: '5px 0px' }}>
        <b>Расходы по проекту учитываются по факту совершения.</b>
        {' '}
        Это значит, что
        {' '}
        <b>Центр не компенсирует «задним числом»</b>
        {' '}
        те расходы, которые вы уже совершили до начала реализации проекта, и не выдает средства на те расходы, которые вы планируете совершить, после того как закончите проект.
      </div>

      <div><b>На что нельзя запрашивать и тратить средства гранта.</b></div>
      <div>Центр не предоставляет гранты на обеспечение следующих расходов:</div>
      <ul>
        <li>не связанных непосредственно с реализацией проекта,</li>
        <li>на приобретение недвижимого имущества (включая земельные участки),</li>
        <li>на капитальное строительство новых зданий,</li>
        <li>на приобретение алкогольной и табачной продукции, а также предметов роскоши,</li>
        <li>на финансирование политических партий, кампаний и акций, подготовку и проведение митингов, демонстраций и пикетирований,</li>
        <li>на погашение задолженности организации,</li>
        <li>на уплату штрафов и пеней.</li>
      </ul>

      <div>
        Не допускается планировать и запрашивать средства гранта на расходы, которые содержательно дублируются с расходами по действующим грантам и субсидиям, предоставленным любыми организациями и органами власти
      </div>

      <FinanceSalaryCostTable formRef={formRef} applicationProp={applicationProp} disabled={disabled} onChangeDetailedSum={onChangeDetailedSum} />

      <FinanceCostTable formRef={formRef} type="material" disabled={disabled} values={applicationProp.applications2Costs_ids.filter((c) => c.type === 'material')} projectTerm={applicationProp.projectTerm} onChangeDetailedSum={onChangeDetailedSum} />
      <FinanceCostTable formRef={formRef} type="equipment" disabled={disabled} values={applicationProp.applications2Costs_ids.filter((c) => c.type === 'equipment')} projectTerm={applicationProp.projectTerm} onChangeDetailedSum={onChangeDetailedSum} />
      <FinanceCostTable formRef={formRef} type="other" disabled={disabled} values={applicationProp.applications2Costs_ids.filter((c) => c.type === 'other')} projectTerm={applicationProp.projectTerm} onChangeDetailedSum={onChangeDetailedSum} />
      <FinanceCostTable formRef={formRef} type="paper" disabled={disabled} values={applicationProp.applications2Costs_ids.filter((c) => c.type === 'paper')} projectTerm={applicationProp.projectTerm} onChangeDetailedSum={onChangeDetailedSum} />

      <div className="common-subTitle">5. СМЕТА РАСХОДОВ ПРОЕКТА</div>
      <span className="common-field-hint">
        По каждой статье расходов предусмотрены предельные размеры затрат, установленные в Приложении 3 к
        {' '}
        <a href="https://api.directual.com/fileUploaded/medtechgrants/4b00dfe6-42c7-41f0-8780-ec676a01c2f3.pdf" target="_blank" rel="noreferrer">Приказу правительства г. Москвы № 1031 от 20.10.2021 г.</a>
        {' '}
        <b>превышение которых недопустимо</b>
      </span>
      <br />
      <br />
      {/* <span className="common-field-hint">В колонке «Доля затрат» происходит автоматический расчет того, какой объем (в %) занимает статья расходов в общем объеме финансирования.</span> */}

      <div className="common-table-wrapper" style={{ marginTop: '20px' }}>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center', fontWeight: 'bold' }}>№ п/п</div>
          <div className="common-table-row-cell" style={{ width: '25%', textAlign: 'center', fontWeight: 'bold' }}>Наименование статей расходов</div>
          <div
            className="common-table-row-cell"
            style={{
              display: 'flex', flexDirection: 'column', width: '70%', padding: '4px 0px 0px 0px', borderRight: '1px solid #d9d9d9',
            }}
          >
            <div
              className="common-table-row-cell"
              style={{
                width: '100%', textAlign: 'center', border: 'none', fontWeight: 'bold',
              }}
            >
              Средства финансирования
            </div>
            <div className="common-table-row">
              <div
                className="common-table-row-cell"
                style={{
                  width: '30%', textAlign: 'center', borderLeft: '0', fontWeight: 'bold',
                }}
              >
                Всего
              </div>
              <div className="common-table-row-cell" style={{ width: applicationProp.projectTerm === '3' ? '20%' : applicationProp.projectTerm === '2' ? '30%' : '60%', textAlign: 'center', fontWeight: 'bold' }}>2025</div>
              {(applicationProp.projectTerm === '2' || applicationProp.projectTerm === '3') && (<div className="common-table-row-cell" style={{ width: applicationProp.projectTerm === '3' ? '20%' : '30%', textAlign: 'center', fontWeight: 'bold' }}>2026</div>)}
              {applicationProp.projectTerm === '3' && (<div className="common-table-row-cell" style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>2027</div>)}
              <div className="common-table-row-cell" style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>Доля затрат</div>
            </div>
          </div>
        </div>
        {getRow(1, applicationProp, disabled, onSumChange, totalPercentByRow.fot)}
        {getRow(2, applicationProp, disabled, onSumChange, totalPercentByRow.material)}
        {getRow(3, applicationProp, disabled, onSumChange, totalPercentByRow.equipment)}
        {getRow(4, applicationProp, disabled, onSumChange, totalPercentByRow.other)}
        {getRow(5, applicationProp, disabled, onSumChange, totalPercentByRow.paper)}
        {getRow(6, applicationProp, disabled, onSumChange, totalPercentByRow.people)}

        <div className="common-table-row" style={{ borderBottom: '1px solid #d9d9d9' }}>
          <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }} />
          <div className="common-table-row-cell" style={{ width: '25%', textAlign: 'right', fontWeight: 'bold' }}>ИТОГО</div>
          <div className="common-table-row" style={{ width: '70%' }}>
            <div
              className="common-table-row-cell"
              style={{
                width: '30%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
            >
              <Form.Item
                style={{ width: '55%' }}
                name="financeTotalMajor"
                initialValue={applicationProp.financeTotalMajor || 0}
              >
                <InputNumber disabled />
              </Form.Item>
              <span>руб.</span>
              <Form.Item
                style={{ width: '25%' }}
                name="financeTotalMinor"
                initialValue={applicationProp.financeTotalMinor || 0}
              >
                <InputNumber disabled />
              </Form.Item>
              <span>коп.</span>
            </div>
            <div
              className="common-table-row-cell"
              style={{
                width: applicationProp.projectTerm === '2' ? '30%' : '20%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
            >
              <Form.Item
                style={{ width: '55%' }}
                name="financeTotal2023Major"
                initialValue={applicationProp.financeTotal2023Major || 0}
              >
                <InputNumber disabled />
              </Form.Item>
              <span>руб.</span>
              <Form.Item
                style={{ width: '25%' }}
                name="financeTotal2023Minor"
                initialValue={applicationProp.financeTotal2023Minor || 0}
              >
                <InputNumber disabled />
              </Form.Item>
              <span>коп.</span>
            </div>
            {(applicationProp.projectTerm === '2' || applicationProp.projectTerm === '3') && (
            <div
              className="common-table-row-cell"
              style={{
                width: applicationProp.projectTerm === '3' ? '20%' : '30%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
            >
              <Form.Item
                style={{ width: '55%' }}
                name="financeTotal2024Major"
                initialValue={applicationProp.financeTotal2024Major || 0}
              >
                <InputNumber disabled />
              </Form.Item>
              <span>руб.</span>
              <Form.Item
                style={{ width: '25%' }}
                name="financeTotal2024Minor"
                initialValue={applicationProp.financeTotal2024Minor || 0}
              >
                <InputNumber disabled />
              </Form.Item>
              <span>коп.</span>
            </div>
            )}
            {applicationProp.projectTerm === '3' && (
            <div
              className="common-table-row-cell"
              style={{
                width: '20%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
            >
              <Form.Item
                style={{ width: '55%' }}
                name="financeTotal2025Major"
                initialValue={applicationProp.financeTotal2025Major || 0}
              >
                <InputNumber disabled />
              </Form.Item>
              <span>руб.</span>
              <Form.Item
                style={{ width: '25%' }}
                name="financeTotal2025Minor"
                initialValue={applicationProp.financeTotal2025Minor || 0}
              >
                <InputNumber disabled />
              </Form.Item>
              <span>коп.</span>
            </div>
            )}
            <div
              className="common-table-row-cell"
              style={{
                width: '10%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
            >
              <Form.Item
                // style={{ width: '10%' }}
                name="financeTotalPercent"
                initialValue={applicationProp.financeTotalPercent}
              >
                <InputNumber disabled />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>

      {isShowFileUpload && (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontStyle: 'italic' }}>Подать письмо обоснование</span>
        <span style={{ fontStyle: 'italic' }}>
          Шаблон письма обоснования доступен по
          {' '}
          <a href="https://api.directual.com/fileUploaded/medtechgrants/04c08a7d-b73c-41b6-beeb-3f3a548e5ef3.docx">ссылке</a>
        </span>
        <Dragger
          showUploadList={false}
          onChange={(file) => onAddFile(file, 'financeFiles_ids')}
          disabled={disabled}
          multiple
          action={null}
          accept=".docx,.doc,.pdf,.xlsx,.xlsm"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Выберите или перенесите файл в формате .docx/.doc/.pdf/.xlsx/.xlsm, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
        </Dragger>
        {files.financeFiles_ids.map((f) => (
          <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
            {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'financeFiles_ids')} /></Col>}
            <Col>{f.name}</Col>
          </Row>
        ))}
      </div>
      )}

      <div className="common-subTitle" style={{ marginTop: '40px' }}>6. ПЛАН-ГРАФИК РЕАЛИЗАЦИИ НАУЧНО-ИССЛЕДОВАТЕЛЬСКОГО ПРОЕКТА</div>
      {/* <span className="common-field-hint">Проект может выполняться от 1 года до 3-х лет. Каждый календарный год выполнения проекта соответствует 1 этапу (каждый этап длится до 12 месяцев).</span> */}
      {/* <br /> */}
      {/* <span className="common-field-hint">Перечень разрабатываемых документов по каждому этапу формирует заявитель, при этом в отчетную документацию должны быть обязательно включены аннотированный и финансовый отчеты. Аннотированный отчет предполагает краткое описание полученных научных результатов за соответствующий период и формируется по результатам завершения Проекта.</span> */}
      {/* <br /> */}
      {/* <span className="common-field-hint">Сведения, указанные в графе «Ожидаемый результат» должны содержать в себе перечень результатов, которые будут получены в соответствии с содержанием выполняемых работ и мероприятий по каждому конкретному этапу. Ожидаемый результат также должен содержать количественные показатели выполняемых работ и мероприятий (например: количество проб/объектов для проведения исследования и т.п.). Пример: Проведены масс-спектрометрические измерения 1000 образцов плазмы крови. Подготовлена база данных (в формате .xls), содержащая результаты измерений 1000 образцов плазмы крови.</span> */}
      <div className="common-table-wrapper" style={{ marginTop: '10px' }}>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '4%', textAlign: 'center', fontWeight: 'bold' }}>№ п/п</div>
          <div className="common-table-row-cell" style={{ width: '16%', textAlign: 'center', fontWeight: 'bold' }}>Наименование этапа</div>
          <div className="common-table-row-cell" style={{ width: '16%', textAlign: 'center', fontWeight: 'bold' }}>Содержание выполняемых работ и мероприятий</div>
          <div className="common-table-row-cell" style={{ width: '16%', textAlign: 'center', fontWeight: 'bold' }}>Дата начала и окончания выполнения работ в каждом этапе</div>
          <div className="common-table-row-cell" style={{ width: '16%', textAlign: 'center', fontWeight: 'bold' }}>Ожидаемый результат</div>
          <div className="common-table-row-cell" style={{ width: '16%', textAlign: 'center', fontWeight: 'bold' }}>Перечень разрабатываемых документов</div>
          <div className="common-table-row-cell" style={{ width: '16%', textAlign: 'center', fontWeight: 'bold' }}>Финансирование (руб.)</div>
        </div>

        {['1', '2', '3'].includes(applicationProp.projectTerm) && getPlanRow(1, applicationProp, disabled, Number(applicationProp.projectTerm))}
        {['2', '3'].includes(applicationProp.projectTerm) && getPlanRow(2, applicationProp, disabled, Number(applicationProp.projectTerm))}
        {['3'].includes(applicationProp.projectTerm) && getPlanRow(3, applicationProp, disabled, Number(applicationProp.projectTerm))}

        <div className="common-table-row" style={{ borderBottom: '1px solid #d9d9d9' }}>
          <div
            className="common-table-row-cell"
            style={{
              width: '84%', textAlign: 'right', fontWeight: 'bold',
            }}
          >
            ИТОГО
          </div>
          <div
            className="common-table-row-cell"
            style={{
              width: '16%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
          >
            <Form.Item
              style={{ width: '55%' }}
              name="financeTotalMajor"
              initialValue={applicationProp.financeTotalMajor || 0}
            >
              <InputNumber disabled />
            </Form.Item>
            <span>руб.</span>
            <Form.Item
              style={{ width: '25%' }}
              name="financeTotalMinor"
              initialValue={applicationProp.financeTotalMinor || 0}
            >
              <InputNumber disabled />
            </Form.Item>
            <span>коп.</span>
          </div>

        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontStyle: 'italic' }}>Загрузите  материалы, обосновывающие объем запланированных расходов, предусмотренных в рамках реализации работ по Проекту</span>
        <Dragger
          showUploadList={false}
          onChange={(file) => onAddFile(file, 'materials_project')}
          disabled={disabled}
          multiple
          action={null}
          accept=".docx,.doc,.pdf,.xlsx,.xlsm"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Выберите или перенесите файл в формате .docx/.doc/.pdf/.xlsx/.xlsm, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
        </Dragger>
        {files.materials_project?.map((f) => (
          <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
            {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'materials_project')} /></Col>}
            <Col>{f.name}</Col>
          </Row>
        ))}
      </div>

    </div>
  );
}

FinanceTab.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  formRef: PropTypes.shape().isRequired,
  onAddFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onPercentagesUpdate: PropTypes.func.isRequired,
  files: PropTypes.shape().isRequired,
};

export default FinanceTab;
