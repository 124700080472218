import React, { useState, useEffect } from 'react';
import {
  Input, Table, Modal,
  Button, Select, Spin,
  notification,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import getApplicationsColumns from '../../utils/getApplicationsColumns';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';
import {
  getExpertProjects, getAllApplicationStepReportExpert, editExpertToApplication, getUsersAdmin, getAllApplications,
} from '../../../../../../utils/http';
// import usersRoles from '../Users/usersRoles';
import './ExpertProjects.less';


const { Search } = Input;
const { Option } = Select;
function ExpertProjects() {
  const [isAdmin] = useState(getUserRole() === 'bigAdmin');
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [applications, setApplications] = useState([]);
  const [modalValues, setModalValues] = useState({ expert_id: null, application_id: null });

  useEffect(() => {
    if (isAdmin) {
      getAllApplicationStepReportExpert()
        .then((data) => {
          setLoading(false);
          setOriginalData(data);
          setDataSource(data);
        });
    } else {
      getExpertProjects()
        .then((data) => {
          setLoading(false);
          setOriginalData(data);
          setDataSource(data);
        });
    }
  }, []);

  const onSearch = (value) => {
    if (value === '') {
      setDataSource(originalData);
    } else {
      setDataSource(originalData.filter((element) => element.displayID.toLowerCase().indexOf(value.toLowerCase()) >= 0
      || element.theme.toLowerCase().indexOf(value.toLowerCase()) >= 0
      || element.webUserShortName.toLowerCase().indexOf(value.toLowerCase()) >= 0));
    }
  };

  const onShowAddExpert = async () => {
    if (users.length === 0 || applications.length === 0) {
      setLoading(true);
      const [respUsers, respApplications] = await Promise.all([getUsersAdmin({ role: 'expertFederal,expertDZM' }), getAllApplications({ pageSize: 200, status: 'Поддержана,Завершен' })]);

      setUsers(respUsers.filter((u) => u.role !== 'bigAdmin').map((u) => ({ value: u.id, label: `${u.shortName}` })));
      setApplications(respApplications.data.map((a) => ({ value: a.id, label: `${a.displayID} [${a.status}]` })));
      setLoading(false);
    }
    setIsShowModal(true);
  };

  const onSaveAddExpert = () => {
    if (!modalValues.expert_id || !modalValues.application_id) {
      notification.error({ message: 'Заполните все поля' });
    } else if (originalData.find((d) => d.application_id === modalValues.application_id && d.expert_id === modalValues.expert_id)) {
      notification.error({ message: 'Эксперт уже есть в заявке' });
    } else {
      setLoading(true);
      editExpertToApplication(modalValues)
        .then((obj) => {
          setLoading(false);
          setOriginalData([...originalData, obj]);
          setDataSource([...dataSource, obj]);
          setIsShowModal(false);
        });
    }
  };

  return (
    <div className="expertProjects-wrapper">
      {loading && (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 100, zIndex: 100 }} spin />}
        style={{
          position: 'absolute', top: '40%', left: '45%', zIndex: 1000,
        }}
      />
      )}
      <div className="expertProjects-filters-wrapper" style={{ marginBottom: 0 }}>
        <div>Поиск</div>
        <Search
          placeholder="Поиск по номеру, теме..."
          style={{ width: '100%' }}
          onSearch={onSearch}
        />
      </div>
      {isAdmin && <Button type="primary" onClick={onShowAddExpert} style={{ margin: '10px 0px' }} disabled={loading}>Добавить</Button>}
      <div className="expertProjects-table-wrapper ">
        <Table
          dataSource={dataSource}
          columns={getApplicationsColumns(null, null, getUserRole() === 'bigAdmin')}
          loading={loading}
          scroll={{ y: '55vh' }}
          pagination={false}
          rowKey="id"
        />
      </div>
      {isShowModal && (
      <Modal title="Добавление эксперта в проект" onOk={onSaveAddExpert} onCancel={() => setIsShowModal(false)} okText="Добавить" visible>
        <div style={{ fontWeight: 'bold' }}>Эксперт</div>
        <Select
          allowClear
          style={{ width: '100%' }}
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onSelect={(e) => setModalValues({ ...modalValues, expert_id: e })}
        >
          {users.map((u) => <Option key={u.value} value={u.value}>{u.label}</Option>)}
        </Select>
        <div style={{ fontWeight: 'bold', marginTop: '10px' }}>Проект</div>

        <Select
          allowClear
          style={{ width: '100%' }}
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onSelect={(e) => setModalValues({ ...modalValues, application_id: e })}
        >
          {applications.map((u) => <Option key={u.value} value={u.value}>{u.label}</Option>)}
        </Select>
      </Modal>
      )}
    </div>
  );
}

export default ExpertProjects;
