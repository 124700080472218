const organisationsCatalogue = [
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 15 имени О.М. Филатова Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени В.В. Виноградова Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 29 имени Н.Э. Баумана Департамента здравоохранения города Москвы» ',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Детская городская клиническая больница имени Н.Ф. Филатова Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Научно-исследовательский институт неотложной детской хирургии и травматологии» Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы Городская клиническая больница имени С.П. Боткина Департамента здравоохранения города Москвы',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Морозовская детская городская клиническая больница Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени В.М. Буянова Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени М.П. Кончаловского Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Научно-практический психоневрологический центр имени З.П. Соловьева Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы Городская клиническая больница № 1 им. Н.И. Пирогова Департамента здравоохранения города Москвы',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Научно-исследовательский институт скорой помощи им. Н.В. Склифосовского Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 67 имени Л.А. Ворохобова Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени С.С. Юдина Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Московский клинический научно-практический центр имени А.С. Логинова Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Детская городская клиническая больница № 9 им. Г.Н. Сперанского Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 52 Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Детская городская клиническая больница имени З.А. Башляевой Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени И.В. Давыдовского Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Психиатрическая клиническая больница № 1 им. Н.А. Алексеева Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Научно-исследовательский клинический институт оториноларингологии им. Л.И. Свержевского» Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени С.И. Спасокукоцкого Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы "Московский научно-практический центр лабораторных исследований Департамента здравоохранения города Москвы"',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Московский многопрофильный клинический центр «Коммунарка» Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение города Москвы «Станция скорой и неотложной медицинской помощи им. А.С. Пучкова» Департамента здравоохранения города Москвы»',
  'Государственное автономное учреждение здравоохранения города Москвы «Московский научно-практический центр медицинской реабилитации, восстановительной и спортивной медицины Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая онкологическая больница № 1 Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Московский научно-практический центр дерматовенерологии и косметологии Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Московская городская онкологическая больница № 62 Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 13 Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени В.В. Вересаева Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени А.К. Ерамишанцева Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения г. Москвы «Городская клиническая больница №31 имени академика Г.М.Савельевой Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 17 Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы "Психиатрическая клиническая больница № 4 имени П.Б. Ганнушкина Департамента здравоохранения города Москвы"',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени В.П. Демихова Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы «Консультативно-диагностическая поликлиника № 121 Департамента здравоохранения города Москвы»',
  'Государственное бюджетное учреждение здравоохранения города Москвы "Московский научно-практический центр лабораторных исследований Департамента здравоохранения города Москвы"',
  'Государственное бюджетное учреждение здравоохранения города Москвы "Госпиталь для ветеранов войн № 3 Департамента здравоохранения города Москвы"',
];

export default organisationsCatalogue;
