const usersRoles = [
  {
    value: 'user',
    label: 'Пользователь',
  },
  // {
  //   value: 'DSM',
  //   label: 'ДЗМ',
  // },
  {
    value: 'noDSM',
    label: 'Не ДЗМ',
  },
  {
    value: 'expertFederal',
    label: 'Эксперт',
  },
  {
    value: 'expertDZM',
    label: 'Эксперт ГВС',
  },
  {
    value: 'bigAdmin',
    label: 'Администратор',
  },
];


export default usersRoles;
