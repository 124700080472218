/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, InputNumber,
} from 'antd';


const { TextArea } = Input;
function ApplicationsPlan({
  loading, visible, applicationsPlan,
}) {
  const [steps, setSteps] = useState(applicationsPlan.steps);
  return (
    <div className="application-main-wrapper" style={{ display: visible ? 'flex' : 'none' }}>
      <div className="application-main-box-wrapper">
        <div className="application-main-box-mask" />

        <div className="application-main-box-content-wrapper">
          <div className="application-subTitle">Оценка фактического выполнения плана работ в отчетном периоде</div>

          <div className="application-main-box-content-wrapper" style={{ width: '100%' }}>
            <span className="application-main-box-label">Количество строк</span>
            <Form.Item
              name="steps"
              initialValue={steps}
            >
              <InputNumber disabled={loading} min={1} max={15} onChange={(e) => setSteps(e)} />
            </Form.Item>
          </div>

          <div className="application-main-box-content-wrapper" style={{ width: '100%' }}>
            <div className="application-table-row">
              <div className="application-table-row-cell" style={{ width: '10%' }}>№</div>
              <div className="application-table-row-cell">Содержание работ, запланированных в рамках реализации проекта</div>
              <div className="application-table-row-cell" style={{ borderRight: '1px solid #d9d9d9' }}>Ожидаемые результаты</div>
            </div>

            {Array(steps).fill(0).map((el, index) => (
              <div className="application-table-row" key={index + 1}>
                <div className="application-table-row-cell" style={{ width: '10%' }}>{index + 1}</div>
                <Form.Item
                  style={{ width: '100%' }}
                  name={`n${index + 1}`}
                  initialValue={applicationsPlan[`n${index + 1}`]}
                >
                  <TextArea autoSize disabled={loading} minrows={2} />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  name={`d${index + 1}`}
                  initialValue={applicationsPlan[`d${index + 1}`]}
                >
                  <TextArea autoSize disabled={loading} minrows={2} />
                </Form.Item>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

ApplicationsPlan.propTypes = {
  loading: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  applicationsPlan: PropTypes.shape().isRequired,
};

export default ApplicationsPlan;
