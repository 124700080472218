/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-cond-assign */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import getTotalValue from './calculateXLSTotalSheet';


const ExcelJS = require('exceljs');


const border = {
  top: { style: 'thin' },
  left: { style: 'thin' },
  bottom: { style: 'thin' },
  right: { style: 'thin' },
};


// function toColumnName(num) {
//   let ret = '';
//   let a = 1;
//   let b = 1;
//   for (ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
//     ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
//   }
//   return ret;
// }
const fields = ['totalPaid', 'ndfl', 'pfr', 'foms', 'fss', 'fssns', 'totalPerson', 'actualPaid', 'remain'];

const getTotalRow = (dataSource) => {
  const obj = {
    id: 'isTotal',
    isTotal: true,
    fio: 'ОБЩИЙ ИТОГ',
  };

  fields.forEach((f) => {
    const MajorSum = dataSource.reduce((a, c) => a + c[`${f}Major`], 0);
    const MinorSum = dataSource.reduce((a, c) => a + c[`${f}Minor`], 0);
    obj[`${f}Major`] = MajorSum + Math.floor(MinorSum / 100);
    obj[`${f}Minor`] = MinorSum % 100;
  });

  return obj;
};

const createFotTable = (rows, worksheet) => {
  for (let i = 1; i <= 11; i += 1) {
    worksheet.getColumn(i).font = {
      name: 'Montserrat',
    };
    if (i === 1) {
      worksheet.getColumn(i).width = 20;
    }
    if (i === 2) {
      worksheet.getColumn(i).width = 40;
    }
    if ([3, 4, 5, 6, 7, 8, 9, 10, 11].includes(i)) {
      worksheet.getColumn(i).width = 30;
    }
  }

  const A1 = worksheet.getCell('A1');
  A1.value = 'Член коллектива специалистов (ФИО)';

  const B1 = worksheet.getCell('B1');
  B1.value = 'Роль в проекте/профессия';

  const C1 = worksheet.getCell('C1');
  C1.value = 'Всего начислено';

  const D1 = worksheet.getCell('D1');
  D1.value = 'в т.ч. НДФЛ';

  const E1 = worksheet.getCell('E1');
  E1.value = 'ПФР';

  const F1 = worksheet.getCell('F1');
  F1.value = 'ФОМС';

  const G1 = worksheet.getCell('G1');
  G1.value = 'ФСС';

  const H1 = worksheet.getCell('H1');
  H1.value = 'ФСС НС';

  const I1 = worksheet.getCell('I1');
  I1.value = 'ИТОГО по сотруднику';

  const J1 = worksheet.getCell('J1');
  J1.value = 'Кассовый расход';

  const K1 = worksheet.getCell('K1');
  K1.value = 'Остаток к уплате';

  for (let index = 0; index < rows.length; index += 1) {
    const row = rows[index];
    const rowXLS = worksheet.getRow(index + 2);

    const name = rowXLS.getCell(1);
    name.value = row.fio;
    name.border = border;
    name.alignment = { vertical: 'middle' };

    const roleAndPosition = rowXLS.getCell(2);
    roleAndPosition.value = `${row.role}/${row.position}`;
    roleAndPosition.border = border;
    roleAndPosition.alignment = { vertical: 'middle' };

    for (let e = 3; e <= 11; e += 1) {
      const valueMajor = row[`${fields[e - 3]}Major`];
      const valueMinor = row[`${fields[e - 3]}Minor`];
      const field = rowXLS.getCell(e);
      field.value = `${valueMajor} руб. ${valueMinor} коп.`;
      field.border = border;
      field.alignment = { vertical: 'middle' };
    }
  }

  const totalRowValues = getTotalRow(rows);
  const totalRow = worksheet.getRow(rows.length + 3);
  const totalRowName = totalRow.getCell(1);
  totalRowName.value = 'ОБЩИЙ ИТОГ';
  totalRowName.border = border;
  totalRowName.alignment = { vertical: 'middle' };
  fields.forEach((f, index) => {
    const field = totalRow.getCell(index + 3);
    field.value = `${totalRowValues[`${f}Major`]} руб. ${totalRowValues[`${f}Minor`]} коп.`;
    field.border = border;
    field.alignment = { vertical: 'middle' };
  });
};

const createTotalSheet = (application, prevStepApplication, worksheet) => {
  const totalValues = getTotalValue(application, prevStepApplication);

  for (let i = 1; i <= 8; i += 1) {
    worksheet.getColumn(i).font = {
      name: 'Montserrat',
    };
    if (i === 1) {
      worksheet.getColumn(i).width = 5;
    }
    if (i === 2) {
      worksheet.getColumn(i).width = 40;
    }
    if ([3, 4, 5, 6, 7].includes(i)) {
      worksheet.getColumn(i).width = 30;
    }
    if (i === 8) {
      worksheet.getColumn(i).width = 80;
    }
  }
  const A1 = worksheet.getCell('A1');
  A1.value = '№';

  const B1 = worksheet.getCell('B1');
  B1.value = 'Направления расходования денежных средств Гранта';

  const C1 = worksheet.getCell('C1');
  C1.value = 'Сумма перечисленного гранта, всего на конец отчетного периода, руб';

  const D1 = worksheet.getCell('D1');
  D1.value = 'Расходы на начало отчетного периода, руб';

  const E1 = worksheet.getCell('E1');
  E1.value = 'Фактические расходы Гранта, руб.';

  const F1 = worksheet.getCell('F1');
  F1.value = 'Кассовые расходы Гранта, руб.';

  const G1 = worksheet.getCell('G1');
  G1.value = 'Остаток на конец отчетного периода, руб.';

  const H1 = worksheet.getCell('H1');
  H1.value = 'Причина возникшего остатка (комментарий)';
  for (let i = 0; i <= 5; i += 1) {
    const row = totalValues[i];
    const rowXLS = worksheet.getRow(i + 2);
    const totalFields = ['orderIndex', 'name', 'money2022', 'moneySpentLastPeriodStart', 'actualSum', 'paperSum', 'moneyLeftPeriodEnd', 'comment'];
    totalFields.forEach((f, index) => {
      if (index === 0 || index === 1 || index === 7) {
        const field = rowXLS.getCell(index + 1);
        field.value = row[f];
        field.border = border;
        field.alignment = { vertical: 'middle' };
      } else {
        const field = rowXLS.getCell(index + 1);
        field.value = `${row[`${f}Major`]} руб. ${row[`${f}Minor`]} коп.`;
        field.border = border;
        field.alignment = { vertical: 'middle' };
      }
    });
  }
};

const createXLSDocument = async (application, prevStepApplication) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Admin';
  workbook.lastModifiedBy = 'Администратор';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  if (prevStepApplication) {
    const worksheet = workbook.addWorksheet(`ФОТ ${prevStepApplication.stepNumber} ЭТАП`);
    createFotTable(prevStepApplication.financialSalary_ids || [], worksheet);
  }

  if (application) {
    const worksheet = workbook.addWorksheet(`ФОТ ${application.stepNumber} ЭТАП`);
    createFotTable(application.financialSalary_ids || [], worksheet);
  }

  const worksheet = workbook.addWorksheet(`ИТОГ ${application.stepNumber} ЭТАП`);
  createTotalSheet(application, prevStepApplication, worksheet);

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};

const arrayBufferToBase64 = (Arraybuffer) => {
  let binary = '';
  const bytes = new Uint8Array(Arraybuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = window.btoa(binary);
  const linkSource = `data:application/xls;base64,${base64String}`;
  const downloadLink = document.createElement('a');
  const fileName = 'Отчет ФОТ.xls';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const onExportXLS = async (application, prevStepApplication) => {
  const buffer = await createXLSDocument(application, prevStepApplication);
  arrayBufferToBase64(buffer);
};


export default onExportXLS;
