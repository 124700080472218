import React, { useEffect } from 'react';
import { Form, InputNumber } from 'antd';


const fields = [
  { name: 'fot', label: 'Оплата труда работников (включая расходы на оплату страховых взносов на ФОТ)' },
  { name: 'material', label: 'Расходы на приобретение материалов и комплектующих, необходимых для реализации проекта' },
  { name: 'equipment', label: 'Расходы, на приобретение оборудования и иного имущества, необходимых для реализации Проекта' },
  { name: 'other', label: 'Прочие прямые расходы' },
  { name: 'paper', label: 'Накладные и общехозяйственные расходы, предусмотренные учетной политикой организации' },
  { name: 'people', label: 'Оплата работ, выполняемых Соисполнителями' },
];


const getRow = (index, applicationProp, disabled, onSumChange, percent) => {
  // Лимиты сметы
  // fot: 70
  // material + equipment: 80
  // other: 40
  // paper: 20
  // people: 60

  const row = fields[index - 1];

  const limit = () => {
    switch (row.name) {
      case 'fot':
        return 70;
      case 'material':
        return 80;
      case 'equipment':
        return 80;
      case 'other':
        return 40;
      case 'paper':
        return 20;
      case 'people':
        return 60;
      default:
        return 0;
    }
  };

  const isOverflow = () => {
    if (row.name === 'material' || row.name === 'equipment') {
      const total = parseFloat(applicationProp.materialPercent || 0) + parseFloat(applicationProp.equipmentPercent || 0);
      return total > limit();
    }

    return percent > limit();
  };

  const percentages = () => {
    let currentPercent = percent;
    if (row.name === 'material' || row.name === 'equipment') {
      currentPercent = parseFloat(applicationProp.materialPercent || 0) + parseFloat(applicationProp.equipmentPercent || 0);
    }
    return `${currentPercent}% / ${limit()}%`;
  };

  return (
    <div className="common-table-row" style={{ border: isOverflow() ? '1px solid red' : 'none' }}>
      <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }}>
        {index}
      </div>
      <div className="common-table-row-cell" style={{ width: '25%', textAlign: 'left' }}>
        {row.label}
        {' '}
        {isOverflow() && (
        <span style={{
          color: 'red', fontWeight: 'bold', fontSize: '9px',
        }}
        >
          {percentages()}
        </span>
        )}
      </div>
      <div className="common-table-row" style={{ width: '70%' }}>
        <div
          className="common-table-row-cell"
          style={{
            width: '30%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
          }}
        >
          <Form.Item
            style={{ width: '55%' }}
            name={`${row.name}TotalMajor`}
            initialValue={applicationProp[`${row.name}TotalMajor`] || 0}
          >
            <InputNumber disabled />

          </Form.Item>
          <span>руб.</span>
          <Form.Item
            style={{ width: '25%' }}
            name={`${row.name}TotalMinor`}
            initialValue={applicationProp[`${row.name}TotalMinor`] || 0}
          >
            <InputNumber disabled />
          </Form.Item>
          <span>коп.</span>
        </div>
        <div
          className="common-table-row-cell"
          style={{
            width: applicationProp.projectTerm === '2' ? '30%' : '20%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
          }}
        >
          <Form.Item
            style={{ width: '55%' }}
            name={`${row.name}2023Major`}
            initialValue={applicationProp[`${row.name}2023Major`] || 0}
          >
            <InputNumber disabled min={0} onChange={() => onSumChange()} />
          </Form.Item>
          <span>руб.</span>
          <Form.Item
            style={{ width: '25%' }}
            name={`${row.name}2023Minor`}
            initialValue={applicationProp[`${row.name}2023Minor`] || 0}
          >
            <InputNumber disabled max={99} min={0} onChange={() => onSumChange()} />
          </Form.Item>
          <span>коп.</span>
        </div>
        {(applicationProp.projectTerm === '2' || applicationProp.projectTerm === '3') && (
          <div
            className="common-table-row-cell"
            style={{
              width: applicationProp.projectTerm === '3' ? '20%' : '30%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
          >
            <Form.Item
              style={{ width: '55%' }}
              name={`${row.name}2024Major`}
              initialValue={applicationProp[`${row.name}2024Major`] || 0}
            >
              <InputNumber disabled min={0} onChange={() => onSumChange()} />
            </Form.Item>
            <span>руб.</span>
            <Form.Item
              style={{ width: '25%' }}
              name={`${row.name}2024Minor`}
              initialValue={applicationProp[`${row.name}2024Minor`]}
            >
              <InputNumber disabled max={99} min={0} onChange={() => onSumChange()} />
            </Form.Item>
            <span>коп.</span>
          </div>
        )}
        {applicationProp.projectTerm === '3' && (
          <div
            className="common-table-row-cell"
            style={{
              width: '20%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
          >
            <Form.Item
              style={{ width: '55%' }}
              name={`${row.name}2025Major`}
              initialValue={applicationProp[`${row.name}2025Major`] || 0}
            >
              <InputNumber disabled min={0} onChange={() => onSumChange()} />
            </Form.Item>
            <span>руб.</span>
            <Form.Item
              style={{ width: '25%' }}
              name={`${row.name}2025Minor`}
              initialValue={applicationProp[`${row.name}2025Minor`] || 0}
            >
              <InputNumber disabled max={99} min={0} onChange={() => onSumChange()} />
            </Form.Item>
            <span>коп.</span>
          </div>
        )}
        <div
          className="common-table-row-cell"
          style={{
            width: '10%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
          }}
        >
          <Form.Item
            // style={{ width: '10%' }}
            name={`${row.name}Percent`}
            initialValue={applicationProp[`${row.name}Percent`]}
          >
            <InputNumber disabled />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default getRow;
