/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Popover, Popconfirm, Button, Checkbox,
} from 'antd';
import getRegApplicationsTag from './getRegApplicationsTag';


const getRegApplicationsColumns = (onDecide) => [
  {
    title: 'Организация',
    dataIndex: 'shortName',
    key: 'shortName',
    width: '20%',
    render: (text, object) => (
      <Popover
        content={[
          <Popconfirm
            key="yes"
            title="Вы уверены в выборе ответа?"
            onConfirm={() => onDecide(true, object.id, null, object.isDzm)}
            okText="Да"
            cancelText="нет"
          >
            <Button type="primary" style={{ marginRight: '10px', backgroundColor: 'green', borderColor: 'green' }}>Согласовать</Button>
          </Popconfirm>,
          <Popconfirm
            key="no"
            title="Вы уверены в выборе ответа?"
            onConfirm={() => onDecide(false, object.id, null, false)}
            okText="Да"
            cancelText="нет"
          >
            <Button type="danger" style={{ marginRight: '10px' }}>Отклонить (Иные)</Button>
          </Popconfirm>,
          <Popconfirm
            key="no"
            title="Вы уверены в выборе ответа?"
            onConfirm={() => onDecide(false, object.id, null, true)}
            okText="Да"
            cancelText="нет"
          >
            <Button type="primary">Отклонить (Федеральная больница)</Button>
          </Popconfirm>,
          <Popconfirm
            key="no"
            title="Вы уверены в выборе ответа?"
            onConfirm={() => onDecide(false, object.id, true, true)}
            okText="Да"
            cancelText="нет"
          >
            <Button type="primary" style={{ margin: '0px 10px' }}>Отклонить (ДЗМ, Общий ЛК)</Button>
          </Popconfirm>,
          <Popconfirm
            key="yes"
            title="Вы уверены в выборе ответа?"
            onConfirm={() => onDecide(true, object.id, null, object.isDzm, true)}
            okText="Да"
            cancelText="нет"
          >
            <Button type="danger">Откатить регистрацию</Button>
          </Popconfirm>,
        ]}
        trigger="click"
      >
        <a>{text}</a>
      </Popover>
    ),
  },
  {
    title: 'ФИО',
    dataIndex: 'fio',
    key: 'fio',
    render: (_, object) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{object.lastName}</div>
        <div>{object.firstName}</div>
        <div>{object.middleName}</div>
      </div>
    ),
  },
  {
    title: 'ДЗМ',
    dataIndex: 'isDzm',
    key: 'isDzm',
    render: (value) => (value ? <Checkbox defaultChecked={value} disabled /> : null),
  },
  {
    title: 'Должность',
    dataIndex: 'position',
    key: 'position',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: (text) => getRegApplicationsTag(text),
    // onFilter: (value) => {
    //   onFilter(value);
    //   return true;
    // },
    // filters: ['На согласовании', 'Отклонено', 'Согласовано'].map((s) => ({ text: s, value: s })),
  },
  {
    title: 'Пароль',
    dataIndex: 'passwordView',
    key: 'passwordView',
  },
  {
    title: 'Регистрация',
    dataIndex: 'regDate',
    key: 'regDate',
    render: (text) => (text ? text.format('DD.MM.YYYY') : ''),
  },
];

export default getRegApplicationsColumns;
