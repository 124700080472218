import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
} from 'antd';
import { simpleFields } from './consts';


const { TextArea } = Input;
function ProjectTextArea({
  disabled, title, hint, label, fieldName, defaultValue, placeholder, isShowCounter,
}) {
  const [fieldLength, setFieldLength] = useState(0);
  const [minLengthRequired] = useState(simpleFields.find((f) => f.field === fieldName)?.min);

  useEffect(() => {
    setFieldLength(defaultValue.length);
  }, []);


  const onChangeTextArea = (value) => setFieldLength(value.length);

  return (
    <div className="application-main-box-content-wrapper">
      {title && <span className="application-main-box-label" style={{ fontWeight: 'bold', fontSize: '16px' }}>{title}</span>}
      <span className="application-main-box-label">{label}</span>
      {hint && <span className="project-hint">{hint}</span>}
      <Form.Item
        name={fieldName}
        initialValue={defaultValue}
      >
        <TextArea
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => onChangeTextArea(e.target.value)}
          autoSize
        />
      </Form.Item>
      {isShowCounter && (
      <span
        className="common-field-hint"
        style={{
          marginTop: '3px', zIndex: 1, fontSize: '11px', color: fieldLength < minLengthRequired ? 'red' : '#212121',
        }}
      >
        {`${fieldLength} из ${minLengthRequired}`}
      </span>
      )}
    </div>
  );
}

ProjectTextArea.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isShowCounter: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
};

ProjectTextArea.defaultProps = {
  isShowCounter: false,
  defaultValue: '',
  placeholder: '',
  title: '',
  hint: '',
  label: '',
};

export default ProjectTextArea;
