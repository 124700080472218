/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { orderBy, wrap } from 'lodash';
import { notification, Input, Select, Table, Alert } from 'antd';
import ExcelJS from 'exceljs';
import moment from 'moment';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';
import getUserId from '../../../../../../utils/localeStorage/getUserId';
import {
  getAllGrantsApplications2,
  getApplications2,
} from '../../../../../../utils/http';
import getColumns from './getColumns';
import getAdminColumns from './getAdminColumns';
import scientificDirectionCatalogue from '../../../../../ApplicationStep2/Common/ShortTab/scientificDirectionCatalogue';
import './ApplicationsStep2.css';

const selectStatusOptions = [
  {
    id: 'Черновик',
    label: 'Черновик',
    value: 'Черновик',
  },
  {
    id: 'На обработке',
    label: 'На обработке',
    value: 'На обработке',
  },
  {
    id: 'На доработке',
    label: 'На доработке',
    value: 'На доработке',
  },
  {
    id: 'Принята к рассмотрению',
    label: 'Принята к рассмотрению',
    value: 'Принята к рассмотрению',
  },
  {
    id: 'В архиве',
    label: 'В архиве',
    value: 'В архиве',
  },
  {
    id: 'Предварительно оценена',
    label: 'Предварительно оценена',
    value: 'Предварительно оценена',
  },
];
const { Search } = Input;
function ApplicationsStep2() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDatasource] = useState([]);
  const [selectStatusValue, setSelectedStatusValue] = useState([
    'Черновик',
    'На обработке',
    'На доработке',
    'Принята к рассмотрению',
    'В архиве',
    'Предварительно оценена'
  ]);
  const [selectScientificDirectionnValue, setSelectScientificDirectionnValue] =
    useState([]);
  const [searchText, setSearchText] = useState(null);

  const excludedUserIds = [
    'ШамаловНА',
    'СкрыпникДВ',
  ];

  const onGetData = (text, status, direction) => {
    setLoading(true);

    const userId = getUserId();
    const isNoWebUser = getUserRole() === 'bigAdmin' || excludedUserIds.includes(getUserId())

    const searchObj = {
      searchText: text,
      status: status.join(','),
      scientificDirection: direction.join(','),
      webUserId: isNoWebUser ? null : userId,
    };
    getApplications2({ webUserId: userId, pageSize: '60', ...searchObj })
      .then((applications) => {
        setLoading(false);
        setDatasource(applications);
      })
      .catch((error) =>
        notification.error({ message: 'Ошибка!', description: error.message })
      );
  };

  const fetchSpecificApplications = async (ids) => {
    const reqs = []

    for (const id of ids) {

      reqs.push(getApplications2({ pageSize: '1', webUserId: null, searchText: id }))
    }

    const resp = await Promise.all(reqs)
    setDatasource(resp.flat())

    setLoading(false);
  }

  useEffect(() => {
    if (excludedUserIds.includes(getUserId())) {
      if (getUserId() === 'ШамаловНА') {
        fetchSpecificApplications(['1609-2', '3009-5'])
      }

      if (getUserId() === 'СкрыпникДВ') {
        fetchSpecificApplications(['1908-6', '0309-1'])
      }

      return
    }

    onGetData(searchText, selectStatusValue, selectScientificDirectionnValue);
  }, []);

  const onSearch = (value) => {
    setSearchText(value);
    onGetData(value, selectStatusValue, selectScientificDirectionnValue);
  };
  const onChangeStatus = (value) => {
    setSelectedStatusValue(value);
    onGetData(searchText, value, selectScientificDirectionnValue);
  };
  const onChangeDirection = (value) => {
    setSelectScientificDirectionnValue(value);
    onGetData(searchText, selectStatusValue, value);
  };

  const generateXLS = async (data) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Admin';
    workbook.lastModifiedBy = 'Администратор';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    console.log(data)

    const cols = [
      {
        header: '№',
        key: 'index',
        width: 7,
      },
      {
        header: 'Номер заявки',
        key: 'displayID',
        width: 15,
      },
      {
        header: 'Статус',
        key: 'status',
        width: 15,
      },
      {
        header: 'Тема Проекта',
        key: 'projectName',
        width: 30,
      },
      {
        header: 'Организация-грантополучатель (полное наименование)',
        key: 'organisationFullName',
        width: 35,
      },
      {
        header: 'Организация-грантополучатель (сокращенное наименование)',
        key: 'organisationShortName',
        width: 35,
      },
      {
        header: 'Научный руководитель проекта',
        key: 'supervisorFullName',
        width: 35,
      },
      {
        header: 'Направление работы',
        key: 'scientificDirection',
        width: 35,
      },
      {
        header: 'Тип продукта',
        key: 'resultType',
        width: 35,
      },
      {
        header: 'Группа продукта',
        key: 'productGroup',
        width: 35,
      },
      {
        header: 'Описание продукта',
        key: 'productDescription',
        width: 35,
      },
      {
        header: 'Краткая аннотация',
        key: 'projectAnnotation',
        width: 65,
      },
      {
        header: 'Срок выполнения, полных лет',
        key: 'projectTerm',
        width: 35,
      },
      {
        header: 'Запрашиваемая сумма всего, руб ',
        key: 'financeTotal',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Сумма на 2025 год всего, руб',
        key: 'financeTotal2023',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Сумма на 2026 год всего, руб',
        key: 'financeTotal2024',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Сумма на 2027 год всего, руб',
        key: 'financeTotal2025',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Запрашиваемая сумма на больницу всего, руб (только больница)',
        key: 'hospitalTotal',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Количество средств на всех соисполнителей всего, руб',
        key: 'peopleTotal',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Сумма на больницу на 2025 год, руб',
        key: 'hospital2023',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Сумма на больницу на 2026 год, руб',
        key: 'hospital2024',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Сумма на больницу на 2027 год, руб',
        key: 'hospital2025',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Ожидаемые результаты работ',
        key: 'result',
        width: 65,
      },
      {
        header: 'Задачи проекта',
        key: 'applications2InfoTabTasks_ids',
        width: 35,
      },
      {
        header: 'Количество планируемых публикаций, всего',
        key: 'numberOfPublicationsTotal',
        width: 35,
      },
      {
        header: 'Количество планируемых РИД, всего',
        key: 'numberOfRID',
        width: 35,
      },
      {
        header: 'Количество планируемых мероприятий, всего',
        key: 'numberOfEvents',
        width: 35,
      },
      {
        header:
          'Количество пациентов, которым поможет предлагаемое решение в Москве',
        key: 'epidemicMoscow',
        width: 35,
      },
      {
        header:
          'Количество пациентов, которым поможет предлагаемое решение в России',
        key: 'epidemicRussia',
        width: 35,
      },
      {
        header: 'Планируетлся получение Регистрационного удостоверения',
        key: 'isRegPaper',
        width: 35,
      },
      {
        header:
          'Перечень ключевых членов коллектива специалистов (ФИО и должности)',
        key: 'applications2AuthorTabPeople_ids',
        width: 60,
      },
      {
        header: 'Соисполнители',
        key: 'peoples',
        width: 60,
      },
      {
        header: 'Количество средств на всех соисполнителей всего, руб',
        key: 'peopleTotal_duplicate',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Сумма всех соисполнителей на 2025 год, руб',
        key: 'people2023',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Сумма всех соисполнителей на 2026 год, руб',
        key: 'people2024',
        type: 'totals',
        width: 35,
      },
      {
        header: 'Сумма всех соисполнителей на 2027 год, руб',
        key: 'people2025',
        type: 'totals',
        width: 35,
      },
    ];

    const worksheet = workbook.addWorksheet('Список заявок на гранты');

    const headers = [];
    const rows = [];

    cols.forEach((col) => {
      headers.push({
        header: col.header,
        key: col.key,
        width: col.width,
      });
    });

    const coexecutorsMaxCount = Math.max(
      ...data.map((el) => el.applications2PersonsTabPeople_ids?.length)
    );

    for (let i = 0; i < coexecutorsMaxCount; i++) {
      headers.push({
        header: `Соисполнитель_${i + 1}`,
        key: `org_${i + 1}`,
        width: 30,
      });
      headers.push({
        header: `Вид организации_${i + 1}`,
        key: `type_${i + 1}`,
        width: 30,
      });
      headers.push({
        header: `Наименование работ Соисполнитель_${i + 1}`,
        key: `theme_${i + 1}`,
        width: 30,
      });
      headers.push({
        header: `Количество этапов Соисполнитель_${i + 1}`,
        key: `steps_${i + 1}`,
        width: 30,
      });
      headers.push({
        header: `Сумма на Соисполнителя на 2025 год, руб_${i + 1}`,
        key: `total2025_${i + 1}`,
        width: 30,
      });
      headers.push({
        header: `Сумма на Соисполнителя на 2026 год, руб_${i + 1}`,
        key: `total2026_${i + 1}`,
        width: 30,
      });
      headers.push({
        header: `Сумма на Соисполнителя на 2027 год, руб_${i + 1}`,
        key: `total2027_${i + 1}`,
        width: 30,
      });
    }

    const calcTotalByKeys = (el, keys) => {
      let sum = 0;

      keys.forEach((key) => {
        sum +=
          parseFloat(
            `${el[`${key}Major`] || 0}.${
              `${el[`${key}Minor`]}`.length === 1
                ? `0${el[`${key}Minor`]}`
                : el[`${key}Minor`]
            }`
          ) * 100;
      });

      const major =
        Math.trunc(sum / 100) === 0 && sum < 0
          ? `-0${Math.trunc(sum / 100)}`
          : Math.trunc(sum / 100);
      const minor = Number((sum % 100).toFixed(0));

      if (major === 0) {
        return '';
      }

      return parseFloat(
        `${major}.${minor ? String(minor).padStart(2, '0') : '0'}`
      );
    };

    data.forEach((el, index) => {
      const getHospitalTotalsByKey = (key) => {
        switch (key) {
          case 'hospitalTotal':
            return calcTotalByKeys(el, [
              'fotTotal',
              'equipmentTotal',
              'materialTotal',
              'otherTotal',
              'paperTotal',
            ]);
          case 'hospital2023':
            return calcTotalByKeys(el, [
              'fot2023',
              'equipment2023',
              'material2023',
              'other2023',
              'paper2023',
            ]);
          case 'hospital2024':
            return calcTotalByKeys(el, [
              'fot2024',
              'equipment2024',
              'material2024',
              'other2024',
              'paper2024',
            ]);
          case 'hospital2025':
            return calcTotalByKeys(el, [
              'fot2025',
              'equipment2025',
              'material2025',
              'other2025',
              'paper2025',
            ]);
          default:
            return '';
        }
      };

      const getTotals = (key) => {
        if (key.includes('hospital')) {
          return getHospitalTotalsByKey(key);
        }

        if (key === 'peopleTotal_duplicate') {
          key = 'peopleTotal';
        }

        if (typeof el[`${key}Major`] !== 'number') {
          return '';
        }

        const major = el[`${key}Major`];
        const minor =
          typeof el[`${key}Minor`] === 'number'
            ? String(el[`${key}Minor`]).padStart(2, '0')
            : 0;

        return parseFloat(`${major}.${minor}]`);
      };

      const getAuthors = () => {
        const list = [];

        if (el.supervisorFullName) {
          list.push(
            `${el.supervisorFullName} - ${el.supervisorPosition || ''}`
          );
        }

        el.applications2AuthorTabPeople_ids?.forEach((author) => {
          if (author?.fullName) {
            list.push(
              `${author?.fullName} - ${author?.position || 'Не указано'}`
            );
          }
        });

        return list.join(',\n');
      };

      const values = {};

      cols.forEach((col) => {
        if (col.key === 'index') {
          values[`${col.key}`] = index + 1;
        } else if (col.type === 'totals') {
          values[`${col.key}`] = getTotals(col.key);
        } else if (col.key === 'applications2AuthorTabPeople_ids') {
          values[`${col.key}`] = getAuthors();
        } else if (col.key === 'applications2InfoTabTasks_ids') {
          values[`${col.key}`] = el.applications2InfoTabTasks_ids
            .map((el) => el.task)
            .join(',\n');
        } else if (col.key === 'peoples') {
          values[`${col.key}`] = el.applications2PersonsTabPeople_ids
            .map((el) => el.shortName)
            .join(',\n');
        } else if (col.key === 'productGroup') {
          values[`${col.key}`] = el[`${col.key}`] !== '' ? JSON.parse(el[`${col.key}`]).join(', ') : '';
        } else {
          values[`${col.key}`] = Array.isArray(el[`${col.key}`]) ? el[`${col.key}`].join('\n') : el[`${col.key}`];
        }
      });

      // Coexecutors
      el.applications2PersonsTabPeople_ids?.forEach((org, orgIndex) => {
        const orgIndexStepsCount = () => {
          let count = 0;
          if (org.isStep1) {
            count += 1;
          }
          if (org.isStep2) {
            count += 1;
          }
          if (org.isStep3) {
            count += 1;
          }
          if (org.isStep4) {
            count += 1;
          }
          if (org.isStep5) {
            count += 1;
          }
          if (org.isStep6) {
            count += 1;
          }
          return count;
        };

        const total2025 = () => {
          let sum = 0;
          if (org.isStep1) {
            sum += org.step1Sum || 0;
          }

          if (org.isStep2) {
            sum += org.step2Sum || 0;
          }

          return sum;
        };

        const total2026 = () => {
          let sum = 0;
          if (org.isStep3) {
            sum += org.step3Sum || 0;
          }

          if (org.isStep4) {
            sum += org.step4Sum || 0;
          }

          return sum;
        };

        const total2027 = () => {
          let sum = 0;
          if (org.isStep5) {
            sum += org.step5Sum || 0;
          }

          if (org.isStep6) {
            sum += org.step6Sum || 0;
          }

          return sum;
        };

        if (org.fullName) {
          values[`org_${orgIndex + 1}`] = org.fullName || '';
          values[`type_${orgIndex + 1}`] = org.type || '';
          values[`theme_${orgIndex + 1}`] = org.theme || '';
          values[`steps_${orgIndex + 1}`] = orgIndexStepsCount();

          values[`total2025_${orgIndex + 1}`] = total2025();
          values[`total2026_${orgIndex + 1}`] = total2026();
          values[`total2027_${orgIndex + 1}`] = total2027();
        }
      });

      rows.push(values);
    });

    worksheet.columns = headers;

    rows.forEach((row) => {
      worksheet.addRow(row);
    });

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
      });
    });

    const buffer = await workbook.xlsx.writeBuffer({ useStyles: true });
    return buffer;
  };

  const exportApplications = () => {
    if (loading) return;

    setLoading(true);
    getAllGrantsApplications2({ year: 2024, pageSize: 2000 })
      .then(async (applications) => {
        const buffer = await generateXLS(
          applications.filter((el) => !el.isDeleted)
        );
        setLoading(false);
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Заявки на гранты 2024.xlsx';
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.log(error);
        notification.error({ message: 'Ошибка!', description: error.message });
      });
  };

  return (
    <div className="applications-wrapper">
      {!excludedUserIds.includes(getUserId()) && (
        <>
          <div className="applications-buttons-wrapper">
            <div className="applications-button-wrapper">
              <div>Поиск</div>
              <Search
                placeholder="Поиск по номеру, теме..."
                style={{ width: '100%' }}
                onSearch={onSearch}
              />
            </div>
            {getUserRole() !== 'noDSM' && (
              <div className="applications-button-wrapper">
                <div>Фильтр по статусу</div>
                <Select
                  allowClear
                  mode="multiple"
                  style={{ width: '100%' }}
                  value={selectStatusValue}
                  options={selectStatusOptions}
                  onChange={(newValue) => onChangeStatus(newValue)}
                  placeholder="Выберите статус..."
                  maxTagCount="responsive"
                />
              </div>
            )}
            {getUserRole() !== 'noDSM' && (
              <div className="applications-button-wrapper">
                <div>Фильтр по направлению</div>
                <Select
                  allowClear
                  mode="multiple"
                  style={{ width: '100%' }}
                  value={selectScientificDirectionnValue}
                  options={scientificDirectionCatalogue.map((sp) => ({
                    label: sp,
                    value: sp,
                  }))}
                  onChange={(newValue) => onChangeDirection(newValue)}
                  placeholder="Выберите направление..."
                  maxTagCount="responsive"
                />
              </div>
            )}
          </div>
          {getUserRole() !== 'bigAdmin' && (
            <Alert
              type="error"
              style={{ margin: '-12px 0px 20px 0px', width: '75%' }}
              description={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <b>Внимание! Срок сбора заявок завершен.</b>
                  {/* <br />
              <div>
                Срок
                {' '}
                <b>подачи заявок</b>
                {' '}
                на получение финансовой поддержки для реализации актуальных научно-практических проектов в сфере медицины
                <b> продлен до 10 октября 2024 года (включительно).</b>
                <br />
                <br />
                Чтобы принять участие в&nbsp;конкурсном отборе в&nbsp;рамках заявочной кампании 2024&nbsp;года, необходимо до&nbsp;10&nbsp;октября подать заявку на&nbsp;получение финансовой поддержки для реализации научно-практического проекта в&nbsp;сфере здравоохранения и&nbsp;медицины. Подготовьте и&nbsp;загрузите заверенную печатью и&nbsp;подписанную руководителем медицинского учреждения заявку в&nbsp;поле &laquo;Загрузить подписанную заявку и&nbsp;направить на&nbsp;рассмотрение&raquo; в&nbsp;карточке соответствующей заявки. Первичная обработка заявки по&nbsp;формальным критериям займёт до&nbsp;5&nbsp;рабочих дней, после чего заявка может быть отправлена на&nbsp;доработку. Повторная подача исправленной заявки будет возможна до&nbsp;23:59 10&nbsp;октября 2024&nbsp;года.
                {' '}
                <br />
                <br />
                Пожалуйста, учитывайте это при подаче заявки!
              </div>
              <br />
              Объявление победителей запланировано на 29 ноября 2024 года. */}
                </div>
              }
            />
          )}
        </>
      )}

      <div className="applications-table-wrapper ">
        {getUserRole() === 'bigAdmin' && (
          <button
            disabled={loading}
            type="button"
            className="ant-btn ant-btn-primary"
            onClick={exportApplications}
          >
            Экспортировать все заявки на гранты за 2024 год
          </button>
        )}
        <Table
          dataSource={orderBy(dataSource, 'status', 'desc')}
          columns={
            getUserRole() === 'bigAdmin' ? getAdminColumns() : getColumns()
          }
          loading={loading}
          scroll={{ y: '50vh' }}
          pagination={false}
          rowKey="id"
        />
      </div>
    </div>
  );
}

// ApplicationsStep2.propTypes = {
//   history: PropTypes.shape().isRequired,
// };

export default ApplicationsStep2;
