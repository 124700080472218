/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Row, Col, Button, InputNumber, Form, notification,
} from 'antd';
import PersonPaymentStep from '../../../AppDZMMain/components/Persons/PersonPaymentStep';
import { editApplicationPersons } from '../../../../../utils/http';


function PaymentModule({ payment, isAppDisabled }) {
  const [isShowBlock, setIsShowBlock] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [isShowAdditionalPayment, setIsShowAdditionalPayment] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    if (payment.stepNDate || payment.stepNRequisites || payment.stepNTransfered || payment.stepNComment) {
      setIsShowAdditionalPayment(true);
    }
    formRef.current.setFieldsValue(payment);
    setDisabled(isAppDisabled);
  }, []);

  const onSave = () => {
    const values = formRef.current.getFieldsValue();
    setDisabled(true);
    editApplicationPersons(values)
      .then(() => {
        notification.success({ message: 'Успех', description: 'Изменения сохранены' });
        setDisabled(false);
      });
  };

  return (
    <Card
      style={{
        width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
      }}
    >
      <Row justify="space-between" align="middle">
        <Col style={{ fontSize: '20px', color: '#212121' }}>Сведения об оплатах</Col>
        <Col style={{ cursor: 'pointer' }} onClick={() => setIsShowBlock(!isShowBlock)}>
          {isShowBlock ? <Button>Закрыть</Button> : <Button>Открыть</Button>}
        </Col>
      </Row>
      {isShowBlock && payment && (
      <Form ref={formRef}>
        <Form.Item
          name="id"
          initialValue={payment.id}
          hidden
        />
        <Col span={24}>
          <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
            <Col span={3} style={{ fontSize: '1.5vh' }}>
              Доведено средств ИТОГО по всем этапам
            </Col>
            <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
              <Form.Item
                name="allStepTransferedTotal"
                initialValue={payment.allStepTransferedTotal}
              >
                <InputNumber disabled={disabled} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          {Array(payment.maxStepsNumber).fill(0).map((_, index) => (
            <Col span={24}>
              <Row style={{ color: '#212121', fontSize: '1.4rem', marginBottom: '10px' }}>{`Этап ${index + 1}`}</Row>
              <PersonPaymentStep
                key={index + 1}
                isEdit
                person={payment}
                disabled={false}
                fieldNameDate={`step${index + 1}Date`}
                fieldNameRequisites={`step${index + 1}Requisites`}
                fieldNameTransfered={`step${index + 1}Transfered`}
                fieldNameComment={`step${index + 1}Comment`}
                fieldNameDateClose={`step${index + 1}DateClose`}
                fieldNameRequisitesClose={`step${index + 1}RequisitesClose`}
                fieldNameTransferedClose={`step${index + 1}TransferedClose`}
                fieldNameCommentClose={`step${index + 1}CommentClose`}
                fieldNameTransferedTotal={`step${index + 1}TransferedTotal`}
              />
            </Col>
          ))}

          {!isShowAdditionalPayment && <Button type="primary" style={{ marginBottom: '20px' }} onClick={() => setIsShowAdditionalPayment(true)}>Добавить оплату</Button>}

          {isShowAdditionalPayment && (
          <Col span={24}>
            <Row style={{ color: '#212121', fontSize: '1.4rem', marginBottom: '10px' }}>Дополнительная оплата</Row>
            <PersonPaymentStep
              key="N"
              isShort
              isEdit
              person={payment}
              disabled={false}
              fieldNameDate="stepNDate"
              fieldNameRequisites="stepNRequisites"
              fieldNameTransfered="stepNTransfered"
              fieldNameComment="stepNComment"
            />
          </Col>
          )}
        </Col>
        <Button type="primary" onClick={onSave}>Сохранить</Button>
      </Form>
      )}
    </Card>
  );
}

PaymentModule.propTypes = {
  payment: PropTypes.shape().isRequired,
  isAppDisabled: PropTypes.bool.isRequired,
};

export default PaymentModule;
