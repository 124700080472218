/* eslint-disable max-len */
import React from 'react';


const checkListRowFields = [
  {
    index: '1.',
    title: 'Проверка требований к учреждению, в котором осуществляют трудовую деятельность коллектив специалистов.',
    description: <div />,
    comment: '-',
    isShowCheckbox: false,
    isShowDescription: false,
    isHeader: true,
    fieldName: null,
  },
  {
    index: '1.1.',
    title: 'Медицинская организация государственной системы здравоохранения осуществляет медицинскую деятельность на территории города Москвы.',
    description: <div>Описание: Необходимо дать оценку, проанализировав Сведения об организации-Заявителе на титульной странице Заявки, устав организации и юридический адрес организации.</div>,
    comment: 'Пример текста: ГБУЗ Московской области "Егорьевская Стоматологическая Поликлиника" согласно выписки из ЕГРЮЛ имеет юридический адрес: 140301, Московская область, город Егорьевск, Советская ул, д. 119/17.',
    isShowCheckbox: true,
    isShowDescription: true,
    isHeader: false,
    fieldName: 'row11',
  },
  {
    index: '1.2.',
    title: 'В учреждении сформированы коллективы специалистов, работающие или привлекаемые им, которые работают и внедряют  научно-практические проект.',
    description:
  <div>
    Описание: Необходимо дать оценку, проанализировав сведения, указанные в кратких сведениях о научном руководителе Проекта и контактных лицах, а также п.7 Заявки “Информация о коллективе специалистов” (см. pdf-файл, подписанной скан-копия Заявки).
  </div>,
    comment: 'Пример текста: В качестве научного руководителя указан медбрат. Более того, в Сведениях об ответственном контактном лице, отвечающем за предоставление финансовых данных по заявке и Проекту (смета, финансовый отчет) указана медсестра. (На портале грантовой программы данные изменения необходимо внести в раздел «Сведения о Заявителе и команде Проекта» и раздел 3.3 «Сведения о ключевых членах команды»).',
    isShowCheckbox: true,
    isShowDescription: true,
    isHeader: false,
    fieldName: 'row12',
  },
  {
    index: '2.',
    title: 'Проверка требований к членам коллективов специалистов.',
    description: <div />,
    comment: '-',
    isShowCheckbox: false,
    isShowDescription: false,
    isHeader: true,
    fieldName: null,
  },
  {
    index: '2.1.',
    title: 'Члены коллектива специалистов Проекта осуществляют трудовую деятельность в организации-Заявителе.',
    description: <div>Описание: Необходимо дать оценку, проанализировав сведения, указанные в п.7 Заявки “Информация о коллективе специалистов” (см. pdf-файл, подписанной скан-копия Заявки).</div>,
    comment: 'Пример текста: Не указана должность у Иванова И.И., Ермолова Г.П. (На портале грантовой программы данные необходимо внести в раздел «Сведения о Заявителе и команде Проекта» и в раздел 3.3 «Сведения о ключевых членах команды»).',
    isShowCheckbox: true,
    isShowDescription: true,
    isHeader: false,
    fieldName: 'row21',
  },
  {
    index: '2.2.',
    title: 'Наличие у членов коллектива опыта по специальности или направлению Проекта.',
    description: <div>Описание: Необходимо проверить наличие сведений, указанных в пп.7.4. Заявки “Сведения о ключевых членах команды Проекта”, в части указания опыта членов коллектива (см. pdf-файл, подписанной скан-копия Заявки).</div>,
    comment: 'Пример текста: Не указан опыт работы по специальности у Иванова И.И., Ермолова Г.П. и Филькина М.Д. (На портале грантовой программы данные вносятся в раздел «Сведения о Заявителе и команде Проекта» и в раздел 3.3 «Сведения о ключевых членах команды»).',
    isShowCheckbox: true,
    isShowDescription: true,
    isHeader: false,
    fieldName: 'row22',
  },
  {
    index: '2.3.',
    title: 'Специалисты являются гражданами Российской Федерации.',
    description: <div>Описание: Необходимо проверить наличие сведений о гражданстве членов коллектива, указанные в пп.7.4. Заявки “Сведения о ключевых членах команды Проекта” (см. pdf-файл, подписанной скан-копия Заявки), а также проверить сведения, указанные в соглашении на обработку персональных данных (см. файлы в папке “Сведения о контактных лицах” в сгенерированном архиве к Заявке).</div>,
    comment: 'Пример текста: Не указано гражданство у заявленного члена коллектива, а именно у Иванова И.И. (На портале грантовой программы данные вносятся в раздел «Сведения о Заявителе и команде Проекта» и  в раздел 3.2 «Сведения о научном руководителе Проекта» и 3.3 «Сведения о ключевых членах команды»).',
    isShowCheckbox: true,
    isShowDescription: true,
    isHeader: false,
    fieldName: 'row23',
  },
  {
    index: '3.',
    title: 'Проверка общих требований к заявке.',
    description: <div />,
    comment: '-',
    isShowCheckbox: false,
    isShowDescription: false,
    isHeader: true,
    fieldName: null,
  },
  {
    index: '3.1.',
    title: 'Обоснование запрашиваемого размера финансовой поддержки (с приложением финансового экономического обоснования и сметы расходов).',
    description:
  <div>
    Описание: Необходимо дать оценку, проанализировав наличие, полноту и корректность, представленной информацию заявителем в разделах Заявки, (см. pdf-файл, подписанной скан-копия Заявки и файлы в сгенерированном архиве):
    <ul>
      <li>“План-график реализации научно-практического проекта”;</li>
      <li>п.9 “Смета расходов научно-практического проекта”;</li>
      <li>пп. 9.1. “Оплата труда работников (включая расходы на оплату страховых взносов на ФОТ)”;</li>
      <li>пп. 9.2. “Расходы на приобретение материалов и комплектующих, необходимых для реализации Проекта”;</li>
      <li> пп. 9.3. “Расходы на приобретение оборудования и иного имущества, необходимых для реализации Проекта”;</li>
      <li>пп. 9.4. “Прочие прямые расходы”;</li>
      <li>пп. 9.5. “Накладные и общехозяйственные расходы, предусмотренные учетной политикой организации не предусмотрены”;</li>
      <li>Планируемых и ожидаемых результатов в п. 10 “План-график реализации научно-исследовательского проекта”;</li>
      <li>Планируемых и ожидаемых результатов работ организаций-соисполнителей, представленных в их план-графиках.</li>
    </ul>
  </div>,
    comment: 'Пример текста: В плане-графике не соответствуют планируемые и ожидаемые результаты работ, а именно: в заявленных результатах утверждено, что будет проанализировано 100 образцов жидкостной цитологией, а в ожидаемых результатах 40 образцов. Кроме того, в прочих прямых расходах некорректно отображено описание необходимости статей расходов. Также необходимо дать более подробное описание запланированных и ожидаемых результатов работ у организации-соисполнителя ООО “Медпрайм”. (На портале данные вносятся в раздел «Смета и план-график реализации проекта», в раздел 3.2 «Сведения о научном руководителе Проекта» и в раздел 3.3 «Сведения о ключевых членах команды»).',
    isShowCheckbox: true,
    isShowDescription: true,
    isHeader: false,
    fieldName: 'row31',
  },
  {
    index: '3.2.',
    title: 'Наличие согласия членов коллектива специалистов и контактных лиц на обработку их персональных данных.',
    description:
  <div>
    Необходимо дать оценку, проанализировав файлы в папке “Сведения о контактных лицах” в генерируемом архиве по Заявке.
    <br />
    Примечание: Если в Заявке указан общий номер телефона организации в качестве контактного номера для руководителя, то согласие на обработку персональных данных от него не требуется
  </div>,
    comment: 'Пример текста: К заявке не прикреплено согласие на обработку данных Иванова И.И. (на портале данные вносятся в раздел «Краткая информация о Проекте», разделы «Сведения о научном руководителе Проекта» - ЕСЛИ в заявке указан мобильный номер руководителя, а не общий городской учреждения, «Сведения о руководителе организации-Заявителя», «Сведения об ответственном контактном лице по заявке и Проекту (по общим вопросам)», «Сведения об ответственном контактном лице, отвечающем за предоставление финансовых данных по заявке и Проекту (смета, финансовый отчет)»)',
    isShowCheckbox: true,
    isShowDescription: true,
    isHeader: false,
    fieldName: 'row32',
  },
  {
    index: '3.3.',
    title: 'Заявка подписана руководителем организации и утверждена печатью.',
    description: <div>Описание: Необходимо проверить на последней странице Заявки подпись руководителя организации и наличие печати.</div>,
    comment: 'Пример текста: Заявка подана без согласования и подписи руководителя организации.',
    isShowCheckbox: true,
    isShowDescription: true,
    isHeader: false,
    fieldName: 'row33',
  },
  {
    index: '4.',
    title: 'Общие рекомендации к Заявке.',
    description: <div>(Данные рекомендации не являются обязательными)</div>,
    comment: 'Пример текста: В целях успешного прохождения конкурсного отбора рекомендуется дополнительно доработать следующую информацию: ...',
    isShowCheckbox: false,
    isShowDescription: true,
    isHeader: false,
    fieldName: 'row4',
  },
];

export default checkListRowFields;
// {
//     index: '',
//     title: '',
//     description: <div />,
//     comment: '',
//     isShowCheckbox: true,
//     isShowDescription: true,
//     isHeader: false,
//     fieldName: '',
//   },
