/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {
  Input, Table, message, Select, Modal, Alert, Button, Upload, Row, Divider, notification, Col,
} from 'antd';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';
import {
  getAllApplications, getSpecializationCatalogue, getScientificDirectionCatalogue, getAllApplicationsByUser, editApplication, getAllApplicationReports,
  uploadFile, editApplicationStepReport,
} from '../../../../../../utils/http';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';
import getUserId from '../../../../../../utils/localeStorage/getUserId';
import getApplicationsColumns from '../../utils/getApplicationsColumns';
import selectStatusOptions from '../../utils/selectStatusOptions';
import './Applications.less';


const pageSize = 60;
const { Dragger } = Upload;
const { Search, TextArea } = Input;
class Applications extends Component {
  state = {
    loading: true,
    dataSource: [],
    specializationCatalogue: [],
    scientificDirectionCatalogue: [],
    selectStatusValue: [],
    selectSpecializationValue: [],
    selectScientificDirectionnValue: [],
    selectPersonStartusValue: [],
    selectSearchText: '',
    selectedApplication: null,
    file: null,
    grantAppComment: null,
    isShowAnnotationReportPDFSigned: false,
    annotationReportPDFSigned: null,
    isShowFinancialReportPDFSigned: null,
    financialReportPDFSigned: null,
  }

  componentDidMount() {
    const { filterParams } = this.props;
    if (getUserRole() === 'user' || getUserRole() === 'noDSM') {
      Promise.all([
        getSpecializationCatalogue(),
        getScientificDirectionCatalogue()])
        .then((response) => {
          const isShowActive = filterParams.is2022 && filterParams.is2023;
          const selectedStatus = isShowActive ? ['Поддержана'] : [];
          this.setState({
            loading: false,
            specializationCatalogue: response[0],
            scientificDirectionCatalogue: response[1],
            selectStatusValue: selectedStatus,
          });
          this.onGetData('', selectedStatus, [], [], [], filterParams);
        })
        .catch((error) => message.error(error.message));
    }
    if (getUserRole() === 'bigAdmin') {
      Promise.all([
        getSpecializationCatalogue(),
        getScientificDirectionCatalogue(),
        getAllApplications()])
        .then((response) => {
          this.setState({
            loading: false,
            specializationCatalogue: response[0],
            scientificDirectionCatalogue: response[1],
            selectStatusValue: ['Поддержана'],
          });
          this.onGetData('', ['Поддержана'], [], [], [], filterParams);
        })
        .catch((error) => message.error(error.message));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectSearchText, selectStatusValue, selectScientificDirectionnValue, selectSpecializationValue, selectPersonStartusValue,
    } = this.state;
    const { filterParams } = this.props;
    if (prevState.selectSearchText !== selectSearchText
      || prevState.selectStatusValue !== selectStatusValue
      || prevState.selectSpecializationValue !== selectSpecializationValue
      || prevState.selectScientificDirectionnValue !== selectScientificDirectionnValue
      || prevState.selectPersonStartusValue !== selectPersonStartusValue
      || JSON.stringify(prevProps.filterParams) !== JSON.stringify(filterParams)
    ) {
      let selectedStatus = selectStatusValue;
      if (JSON.stringify(prevProps.filterParams) !== JSON.stringify(filterParams) && (getUserRole() === 'user' || getUserRole() === 'noDSM')) {
        const isShowActive = filterParams.is2022 && filterParams.is2023;
        selectedStatus = isShowActive ? ['Поддержана'] : [];
      }
      this.onGetData(selectSearchText, selectedStatus, selectScientificDirectionnValue, selectSpecializationValue, selectPersonStartusValue, filterParams);
    }
  }

  onGetData = (searchText, status, scientificDirection, specialization, agreementPersonsStatus, filterParams) => {
    this.setState({ loading: true });
    let statusValue = '';
    if (status.length > 0) {
      statusValue = status.join(',');
    } else if (filterParams.isArchive) {
      statusValue = selectStatusOptions.filter((s) => s.id !== 'Поддержана').map((s) => s.id).join(',');
    } else {
      statusValue = null;
    }

    const searchObj = {
      searchText,
      status: statusValue,
      scientificDirection: scientificDirection.length > 0 ? scientificDirection.join(',') : null,
      specialization: specialization.length > 0 ? specialization.join(',') : null,
      agreementPersonsStatus: agreementPersonsStatus.length > 0 ? agreementPersonsStatus.join(',') : null,
      isGrants2023: filterParams.is2023 && filterParams.is2022 ? null : filterParams.is2023,
    };
    if (getUserRole() === 'user' || getUserRole() === 'noDSM') {
      getAllApplicationsByUser({ webUserID: getUserId(), pageSize, ...searchObj }).then((dataSource) => this.setState({ loading: false, dataSource }));
    }
    if (getUserRole() === 'bigAdmin') {
      getAllApplications({ pageSize, ...searchObj }).then((r) => this.setState({ loading: false, dataSource: r.data }));
    }
  }

  onApplicationSelect = (selectedApplication) => {
    this.setState({ selectedApplication });
  }

  onCloseModal = () => {
    this.setState({ selectedApplication: null, file: null });
  }

  onSaveModal = () => {
    this.setState({ loading: true });
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const form = new FormData();
    const { selectedApplication, file, grantAppComment } = this.state;
    if (!grantAppComment || !file) {
      notification.error({ message: 'Заполните, пожалуйста, комментарий и прикрепите файл' });
      this.setState({ loading: false });
    } else {
      form.append('id', selectedApplication.id);
      form.append('grantAgreement_id', file);
      form.append('grantAppStatus', 'На согласовании администратора');
      editApplication(form, headers)
        .then(() => {
          this.setState({
            loading: false,
            selectedApplication: null,
          });
          window.location.reload();
        });
    }
  }

  onApproveGrantAgreement = () => {
    this.setState({ loading: true });
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const form = new FormData();
    const { selectedApplication } = this.state;
    form.append('id', selectedApplication.id);
    form.append('grantAppStatus', 'Согласовано');
    if (selectedApplication.grantAgreementLink) {
      editApplication(form, headers)
        .then(() => {
          this.setState({
            loading: false,
            selectedApplication: null,
          });
          window.location.reload();
        });
    } else {
      this.setState({
        loading: false,
        selectedApplication: null,
      });
    }
  }

  onFileUpload = (info) => {
    this.setState({ file: info.file.originFileObj });
  }

  removeFile = () => {
    this.setState({ file: null });
  }

  downloadAppFiles = (files) => {
    files.forEach((f) => window.open(f.urlLink, '_blank'));
  }

  onShowAnnotationReportPDFSigned = (id) => {
    this.setState({ isShowAnnotationReportPDFSigned: id });
  }

  onHideAnnotationReportPDFSigned = () => {
    this.setState({ isShowAnnotationReportPDFSigned: null });
  }

  onAnnotationReportPDFSignedUpload = (info) => {
    if (info.file.type !== 'application/pdf') {
      notification.error({ message: 'Ошибка! Загрузите, пожалуйста, файл в формате PDF' });
      return;
    }
    this.setState({ annotationReportPDFSigned: info.file.originFileObj });
  }

  removeAnnotationReportPDFSigned = () => {
    this.setState({ annotationReportPDFSigned: null });
  }

  onSaveAnnotationReportPDFSigned = () => {
    const { isShowAnnotationReportPDFSigned, annotationReportPDFSigned } = this.state;
    const form = new FormData();
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    form.append('file', annotationReportPDFSigned);
    uploadFile(form, headers)
      .then((file) => editApplicationStepReport({ id: isShowAnnotationReportPDFSigned, annotationReportPDFSigned: file.id })
        .then(() => window.location.reload(false)));
  }

  onShowFinancialReportPDFSigned = (id) => {
    this.setState({ isShowFinancialReportPDFSigned: id });
  }

  onHideFinancialReportPDFSigned = () => {
    this.setState({ isShowFinancialReportPDFSigned: null });
  }

  onFinancialReportPDFSignedUpload = (info) => {
    if (info.file.type !== 'application/pdf') {
      notification.error({ message: 'Ошибка! Загрузите, пожалуйста, файл в формате PDF' });
      return;
    }
    this.setState({ financialReportPDFSigned: info.file.originFileObj });
  }

  removeFinancialReportPDFSigned = () => {
    this.setState({ financialReportPDFSigned: null });
  }

  onSaveFinancialReportPDFSigned = () => {
    const { isShowFinancialReportPDFSigned, financialReportPDFSigned } = this.state;
    const form = new FormData();
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    form.append('file', financialReportPDFSigned);
    uploadFile(form, headers)
      .then((file) => editApplicationStepReport({ id: isShowFinancialReportPDFSigned, financialReportPDFSigned: file.id })
        .then(() => window.location.reload(false)));
  }

  render() {
    const {
      loading, specializationCatalogue, scientificDirectionCatalogue, selectStatusValue, selectSpecializationValue,
      selectScientificDirectionnValue, selectedApplication, file, selectPersonStartusValue, isShowAnnotationReportPDFSigned, annotationReportPDFSigned,
      isShowFinancialReportPDFSigned, financialReportPDFSigned, dataSource,
    } = this.state;
    const { history } = this.props;

    return (
      <div className="applications-wrapper">
        <div className="applications-buttons-wrapper">
          <div className="applications-button-wrapper">
            <div>Поиск</div>
            <Search
              placeholder="Поиск по номеру, теме..."
              onSearch={(e) => this.setState({ selectSearchText: e })}
              style={{ width: '100%' }}
              disabled={loading}
            />
          </div>
          {getUserRole() !== 'noDSM' && (
          <div className="applications-button-wrapper">
            <div>Фильтр по статусу</div>
            <Select
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              value={selectStatusValue}
              options={selectStatusOptions}
              disabled={loading}
              onChange={(newValue) => {
                this.setState({ selectStatusValue: newValue });
              }}
              placeholder="Выберите статус..."
              maxTagCount="responsive"
            />
          </div>
          )}
          {getUserRole() !== 'noDSM' && (
          <div className="applications-button-wrapper">
            <div>Фильтр по направлению</div>
            <Select
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              disabled={loading}
              value={selectScientificDirectionnValue}
              options={scientificDirectionCatalogue.map((sp) => ({ label: sp.value, value: sp.id }))}
              onChange={(newValue) => {
                this.setState({ selectScientificDirectionnValue: newValue });
              }}
              placeholder="Выберите направление..."
              maxTagCount="responsive"
            />
          </div>
          )}
          {getUserRole() === 'user' && (
          <div className="applications-button-wrapper">
            <div>Фильтр по специализации</div>
            <Select
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              disabled={loading}
              value={selectSpecializationValue}
              options={specializationCatalogue.map((sp) => ({ label: sp.value, value: sp.id }))}
              onChange={(newValue) => {
                this.setState({ selectSpecializationValue: newValue });
              }}
              placeholder="Выберите специализацию..."
              maxTagCount="responsive"
            />
          </div>
          )}
          {getUserRole() === 'bigAdmin' && (
          <div className="applications-button-wrapper">
            <div>Фильтр по Соисполнители</div>
            <Select
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              disabled={loading}
              value={selectPersonStartusValue}
              options={[{ value: 'На заполнении' }, { value: 'На согласовании' }, { value: 'Согласовано' }].map((sp) => ({ label: sp.value, value: sp.value }))}
              onChange={(newValue) => {
                this.setState({ selectPersonStartusValue: newValue });
              }}
              placeholder="Выберите статус..."
              maxTagCount="responsive"
            />
          </div>
          )}
        </div>
        <div className="applications-table-wrapper ">
          <Table
            dataSource={orderBy(dataSource, 'status', 'desc')}
            columns={getApplicationsColumns(history)}
            loading={loading}
            scroll={{ y: '55vh' }}
            pagination={false}
            rowKey="id"
          />
        </div>
        {selectedApplication && (
        <Modal
          visible
          width="70vw"
          maskClosable={false}
          onCancel={this.onCloseModal}
          title={`Заключение грантового соглашения по заявке ${selectedApplication.displayID}`}
          footer={selectedApplication.grantAppStatus === ''
            ? [
              <Button key="submit" type="primary" loading={loading} onClick={this.onSaveModal}>
                Отправить на рассмотрение
              </Button>,
            ]
            : selectedApplication.grantAppStatus === 'На согласовании пользователя'
              ? [
                <Button key="submit" loading={loading} onClick={this.onSaveModal}>
                  Отправить на рассмотрение свою версию
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={this.onApproveGrantAgreement}>
                  Согласовать предложенную версию
                </Button>,
              ]
              : [

              ]}
        >
          {selectedApplication.grantAppStatus !== 'На согласовании администратора' && selectedApplication.grantAppStatus !== 'Согласовано' && (
          <Col>
            <Alert
              message={(
                <div>
                  <p>Для заключения грантового соглашения вам необходимо:</p>
                  <p>
                    <span>1.</span>
                    <a style={{ margin: '0px 2px' }} href="https://api.directual.com/fileUploaded/medtechgrants/5466d4b5-cdc2-4830-a059-1564eeeb735b.docx" target="_blank" rel="noreferrer">Скачать шаблон</a>
                    <span>грантового соглашения в формате word и заполнить поля, выделенные цветом</span>
                  </p>
                  <p>2. Сохранить заполненное грантовое соглашение в формате word, назвать файл «Грантовое соглашение_№заявки». Пример: «Грантовое соглашение_№2412-25».</p>
                  <p>3. Заполненный файл необходимо прикрепить в окно ниже и нажать кнопку &quot;Отправить грантовое соглашение&quot;</p>
                  <p />
                </div>
            )}
              type="warning"
              style={{ marginBottom: '20px' }}
            />
            {selectedApplication.grantAppComment && (
            <Alert
              type="error"
              style={{ marginBottom: '20px' }}
              message="Комментарий администратора"
              description={selectedApplication.grantAppComment}
            />
            )}
            {selectedApplication.grantAgreementLink && (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Button type="primary" style={{ width: '100%' }} onClick={() => window.open(selectedApplication.grantAgreementLink)} shape="round" icon={<DownloadOutlined />}>
                Текущая версия грантового соглашения
              </Button>
            </Row>
            )}
            <Divider />
            <Row style={{ color: '#212121' }}>Внесите комментарий о внесенных изменениях:</Row>
            <TextArea rows={3} style={{ marginBottom: '10px' }} onChange={(e) => this.setState({ grantAppComment: e.target.value })} />
            {!file && (
            <Dragger
              showUploadList={false}
              onChange={this.onFileUpload}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
            </Dragger>
            )}
            {file && (
            <Upload
              showUploadList={{
                showDownloadIcon: true,
                downloadIcon: 'скачать ',
                showRemoveIcon: true,
              }}
              fileList={[file]}
              onRemove={this.removeFile}
            />
            )}
          </Col>
          )}

          {(selectedApplication.grantAppStatus === 'На согласовании администратора' || selectedApplication.grantAppStatus === 'Согласовано') && (
          <Col>
            {selectedApplication.grantAgreementLink && (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Button type="primary" style={{ width: '100%' }} onClick={() => window.open(selectedApplication.grantAgreementLink)} shape="round" icon={<DownloadOutlined />}>
                Текущая версия грантового соглашения
              </Button>
            </Row>
            )}
          </Col>
          )}
        </Modal>
        )}
        {!!isShowAnnotationReportPDFSigned && (
        <Modal
          visible
          maskClosable={false}
          onCancel={this.onHideAnnotationReportPDFSigned}
          title="Загрузка подписанного файла"
          footer={null}
        >
          <Col>
            {!annotationReportPDFSigned && (
              <Dragger
                showUploadList={false}
                onChange={this.onAnnotationReportPDFSignedUpload}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
              </Dragger>
            )}
            {annotationReportPDFSigned && (
              <Upload
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: 'скачать ',
                  showRemoveIcon: true,
                }}
                fileList={[annotationReportPDFSigned]}
                onRemove={this.removeAnnotationReportPDFSigned}
              />
            )}
            <Button type="primary" onClick={this.onSaveAnnotationReportPDFSigned} disabled={!annotationReportPDFSigned} style={{ marginTop: '20px' }}>Загрузить</Button>
          </Col>
        </Modal>
        )}
        {!!isShowFinancialReportPDFSigned && (
        <Modal
          visible
          maskClosable={false}
          onCancel={this.onHideFinancialReportPDFSigned}
          title="Загрузка подписанного файла"
          footer={null}
        >
          <Col>
            {!financialReportPDFSigned && (
              <Dragger
                showUploadList={false}
                onChange={this.onFinancialReportPDFSignedUpload}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
              </Dragger>
            )}
            {financialReportPDFSigned && (
              <Upload
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: 'скачать ',
                  showRemoveIcon: true,
                }}
                fileList={[financialReportPDFSigned]}
                onRemove={this.removeFinancialReportPDFSigned}
              />
            )}
            <Button type="primary" onClick={this.onSaveFinancialReportPDFSigned} disabled={!financialReportPDFSigned} style={{ marginTop: '20px' }}>Загрузить</Button>
          </Col>
        </Modal>
        )}
      </div>
    );
  }
}

Applications.propTypes = {
  history: PropTypes.shape().isRequired,
  filterParams: PropTypes.shape().isRequired,
};


export default withRouter(Applications);
