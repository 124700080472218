/* eslint-disable no-cond-assign */
/* eslint-disable radix */
import exportSmetaHeader from './exportSmetaHeader';


const fields = [
  { name: 'fot', label: 'Оплата труда работников (включая расходы на оплату страховых взносов на ФОТ)' },
  { name: 'material', label: 'Расходы на приобретение материалов и комплектующих, необходимых для реализации проекта' },
  { name: 'equipment', label: 'Расходы, на приобретение оборудования и иного имущества, необходимых для реализации Проекта' },
  { name: 'other', label: 'Прочие прямые расходы' },
  { name: 'paper', label: 'Накладные и общехозяйственные расходы, предусмотренные учетной политикой организации' },
  { name: 'people', label: 'Оплата работ, выполняемых Соисполнителями' },
];


function toColumnName(num) {
  let ret = '';
  let a = 1;
  let b = 1;
  for (ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}


const prepareSmetaData = (rawData, stepsNumber, projectYears) => {
  const data = [];
  const header = [...exportSmetaHeader];

  projectYears.forEach((year, index) => {
    header.push({
      cell: `${toColumnName(3 + index + 1)}2`,
      name: `${year} год`,
      width: 45,
      columnNumber: 3 + index + 1,
      field: `${year}year`,
    });
  });

  header.push({
    cellsToMerge: `C1:${toColumnName(3 + stepsNumber)}1`,
    cell: 'C1',
    name: 'ОБЪЕМ ФИНАНСИРОВАНИЯ (руб.)',
    width: 15,
    columnNumber: 3,
    field: 'finance',
  });

  fields.forEach((field, i) => {
    const obj = {
      [`${field.name}index`]: i + 1,
      key: field.name,
      [`${field.name}direction`]: field.label,
      [`${field.name}total`]: `${rawData[`${field.name}TotalMajor`]},${rawData[`${field.name}TotalMinor`]}`,
    };

    projectYears.forEach((year) => {
      const major = rawData[`${field.name}${year}Major`];
      const minor = rawData[`${field.name}${year}Minor`];
      obj[`${field.name}${year}year`] = !major ? '' : `${major},${minor}`;
    });
    data.push(obj);
  });

  const totalObj = {
    financeTotalindex: '',
    key: 'financeTotal',
    financeTotaldirection: 'ИТОГО',
    financeTotaltotal: `${rawData.financeTotalMajor},${rawData.financeTotalMinor}`,
  };
  projectYears.forEach((year) => {
    const major = rawData[`financeTotal${year}Major`];
    const minor = rawData[`financeTotal${year}Minor`];
    totalObj[`financeTotal${year}year`] = !major ? '' : `${major},${minor}`;
  });

  data.push(totalObj);

  return {
    header,
    data,
  };
};

export default prepareSmetaData;
