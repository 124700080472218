/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Button, Table, Input, Row, Modal, Form, InputNumber, notification, Checkbox,
} from 'antd';
import {
  editApplicationsFinancialReportTotal, getApplicationStepReportById, editApplicationStepReport,
} from '../../../../../../../utils/http';
import getTotalColumns from './getTotalColumns';


const customFinancialReportIds = ['2312-29/22-2С', '2412-41/22-1С', '2312-27/22-1С'];
const { TextArea } = Input;
class Total extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowAddModal: false,
      objectInEdit: null,
      dataSource: [],
      loading: true,
      isFinancialTotalChecked: false,
      application: {},
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { applicationId } = this.props;
    getApplicationStepReportById(applicationId)
      .then((application) => this.getDataSource(application));
  }

  onEditClick = (objectInEdit) => {
    this.setState({ objectInEdit, isShowAddModal: true });
  }

  onCloseModal = () => {
    this.setState({ objectInEdit: null, isShowAddModal: false });
  }

  onSaveClick = () => {
    const { applicationId } = this.props;
    const { objectInEdit, application, dataSource } = this.state;
    this.formRef.current.validateFields()
      .then((values) => {
        editApplicationsFinancialReportTotal({ ...values, applicationStepReport_id: applicationId, type: objectInEdit.type })
          .then((id) => {
            const totalRow = { ...dataSource.find((d) => d.type === objectInEdit.type), ...values, id };
            let totalSumleft = 0;
            if (application.stepNumber === '1') {
              totalSumleft = parseFloat(`${totalRow.grantSumMajor}.${(`${totalRow.grantSumMinor}`).length === 1 ? `0${totalRow.grantSumMinor}` : totalRow.grantSumMinor}`) * 100
              - parseFloat(`${totalRow.paperSumMajor}.${(`${totalRow.paperSumMinor}`).length === 1 ? `0${totalRow.paperSumMinor}` : totalRow.paperSumMinor}`) * 100;
            } else {
              totalSumleft = parseFloat(`${totalRow.money2022Major}.${(`${totalRow.money2022Minor}`).length === 1 ? `0${totalRow.money2022Minor}` : totalRow.money2022Minor}`) * 100
              - parseFloat(`${totalRow.moneySpentLastPeriodStartMajor}.${(`${totalRow.moneySpentLastPeriodStartMinor}`).length === 1 ? `0${totalRow.moneySpentLastPeriodStartMinor}` : totalRow.moneySpentLastPeriodStartMinor}`) * 100
              - parseFloat(`${totalRow.paperSumMajor}.${(`${totalRow.paperSumMinor}`).length === 1 ? `0${totalRow.paperSumMinor}` : totalRow.paperSumMinor}`) * 100;
            }
            const moneyLeftPeriodEndMajor = Math.trunc(totalSumleft / 100) === 0 && totalSumleft < 0 ? `-0${Math.trunc(totalSumleft / 100)}` : Math.trunc(totalSumleft / 100);
            const moneyLeftPeriodEndMinor = Number(Math.abs(totalSumleft % 100).toFixed(0));

            this.setState((prevState) => ({
              objectInEdit: null,
              isShowAddModal: false,
              dataSource: prevState.dataSource.map((d) => (d.type === objectInEdit.type
                ? {
                  ...d,
                  ...values,
                  moneyLeftPeriodEndMajor,
                  moneyLeftPeriodEndMinor,
                  id,
                }
                : d)),
            }));
          });
      })
      .catch(() => {
        this.setState({ loading: false });
        notification.error({ message: 'Не сохранено! Заполните все обязательные поля!' });
      });
  }

  calculateSum = (array, fieldName) => {
    const sum = array.reduce((a, c) => {
      const major = c[`${fieldName}Major`];
      const minor = Math.abs(c[`${fieldName}Minor`]);
      return a + parseFloat((parseFloat(`${major}.${(`${minor}`).length === 1 ? `0${minor}` : minor}`) * 100).toFixed(0));
    }, 0);
    return {
      major: Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100),
      minor: Number((sum % 100).toFixed(0)),
      sum,
    };
  }

  getTotalRow = (dataSource) => {
    const fields = ['moneyLeftPeriodStart', 'grantSum', 'money2022', 'actualSum', 'paperSum', 'moneyLeftPeriodEnd', 'moneySpentLastPeriodStart', 'moneyLeftLastPeriodStart'];

    const obj = {
      id: 'isTotal',
      isTotal: true,
      name: 'ИТОГО',
    };

    fields.forEach((f) => {
      const sum = this.calculateSum(dataSource, f);
      obj[`${f}Major`] = sum.major;
      obj[`${f}Minor`] = sum.minor;
    });

    return obj;
  }

  getDataSource = (application) => {
    const { prevStepApplications } = this.props;
    const dataSource = [];
    const fields = [
      {
        type: 'salary',
        orderIndex: '1',
        name: 'Оплата труда работников (с учетом страховых взносов во небюджетные фонды)',
      },
      {
        type: 'material',
        orderIndex: '2',
        name: 'Расходы на приобретение материалов и комплектующих, необходимых для реализации Проекта',
      },
      {
        type: 'equipment',
        orderIndex: '3',
        name: 'Расходы на приобретение оборудования и иного имущества, необходимых для реализации Проекта',
      },
      {
        type: 'other',
        orderIndex: '4',
        name: 'Прочие прямые расходы',
      },
      {
        type: 'paper',
        orderIndex: '5',
        name: 'Накладные и общехозяйственные расходы',
      },
    ];

    fields.forEach((f) => {
      let array = [];
      let actualSumMajor = 0;
      let actualSumMinor = 0;
      let paperSumMajor = 0;
      let paperSumMinor = 0;

      let prevStepArray = [];
      let prevStepPaperSumMajor = 0;
      let prevStepPaperSumMinor = 0;

      if (f.type === 'salary') {
        array = application.financialSalary_ids;
        actualSumMajor = this.calculateSum(array, 'totalPerson').major;
        actualSumMinor = this.calculateSum(array, 'totalPerson').minor;
        paperSumMajor = this.calculateSum(array, 'actualPaid').major;
        paperSumMinor = this.calculateSum(array, 'actualPaid').minor;

        if (prevStepApplications.length > 0) {
          prevStepApplications.forEach((prevStepApplication) => {
            prevStepArray = prevStepApplication.financialSalary_ids || [];
            const prevStepArraySum = this.calculateSum(prevStepArray, 'actualPaid').sum + parseFloat(`${prevStepPaperSumMajor}.${(`${prevStepPaperSumMinor}`).length === 1 ? `0${prevStepPaperSumMinor}` : prevStepPaperSumMinor}`) * 100;
            prevStepPaperSumMajor = Math.trunc(prevStepArraySum / 100) === 0 && prevStepArraySum < 0 ? `-0${Math.trunc(prevStepArraySum / 100)}` : Math.trunc(prevStepArraySum / 100);
            prevStepPaperSumMinor = Number(Math.abs(prevStepArraySum % 100).toFixed(0));
          });
        }
      } else if (f.type === 'paper') {
        array = application.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]);
        actualSumMajor = this.calculateSum(array, 'actualCosts').major;
        actualSumMinor = this.calculateSum(array, 'actualCosts').minor;

        paperSumMajor = this.calculateSum(array, 'grantSum').major;
        paperSumMinor = this.calculateSum(array, 'grantSum').minor;

        if (prevStepApplications.length > 0) {
          prevStepApplications.forEach((prevStepApplication) => {
            prevStepArray = prevStepApplication.financialCosts_ids ? prevStepApplication.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]) : [];
            const prevStepArraySum = this.calculateSum(prevStepArray, 'grantSum').sum + parseFloat(`${prevStepPaperSumMajor}.${(`${prevStepPaperSumMinor}`).length === 1 ? `0${prevStepPaperSumMinor}` : prevStepPaperSumMinor}`) * 100;
            prevStepPaperSumMajor = Math.trunc(prevStepArraySum / 100) === 0 && prevStepArraySum < 0 ? `-0${Math.trunc(prevStepArraySum / 100)}` : Math.trunc(prevStepArraySum / 100);
            prevStepPaperSumMinor = Number(Math.abs(prevStepArraySum % 100).toFixed(0));
          });
        }
      } else {
        array = application.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]);
        actualSumMajor = this.calculateSum(array, 'actualCosts').major;
        actualSumMinor = this.calculateSum(array, 'actualCosts').minor;

        paperSumMajor = this.calculateSum(array, 'paperCosts').major;
        paperSumMinor = this.calculateSum(array, 'paperCosts').minor;

        if (prevStepApplications.length > 0) {
          prevStepApplications.forEach((prevStepApplication) => {
            prevStepArray = prevStepApplication.financialCosts_ids ? prevStepApplication.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]) : [];
            const prevStepArraySum = this.calculateSum(prevStepArray, 'paperCosts').sum + parseFloat(`${prevStepPaperSumMajor}.${(`${prevStepPaperSumMinor}`).length === 1 ? `0${prevStepPaperSumMinor}` : prevStepPaperSumMinor}`) * 100;
            prevStepPaperSumMajor = Math.trunc(prevStepArraySum / 100) === 0 && prevStepArraySum < 0 ? `-0${Math.trunc(prevStepArraySum / 100)}` : Math.trunc(prevStepArraySum / 100);
            prevStepPaperSumMinor = Number(Math.abs(prevStepArraySum % 100).toFixed(0));
          });
        }
      }

      const total = application.financialReportTotal_ids.find((t) => t.type === f.type) || {};
      let prevStepTotal = 0;
      prevStepApplications.forEach((prevStepApplication) => {
        const prevSum = prevStepApplication?.financialReportTotal_ids.find((t) => t.type === f.type);
        if (prevSum) {
          prevStepTotal += parseFloat(`${prevStepTotal.grantSumMajor}.${(`${prevStepTotal.grantSumMinor}`).length === 1 ? `0${prevStepTotal.grantSumMinor}` : prevStepTotal.grantSumMinor}`) * 100;
        }
      });

      let prevStepMoneyLeft = 0;
      if (prevStepTotal > 0) {
        prevStepMoneyLeft = prevStepTotal - parseFloat(`${prevStepTotal.prevStepPaperSumMajor}.${(`${prevStepTotal.prevStepPaperSumMinor}`).length === 1 ? `0${prevStepTotal.prevStepPaperSumMinor}` : prevStepTotal.prevStepPaperSumMinor}`) * 100;
      }
      const finalObject = {
        id: total.id,
        type: f.type,
        orderIndex: f.orderIndex,
        name: f.name,
        money2022Major: total.money2022Major || 0,
        money2022Minor: total.money2022Minor || 0,
        moneySpentLastPeriodStartMajor: prevStepPaperSumMajor,
        moneySpentLastPeriodStartMinor: prevStepPaperSumMinor,
        moneyLeftLastPeriodStartMajor: Math.trunc(prevStepMoneyLeft / 100) === 0 && prevStepMoneyLeft < 0 ? `-0${Math.trunc(prevStepMoneyLeft / 100)}` : Math.trunc(prevStepMoneyLeft / 100),
        moneyLeftLastPeriodStartMinor: Number((prevStepMoneyLeft % 100).toFixed(0)),
        moneyLeftPeriodStartMajor: total.moneyLeftPeriodStartMajor || 0,
        moneyLeftPeriodStartMinor: total.moneyLeftPeriodStartMinor || 0,
        grantSumMajor: total.grantSumMajor || 0,
        grantSumMinor: total.grantSumMinor || 0,
        actualSumMajor,
        actualSumMinor,
        paperSumMajor,
        paperSumMinor,
        comment: total.comment || '',
      };
      if (application.stepNumber === '2') {
        if (application.displayID === '0408-1/22-1С') {
          if (f.type === 'salary') { finalObject.moneyLeftLastPeriodStartMajor = 500000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
        }
        if (application.displayID === '2312-29/22-3С') {
          if (f.type === 'salary') { finalObject.moneyLeftLastPeriodStartMajor = 166005; finalObject.moneyLeftLastPeriodStartMinor = 0; }
          if (f.type === 'material') { finalObject.moneyLeftLastPeriodStartMajor = 5500; finalObject.moneyLeftLastPeriodStartMinor = 0; }
          if (f.type === 'equipment') { finalObject.moneyLeftLastPeriodStartMajor = 90000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
          if (f.type === 'other') { finalObject.moneyLeftLastPeriodStartMajor = 238495; finalObject.moneyLeftLastPeriodStartMinor = 0; }
        }
        if (application.displayID === '2412-55/22-1С') {
          if (f.type === 'salary') { finalObject.moneyLeftLastPeriodStartMajor = 210000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
          if (f.type === 'material') { finalObject.moneyLeftLastPeriodStartMajor = 130000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
          if (f.type === 'paper') { finalObject.moneyLeftLastPeriodStartMajor = 60000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
        }
        if (application.displayID === '0403-1/22-1С') {
          if (f.type === 'salary') { finalObject.moneyLeftLastPeriodStartMajor = 2055000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
          if (f.type === 'equipment') { finalObject.moneyLeftLastPeriodStartMajor = 130000; finalObject.moneyLeftLastPeriodStartMinor = 0; }
        }
      }
      let totalSumleft = 0;
      const isShowOldForm = application.stepNumber === '1' && !customFinancialReportIds.includes(application.displayID);

      if (prevStepApplications.length === 0 && application.stepNumber === '1') {
        totalSumleft = (isShowOldForm ? (parseFloat(`${finalObject.grantSumMajor}.${(`${finalObject.grantSumMinor}`).length === 1 ? `0${finalObject.grantSumMinor}` : finalObject.grantSumMinor}`) * 100) : (parseFloat(`${finalObject.money2022Major}.${(`${finalObject.money2022Minor}`).length === 1 ? `0${finalObject.money2022Minor}` : finalObject.money2022Minor}`) * 100))
        - parseFloat((parseFloat(`${finalObject.paperSumMajor}.${(`${finalObject.paperSumMinor}`).length === 1 ? `0${finalObject.paperSumMinor}` : finalObject.paperSumMinor}`) * 100).toFixed(0));
      } else {
        totalSumleft = parseFloat(`${finalObject.money2022Major}.${(`${finalObject.money2022Minor}`).length === 1 ? `0${finalObject.money2022Minor}` : finalObject.money2022Minor}`) * 100
        - parseFloat(`${finalObject.moneySpentLastPeriodStartMajor}.${(`${finalObject.moneySpentLastPeriodStartMinor}`).length === 1 ? `0${finalObject.moneySpentLastPeriodStartMinor}` : finalObject.moneySpentLastPeriodStartMinor}`) * 100
        - parseFloat(`${finalObject.paperSumMajor}.${(`${finalObject.paperSumMinor}`).length === 1 ? `0${finalObject.paperSumMinor}` : finalObject.paperSumMinor}`) * 100;
      }
      finalObject.moneyLeftPeriodEndMajor = Math.trunc(totalSumleft / 100) === 0 && totalSumleft < 0 ? `-0${Math.trunc(totalSumleft / 100)}` : Math.trunc(totalSumleft / 100);
      finalObject.moneyLeftPeriodEndMinor = Number(Math.abs(totalSumleft % 100).toFixed(0));
      dataSource.push(finalObject);
    });
    this.setState({
      loading: false,
      dataSource,
      isFinancialTotalChecked: application.isFinancialTotalChecked,
      application,
    });
  }

  onCheckboxChange = (e) => {
    this.setState({ loading: true });
    const { applicationId } = this.props;
    editApplicationStepReport({ id: applicationId, isFinancialTotalChecked: e.target.checked })
      .then(() => {
        this.setState({ isFinancialTotalChecked: e.target.checked, loading: false });
        notification.success({ message: 'Сохранено!' });
      });
  }

  render() {
    const {
      dataSource, isShowAddModal, objectInEdit, loading, isFinancialTotalChecked, application,
    } = this.state;
    const {
      isFormDisabled, financialReportPosition, financialReportFIO, onChangeTextFiled,
    } = this.props;
    const isShowOldForm = application.stepNumber === '1' && !customFinancialReportIds.includes(application.displayID);
    return (
      <Col>
        <Table
          dataSource={[...dataSource, this.getTotalRow(dataSource)]}
          columns={getTotalColumns(this.onEditClick, isFormDisabled, isShowOldForm)}
          pagination={false}
          rowKey="id"
          bordered
          loading={loading}
        />
        <Checkbox defaultChecked={isFinancialTotalChecked} checked={isFinancialTotalChecked} disabled={isFormDisabled || loading} style={{ marginTop: '5px', color: '#1890ff' }} onChange={this.onCheckboxChange}>Подтверждаю, что сводная таблица финансовых расходов по проекту заполнена и проверена</Checkbox>
        <div className="application-main-box-content-wrapper" style={{ padding: 0, marginTop: '20px' }}>
          <span className="application-main-box-label">ФИО работника организации, уполномоченного на представление отчета о целевом использовании средств гранта:</span>
          <Input value={financialReportFIO} onChange={(e) => onChangeTextFiled('financialReportFIO', e.target.value)} disabled={isFormDisabled || loading} />
        </div>
        <div className="application-main-box-content-wrapper" style={{ padding: 0 }}>
          <span className="application-main-box-label">Должность работника организации, уполномоченного на представление отчета о целевом использовании средств гранта:</span>
          <Input value={financialReportPosition} onChange={(e) => onChangeTextFiled('financialReportPosition', e.target.value)} disabled={isFormDisabled || loading} />
        </div>
        {isShowAddModal && (
        <Modal
          title="Редактирование формы"
          visible
          footer={[<Button type="primary" onClick={this.onSaveClick}>Сохранить</Button>]}
          onCancel={this.onCloseModal}
        >
          <Form
            initialValues={{ remember: true }}
            ref={this.formRef}
          >
            <Col>
              <Form.Item
                name="id"
                hidden
                initialValue={objectInEdit && objectInEdit.id}
              />
              {application?.stepNumber !== '1' && (
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Сумма перечисленного гранта, всего на конец отчетного периода, руб:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="money2022Major"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.money2022Major}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="money2022Minor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.money2022Minor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              )}
              {application?.stepNumber === '1' && (
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Сумма перечисленного Гранта:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="grantSumMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.grantSumMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="grantSumMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.grantSumMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              )}
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Комментарий:</span>
                <Form.Item
                  name="comment"
                  initialValue={objectInEdit.comment}
                >
                  <TextArea />
                </Form.Item>
              </div>
            </Col>
          </Form>
        </Modal>
        )}
      </Col>
    );
  }
}

Total.propTypes = {
  applicationId: PropTypes.string.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  financialReportFIO: PropTypes.string.isRequired,
  financialReportPosition: PropTypes.string.isRequired,
  onChangeTextFiled: PropTypes.func.isRequired,
  prevStepApplications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Total;
