import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Empty } from 'antd';
import ApplicationCard from './ApplicationCard/ApplicationCard';
import selectStatusOptions from '../../HomePageDesktop/utils/selectStatusOptions';
import { routeGenerator } from '../../../../../utils/routes';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';


const { Option } = Select;
class ApplicationMobile extends Component {
    state = {
      selectStatusValue: 'all',
      selectedUserValue: 'all',
    }

    render() {
      const { selectStatusValue, selectedUserValue } = this.state;
      const { history, users, dataSource } = this.props;
      const filteredDatasource = dataSource.filter((el) => (selectStatusValue === 'all' || selectStatusValue === el.status)
      && (selectedUserValue === 'all' || selectedUserValue === el.shortName));
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {getUserRole() === 'user' && <div style={{ paddingTop: '10px', width: '100%' }}>Мои заявки</div>}
          {getUserRole() !== 'person' && (
          <div className="homePageMobile-header" style={{ paddingTop: '0px' }}>
            <div className="homePageMobile-select-wrapper" style={{ width: getUserRole() === 'user' ? '100%' : '48%' }}>
              <span>Выберите статус</span>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                optionFilterProp="children"
                showSearch
                onChange={(e) => this.setState({ selectStatusValue: e })}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {[...selectStatusOptions.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>),
                  <Option key="all" value="all">Все</Option>]}
              </Select>
            </div>
            {getUserRole() !== 'user' && (
            <div className="homePageMobile-select-wrapper">
              <span>Выберите больницу</span>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                optionFilterProp="children"
                showSearch
                onChange={(e) => this.setState({ selectedUserValue: e })}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {[...users.map((u) => <Option key={u.shortName} value={u.shortName}>{u.shortName}</Option>),
                  <Option key="all" value="all">Все</Option>]}
              </Select>
            </div>
            )}
          </div>
          )}
          <div className="homePageMobile-application-list-wrapper">
            {filteredDatasource.length === 0 ? <Empty description="У вас пока нет заявок" /> : filteredDatasource.map((application) => (
              <ApplicationCard
                key={application.id}
                application={application}
                onAppClick={() => history.push(routeGenerator.applicationLink(application.id))}
              />
            ))}
          </div>
        </div>
      );
    }
}


ApplicationMobile.propTypes = {
  history: PropTypes.shape().isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ApplicationMobile;
