/* eslint-disable radix */
/* eslint-disable no-cond-assign */


const ExcelJS = require('exceljs');


function toColumnName(num) {
  let ret = '';
  let a = 1;
  let b = 1;
  for (ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}


const fields = [
  {
    name: 'Номер проекта',
    field: 'displayID',
  },
  {
    name: 'Наименование организации',
    field: 'webUser',
  },
  {
    name: 'Тема проекта',
    field: 'theme',
  },
  {
    name: 'Дата завершения проекта',
    field: 'endDate',
  },
  {
    name: 'Наименование результата',
    field: 'resultName',
  },
  {
    name: '1. Результат реализации Вашего научно-технического проекта и возможность масштабирования',
    field: 'result',
  },
  {
    name: '2. Ориентировочное количество пользователей разработки на текущий момент (работники системы здравоохранения)',
    field: 'usersOrg',
  },
  {
    name: '3. Ориентировочное количество пользователей разработки на текущий момент (пациенты)',
    field: 'usersPatience',
  },
  {
    name: '4. Уровень технологической готовности по Вашей оценке:',
    field: 'techLevel',
  },
  {
    name: '5. Барьеры (на текущий момент), препятствующие масштабированию разработанного Вами решения в другие медицинские учреждения или другие региональные системы здравоохранения',
    field: 'barrier',
  },
  {
    name: 'получение другой разрешительной документации',
    field: 'barrierAdditional1',
  },
  {
    name: 'технические доработки для интеграции',
    field: 'barrierAdditional2',
  },
  {
    name: 'необходимость PR-сопровождения',
    field: 'barrierAdditional3',
  },
  {
    name: 'необходимость GR-поддержки для масштабирования в регионы',
    field: 'barrierAdditional4',
  },
  {
    name: 'необходимость внедрения разработанного продукта в образовательные стандарты и программы',
    field: 'barrierAdditional5',
  },
  {
    name: 'свой вариант',
    field: 'barrierAdditional6',
  },
  {
    name: '6. Уровень подготовки к масштабированию Вашего продукта и анализ рынка (по Вашей оценке, выбрать совпадающие)',
    field: 'level',
  },
  {
    name: 'определены стратегические партнеры в коммерциализации продукта',
    field: 'levelAdditional1',
  },
  {
    name: '7. Анализ рынка для Вашего продукта по Вашей оценке',
    field: 'analys',
  },
  {
    name: 'проведена оценка затрат необходимых для вывода продукта на рынок',
    field: 'analysAdditional1',
  },
  {
    name: 'рассчитана прогнозируемая доля рынка',
    field: 'analysAdditional2',
  },
  {
    name: 'определены потенциальные рынки сбыта',
    field: 'analysAdditional3',
  },
  {
    name: 'определены потенциальные рынки сбыта. свой вариант',
    field: 'analysAdditional3Additional1',
  },
  {
    name: '8. Если для производства необходима производственная площадка, опишите статус',
    field: 'place',
  },
  {
    name: 'уже проработан поиск, но необходимо проведение дополнительных работ',
    field: 'placeAdditional1',
  },
  {
    name: 'уже проработан поиск, но необходимо проведение дополнительных работ. иных разрешительных процедур',
    field: 'placeAdditional1Additional1',
  },
  {
    name: '9. Какая помощь Вам требуется для дальнейшей коммерциализации продукта',
    field: 'help',
  },
  {
    name: 'помощь в получении разрешительной документации',
    field: 'helpAdditional1',
  },
  {
    name: 'помощь в получении разрешительной документации. свой вариант',
    field: 'helpAdditional1Additional1',
  },
  {
    name: 'свой вариант',
    field: 'helpAdditional2',
  },
  {
    name: '10. Зависимость продукта в рамках проекта от импортных технологий',
    field: 'addiction',
  },
  {
    name: 'свой вариант',
    field: 'addictionAdditional1',
  },
  {
    name: '11. Перечислите зарубежные аналоги (конкурентные решения)',
    field: 'foreignAnalogues',
  },
  {
    name: '12. Перечислите российские аналоги (конкурентные решения)',
    field: 'russianAnalogues',
  },
  {
    name: '13. Объем уже полученной выручки или экономический эффект от внедрения решения в рамках проекта',
    field: 'revenue',
  },
  {
    name: 'Рассчитывали',
    field: 'revenueAdditional1',
  },
  {
    name: '14. Общее количество объектов интеллектуальной собственности, уже полученных в рамках реализации проекта',
    field: 'rid',
  },
  {
    name: 'Реквизиты патента',
    field: 'patent',
  },
  {
    name: 'Реквизиты ЭВМ',
    field: 'program',
  },
  {
    name: 'Реквизиты РУ',
    field: 'paper',
  },
  {
    name: '15. Планируемое к получению количество объектов интеллектуальной собственности',
    field: 'ridAmount',
  },
];


const prepareSurveyData = (rawData) => {
  const data = [];
  const header = [];

  fields.forEach((field, i) => {
    header.push({
      cell: `${toColumnName(i + 1)}1`,
      name: field.name,
      columnNumber: i + 1,
      field: field.field,
    });
  });

  rawData.forEach((s) => {
    if (s.applicationSurveyPostmonitoringResult_ids.length === 0) {
      const obj = {
        displayID: s.displayID,
        theme: s.theme,
        webUser: s.webUser,
        endDate: s.endDate,
        rid: `патенты - ${s.applicationSurveyPostmonitoringRID_ids.filter((e) => e.type === 'patent').length}; Свидетельства о регистрации программы для ЭВМ - ${s.applicationSurveyPostmonitoringRID_ids.filter((e) => e.type === 'program').length}; регистрационные удостоверения - ${s.applicationSurveyPostmonitoringRID_ids.filter((e) => e.type === 'paper').length}`,
        ridAmount: `патенты - ${s.patentPlan || ''}; Свидетельства о регистрации программы для ЭВМ - ${s.programPlan || ''}; регистрационные удостоверения - ${s.paperPlan || ''}`,
      };
      data.push(obj);
    } else {
      s.applicationSurveyPostmonitoringResult_ids.forEach((result) => {
        const patent = s.applicationSurveyPostmonitoringRID_ids.filter((e) => e.type === 'patent');
        const program = s.applicationSurveyPostmonitoringRID_ids.filter((e) => e.type === 'program');
        const paper = s.applicationSurveyPostmonitoringRID_ids.filter((e) => e.type === 'paper');

        const obj = {
          displayID: s.displayID,
          theme: s.theme,
          webUser: s.webUser,
          endDate: s.endDate,
          resultName: result.resultName,

          result: result.result,
          resultAdditional1: result.resultAdditional1,

          usersOrg: result.usersOrg,

          usersPatience: result.usersPatience,

          techLevel: result.techLevel,

          barrier: result.barrier.join('; '),
          barrierAdditional1: result.barrierAdditional1,
          barrierAdditional2: result.barrierAdditional2,
          barrierAdditional3: result.barrierAdditional3,
          barrierAdditional4: result.barrierAdditional4,
          barrierAdditional5: result.barrierAdditional5,
          barrierAdditional6: result.barrierAdditional6,

          level: result.level.join('; '),
          levelAdditional1: result.levelAdditional1,

          analys: result.analys.join('; '),
          analysAdditional1: result.analysAdditional1,
          analysAdditional2: result.analysAdditional2,
          analysAdditional3: result.analysAdditional3.join('; '),
          analysAdditional3Additional1: result.analysAdditional3Additional1,

          place: result.place,
          placeAdditional1: result.placeAdditional1.join('; '),
          placeAdditional1Additional1: result.placeAdditional1Additional1,

          help: result.help,
          helpAdditional1: result.helpAdditional1,
          helpAdditional2: result.helpAdditional2,
          helpAdditional1Additional1: result.helpAdditional1Additional1,

          addiction: result.addiction,
          addictionAdditional1: result.addictionAdditional1,

          foreignAnalogues: result.foreignAnalogues.map((e) => e.value).join('; '),

          russianAnalogues: result.russianAnalogues.map((e) => e.value).join('; '),

          revenue: result.revenue,
          revenueAdditional1: result.revenueAdditional1,

          rid: `патенты - ${patent.length}; Свидетельства о регистрации программы для ЭВМ - ${program.length}; регистрационные удостоверения - ${paper.length}`,
          ridAmount: `патенты - ${s.patentPlan || ''}; Свидетельства о регистрации программы для ЭВМ - ${s.programPlan || ''}; регистрационные удостоверения - ${s.paperPlan || ''}`,
          patent: patent.map((e) => `${e.name} ${e.number} ${e.owner}`).join(';\n'),
          program: program.map((e) => `${e.name} ${e.number} ${e.owner}`).join(';\n'),
          paper: paper.map((e) => `${e.name} ${e.number} ${e.owner} `).join(';\n'),
        };
        data.push(obj);
      });
    }
  });

  return {
    header,
    data,
  };
};

const createTotalSheet = (data, worksheet) => {
  // Заполняем шапку
  data.header.forEach((header) => {
    if (header.columnNumber) {
      worksheet.getColumn(header.columnNumber).font = { name: 'Montserrat' };
      worksheet.getColumn(header.columnNumber).width = 50;
    }
    const cell = worksheet.getCell(header.cell);
    cell.value = header.name;
    cell.alignment = { vertical: 'middle' };
  });

  // Заполянем строчки
  let skipRows = 1;
  data.data.forEach((row) => {
    const rowXls = worksheet.getRow(skipRows + 1);
    data.header.forEach((header) => {
      const fieldXls = rowXls.getCell(header.columnNumber);
      fieldXls.value = row[header.field];
    });
    skipRows += 1;
  });
};

const createXLSDocument = async (data) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Admin';
  workbook.lastModifiedBy = 'Администратор';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const worksheet = workbook.addWorksheet('Опрос');
  createTotalSheet(data, worksheet);

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};

const arrayBufferToBase64 = (Arraybuffer) => {
  let binary = '';
  const bytes = new Uint8Array(Arraybuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = window.btoa(binary);
  const linkSource = `data:application/xls;base64,${base64String}`;
  const downloadLink = document.createElement('a');
  const fileName = 'Опрос.xls';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};


const exportSurvey2024XLS = async (rawData) => {
  const parsedData = prepareSurveyData(rawData);
  const buffer = await createXLSDocument(parsedData);
  arrayBufferToBase64(buffer);
};


export default exportSurvey2024XLS;
