/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Card, Col, Row, Popconfirm, notification, Modal,
} from 'antd';
import { editApplications2AuthorTabPublications } from '../../../../utils/http';


const labels = [
  {
    label: 'Название публикации',
    key: 'name',
  },
  {
    label: 'Месяц и год публикации',
    key: 'date',
  },
  {
    label: 'Название издания',
    key: 'publisherName',
  },
  {
    label: 'Импакт-фактор издания',
    key: 'impactFactor',
  },
];

const { TextArea } = Input;
function AuthorTabPublicationsModule({
  elementsArrayProp, applicationId, disabled, updateOriginalObject, isAuthorTabPeople,
}) {
  const [elementsArray, setElementsArray] = useState([]);
  const [objectInEditId, setObjectInEditId] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const formRef = useRef(null);
  const [publishedEmail, setPublishedEmail] = useState(null);
  const [publicationFile, setPublicationFile] = useState(null);
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  useEffect(() => {
    setElementsArray(elementsArrayProp);
    setIsFormDisabled(disabled);
  }, []);

  const onEditClick = (p) => {
    setObjectInEditId(p.id);
    setPublishedEmail(p.publishedEmail_id);
    setPublicationFile(p.publicationFile_id);
  };

  const onAddClick = () => {
    // setLoading(true);
    const objToSave = {};
    if (isAuthorTabPeople) {
      objToSave.applications2AuthorTabPeople_id = applicationId;
    } else {
      objToSave.applications2_id = applicationId;
    }
    editApplications2AuthorTabPublications(objToSave)
      .then((id) => {
        setObjectInEditId(id);
        const updatedArray = [...elementsArray, { id }];
        setElementsArray(updatedArray);
        // setLoading(false);
        updateOriginalObject('applications2AuthorTabPublications_ids', updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onDeleteClick = (id) => {
    // setLoading(true);
    editApplications2AuthorTabPublications({ id, isDeleted: true })
      .then(() => {
        setObjectInEditId(null);
        const updatedArray = elementsArray.filter((p) => p.id !== id);
        setElementsArray(updatedArray);
        // setLoading(false);
        updateOriginalObject('applications2AuthorTabPublications_ids', updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onCancelClick = () => {
    const values = formRef.current.getFieldsValue();
    const valuesKeys = Object.keys(values);
    let isEmpty = true;
    valuesKeys.forEach((k) => {
      if (values[k]) {
        isEmpty = false;
      }
    });
    if (isEmpty) {
      onDeleteClick(objectInEditId);
    } else {
      setObjectInEditId(null);
    }
  };

  const onSaveClick = () => {
    const values = formRef.current.getFieldsValue();

    let error = false;

    Object.entries(values).forEach(([key, value]) => {
      if (!value && !error) {
        const { label } = labels.find((l) => l.key === key);
        error = true;
        notification.error({ message: `Не заполнено поле: ${label}` });
      }
    });

    if (error) return;
    // setLoading(true);
    editApplications2AuthorTabPublications({
      id: objectInEditId,
      ...values,
      publishedEmail_id: publishedEmail?.id,
      publicationFile_id: publicationFile?.id,
    })
      .then(() => {
        const updatedArray = elementsArray.map((p) => (p.id === objectInEditId ? {
          id: objectInEditId,
          ...values,
          publishedEmail_id: publishedEmail,
          publicationFile_id: publicationFile,
        } : p));
        setObjectInEditId(null);
        setElementsArray(updatedArray);
        // setLoading(false);
        updateOriginalObject('applications2AuthorTabPublications_ids', updatedArray);
        notification.success({ message: 'Успешно' });
      });
  };

  const onExpand = (p) => {
    setObjectInEditId(p.id);
    setPublishedEmail(p.publishedEmail_id);
    setPublicationFile(p.publicationFile_id);
    setIsExpanded(true);
    setIsFormDisabled(true);
  };

  const onCollapse = () => {
    setObjectInEditId(null);
    setIsExpanded(false);
    setIsFormDisabled(disabled);
  };

  // const onRemoveFile = (field) => {
  //   if (field === 'publishedEmail') {
  //     setPublishedEmail(null);
  //   }
  //   if (field === 'publicationFile') {
  //     setPublicationFile(null);
  //   }
  // };

  // const onUploadFile = (info, field) => {
  //   if (info.file.status === 'uploading' && !info.event) {
  //     const form = new FormData();
  //     const headers = {
  //       'Content-Type': 'multipart/form-data',
  //     };
  //     form.append('file', info.file.originFileObj);
  //     uploadFile(form, headers)
  //       .then((file) => {
  //         if (field === 'publishedEmail') {
  //           setPublishedEmail(file);
  //         }
  //         if (field === 'publicationFile') {
  //           setPublicationFile(file);
  //         }
  //       });
  //   }
  // };

  return (
    <div style={{
      margin: '20px', padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
    }}
    >
      <span style={{ fontSize: '24px', color: '#212121' }}>Публикации</span>
      {elementsArray.map((p, i) => (
        <Card
          key={p.id}
          style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
          bodyStyle={{
            borderLeft: objectInEditId === p.id ? '4px solid #1890FF' : '',
            borderRadius: objectInEditId === p.id ? '5px' : '',
          }}
        >
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                {p.name || `Публикация №${i + 1}`}
              </Col>
              <Col>
                <Row align="middle" gutter={10}>
                  {!objectInEditId && (<Col><Button type="primary" onClick={() => onEditClick(p)} disabled={isFormDisabled}>Редактировать</Button></Col>)}
                  {!objectInEditId && (
                    <Col>
                      <Popconfirm
                        title="Уверены, что хотите удалить публикацию?"
                        onConfirm={() => onDeleteClick(p.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button type="danger" disabled={isFormDisabled}>Удалить</Button>
                      </Popconfirm>
                    </Col>
                  )}
                  {!objectInEditId && (<Col><Button onClick={() => onExpand(p)}>Показать</Button></Col>)}
                </Row>
              </Col>
            </Row>
            {objectInEditId === p.id
                && (
                  <Modal title="Редактирование публикацию" visible footer={null} width="90vw" maskClosable={false} closable={false}>
                    {/* <Row justify="end">
                      {objectInEditId === p.id && isExpanded && (<Col><Button type="primary" onClick={() => onCollapse()}>Закрыть</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button onClick={onCancelClick}>Отменить</Button></Col>)}
                    </Row> */}
                    <Form initialValues={{ remember: true }} ref={formRef} style={{ marginTop: '20px' }}>
                      <Col>
                        {/* <div className="common-field-wrapper">
                          <span className="common-field-label">1. Авторы публикации</span>
                          <span className="common-field-hint">Указываются в порядке, приведенном в публикации, в формате Фамилия И.О., Фамилия2 И2.О2., и т.д.</span>
                          <Form.Item
                            name="authors"
                            initialValue={p.authors}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div> */}

                        <div className="common-field-wrapper">
                          <span className="common-field-label">Название публикации</span>
                          <Form.Item
                            name="name"
                            initialValue={p.name}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Месяц и год публикации</span>
                          <Form.Item
                            name="date"
                            initialValue={p.date}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Название издания</span>
                          {/* <span className="common-field-hint">Для монографий также указывается название издания, город</span> */}
                          <Form.Item
                            name="publisherName"
                            initialValue={p.publisherName}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Импакт-фактор издания</span>
                          <span className="common-field-hint">укажите значение и базу данных от куда оно взято</span>
                          <Form.Item
                            name="impactFactor"
                            initialValue={p.impactFactor}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        {/* <div className="common-field-wrapper">
                          <span className="common-field-label">3. Месяц и год публикации</span>
                          <Form.Item
                            name="date"
                            initialValue={p.date}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">4. Ключевые слова</span>
                          <Form.Item
                            name="keyWords"
                            initialValue={p.keyWords}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">5. Вид публикации</span>
                          <Form.Item
                            name="type"
                            initialValue={p.type}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">6. Название издания</span>
                          <span className="common-field-hint">Для монографий также указывается название издания, город</span>
                          <Form.Item
                            name="publisherName"
                            initialValue={p.publisherName}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">7. ISSN</span>
                          <span className="common-field-hint">При наличии</span>
                          <Form.Item
                            name="ISSN"
                            initialValue={p.ISSN}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">8. e-ISSN</span>
                          <span className="common-field-hint">При наличии</span>
                          <Form.Item
                            name="eISSN"
                            initialValue={p.eISSN}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">9. ISBN</span>
                          <span className="common-field-hint">При наличии</span>
                          <Form.Item
                            name="ISBN"
                            initialValue={p.ISBN}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">10. Укажите квартиль (Q_) по импакт-фактору JCR Science Edition, JCR Social Sciences Edition, по SJR (квартиль издания в Scopus определяется по базе данных https://www.scimagojr.com/ или https://journalsearches.com/)</span>
                          <Form.Item
                            name="qvartil"
                            initialValue={p.qvartil}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">11. Выходные данные публикации (номер, том, выпуск, страницы)</span>
                          <Form.Item
                            name="publicationParams"
                            initialValue={p.publicationParams}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">12. Адрес полнотекстовой электронной версии публикации (URL) в открытом источнике</span>
                          <span className="common-field-hint">При наличии</span>
                          <Form.Item
                            name="publicationURL"
                            initialValue={p.publicationURL}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">13. DOI</span>
                          <span className="common-field-hint">При наличии</span>
                          <Form.Item
                            name="DOI"
                            initialValue={p.DOI}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">14. Принята в печать (указывается в случае официального принятия к публикации в последующих изданиях)</span>
                          <span className="common-field-hint">Для принятых к публикации материалов пункт 11 не заполняется</span>
                          <Form.Item
                            name="isPublished"
                            initialValue={p.isPublished}
                          >
                            <Select disabled={isFormDisabled}>
                              {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">15. Письмо из редакции или издательства с извещением об официальном принятии рукописи к публикации</span>
                          <Dragger
                            showUploadList={false}
                            onChange={(file) => onUploadFile(file, 'publishedEmail')}
                            disabled={loading}
                            multiple
                            action={null}
                            accept=".docx,.doc,.pdf,.xlsx,.xlsm"
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
                          </Dragger>
                          {publishedEmail && (
                          <Row style={{ margin: '5px 0px' }} gutter={12}>
                            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(publishedEmail.urlLink)} /></Col>
                            {!loading && !isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onRemoveFile('publishedEmail')} /></Col>}
                            <Col>{publishedEmail.name}</Col>
                          </Row>
                          )}
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">16. Издание индексируется базой данных Web of Science Core Collection</span>
                          <span className="common-field-hint">Выберите вариант ответа</span>
                          <Form.Item
                            name="isWebOfScience"
                            initialValue={p.isWebOfScience}
                          >
                            <Select disabled={isFormDisabled}>
                              {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">17. Импакт-фактор издания</span>
                          <span className="common-field-hint">По JCR Science Edition или JCR Social Sciences Edition, для Scopus – CiteScore (при отсутствии индексирования в Web of Science Core Collection</span>
                          <Form.Item
                            name="impactFactor"
                            initialValue={p.impactFactor}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">18. Издание индексируется базой данных Scopus</span>
                          <span className="common-field-hint">Выберите вариант ответа</span>
                          <Form.Item
                            name="isScopus"
                            initialValue={p.isScopus}
                          >
                            <Select disabled={isFormDisabled}>
                              {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">19. Издание индексируется базой данных РИНЦ</span>
                          <span className="common-field-hint">Выберите вариант ответа</span>
                          <Form.Item
                            name="isRinc"
                            initialValue={p.isRinc}
                          >
                            <Select disabled={isFormDisabled}>
                              {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">20. Входит в перечень научных журналов ВАК с Импакт-фактором > 0,5</span>
                          <span className="common-field-hint">Выберите вариант ответа</span>
                          <Form.Item
                            name="isVac"
                            initialValue={p.isVac}
                          >
                            <Select disabled={isFormDisabled}>
                              {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">21. Публикация аффилирована с организацией-грантополучателем</span>
                          <span className="common-field-hint">Выберите вариант ответа</span>
                          <Form.Item
                            name="isAffiliate"
                            initialValue={p.isAffiliate}
                          >
                            <Select disabled={isFormDisabled}>
                              {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">Файл с текстом публикации</span>
                          <span className="common-field-hint">Для материалов в открытом доступе можно не размещать, для монографий представляются отдельные страницы с выходными данными. Формат файлов - .pdf</span>
                          <Dragger
                            showUploadList={false}
                            onChange={(file) => onUploadFile(file, 'publicationFile')}
                            disabled={loading}
                            multiple
                            action={null}
                            accept=".docx,.doc,.pdf,.xlsx,.xlsm"
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
                          </Dragger>
                          {publicationFile && (
                          <Row style={{ margin: '5px 0px' }} gutter={12}>
                            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(publicationFile.urlLink)} /></Col>
                            {!loading && !isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onRemoveFile('publicationFile')} /></Col>}
                            <Col>{publicationFile.name}</Col>
                          </Row>
                          )}
                        </div> */}
                      </Col>
                    </Form>
                    <Row justify="end">
                      {objectInEditId === p.id && isExpanded && (<Col><Button type="primary" onClick={() => onCollapse()}>Закрыть</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button onClick={onCancelClick}>Отменить</Button></Col>)}
                    </Row>
                  </Modal>
                )}
          </Col>
        </Card>
      ))}
      {!objectInEditId && <Button type="primary" onClick={onAddClick} style={{ marginTop: '20px', width: '200px', fontSize: '16px' }} disabled={isFormDisabled}>Добавить публикацию</Button>}
    </div>
  );
}

AuthorTabPublicationsModule.propTypes = {
  elementsArrayProp: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  applicationId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  updateOriginalObject: PropTypes.func.isRequired,
  isAuthorTabPeople: PropTypes.bool,
};

AuthorTabPublicationsModule.defaultProps = {
  isAuthorTabPeople: false,
};

export default AuthorTabPublicationsModule;
