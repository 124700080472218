/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


const addFilesToFolder = async (files, folder) => {
  for (const fileObj of files) {
    const file = await fetch(fileObj.urlLink).then((r) => r.blob()).then((blobFile) => new File([blobFile], `${fileObj.name}`, { type: blobFile.type }));
    folder.file(`${fileObj.name}`, file, { base64: true });
  }
};


const prepareFiles = (applicationStepReport) => {
  const folders = [
    {
      name: '1. Краткая аннотации результатов выполненных работ',
      files: applicationStepReport.projectShortAnnotationFiles,
      subFolders: [],
    },
    {
      name: '2. Научные статьи ВАК',
      files: [],
      subFolders: applicationStepReport.projectVACPublications_ids.map((e, i) => ({
        name: `2.${i + 1}. Научная статья ВАК № ${i + 1}`,
        files: [e.publishedEmail_id, e.publicationFile_id].filter((o) => !!o),
      })) || [],
    },
    {
      name: '3. Научные статьи РИНЦ',
      files: [],
      subFolders: applicationStepReport.projectVACPublications_ids.map((e, i) => ({
        name: `3.${i + 1}. Научная статья РИНЦ № ${i + 1}`,
        files: [e.publishedEmail_id, e.publicationFile_id].filter((o) => !!o),
      })) || [],
    },
    {
      name: '4. Результаты интеллектуальной деятельности',
      files: [],
      subFolders: applicationStepReport.projectRID_ids.map((e, i) => ({
        name: `4.${i + 1}. РИД № ${i + 1}`,
        files: e.materials_files_field || [],
      })) || [],
    },
    {
      name: '5. Научные мероприятия',
      files: [],
      subFolders: applicationStepReport.projectEvents_ids.map((e, i) => ({
        name: `5.${i + 1}. Научное мероприятие № ${i + 1}`,
        files: e.materials_files_field || [],
      })) || [],
    },
    {
      name: '6. Международные научные мероприятия',
      files: [],
      subFolders: applicationStepReport.projectInternationalEvents_ids.map((e, i) => ({
        name: `6.${i + 1}. Международное научное мероприятие № ${i + 1}`,
        files: e.materials_files_field || [],
      })) || [],
    },
    {
      name: '7. Акты и протоколы испытаний',
      files: applicationStepReport.projectActsFiles_ids || [],
      subFolders: [],
    },
  ];

  return folders;
};

const exportZip = async (data) => {
  const zip = new JSZip();

  for (const folder of data) {
    const mainFolder = zip.folder(folder.name);
    if (folder.files.length > 0) {
      await addFilesToFolder(folder.files, mainFolder);
    }
    if (folder.subFolders.length > 0) {
      for (const sf of folder.subFolders) {
        const subFolder = mainFolder.folder(sf.name);
        if (sf.files.length > 0) {
          await addFilesToFolder(sf.files, subFolder);
        }
      }
    }
  }
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, 'Приложения к итоговому аннотированному отчету.zip');
  });
};

export const getAnnotationFiles = (applicationStepReport) => {
  const data = prepareFiles(applicationStepReport);
  exportZip(data);
};
