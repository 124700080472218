import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form, InputNumber, notification, Button,
} from 'antd';
import planFactRows from './planFactRows';
import {
  editApplicationStepReport,
} from '../../../../../../utils/http';


function PlanFactLeft({ application }) {
  const formRef = useRef();

  const onSave = () => {
    const values = formRef.current.getFieldsValue();
    editApplicationStepReport(values)
      .then(() => notification.success({ message: 'Сохранено' }));
  };

  const onChange = (field) => {
    const values = formRef.current.getFieldsValue();
    formRef.current.setFieldsValue({ [`${field}Left`]: Number((values[`${field}Plan`] - values[`${field}Fact`]).toFixed(0)) });
  };

  return (
    <>
      <Form ref={formRef}>
        <Form.Item
          name="id"
          initialValue={application.id}
          hidden
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '40%' }}>Наименование показателя</div>
          <div style={{ width: '20%' }}>План</div>
          <div style={{ width: '20%' }}>Факт</div>
          <div style={{ width: '20%' }}>Осталось</div>
        </div>
        {planFactRows.map((row) => (
          <div
            style={{
              display: 'flex', flexDirection: 'row', margin: '10px 0px', padding: '5px',
            }}
            key={row.label}
          >
            <div style={{ width: '40%' }}>{row.label}</div>
            <div style={{ width: '20%' }}>
              <Form.Item
                name={`${row.field}Plan`}
                initialValue={application[`${row.field}Plan`]}
              >
                <InputNumber style={{ width: '90%' }} onChange={() => onChange(row.field)} />
              </Form.Item>
            </div>
            <div style={{ width: '20%' }}>
              <Form.Item
                name={`${row.field}Fact`}
                initialValue={application[`${row.field}Fact`]}
              >
                <InputNumber style={{ width: '90%' }} onChange={() => onChange(row.field)} />
              </Form.Item>
            </div>
            <div style={{ width: '20%' }}>
              <Form.Item
                name={`${row.field}Left`}
                initialValue={application[`${row.field}Left`]}
              >
                <InputNumber style={{ width: '90%' }} disabled />
              </Form.Item>
            </div>
          </div>
        ))}
      </Form>
      <Button type="primary" onClick={onSave}>Сохранить</Button>
    </>
  );
}

PlanFactLeft.propTypes = {
  application: PropTypes.shape().isRequired,
};

export default PlanFactLeft;
