/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, InputNumber, Input, Button,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import md5 from 'md5';


const { TextArea } = Input;
function FinanceCostTable({
  values, disabled, type, projectTerm, formRef, onChangeDetailedSum,
}) {
  const [tableValues, setTableValues] = useState({});
  const [rows, setRows] = useState([]);

  const years = [];
  if (['1', '2', '3'].includes(projectTerm)) {
    years.push('2023');
  }
  if (['2', '3'].includes(projectTerm)) {
    years.push('2024');
  }
  if (['3'].includes(projectTerm)) {
    years.push('2025');
  }

  const getTableValues = () => {
    switch (type) {
      case 'material':
        return {
          title: '5.2. Расходы на приобретение материалов и комплектующих, необходимых для реализации Проекта',
          titleHint: 'Материалы и комплектующие — это виды запасов, которые нужны для производства продукции, выполнения работ или оказания услуг. К ним относятся: сырье, полуфабрикаты, комплектующие, основные и вспомогательные материалы, тары, топливо, запчасти и пр. (раздел II Плана счетов бухгалтерскго учета финансово-хозяйственной деятельности организации, утвержденного приказом Минфина России от 31 октября 2000 г. № 94н «Об утверждении плана счетов бухгалтерского учета финансово-хозяйственной деятельности организаций и инструкции по его применению»)',
          nameColumn: 'Перечень расходных материалов и комплектующих, планируемых к приобретению для выполнения работ по Проекту',
          descriptionColumn: 'Коротко опишите, как будут использованы данные расходные материалы и комплектующие',
          totalYear: 'Всего бюджет на приобретение расходных материалов и комплектующих за ',
          total: 'Общий бюджет на приобретение расходных материалов и комплектующих для нужд Проекта, руб:',
        };
      case 'equipment':
        return {
          title: '5.3. Расходы на приобретение оборудования и иного имущества, необходимых для реализации Проекта',
          titleHint: 'В этой статье расходов укажите узкоспециализированное оборудование или инвентарь, которые не входят в перечень офисных расходов, но необходимы для реализации проекта. Допустимо сгруппировать однотипные единицы закупки. Если для реализации проекта необходимо использовать дорогостоящее оборудование, рассмотрите вариант аренды в качестве альтернативы.',
          nameColumn: 'Перечень оборудования, планируемого к приобретению для выполнения работ по Проекту',
          descriptionColumn: 'Коротко опишите, как будет использовано данное оборудование при выполнении работ',
          totalYear: 'Всего бюджет на приобретение оборудования за ',
          total: 'Общий бюджет на приобретение оборудования для нужд Проекта, руб:',
        };
      case 'other':
        return {
          title: '5.4. Прочие прямые расходы',
          titleHint: 'В эту статью бюджета включите все расходы, которые не предусмотрены в других статьях бюджета проекта, но необходимы для реализации проекта. Недопустимо указывать за счет средств гранта такие расходы, как «Непредвиденные расходы» или аналогичные. Центр не рекомендует включать в бюджет проекта печать различных материалов за счет средств гранта. Если вы все же включаете такие расходы в бюджет проекта, обоснуйте их необходимость и проверьте на соответствие среднерыночной стоимости.',
          nameColumn: 'Перечень прочих прямых расходов, планируемых для выполнения работ по Проекту',
          nameHint: '(например: оплата публикаций, оплата командировок, организация конференций, изготовление полиграфической продукции и стендов, техническое обслуживание оборудование по договору со сторонней организацией и проч.)',
          descriptionColumn: 'Коротко опишите необходимость данных видов расходов для реализации Проекта',
          totalYear: 'Всего бюджет на прочие прямые расходы за ',
          total: 'Общий бюджет на прочие прямые расходы для нужд Проекта, руб:',
        };
      case 'paper':
        return {
          title: '5.5. Накладные и общехозяйственные расходы, предусмотренные учетной политикой организации',
          titleHint: `
            Накладные расходы организации — это дополнительные расходы, которые не связаны с деятельностью организации напрямую. 
             <br />Они не включаются в себестоимость выпускаемой продукции или оказываемых услуг. Их невозможно отнести на конкретный вид продукции, работы или услуг. <br />
          Общехозяйственные расходы — это расходы на нужды управления, не связанные непосредственно с процессом оказания услуг, выполнения работ.  <br />
          Перечень накладных расходов медицинской организации утверждается на основе следующих документов: <br /> <br />
          ● Приказ Министерства здравоохранения РФ от 25 июня 2015 года №366н. В нём определён норматив накладных расходов в сфере медицины.  <br />
          ● Инструкция к Единому плану счетов №157н. В ней предусмотрены виды расходов в разрезе групп затрат, включая накладные.  <br />
          ● Письмо Минфина от 11 июля 2012 года №02-6-10/2682. В нём указано, что к накладным расходам относят расходы, если их нельзя отнести на конкретную услугу.  <br /> <br />
          Медицинское учреждение самостоятельно определяет, как разносить затраты по видам, и прописывает это в учётной политике.
          `,
          nameColumn: 'Перечень накладных и общехозяйственных расходов, предусмотренных учетной политикой организации, и необходимых для реализации Проекта',
          nameHint: '(например: оплата персонала, не задействованного напрямую в проведении работ по гранту - бухгалтеров, экономистов, технических специалистов; коммунальные расходы - аренда, расходы на содержание зданий и проч.)',
          descriptionColumn: 'Коротко опишите необходимость данных видов расходов для реализации Проекта',
          totalYear: 'Всего бюджет на накладные и общехозяйственные расходы за ',
          total: 'Общий бюджет на накладные и общехозяйственные расходы для нужд Проекта, руб:',
        };
      default: break;
    }
    return {};
  };

  useEffect(() => {
    setTableValues(getTableValues());
    setRows(values);
  }, []);

  const onAddRow = (year) => {
    setRows([...rows, {
      id: md5(new Date() + Math.random()),
      type,
      year,
      isTotal: false,
    }]);
  };

  const onDeleteRow = (rowId) => {
    setRows(rows.filter((r) => r.id !== rowId));
  };

  const onSumChange = () => {
    const formValues = formRef.current.getFieldsValue();

    const sum = parseFloat(`${formValues[`${type}_majorSum_2023`] || 0}.${(`${formValues[`${type}_minorSum_2023`]}`).length === 1 ? `0${formValues[`${type}_minorSum_2023`]}` : formValues[`${type}_minorSum_2023`]}`) * 100
    + parseFloat(`${formValues[`${type}_majorSum_2024`] || 0}.${(`${formValues[`${type}_minorSum_2024`]}`).length === 1 ? `0${formValues[`${type}_minorSum_2024`]}` : formValues[`${type}_minorSum_2024`]}`) * 100
    + parseFloat(`${formValues[`${type}_majorSum_2025`] || 0}.${(`${formValues[`${type}_minorSum_2025`]}`).length === 1 ? `0${formValues[`${type}_minorSum_2025`]}` : formValues[`${type}_minorSum_2025`]}`) * 100;
    const sumMajor = Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100);
    const sumMinor = Number((sum % 100).toFixed(0));
    formRef.current.setFieldsValue({
      [`${type}_majorSum_total`]: sumMajor,
      [`${type}_minorSum_total`]: sumMinor,
    });

    const updatedObject = {
      [`${type}TotalMajor`]: sumMajor,
      [`${type}TotalMinor`]: sumMinor,
    };

    years.forEach((year) => {
      updatedObject[`${type}${year}Major`] = formValues[`${type}_majorSum_${year}`] || 0;
      updatedObject[`${type}${year}Minor`] = formValues[`${type}_minorSum_${year}`] || 0;
    });

    onChangeDetailedSum(updatedObject);
  };

  return (
    <div className="financeTab-wrapper">
      <div className="common-subTitle">{tableValues.title}</div>
      <div dangerouslySetInnerHTML={{ __html: tableValues.titleHint }} />
      <div className="common-table-wrapper" style={{ marginTop: '10px', borderRight: '1px solid #d9d9d9', borderBottom: '1px solid #d9d9d9' }}>
        {['1', '2', '3'].includes(projectTerm) && (
          <div className="common-table-row">
            <div className="common-table-row-cell" style={{ width: '45%', textAlign: 'center', fontWeight: 'bold' }}>{`${tableValues.totalYear}2025 год, руб:`}</div>
            <div
              className="common-table-row-cell"
              style={{
                width: '55%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
            >
              <Form.Item
                name={`${type}_id_2023`}
                hidden
                initialValue={values.find((v) => v.year === '2023' && v.isTotal)?.id || md5(new Date() + Math.random())}
              />
              <Form.Item
                style={{ width: '55%' }}
                name={`${type}_majorSum_2023`}
                initialValue={values.find((v) => v.year === '2023' && v.isTotal)?.majorSum || 0}
              >
                <InputNumber disabled={disabled} onChange={onSumChange} />
              </Form.Item>
              <span>руб.</span>
              <Form.Item
                style={{ width: '25%' }}
                name={`${type}_minorSum_2023`}
                initialValue={values.find((v) => v.year === '2023' && v.isTotal)?.minorSum || 0}
              >
                <InputNumber disabled={disabled} onChange={onSumChange} max={99} />
              </Form.Item>
              <span>коп.</span>
            </div>
          </div>
        )}
        {/* {['1', '2', '3'].includes(projectTerm) && rows.filter((v) => v.year === '2023' && !v.isTotal).map((v, index) => (
          <div className="common-table-row" key={v.id}>
            <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }} key={v.id}>
              {index + 1}
              <DeleteOutlined style={{ cursor: 'pointer', marginLeft: '5px', color: 'red' }} onClick={() => onDeleteRow(v.id)} />
            </div>
            <div className="common-table-row-cell" style={{ width: '40%', textAlign: 'center' }}>
              <Form.Item
                name={`${type}_${v.id}_id_2023`}
                hidden
                initialValue={v.id}
              />
              <Form.Item
                style={{ width: '100%' }}
                name={`${type}_${v.id}_name_2023`}
                initialValue={v.name}
              >
                <TextArea disabled={disabled} placeholder="Укажите название позиции" />
              </Form.Item>
            </div>
            <div className="common-table-row-cell" style={{ width: '55%', textAlign: 'center' }}>
              <Form.Item
                style={{ width: '100%' }}
                name={`${type}_${v.id}_description_2023`}
                initialValue={v.description}
              >
                <TextArea disabled={disabled} placeholder="Введите текст" />
              </Form.Item>
            </div>
          </div>
        ))} */}
        {/* {['1', '2', '3'].includes(projectTerm) && (
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '100%' }}>
            <Button type="primary" onClick={() => onAddRow('2023')}>Добавить</Button>
          </div>
        </div>
        )} */}
        {['2', '3'].includes(projectTerm) && (
          <div className="common-table-row">
            <div className="common-table-row-cell" style={{ width: '45%', textAlign: 'center', fontWeight: 'bold' }}>{`${tableValues.totalYear}2026 год, руб:`}</div>
            <div
              className="common-table-row-cell"
              style={{
                width: '55%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
            >
              <Form.Item
                name={`${type}_id_2024`}
                hidden
                initialValue={values.find((v) => v.year === '2024' && v.isTotal)?.id || md5(new Date() + Math.random())}
              />
              <Form.Item
                style={{ width: '55%' }}
                name={`${type}_majorSum_2024`}
                initialValue={values.find((v) => v.year === '2024' && v.isTotal)?.majorSum || 0}
              >
                <InputNumber disabled={disabled} onChange={onSumChange} />
              </Form.Item>
              <span>руб.</span>
              <Form.Item
                style={{ width: '25%' }}
                name={`${type}_minorSum_2024`}
                initialValue={values.find((v) => v.year === '2024' && v.isTotal)?.minorSum || 0}
              >
                <InputNumber disabled={disabled} onChange={onSumChange} max={99} />
              </Form.Item>
              <span>коп.</span>
            </div>
          </div>
        )}
        {/* {['2', '3'].includes(projectTerm) && rows.filter((v) => v.year === '2024' && !v.isTotal).map((v, index) => (
          <div className="common-table-row" key={v.id}>
            <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }} key={v.id}>
              {index + 1}
              <DeleteOutlined style={{ cursor: 'pointer', marginLeft: '5px', color: 'red' }} onClick={() => onDeleteRow(v.id)} />
            </div>
            <div className="common-table-row-cell" style={{ width: '40%', textAlign: 'center' }}>
              <Form.Item
                name={`${type}_${v.id}_id_2024`}
                hidden
                initialValue={v.id}
              />
              <Form.Item
                style={{ width: '100%' }}
                name={`${type}_${v.id}_name_2024`}
                initialValue={v.name}
              >
                <TextArea disabled={disabled} placeholder="Укажите название позиции" />
              </Form.Item>
            </div>
            <div className="common-table-row-cell" style={{ width: '55%', textAlign: 'center' }}>
              <Form.Item
                style={{ width: '100%' }}
                name={`${type}_${v.id}_description_2024`}
                initialValue={v.description}
              >
                <TextArea disabled={disabled} placeholder="Введите текст" />
              </Form.Item>
            </div>
          </div>
        ))} */}
        {/* {['2', '3'].includes(projectTerm) && (
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '100%' }}>
            <Button type="primary" onClick={() => onAddRow('2024')}>Добавить</Button>
          </div>
        </div>
        )} */}
        {['3'].includes(projectTerm) && (
          <div className="common-table-row">
            <div className="common-table-row-cell" style={{ width: '45%', textAlign: 'center', fontWeight: 'bold' }}>{`${tableValues.totalYear}2027 год, руб:`}</div>
            <div
              className="common-table-row-cell"
              style={{
                width: '55%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
            >
              <Form.Item
                name={`${type}_id_2025`}
                hidden
                initialValue={values.find((v) => v.year === '2025' && v.isTotal)?.id || md5(new Date() + Math.random())}
              />
              <Form.Item
                style={{ width: '55%' }}
                name={`${type}_majorSum_2025`}
                initialValue={values.find((v) => v.year === '2025' && v.isTotal)?.majorSum || 0}
              >
                <InputNumber disabled={disabled} onChange={onSumChange} />
              </Form.Item>
              <span>руб.</span>
              <Form.Item
                style={{ width: '25%' }}
                name={`${type}_minorSum_2025`}
                initialValue={values.find((v) => v.year === '2025' && v.isTotal)?.minorSum || 0}
              >
                <InputNumber disabled={disabled} onChange={onSumChange} max={99} />
              </Form.Item>
              <span>коп.</span>
            </div>
          </div>
        )}
        {/* {['3'].includes(projectTerm) && rows.filter((v) => v.year === '2025' && !v.isTotal).map((v, index) => (
          <div className="common-table-row" key={v.id}>
            <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }} key={v.id}>
              {index + 1}
              <DeleteOutlined style={{ cursor: 'pointer', marginLeft: '5px', color: 'red' }} onClick={() => onDeleteRow(v.id)} />
            </div>
            <div className="common-table-row-cell" style={{ width: '40%', textAlign: 'center' }}>
              <Form.Item
                name={`${type}_${v.id}_id_2025`}
                hidden
                initialValue={v.id}
              />
              <Form.Item
                style={{ width: '100%' }}
                name={`${type}_${v.id}_name_2025`}
                initialValue={v.name}
              >
                <TextArea disabled={disabled} placeholder="Укажите название позиции" />
              </Form.Item>
            </div>
            <div className="common-table-row-cell" style={{ width: '55%', textAlign: 'center' }}>
              <Form.Item
                style={{ width: '100%' }}
                name={`${type}_${v.id}_description_2025`}
                initialValue={v.description}
              >
                <TextArea disabled={disabled} placeholder="Введите текст" />
              </Form.Item>
            </div>
          </div>
        ))}
        {['3'].includes(projectTerm) && (
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '100%' }}>
            <Button type="primary" onClick={() => onAddRow('2025')}>Добавить</Button>
          </div>
        </div>
        )} */}
        <div className="common-table-row" style={{ borderBottom: '1px solid #d9d9d9' }}>
          <div className="common-table-row-cell" style={{ width: '45%', textAlign: 'center', fontWeight: 'bold' }}>{tableValues.total}</div>
          <div
            className="common-table-row-cell"
            style={{
              width: '55%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
          >

            <Form.Item
              name={`${type}_id_total`}
              hidden
              initialValue={values.find((v) => (v.year === '' || !v.year) && v.isTotal)?.id || md5(new Date() + Math.random())}
            />
            <Form.Item
              style={{ width: '55%' }}
              name={`${type}_majorSum_total`}
              initialValue={values.find((v) => (v.year === '' || !v.year) && v.isTotal)?.majorSum || 0}
            >
              <InputNumber disabled />
            </Form.Item>
            <span>руб.</span>
            <Form.Item
              style={{ width: '25%' }}
              name={`${type}_minorSum_total`}
              initialValue={values.find((v) => (v.year === '' || !v.year) && v.isTotal)?.minorSum || 0}
            >
              <InputNumber disabled />
            </Form.Item>
            <span>коп.</span>
          </div>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center', fontWeight: 'bold' }}>№ п/п</div>
          <div className="common-table-row-cell" style={{ width: '40%', textAlign: 'center', fontWeight: 'bold' }}>
            <div>
              {tableValues.nameColumn}
            </div>
            <span style={{ fontWeight: 'normal', fontSize: '12px', fontStyle: 'italic' }}>{tableValues.nameHint}</span>
          </div>
          <div className="common-table-row-cell" style={{ width: '55%', textAlign: 'center', fontWeight: 'bold' }}>{tableValues.descriptionColumn}</div>
        </div>
        {['1', '2', '3'].includes(projectTerm) && rows.filter((v) => v.year === '2023' && !v.isTotal).map((v, index) => (
          <div className="common-table-row" key={v.id}>
            <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }} key={v.id}>
              {index + 1}
              <DeleteOutlined style={{ cursor: 'pointer', marginLeft: '5px', color: 'red' }} onClick={() => onDeleteRow(v.id)} />
            </div>
            <div className="common-table-row-cell" style={{ width: '40%', textAlign: 'center' }}>
              <Form.Item
                name={`${type}_${v.id}_id_2023`}
                hidden
                initialValue={v.id}
              />
              <Form.Item
                style={{ width: '100%' }}
                name={`${type}_${v.id}_name_2023`}
                initialValue={v.name}
              >
                <TextArea disabled={disabled} placeholder="Укажите название позиции" />
              </Form.Item>
            </div>
            <div className="common-table-row-cell" style={{ width: '55%', textAlign: 'center' }}>
              <Form.Item
                style={{ width: '100%' }}
                name={`${type}_${v.id}_description_2023`}
                initialValue={v.description}
              >
                <TextArea disabled={disabled} placeholder="Введите текст" />
              </Form.Item>
            </div>
          </div>
        ))}
        {['1', '2', '3'].includes(projectTerm) && (
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '100%' }}>
            <Button type="primary" onClick={() => onAddRow('2023')}>Добавить</Button>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

FinanceCostTable.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  projectTerm: PropTypes.string.isRequired,
  formRef: PropTypes.shape().isRequired,
  onChangeDetailedSum: PropTypes.func.isRequired,
};

export default FinanceCostTable;
