/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-cond-assign */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import prepareXlsData from './prepareXlsData';


const ExcelJS = require('exceljs');


function toColumnName(num) {
  let ret = '';
  let a = 1;
  let b = 1;
  for (ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}


const fields = [];

const createTotalSheet = (data, worksheet) => {
  // стиль столбцов и название шапки
  data.fields.forEach((field, index) => {
    if (field.key !== 'companies') {
      worksheet.getColumn(index + 1).font = { name: 'Montserrat' };
      worksheet.getColumn(index + 1).width = field.width;

      const cell = worksheet.getCell((`${toColumnName(index + 1)}1`));
      cell.value = field.name;
      cell.alignment = { vertical: 'middle' };
      cell.style = { font: { bold: true } };
    } else {
      Array(data.maxNumberOfPeople).fill(0).forEach((_, ind) => {
        field.fields.forEach((f, i) => {
          worksheet.getColumn(index + i + ind * 5 + 1).font = { name: 'Montserrat' };
          worksheet.getColumn(index + i + ind * 5 + 1).width = f.width;

          const cell = worksheet.getCell((`${toColumnName(index + i + ind * 5 + 1)}1`));
          cell.value = `${f.name}_${ind + 1}`;
          cell.alignment = { vertical: 'middle' };
          cell.style = { font: { bold: true } };
        });
      });
    }
  });

  // создаем строчки
  data.applications.forEach((application) => {
    const rowXls = worksheet.getRow(application.index + 1);
    data.fields.forEach((field, index) => {
      if (field.key !== 'companies') {
        const fieldXls = rowXls.getCell(index + 1);
        fieldXls.value = application[field.key];
        fieldXls.alignment = {
          vertical: 'middle',
          wrapText: true,
        };
      } else {
        Array(data.maxNumberOfPeople).fill(0).forEach((_, ind) => {
          field.fields.forEach((f, i) => {
            const fieldXls = rowXls.getCell(index + i + ind * 5 + 1);
            fieldXls.value = application.companies[ind]?.[f.key];
            fieldXls.alignment = {
              vertical: 'middle',
              wrapText: true,
            };
          });
        });
      }
    });
  });
};

const createXLSDocument = async (data) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Admin';
  workbook.lastModifiedBy = 'Администратор';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const worksheet = workbook.addWorksheet('Отчет');
  createTotalSheet(data, worksheet);

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};

const arrayBufferToBase64 = (Arraybuffer) => {
  let binary = '';
  const bytes = new Uint8Array(Arraybuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = window.btoa(binary);
  const linkSource = `data:application/xls;base64,${base64String}`;
  const downloadLink = document.createElement('a');
  const fileName = 'Отчет.xls';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const onExportXLS = async (rawData) => {
  const data = prepareXlsData(rawData);
  const buffer = await createXLSDocument(data);
  arrayBufferToBase64(buffer);
};


export default onExportXLS;
