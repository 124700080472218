/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import md5 from 'md5';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';


const { TextArea } = Input;
function SectionSevenTable({
  values, disabled,
}) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(values);
  }, []);

  const onAddRow = () => {
    setRows([...rows, {
      id: md5(new Date() + Math.random()),
    }]);
  };

  const onDeleteRow = (rowId) => {
    setRows(rows.filter((r) => r.id !== rowId));
  };

  return (
    <div className="financeTab-wrapper">
      <div className="common-table-wrapper" style={{ marginTop: '10px', borderRight: '1px solid #d9d9d9', borderBottom: '1px solid #d9d9d9' }}>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center', fontWeight: 'bold' }}>№ п/п</div>
          <div className="common-table-row-cell" style={{ width: '25%', textAlign: 'center', fontWeight: 'bold' }}>Задачи, запланированные к выполнению в рамках реализации Проекта</div>
          <div className="common-table-row-cell" style={{ width: '35%', textAlign: 'center', fontWeight: 'bold' }}>Содержание выполняемых работ и мероприятий</div>
          <div className="common-table-row-cell" style={{ width: '35%', textAlign: 'center', fontWeight: 'bold' }}>Ожидаемый результат</div>
        </div>
        {rows.map((v, index) => (
          <div className="common-table-row" key={v.id}>
            <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }} key={v.id}>
              {index + 1}
              {!(disabled || getUserRole() !== 'bigAdmin') && <DeleteOutlined style={{ cursor: 'pointer', marginLeft: '5px', color: 'red' }} onClick={() => onDeleteRow(v.id)} />}
            </div>
            <Form.Item
              name={`${v.id}_orderIndex`}
              hidden
              initialValue={index}
            />
            <Form.Item
              name={`${v.id}_id`}
              hidden
              initialValue={v.id}
            />
            <div className="common-table-row-cell" style={{ width: '25%', textAlign: 'center' }}>
              <Form.Item
                style={{ width: '100%' }}
                name={`${v.id}_task`}
                initialValue={v.task}
              >
                <TextArea disabled={disabled || getUserRole() !== 'bigAdmin'} placeholder="Введите текст" />
              </Form.Item>
            </div>
            <div className="common-table-row-cell" style={{ width: '35%', textAlign: 'center' }}>
              <Form.Item
                style={{ width: '100%' }}
                name={`${v.id}_description`}
                initialValue={v.description}
              >
                <TextArea disabled={disabled} placeholder="Введите текст" />
              </Form.Item>
            </div>
            <div className="common-table-row-cell" style={{ width: '35%', textAlign: 'center' }}>
              <Form.Item
                style={{ width: '100%' }}
                name={`${v.id}_result`}
                initialValue={v.result}
              >
                <TextArea disabled={disabled} placeholder="Введите текст" />
              </Form.Item>
            </div>
          </div>
        ))}
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '100%' }}>
            <Button type="primary" onClick={onAddRow} disabled={disabled || getUserRole() !== 'bigAdmin'}>Добавить</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

SectionSevenTable.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SectionSevenTable;
