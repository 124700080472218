/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Card, Col, Row, Popconfirm, notification, Radio, Upload, Select,
} from 'antd';
import { InboxOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { editAplicationStep1Publications, editApplicationStepReport, uploadFile } from '../../../../../../utils/http';


const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;
class ReportPublicationsModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportInEditID: null,
      publications: this.props.publications,
      applicationId: this.props.applicationId,
      loading: false,
      isInView: false,
      publishedEmail_id: null,
      publicationFile_id: null,
      type: null,
    };
    this.formRef = React.createRef();
  }

  onEditClick = (p) => {
    this.setState({
      reportInEditID: p.id,
      publishedEmail_id: p.publishedEmail_id,
      publicationFile_id: p.publicationFile_id,
      type: p.type,
    });
  }

  onAddClick = () => {
    const { applicationId, publications } = this.state;
    const { fieldToSave } = this.props;
    editApplicationStepReport({ id: applicationId, annotationIsPublications: 'Да' });
    editAplicationStep1Publications({ applicationStepReport_id: applicationId, field: fieldToSave })
      .then((res) => {
        this.props.onChangeAmount(publications.length + 1);
        this.setState((prevState) => ({
          reportInEditID: res.result[0].id,
          publications: [...prevState.publications, { id: res.result[0].id }],
        }));
      });
  }

  onDeleteClick = (id) => {
    const { publications } = this.state;
    editAplicationStep1Publications({ id, isDeleted: true })
      .then(() => {
        this.props.onChangeAmount(publications.length - 1);
        this.setState((prevState) => ({
          reportInEditID: null,
          publications: prevState.publications.filter((p) => p.id !== id),
        }));
      });
  }

  onCancelClick = () => {
    this.setState({ reportInEditID: null });
  }

  onSaveClick = () => {
    const { reportInEditID, publishedEmail_id, publicationFile_id } = this.state;
    this.setState({ loading: true });
    const values = this.formRef.current.getFieldsValue();
    editAplicationStep1Publications({
      id: reportInEditID, ...values, publishedEmail_id: publishedEmail_id?.id || null, publicationFile_id: publicationFile_id?.id || null,
    })
      .then(() => {
        this.setState((prevState) => ({
          loading: false,
          reportInEditID: null,
          publishedEmail_id: null,
          type: null,
          publicationFile_id: null,
          publications: prevState.publications.map((p) => (p.id === reportInEditID ? {
            id: reportInEditID, ...values, publishedEmail_id, publicationFile_id,
          } : p)),
        }));
        notification.success({ message: 'Успешно' });
      });
  }

  onRemoveFile = (field) => {
    this.setState({ [field]: null });
  }

  onUploadFile = (info, field) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => this.setState({ [field]: file }));
    }
  }

  render() {
    const {
      reportInEditID, publications, loading, isInView, publishedEmail_id, publicationFile_id, type,
    } = this.state;
    const { label } = this.props;
    return (
      <div style={{
        margin: '20px', padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
      }}
      >
        <span style={{ fontSize: '24px', color: '#212121' }}>{label}</span>
        {publications.map((p, i) => (
          <Card
            key={p.id}
            style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
            bodyStyle={{
              borderLeft: reportInEditID === p.id ? '4px solid #1890FF' : '',
              borderRadius: reportInEditID === p.id ? '5px' : '',
            }}
          >
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  {`Научная статья №${i + 1}`}
                </Col>
                <Col>
                  <Row align="middle" gutter={10}>
                    {!reportInEditID && (<Col><Button type="primary" onClick={() => this.onEditClick(p)}>Редактировать</Button></Col>)}
                    {!reportInEditID && (
                    <Col>
                      <Popconfirm
                        title="Уверены, что хотите удалить статью?"
                        onConfirm={() => this.onDeleteClick(p.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button type="danger">Удалить</Button>
                      </Popconfirm>
                    </Col>
                    )}
                    {!reportInEditID && (
                    <Col>
                      <Button onClick={() => this.setState({
                        reportInEditID: p.id, isInView: true, type: p.type, publishedEmail_id: p.publishedEmail_id, publicationFile_id: p.publicationFile_id,
                      })}
                      >
                        Развернуть
                      </Button>
                    </Col>
                    )}
                    {reportInEditID === p.id && isInView && (
                    <Col>
                      <Button onClick={() => this.setState({
                        reportInEditID: null, isInView: false, type: null, publishedEmail_id: null, publicationFile_id: null,
                      })}
                      >
                        Свернуть
                      </Button>
                    </Col>
                    )}
                    {reportInEditID === p.id && !isInView && (<Col><Button type="primary" onClick={this.onSaveClick}>Сохранить</Button></Col>)}
                    {reportInEditID === p.id && !isInView && (<Col><Button onClick={this.onCancelClick}>Отменить</Button></Col>)}
                  </Row>
                </Col>
              </Row>
              {reportInEditID === p.id
                && (
                <Form
                  initialValues={{ remember: true }}
                  ref={this.formRef}
                >
                  <Col>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.1 Авторы публикации</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Указываются в порядке, приведенном в публикации, в формате Фамилия И.О., Фамилия2 И2.О2., и т.д.</span>
                      <Form.Item
                        name="authors"
                        initialValue={p.authors}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.2 Название публикации</span>
                      <Form.Item
                        name="name"
                        initialValue={p.name}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.3 Месяц и год публикации</span>
                      <Form.Item
                        name="date"
                        initialValue={p.date}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.4 Ключевые слова</span>
                      <Form.Item
                        name="keyWords"
                        initialValue={p.keyWords}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.5 Вид публикации</span>
                      <Form.Item
                        name="type"
                        initialValue={p.type}
                      >
                        <Select
                          disabled={loading}
                          placeholder="Необходимо заполнить"
                          onChange={(e) => this.setState({ type: e })}
                        >
                          {[
                            'Монография',
                            'Оригинальная статья',
                            'Обзорная статья',
                            'Тезисы',
                            'Другое',
                          ].map((u) => <Option key={u} value={u}>{u}</Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                    {type === 'Другое' && (
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Другое</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Внесите вид публикации</span>
                      <Form.Item
                        name="typeOther"
                        initialValue={p.typeOther}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    )}
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.6 Название издания</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Для монографий также указывается название издания, город</span>
                      <Form.Item
                        name="publisherName"
                        initialValue={p.publisherName}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">ISSN</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>При наличии</span>
                      <Form.Item
                        name="ISSN"
                        initialValue={p.ISSN}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">e-ISSN</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>При наличии</span>
                      <Form.Item
                        name="eISSN"
                        initialValue={p.eISSN}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">ISBN</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>При наличии</span>
                      <Form.Item
                        name="ISBN"
                        initialValue={p.ISBN}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Укажите квартиль (Q_) по импакт-фактору JCR Science Edition, JCR Social Sciences Edition, по SJR (квартиль издания в Scopus определяется по базе данных http://www.scimajor.com/)</span>
                      <Form.Item
                        name="qvartil"
                        initialValue={p.qvartil}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.7 Выходные данные публикации (номер, том, выпуск, страницы)</span>
                      <Form.Item
                        name="publicationParams"
                        initialValue={p.publicationParams}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Адрес полнотекстовой электронной версии публикации (URL) в открытом источнике</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>При наличии</span>
                      <Form.Item
                        name="publicationURL"
                        initialValue={p.publicationURL}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.8 DOI</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>При наличии</span>
                      <Form.Item
                        name="DOI"
                        initialValue={p.DOI}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Accession Number WoS</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>При наличии</span>
                      <Form.Item
                        name="WoS"
                        initialValue={p.WoS}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Scopus EID</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>При наличии</span>
                      <Form.Item
                        name="EID"
                        initialValue={p.EID}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.9 Принята в печать (указывается в случае официального принятия к публикации в последующих изданиях)</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Для принятых к публикации материалов пункт 16.7 не заполняется</span>
                      <Form.Item
                        name="isPublished"
                        initialValue={p.isPublished}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Письмо из редакции или издательства с извещением об официальном принятии рукописи к публикации</span>
                      <Dragger
                        showUploadList={false}
                        onChange={(file) => this.onUploadFile(file, 'publishedEmail_id')}
                        disabled={loading}
                        multiple
                        action={null}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
                      </Dragger>
                      {publishedEmail_id && (
                        <Row style={{ margin: '5px 0px' }} gutter={12}>
                          <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(publishedEmail_id.urlLink)} /></Col>
                          {!loading && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveFile('publishedEmail_id')} /></Col>}
                          <Col>{publishedEmail_id.name}</Col>
                        </Row>
                      )}
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.10 Издание индексируется базой данных Web of Science Core Collection</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="isWebOfScience"
                        initialValue={p.isWebOfScience}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          disabled={loading}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Импакт-фактор издания</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>По JCR Science Edition или JCR Social Sciences Edition, для Scopus – CiteScore (при отсутствии индексирования в Web of Science Core Collection</span>
                      <Form.Item
                        name="impactFactor"
                        initialValue={p.impactFactor}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Издание индексируется базой данных Scopus</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="isScopus"
                        initialValue={p.isScopus}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          disabled={loading}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Издание индексируется базой данных РИНЦ</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="isRinc"
                        initialValue={p.isRinc}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          disabled={loading}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Публикация аффилирована с организацией-грантополучателем</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="isAffiliate"
                        initialValue={p.isAffiliate}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          disabled={loading}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">16.15 В публикации в качестве источника финансирования указан грант Правительства Москвы</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="isMoscowGrant"
                        initialValue={p.isMoscowGrant}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          disabled={loading}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Указаны иные источники финансирования, помимо данного гранта Правительства Москвы</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="isNonMoscowGrant"
                        initialValue={p.isNonMoscowGrant}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          disabled={loading}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Файл с текстом публикации</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Для материалов в открытом доступе можно не размещать, для монографий представляются отдельные страницы с выходными данными и информацией о поддержке публикации грантом Правительства Москвы. Формат файлов - .pdf</span>
                      <Dragger
                        showUploadList={false}
                        onChange={(file) => this.onUploadFile(file, 'publicationFile_id')}
                        disabled={loading}
                        multiple
                        action={null}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
                      </Dragger>
                      {publicationFile_id && (
                        <Row style={{ margin: '5px 0px' }} gutter={12}>
                          <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(publicationFile_id.urlLink)} /></Col>
                          {!loading && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveFile('publicationFile_id')} /></Col>}
                          <Col>{publicationFile_id.name}</Col>
                        </Row>
                      )}
                    </div>
                  </Col>
                </Form>
                )}
            </Col>
          </Card>
        ))}
        {!reportInEditID && <Button type="primary" onClick={this.onAddClick} style={{ marginTop: '20px' }}>Добавить статью</Button>}
      </div>
    );
  }
}

ReportPublicationsModule.propTypes = {
  publications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  applicationId: PropTypes.string.isRequired,
  fieldToSave: PropTypes.string,
  onChangeAmount: PropTypes.func.isRequired,
  label: PropTypes.func,
};

ReportPublicationsModule.defaultProps = {
  fieldToSave: '',
  label: 'Научные статьи по теме Проекта',
};

export default ReportPublicationsModule;
