/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { FolderOpenOutlined, FileOutlined } from '@ant-design/icons';
import './ArchiveRow.css';


function ArchiveRow({ rawData, onSelectRow, selectedFileId }) {
  return (
    <div
      className="greatArchive-row-wrapper"
      onClick={() => onSelectRow(rawData, true)}
      style={{ backgroundColor: selectedFileId === rawData.id ? '#f0f1f1' : null }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <>
          {rawData.isFolder && <FolderOpenOutlined />}
          {!rawData.isFolder && <FileOutlined />}
        </>
        <div>{rawData.name}</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <div>{rawData.extension}</div>
        {rawData.size > 0 && <div>{`${(rawData.size * 0.00098).toFixed(2)}Kb`}</div>}
        <div>{rawData.author}</div>
        <div>{rawData.createDate}</div>
      </div>
    </div>
  );
}

ArchiveRow.propTypes = {
  rawData: PropTypes.shape().isRequired,
  onSelectRow: PropTypes.func.isRequired,
  selectedFileId: PropTypes.string,
};

ArchiveRow.defaultProps = {
  selectedFileId: null,
};

export default ArchiveRow;
