/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Card, Button, Input, Form, InputNumber, Menu, Dropdown, Popconfirm, Select, Divider, Tabs, Alert, Tooltip, notification,
} from 'antd';
import {
  QuestionCircleOutlined, DownloadOutlined,
} from '@ant-design/icons';
import PersonStep from './PersonStep';
import PersonPaymentStep from './PersonPaymentStep';
import PersonPaymentStepDocs from './PersonPaymentStepDocs';
import { generateApplicationPersonDocx, sendApplicationPersonsEmail } from '../../../../../utils/http';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';


const { Option } = Select;
const { TabPane } = Tabs;
const steps = [
  {
    stepName: 'Этап 1',
    index: 1,
  },
  {
    stepName: 'Этап 2',
    index: 2,
  },
  {
    stepName: 'Этап 3',
    index: 3,
  },
  {
    stepName: 'Этап 4',
    index: 4,
  },
  {
    stepName: 'Этап 5',
    index: 5,
  },
  {
    stepName: 'Этап 6',
    index: 6,
  },
];
class Person extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowCommonBlock: false,
      loading: false,
      isShowAdditionalPayment: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { person } = this.props;
    if (person.stepNDate || person.stepNRequisites || person.stepNTransfered || person.stepNComment) {
      this.setState({ isShowAdditionalPayment: true });
    }
  }

  onSave = () => {
    const { onSavePerson, person } = this.props;
    const values = this.formRef.current.getFieldsValue();
    onSavePerson(values, person.id);
  }

  onFinishPerson = () => {
    const { onFinishPerson, person } = this.props;
    const personFields = Object.keys(person);
    let isAllFieldsDone = true;
    personFields.forEach((f) => {
      if (person[f] == null) {
        isAllFieldsDone = false;
      }
    });
    if (isAllFieldsDone) {
      onFinishPerson(person.id);
    } else {
      notification.error({ message: 'Заполните и сохраните все поля, пожалуйста! Общая сумма средств должна совпадать с суммой по этапам ' });
    }
  }

  onDownloadPerson = () => {
    const { person } = this.props;
    this.setState({ loading: true });
    generateApplicationPersonDocx({ id: person.id })
      .then((url) => {
        if (url) {
          window.open(url);
        } else {
          notification.error({ message: 'Что-то пошло не так' });
        }
        this.setState({ loading: false });
      });
  }

  sendApplicationPersonsEmail = () => {
    const { person } = this.props;
    this.setState({ loading: true });
    sendApplicationPersonsEmail({ id: person.id })
      .then(() => {
        this.setState({ loading: false });
        notification.success({ message: 'Письмо с формой отправлено!' });
      });
  }

  render() {
    const { isShowCommonBlock, loading, isShowAdditionalPayment } = this.state;
    const {
      person, disabled, isEditPersonID, onCancelPerson, onEditPerson, onDeletePerson, agreementPersonsStatus, onShowModal,
    } = this.props;

    return (
      <div>
        <Card
          style={{ width: '100%', borderRadius: '15px', marginTop: '20px' }}
          bodyStyle={{
            borderLeft: isEditPersonID === person.id ? '4px solid #1890FF' : '',
            borderRadius: isEditPersonID === person.id ? '15px' : '',
          }}
        >
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Row>
                  <Col>
                    <Row style={{ fontSize: '2vh', color: '#212121' }}>
                      {person.form}
                    </Row>
                    <Row>
                      {person.totalSum}
                    </Row>
                    <Row style={{
                      color: '#212121', fontSize: '1.8vh', marginTop: '10px', marginBottom: '10px',
                    }}
                    >
                      Скан-копия договора (соглашения)
                    </Row>
                    {getUserRole() === 'bigAdmin' && (
                    <Button onClick={onShowModal} style={{ marginRight: '10px' }}>Загрузить</Button>
                    )}
                    {person.agreementScanFile_id && (
                    <Button type="primary" onClick={() => window.open(person.agreementScanFile_id.urlLink)}>Скачать</Button>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row justify="end">
                  {!isEditPersonID && ((agreementPersonsStatus !== 'Согласовано' && agreementPersonsStatus !== 'На согласовании') || getUserRole() === 'bigAdmin') && (
                  <Col style={{ cursor: 'pointer', marginRight: '10px' }}>
                    <Dropdown
                      trigger={['click']}
                      overlay={(
                        <Menu>
                          <Menu.Item key={1}>
                            <a onClick={() => onEditPerson(person.id)}>
                              Редактировать
                            </a>
                          </Menu.Item>
                          <Popconfirm
                            key={2}
                            title="Уверены, что хотите удалить соисполнителя?"
                            onConfirm={() => onDeletePerson(person.id)}
                            okText="Да"
                            cancelText="Нет"
                          >
                            <Menu.Item danger key={2}>
                              Удалить
                            </Menu.Item>
                          </Popconfirm>
                        </Menu>
)}
                    >
                      <Button type="primary">Меню</Button>
                    </Dropdown>
                  </Col>
                  )}
                  {!isEditPersonID && (
                  <Col style={{ cursor: 'pointer' }} onClick={() => this.setState((prevState) => ({ isShowCommonBlock: !prevState.isShowCommonBlock }))}>
                    {isShowCommonBlock ? <Button>Закрыть</Button> : <Button>Открыть</Button>}
                  </Col>
                  )}
                </Row>
                {getUserRole() === 'bigAdmin' && (
                <Row style={{ marginTop: '10px' }}>
                  <Col>
                    <Button disabled={loading} shape="round" onClick={this.onDownloadPerson} icon={<DownloadOutlined />}>Скачать docx</Button>
                  </Col>
                  <Col>
                    <Button style={{ marginLeft: '10px' }} disabled={loading} shape="round" onClick={this.sendApplicationPersonsEmail}>Отправить email</Button>
                  </Col>
                </Row>
                )}
              </Col>
            </Row>
            {(isShowCommonBlock || isEditPersonID === person.id) && (
            <Form
              ref={this.formRef}
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="Общая информация" key="1">
                  <Col span={24} style={{ marginTop: '20px' }}>
                    <span style={{ color: '#212121', fontSize: '0.9rem' }}>Общая информация</span>
                    <Row gutter={40} style={{ marginTop: '5px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>
                        Общая сумма средств, подлежащая соисполнителю 1 по договору НИР:
                        <Tooltip title="Введите точную сумму, подлежащую перечислению указанному соисполнителю за весь период реализации проекта">
                          <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#1890FF' }} />
                        </Tooltip>
                      </Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="totalSum"
                              initialValue={person.totalSum}
                            >
                              <Input disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.totalSum ? person.totalSum : 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: '20px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>Организационно-правовая форма и наименование организации/учреждения-соисполнителя:</Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="form"
                              initialValue={person.form}
                            >
                              <Input disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.form || 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: '20px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>ОГРН Соисполнителя:</Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="ogrn"
                              initialValue={person.ogrn}
                            >
                              <InputNumber disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.ogrn || 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: '20px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>ИНН Соисполнителя:</Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="inn"
                              initialValue={person.inn}
                            >
                              <InputNumber disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.inn || 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: '20px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>Соисполнитель является организацией, подведомственной Департаменту здравоохранения города Москвы:</Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="isMoscow"
                              initialValue={person.isMoscow}
                            >
                              <Select disabled={disabled} style={{ width: '100%' }}>
                                {['Да', 'Нет'].map((u) => <Option key={u} value={u}>{u}</Option>)}
                              </Select>
                            </Form.Item>
                          )
                          : <span>{person.isMoscow || 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: '20px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>Кол-во этапов:</Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="maxStepsNumber"
                              initialValue={person.maxStepsNumber}
                            >
                              <InputNumber disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.maxStepsNumber || 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                    <Divider />
                    <span style={{ color: '#212121', fontSize: '0.9rem' }}>Научный руководитель</span>
                    <Row gutter={40} style={{ marginTop: '5px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>ФИО:</Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="directorFullName"
                              initialValue={person.directorFullName}
                            >
                              <Input disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.directorFullName || 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                    <Divider />
                    <span style={{ color: '#212121', fontSize: '0.9rem' }}>Ответственное за проект лицо от Соисполнителя</span>
                    <Row gutter={40} style={{ marginTop: '5px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>ФИО:</Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="fullName"
                              initialValue={person.fullName}
                            >
                              <Input disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.fullName || 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: '5px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>Должность:</Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="position"
                              initialValue={person.position}
                            >
                              <Input disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.position || 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: '20px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>Телефон:</Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="phone"
                              initialValue={person.phone}
                            >
                              <Input disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.phone || 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: '20px' }}>
                      <Col span={6} style={{ fontSize: '1.5vh' }}>E-mail:</Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="email"
                              initialValue={person.email}
                            >
                              <Input disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.email || 'Не заполнено'}</span>}
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tab="Календарный план работ" key="2">
                  <Col span={24}>
                    <Alert
                      style={{ marginBottom: '20px' }}
                      description="Внимание! Если работы партнера на каком-то этапе не предусмотрены, стоимость работ укажите 0, а в содержании поставьте прочерк (-)"
                      type="warning"
                    />
                    {steps.filter((s) => s.index <= person.maxStepsNumber).map((step) => (
                      <PersonStep
                        key={step.index}
                        stepName={step.stepName}
                        fieldNameDescription={`step${step.index}Description`}
                        fieldNameCost={`step${step.index}Cost`}
                        isEdit={isEditPersonID === person.id}
                        person={person}
                        disabled={disabled}
                      />
                    ))}
                  </Col>
                </TabPane>
                <TabPane tab="Сведения об оплатах" key="3">
                  <Col span={24}>
                    <Row gutter={40} style={{ marginTop: '5px', marginBottom: '20px' }} align="bottom">
                      <Col span={3} style={{ fontSize: '1.5vh' }}>
                        Доведено средств ИТОГО по всем этапам
                      </Col>
                      <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
                        {isEditPersonID === person.id
                          ? (
                            <Form.Item
                              name="allStepTransferedTotal"
                              initialValue={person.allStepTransferedTotal}
                            >
                              <InputNumber disabled={disabled} style={{ width: '100%' }} />
                            </Form.Item>
                          )
                          : <span>{person.allStepTransferedTotal ? person.allStepTransferedTotal : 'Не заполнено'}</span>}
                      </Col>
                    </Row>

                    {steps.filter((s) => s.index <= person.maxStepsNumber).map((step) => (
                      <Col span={24}>
                        <Row style={{ color: '#212121', fontSize: '1.4rem', marginBottom: '10px' }}>{step.stepName}</Row>
                        <PersonPaymentStep
                          key={step.index}
                          isEdit={isEditPersonID === person.id}
                          person={person}
                          disabled={false}
                          fieldNameDate={`step${step.index}Date`}
                          fieldNameRequisites={`step${step.index}Requisites`}
                          fieldNameTransfered={`step${step.index}Transfered`}
                          fieldNameComment={`step${step.index}Comment`}
                          fieldNameDateClose={`step${step.index}DateClose`}
                          fieldNameRequisitesClose={`step${step.index}RequisitesClose`}
                          fieldNameTransferedClose={`step${step.index}TransferedClose`}
                          fieldNameCommentClose={`step${step.index}CommentClose`}
                          fieldNameTransferedTotal={`step${step.index}TransferedTotal`}
                        />
                      </Col>
                    ))}

                    {!isShowAdditionalPayment && <Button type="primary" style={{ marginBottom: '20px' }} onClick={() => this.setState({ isShowAdditionalPayment: true })}>Добавить оплату</Button>}


                    {isShowAdditionalPayment && (
                    <Col span={24}>
                      <Row style={{ color: '#212121', fontSize: '1.4rem', marginBottom: '10px' }}>Дополнительная оплата</Row>
                      <PersonPaymentStep
                        key="N"
                        isShort
                        isEdit={isEditPersonID === person.id}
                        person={person}
                        disabled={false}
                        fieldNameDate="stepNDate"
                        fieldNameRequisites="stepNRequisites"
                        fieldNameTransfered="stepNTransfered"
                        fieldNameComment="stepNComment"
                      />
                    </Col>
                    )}
                  </Col>
                </TabPane>
                <TabPane tab="Отчетность" key="4">
                  <Col span={24}>
                    {steps.filter((s) => s.index <= person.maxStepsNumber).map((step) => (
                      <Col span={24} key={step.index}>
                        <Row style={{ color: '#212121', fontSize: '1.4rem', marginBottom: '10px' }}>{step.stepName}</Row>
                        <PersonPaymentStepDocs
                          originalPerson={person}
                          fieldName={`step${step.index}files_ids`}
                        />
                      </Col>
                    ))}
                  </Col>
                </TabPane>
              </Tabs>
            </Form>
            )}
          </Col>
          {isEditPersonID === person.id && (
          <Row gutter={10} style={{ marginTop: '20px' }}>
            <Col><Button onClick={onCancelPerson}>Закрыть</Button></Col>
            <Col><Button type="primary" onClick={this.onSave}>Сохранить</Button></Col>
            <Col>
              <Popconfirm
                title="Вы уверены, что хотите завершить заполнение? Вы не сможете редактировать соисполнителя после завершения"
                onConfirm={this.onFinishPerson}
                okText="Да"
                cancelText="Нет"
              >
                <Button type="primary" style={{ backgroundColor: '#389e0d', color: '#fff' }}>Завершить</Button>
              </Popconfirm>
            </Col>
          </Row>
          )}
        </Card>
      </div>
    );
  }
}

Person.propTypes = {
  person: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  isEditPersonID: PropTypes.string,
  onSavePerson: PropTypes.func.isRequired,
  onCancelPerson: PropTypes.func.isRequired,
  onEditPerson: PropTypes.func.isRequired,
  onDeletePerson: PropTypes.func.isRequired,
  onFinishPerson: PropTypes.func.isRequired,
  agreementPersonsStatus: PropTypes.string,
  maxStepsNumber: PropTypes.number.isRequired,
  onShowModal: PropTypes.func.isRequired,
};

Person.defaultProps = {
  isEditPersonID: null,
  agreementPersonsStatus: '',
};

export default Person;
