/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { groupBy } from 'lodash';


const addFilesToFolder = async (files, name, folder) => {
  for (const [index, fileObj] of files.entries()) {
    const file = await fetch(fileObj.urlLink).then((r) => r.blob()).then((blobFile) => new File([blobFile], `${name} ${index + 1}`, { type: blobFile.type }));
    folder.file(`${name} ${index + 1}.pdf`, file, { base64: true });
  }
};


const exportZip = async (data, archiveName) => {
  const grouped = groupBy(data, 'webUserShortName');
  const orgNames = Object.keys(grouped);
  const zip = new JSZip();

  for (const orgName of orgNames) {
    const orgFolder = zip.folder(orgName);

    for (const app of grouped[orgName]) {
      const appFolder = orgFolder.folder(app.displayID);
      if (app.printFormFull) {
        const printFormFull = await fetch(app.printFormFull).then((r) => r.blob()).then((blobFile) => new File([blobFile], `Заявка ${app.displayID}`, { type: blobFile.type }));
        appFolder.file(`Заявка ${app.displayID}.pdf`, printFormFull, { base64: true });
      }
      if (app.printFormFullSigned) {
        const printFormFullSigned = await fetch(app.printFormFullSigned).then((r) => r.blob()).then((blobFile) => new File([blobFile], `Подписанная заявка ${app.displayID}`, { type: blobFile.type }));
        appFolder.file(`Подписанная заявка ${app.displayID}.pdf`, printFormFullSigned, { base64: true });
      }
      if (app.supervisorSOPDFiles_ids.length > 0) {
        await addFilesToFolder(app.supervisorSOPDFiles_ids, `СОПД ${app.supervisorFullName} (научный руководитель)`, appFolder);
      }
      if (app.directorSOPDFiles_ids.length > 0) {
        await addFilesToFolder(app.directorSOPDFiles_ids, `СОПД ${app.directorFullName} (руководитель организации заявителя)`, appFolder);
      }
      if (app.contactFinanceSOPDFiles_ids.length > 0) {
        await addFilesToFolder(app.contactFinanceSOPDFiles_ids, `СОПД ${app.contactFinanceFullName} (ответственном контактном лице, отвечающем за предоставление финансовых данных по заявке и Проекту (смета, финансовый отчет))`, appFolder);
      }
      if (app.epidemicFiles_ids.length > 0) {
        await addFilesToFolder(app.epidemicFiles_ids, 'Актуальность проекта', appFolder);
      }
      if (app.economicEffectFiles_ids.length > 0) {
        await addFilesToFolder(app.economicEffectFiles_ids, 'Экономическая эффективность', appFolder);
      }
      if (app.medicalEffectFiles_ids.length > 0) {
        await addFilesToFolder(app.medicalEffectFiles_ids, 'Медицинская эффективность', appFolder);
      }
      if (app.stateFiles_ids.length > 0) {
        await addFilesToFolder(app.stateFiles_ids, 'Состояние науки', appFolder);
      }
      if (app.supervisorRIDFiles_ids.length > 0) {
        await addFilesToFolder(app.supervisorRIDFiles_ids, 'Патенты', appFolder);
      }
      if (app.applications2AuthorTabPeople_ids.length > 0) {
        for (const authorTabPerson of app.applications2AuthorTabPeople_ids) {
          if (authorTabPerson.SOPDFiles_ids.length > 0) {
            await addFilesToFolder(authorTabPerson.SOPDFiles_ids, `СОПД ${authorTabPerson.fullName}`, appFolder);
          }
        }
      }
      if (app.applications2PersonsTabPeople_ids.length > 0) {
        for (const person of app.applications2PersonsTabPeople_ids) {
          if (person.confirmEmail_id) {
            const file = await fetch(person.confirmEmail_id.urlLink).then((r) => r.blob()).then((blobFile) => new File([blobFile], `Письмо ${person.fullName}.pdf`, { type: blobFile.type }));
            appFolder.file(`Письмо ${person.fullName}.pdf`, file, { base64: true });
          }
        }
      }
      if (app.financeFiles_ids.length > 0) {
        await addFilesToFolder(app.financeFiles_ids, 'Смета', appFolder);
      }
    }
  }
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, archiveName ?? 'Заявки 2023 (На рассмотрении).zip');
  });
};

export default exportZip;
