/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Card, Modal, Button, Input, notification, Upload,
} from 'antd';
import {
  EditTwoTone, InboxOutlined, DownloadOutlined, DeleteOutlined,
} from '@ant-design/icons';
import Person from './Person';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';
import { editApplication, editApplicationPersons, uploadFile } from '../../../../../utils/http';


const { Dragger } = Upload;
class People extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiled: null,
      selectedFiledValue: null,
      isEditPersonID: null,
      application: this.props.application,
      isShowFileModal: false,
      file: null,
    };
    this.formRef = React.createRef();
  }

    onFieldSelect = (selectedFiled) => {
      this.setState({ selectedFiled });
    }

    onCloseModal = () => {
      this.setState({ selectedFiled: null, selectedFiledValue: null });
    }

    onSaveModal = () => {
      const { selectedFiled, selectedFiledValue, application } = this.state;
      editApplication({ id: application.id, [selectedFiled.name]: selectedFiledValue })
        .then(() => {
          this.setState((prevState) => ({
            application: { ...prevState.application, [selectedFiled.name]: selectedFiledValue },
            selectedFiled: null,
            selectedFiledValue: null,
          }));
        });
    }

    onchangeFieldValue = (selectedFiledValue) => {
      this.setState({ selectedFiledValue });
    }

    onCancelPerson = () => {
      this.setState({ isEditPersonID: null });
    }

    onEditPerson = (isEditPersonID) => {
      this.setState({ isEditPersonID });
    }

    onFinishPerson = (id) => {
      editApplicationPersons({ id, status: 'Завершено' })
        .then((person) => {
          notification.success({ message: 'Сохранено успешно' });
          this.setState((prevState) => ({
            isEditPersonID: null,
            application: {
              ...prevState.application,
              persons: prevState.application.persons.map((p) => (p.id === id ? person : p)),
            },
          }));
        });
    }


    onAddPerson = () => {
      const { application } = this.state;
      editApplicationPersons({ application_id: application.id, status: 'Черновик' })
        .then((appPerson) => this.setState((prevState) => ({
          application: {
            ...prevState.application,
            persons: [...prevState.application.persons, appPerson],
          },
        })));
    }

    onDeletePerson = (id) => {
      editApplicationPersons({ id, isDeleted: true })
        .then(() => this.setState((prevState) => ({
          application: {
            ...prevState.application,
            persons: prevState.application.persons.filter((p) => p.id !== id),
          },
        })));
    }

    onSavePerson = (values, id) => {
      editApplicationPersons({ id, ...values, status: 'Черновик' })
        .then((person) => {
          notification.success({
            message: 'Сохранено успешно',
          });
          this.setState((prevState) => ({
            application: {
              ...prevState.application,
              persons: prevState.application.persons.map((p) => (p.id === id ? person : p)),
            },
          }));
        });
    }

    onSingleFileUpload = (info) => {
      if (info.file.status === 'uploading' && !info.event) {
        const form = new FormData();
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        form.append('file', info.file.originFileObj);
        uploadFile(form, headers)
          .then((file) => {
            this.setState({ file });
          });
      }
    };

    onSingleFileRemove = () => {
      this.setState({ file: null });
    };

    onSaveSingleFileModal = () => {
      const { file, isShowFileModal } = this.state;
      editApplicationPersons({ id: isShowFileModal, agreementScanFile_id: file.id })
        .then(() => {
          notification.success({ message: 'Сохранено успешно' });
          this.setState((prevState) => ({
            isEditPersonID: null,
            application: {
              ...prevState.application,
              persons: prevState.application.persons.map((p) => (p.id === isShowFileModal ? { ...p, agreementScanFile_id: file } : p)),
            },
            file: null,
            isShowFileModal: null,
          }));
          notification.success({ message: 'Сохранено' });
        });
    };

    render() {
      const {
        selectedFiled, isEditPersonID, application, isShowFileModal, file,
      } = this.state;
      const {
        disabled,
      } = this.props;

      return (
        <div style={{ overflowY: 'scroll', height: '79vh' }}>
          <Card style={{ width: '100%', borderRadius: '15px', marginTop: '20px' }}>
            <Col span={24}>
              <Row style={{ fontSize: '2vh', color: '#212121' }} justify="space-between">
                <Col>
                  Общая информация
                </Col>
              </Row>
              <Col span={24}>
                <Row gutter={40} style={{ marginTop: '20px' }}>
                  <Col span={6} style={{ fontSize: '1.5vh' }}>Общая сумма средств гранта по Соглашению</Col>
                  <Col span={12}>
                    <Row style={{ fontSize: '1.7vh', color: '#212121' }} gutter={8} wrap={false}>
                      {getUserRole() === 'bigAdmin' && (
                      <Col>
                        <EditTwoTone
                          color="#1890ff"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.onFieldSelect({
                            name: 'peopleAgreementTotalSum', label: 'Общая сумма средств гранта по Соглашению', value: application.peopleAgreementTotalSum, fieldType: 'input',
                          })}
                        />
                      </Col>
                      )}
                      <Col style={{ whiteSpace: 'break-spaces' }} flex="auto">
                        {application.peopleAgreementTotalSum}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={40} style={{ marginTop: '20px' }}>
                  <Col span={6} style={{ fontSize: '1.5vh' }}>Средств грантополучателя из общей суммы средств гранта</Col>
                  <Col span={12}>
                    <Row style={{ fontSize: '1.7vh', color: '#212121' }} gutter={8} wrap={false}>
                      {getUserRole() === 'bigAdmin' && (
                      <Col>
                        <EditTwoTone
                          color="#1890ff"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.onFieldSelect({
                            name: 'peopleMainTotalSum', label: 'Средств грантополучателя из общей суммы средств гранта', value: application.peopleMainTotalSum, fieldType: 'input',
                          })}
                        />
                      </Col>
                      )}
                      <Col style={{ whiteSpace: 'break-spaces' }} flex="auto">
                        {application.peopleMainTotalSum}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={40} style={{ marginTop: '20px' }}>
                  <Col span={6} style={{ fontSize: '1.5vh' }}>Средств соисполнителей из общей суммы средств гранта</Col>
                  <Col span={12}>
                    <Row style={{ fontSize: '1.7vh', color: '#212121' }} gutter={8} wrap={false}>
                      {getUserRole() === 'bigAdmin' && (
                      <Col>
                        <EditTwoTone
                          color="#1890ff"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.onFieldSelect({
                            name: 'peoplePersonsTotalSum', label: 'Средств соисполнителей из общей суммы средств гранта', value: application.peoplePersonsTotalSum, fieldType: 'input',
                          })}
                        />
                      </Col>
                      )}
                      <Col style={{ whiteSpace: 'break-spaces' }} flex="auto">
                        {application.peoplePersonsTotalSum}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Card>
          {application.persons.map((p, index) => (
            <Person
              key={p.id}
              index={index + 1}
              person={p}
              disabled={disabled || (p.status === 'Завершено' && getUserRole() !== 'bigAdmin')}
              isEditPersonID={isEditPersonID}
              onSavePerson={this.onSavePerson}
              onCancelPerson={this.onCancelPerson}
              onEditPerson={this.onEditPerson}
              onDeletePerson={this.onDeletePerson}
              onFinishPerson={this.onFinishPerson}
              agreementPersonsStatus={application.agreementPersonsStatus}
              maxStepsNumber={application.maxStepsNumber}
              onShowModal={() => this.setState({ isShowFileModal: p.id })}
            />
          ))}
          {!isEditPersonID && application.agreementPersonsStatus !== 'На согласовании' && application.agreementPersonsStatus !== 'Согласовано' && <Button type="primary" style={{ marginTop: '20px' }} onClick={this.onAddPerson}>Добавить соисполнителя</Button>}
          {selectedFiled
      && (
      <Modal
        title={selectedFiled.label}
        onCancel={this.onCloseModal}
        visible
        footer={[
          <Button key="back" onClick={this.onCloseModal}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={this.onSaveModal}>
            Сохранить
          </Button>,
        ]}
      >
        <Col span={24}>
          {selectedFiled.hint && <Row style={{ fontSize: '1.3vh', marginBottom: '5px' }}>{selectedFiled.hint}</Row>}
          <Row>
            {selectedFiled.fieldType === 'input' && <Input onChange={(e) => this.onchangeFieldValue(e.target.value)} defaultValue={selectedFiled.value} style={{ width: '100%' }} />}
          </Row>
        </Col>
      </Modal>
      )}
          {isShowFileModal && (
          <Modal
            visible
            width="70vw"
            maskClosable={false}
            onCancel={() => this.setState({ isShowFileModal: null, file: null })}
            title={`Загрузка файла для ${application.displayID}`}
            footer={[
              <Button key="submit" type="primary" onClick={this.onSaveSingleFileModal}>
                Загрузить
              </Button>]}
          >
            <Col>
              {!file && (
              <Dragger
                showUploadList={false}
                onChange={this.onSingleFileUpload}
                action={null}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
              </Dragger>
              )}
              {file
            && (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(file.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={this.onSingleFileRemove} /></Col>
              <Col>{file.name}</Col>
            </Row>
            )}
            </Col>
          </Modal>
          )}
        </div>
      );
    }
}

People.propTypes = {
  application: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default People;
