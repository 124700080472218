import React from 'react';
import { Tag } from 'antd';


const getStatus = (status) => {
  switch (status) {
    case 'Черновик':
      return <Tag color="purple">Черновик</Tag>;
    case 'Согласован':
      return <Tag color="green">Согласован</Tag>;
    case 'Отклонен':
      return <Tag color="red">Отклонен</Tag>;
    case 'На рассмотрении':
      return <Tag color="blue">На рассмотрении</Tag>;
    default:
      return <Tag color="gold">Без статуса</Tag>;
  }
};

export default getStatus;
