import { LOCALES } from '../locales';


export default {
  login: {
    id: 'login',
    [LOCALES.RUSSIAN]: 'Адрес электронной почты',
    [LOCALES.ENGLISH]: 'Username',
  },
  enter: {
    id: 'enter',
    [LOCALES.RUSSIAN]: 'Войти',
    [LOCALES.ENGLISH]: 'Login',
  },
  password: {
    id: 'password',
    [LOCALES.RUSSIAN]: 'Пароль',
    [LOCALES.ENGLISH]: 'Password',
  },
  registration: {
    id: 'registration',
    [LOCALES.RUSSIAN]: 'Регистрация',
    [LOCALES.ENGLISH]: 'Registration',
  },
  restorePass: {
    id: 'restorePass',
    [LOCALES.RUSSIAN]: 'Восстановить пароль',
    [LOCALES.ENGLISH]: 'Restore password',
  },
  enterLogin: {
    id: 'enterLogin',
    [LOCALES.RUSSIAN]: 'Пожалуйста, введите логин',
    [LOCALES.ENGLISH]: 'Please enter login',
  },
  enterPassword: {
    id: 'enterPassword',
    [LOCALES.RUSSIAN]: 'Пожалуйста, введите пароль',
    [LOCALES.ENGLISH]: 'Please enter password',
  },
  success: {
    id: 'success',
    [LOCALES.RUSSIAN]: 'Успешно',
    [LOCALES.ENGLISH]: 'Success',
  },
  passwordError: {
    id: 'passwordError',
    [LOCALES.RUSSIAN]: 'Неверный логин или пароль',
    [LOCALES.ENGLISH]: 'Wrong login or password',
  },
  users: {
    id: 'users',
    [LOCALES.RUSSIAN]: 'Пользователи',
    [LOCALES.ENGLISH]: 'Users',
  },
  usersPopover: {
    id: 'usersPopover',
    [LOCALES.RUSSIAN]: 'Здесь можно управлять аккаунтами пользователей',
    [LOCALES.ENGLISH]: 'Here you can manage user accounts',
  },
  search: {
    id: 'search',
    [LOCALES.RUSSIAN]: 'Введите для поиска',
    [LOCALES.ENGLISH]: 'Enter for search',
  },
  tableUserName: {
    id: 'tableUserName',
    [LOCALES.RUSSIAN]: 'Имя',
    [LOCALES.ENGLISH]: 'Name',
  },
  tableUserNamePopover: {
    id: 'tableUserNamePopover',
    [LOCALES.RUSSIAN]: 'Имя пользователя',
    [LOCALES.ENGLISH]: 'User name here',
  },
  add: {
    id: 'add',
    [LOCALES.RUSSIAN]: 'Добавить',
    [LOCALES.ENGLISH]: 'Add',
  },
  newUserModalTitle: {
    id: 'newUserModalTitle',
    [LOCALES.RUSSIAN]: 'Новый пользователь',
    [LOCALES.ENGLISH]: 'New user',
  },
  cancel: {
    id: 'cancel',
    [LOCALES.RUSSIAN]: 'Отмена',
    [LOCALES.ENGLISH]: 'Cancel',
  },
  save: {
    id: 'save',
    [LOCALES.RUSSIAN]: 'Сохранить',
    [LOCALES.ENGLISH]: 'Save',
  },
  done: {
    id: 'done',
    [LOCALES.RUSSIAN]: 'Готово',
    [LOCALES.ENGLISH]: 'Done',
  },
  firstName: {
    id: 'firstName',
    [LOCALES.RUSSIAN]: 'Имя',
    [LOCALES.ENGLISH]: 'First Name',
  },
  lastName: {
    id: 'lastName',
    [LOCALES.RUSSIAN]: 'Фамилия',
    [LOCALES.ENGLISH]: 'Last Name',
  },
  middleName: {
    id: 'middleName',
    [LOCALES.RUSSIAN]: 'Отчество',
    [LOCALES.ENGLISH]: 'Middle Name',
  },
  newUserModalIdPlaceholder: {
    id: 'newUserModalIdPlaceholder',
    [LOCALES.RUSSIAN]: 'Пожалуйста введите уникальный ID',
    [LOCALES.ENGLISH]: 'Please enter unique ID',
  },
  editUserDrawerTitle: {
    id: 'editUserDrawerTitle',
    [LOCALES.RUSSIAN]: 'Изменить данные пользователя',
    [LOCALES.ENGLISH]: 'Edit user data',
  },
  personalData: {
    id: 'personalData',
    [LOCALES.RUSSIAN]: 'Личные данные',
    [LOCALES.ENGLISH]: 'Personal Data',
  },
  notifications: {
    id: 'notifications',
    [LOCALES.RUSSIAN]: 'Уведомления',
    [LOCALES.ENGLISH]: 'Notifications',
  },
  help: {
    id: 'help',
    [LOCALES.RUSSIAN]: 'Помощь',
    [LOCALES.ENGLISH]: 'Help',
  },
  settings: {
    id: 'settings',
    [LOCALES.RUSSIAN]: 'Настройки',
    [LOCALES.ENGLISH]: 'Settings',
  },
  logout: {
    id: 'logout',
    [LOCALES.RUSSIAN]: 'Выход',
    [LOCALES.ENGLISH]: 'Logout',
  },
  generalSettings: {
    id: 'generalSettings',
    [LOCALES.RUSSIAN]: 'Общие настройки',
    [LOCALES.ENGLISH]: 'General Settings',
  },
  personalDataFIOHint: {
    id: 'personalDataFIOHint',
    [LOCALES.RUSSIAN]: 'Укажите ваши Имя, Фамилию, Отчество и Дату Рождения',
    [LOCALES.ENGLISH]: 'Enter your first name, last name, birthday and optional middle name',
  },
  email: {
    id: 'email',
    [LOCALES.RUSSIAN]: 'E-mail',
    [LOCALES.ENGLISH]: 'E-mail',
  },
  phone: {
    id: 'phone',
    [LOCALES.RUSSIAN]: 'Мобильный телефон',
    [LOCALES.ENGLISH]: 'Phone',
  },
  personalDataContactHint: {
    id: 'personalDataContactHint',
    [LOCALES.RUSSIAN]: 'Укажите ваши контактные данные, чтобы мы могли с вами связаться',
    [LOCALES.ENGLISH]: 'Here you can add your phone and email so we can contact you',
  },
  setNewPhoto: {
    id: 'setNewPhoto',
    [LOCALES.RUSSIAN]: 'Изменить фотографию',
    [LOCALES.ENGLISH]: 'Set New Photo',
  },
  selectImage: {
    id: 'selectImage',
    [LOCALES.RUSSIAN]: 'Выбрать фотографию',
    [LOCALES.ENGLISH]: 'Select Image',
  },
  removeImage: {
    id: 'removeImage',
    [LOCALES.RUSSIAN]: 'Удалить фотографию',
    [LOCALES.ENGLISH]: 'Remove Image',
  },
  pickADate: {
    id: 'pickADate',
    [LOCALES.RUSSIAN]: 'Выберите дату',
    [LOCALES.ENGLISH]: 'Pick a date',
  },
  birthdayDate: {
    id: 'birthdayDate',
    [LOCALES.RUSSIAN]: 'Дата рождения',
    [LOCALES.ENGLISH]: 'Birthday date',
  },
  lightTheme: {
    id: 'lightTheme',
    [LOCALES.RUSSIAN]: 'Светлая тема',
    [LOCALES.ENGLISH]: 'Light theme',
  },
  darkTheme: {
    id: 'darkTheme',
    [LOCALES.RUSSIAN]: 'Темная тема',
    [LOCALES.ENGLISH]: 'Dark theme',
  },
  userProfile: {
    id: 'userProfile',
    [LOCALES.RUSSIAN]: 'Профиль',
    [LOCALES.ENGLISH]: 'User profile',
  },
  oldPassword: {
    id: 'oldPassword',
    [LOCALES.RUSSIAN]: 'Старый пароль',
    [LOCALES.ENGLISH]: 'Old Password',
  },
  newPassword: {
    id: 'newPassword',
    [LOCALES.RUSSIAN]: 'Новый пароль',
    [LOCALES.ENGLISH]: 'New Password',
  },
  helpNotification: {
    id: 'helpNotification',
    [LOCALES.RUSSIAN]: 'Нужна помощь? Пиши в телегу',
    [LOCALES.ENGLISH]: 'Need Help? Ask me in TG',
  },
  passwordEditError: {
    id: 'passwordEditError',
    [LOCALES.RUSSIAN]: 'Необходимо заполнить оба поля',
    [LOCALES.ENGLISH]: 'You have to fill both fields',
  },
};


