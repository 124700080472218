import React from 'react';
import { Tag } from 'antd';


const getApplicationStatusTag = (status) => {
  let tag = <Tag color="gold">{status}</Tag>;
  switch (status) {
    case 'Черновик':
      tag = <Tag color="purple">{status}</Tag>;
      break;
    case 'Согласован':
    case 'Согласован 2 Этап':
    case 'Поддержана':
    case 'Принята к рассмотрению':
      tag = <Tag color="green">{status}</Tag>;
      break;
    case 'Отклонен':
    case 'Не поддержана':
      tag = <Tag color="red">{status}</Tag>;
      break;
    case 'На рассмотрении':
    case 'На обработке':
    case 'На рассмотрении. Этап 2':
      tag = <Tag color="blue">{status}</Tag>;
      break;
    case 'На доработке':
      tag = <Tag color="purple">{status}</Tag>;
      break;
    case 'Заполнена':
      tag = <Tag color="gold">{status}</Tag>;
      break;
    default: break;
  }
  return tag;
};

export default getApplicationStatusTag;
