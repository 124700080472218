import { groupBy, uniqBy } from 'lodash';


const prepareFinanceData = (rawData) => {
  const orgList = uniqBy(rawData.map((e) => ({ id: e.webUserId, value: e.webUserName }), 'id'));

  const result = {
    grants2022: [],
    grants2023: [],
  };

  const groupedByOrg2022 = groupBy(rawData.filter((e) => !e.isGrants2023), 'webUserId');
  const groupedKeys2022 = Object.keys(groupedByOrg2022);
  groupedKeys2022.forEach((org) => {
    const orgName = orgList.find((e) => e.id === org)?.value;
    const applications = groupedByOrg2022[org];
    result.grants2022.push({
      orgId: org,
      orgName,
      applications,
    });
  });

  const groupedByOrg2023 = groupBy(rawData.filter((e) => e.isGrants2023), 'webUserId');
  const groupedKeys2023 = Object.keys(groupedByOrg2023);
  groupedKeys2023.forEach((org) => {
    const orgName = orgList.find((e) => e.id === org)?.value;
    const applications = groupedByOrg2023[org];
    result.grants2023.push({
      orggId: org,
      orgName,
      applications,
    });
  });

  return result;
};

export default prepareFinanceData;
