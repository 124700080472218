import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Navigation from './components/Navigation/Navigation';
import Information from './components/Information/Information';
import Step from './components/Step/Step';
import PaymentModule from './components/PaymentModule/PaymentModule';


function AppNDZM({
  application, isShowSurvey, isShowSurveyTab, updateParentApplication, editStepDate, onAddStep,
}) {
  const [view, setView] = useState('common');
  const onChangeView = (updatedView) => setView(updatedView);

  useEffect(() => {
    if (isShowSurvey) {
      setView('survey');
    }
  }, []);

  return (
    <div>
      <Navigation
        application={application}
        view={view}
        onChangeView={onChangeView}
        isShowSurveyTab={isShowSurveyTab}
        isShowPaymentTab={!!application.parentApplicationPerson && application.displayID.includes('С')}
        editStepDate={editStepDate}
        onAddStep={onAddStep}
      />
      {view === 'common' && (
      <Information
        applicationProp={application}
      />
      )}
      {view === '0' && (
        <Step
          applicationProp={application}
          step={1}
          updateParentApplication={updateParentApplication}
        />
      )}
      {view === '1' && (
      <Step
        applicationProp={application}
        step={2}
        updateParentApplication={updateParentApplication}
      />
      )}
      {view === '2' && (
      <Step
        applicationProp={application}
        step={3}
        updateParentApplication={updateParentApplication}
      />
      )}
      {view === '3' && (
      <Step
        applicationProp={application}
        step={4}
        updateParentApplication={updateParentApplication}
      />
      )}
      {view === '4' && (
      <Step
        applicationProp={application}
        step={5}
        updateParentApplication={updateParentApplication}
      />
      )}
      {view === '5' && (
      <Step
        applicationProp={application}
        step={6}
        updateParentApplication={updateParentApplication}
      />
      )}
      {view === 'payment' && (
      <PaymentModule payment={application.parentApplicationPerson} updateParentApplication={updateParentApplication} />
      )}
    </div>
  );
}

AppNDZM.propTypes = {
  application: PropTypes.shape().isRequired,
  isShowSurveyTab: PropTypes.bool.isRequired,
  isShowSurvey: PropTypes.bool,
  updateParentApplication: PropTypes.func.isRequired,
  editStepDate: PropTypes.func.isRequired,
  onAddStep: PropTypes.func.isRequired,
};

AppNDZM.defaultProps = {
  isShowSurvey: false,
};

export default withRouter(AppNDZM);
