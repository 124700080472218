/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Row, Col, Button, InputNumber, Form, notification,
} from 'antd';
import '../Smeta.css';
import PersonPaymentStep from './PersonPaymentStep';
import { editApplicationPayment, getApplicationPayment, editApplication } from '../../../../../../utils/http';


function PaymentModule({ applicationId, updateParentApplication, isAppDisabled }) {
  const [isShowBlock, setIsShowBlock] = useState(false);
  const [isShowAdditionalPayment, setIsShowAdditionalPayment] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [payment, setPayment] = useState({});
  const formRef = useRef();

  useEffect(() => {
    getApplicationPayment({ id: applicationId })
      .then((result) => {
        setPayment(result);
        setDisabled(isAppDisabled);
        if (result.stepNDate || result.stepNRequisites || result.stepNTransfered || result.stepNComment) {
          setIsShowAdditionalPayment(true);
        }
        formRef.current.setFieldsValue(result);
      });
  }, []);

  const onSave = () => {
    const values = formRef.current.getFieldsValue();
    setDisabled(true);
    Promise.all([editApplicationPayment(values), editApplication({ id: applicationId, currentTransferedSum: values.allStepTransferedTotal })])
      .then(() => {
        notification.success({ message: 'Успех', description: 'Изменения сохранены' });
        setDisabled(false);
        updateParentApplication({ currentTransferedSum: values.allStepTransferedTotal });
      });
  };

  let stepsNumber = payment.maxStepsNumber;
  if (applicationId === '20daed62-9a23-4169-bd4f-7a4c8b7f5d32') {
    stepsNumber = 4;
  }
  if (['761200b7-9583-459e-ad37-8371f15b3794', '356c3afc-357a-4062-a492-283835afd7ce'].includes(applicationId)) {
    stepsNumber = 2;
  }

  return (
    <Card
      style={{
        width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
      }}
    >
      <Row justify="space-between" align="middle">
        <Col style={{ fontSize: '20px', color: '#212121' }}>Сведения об оплатах</Col>
        <Col style={{ cursor: 'pointer' }} onClick={() => setIsShowBlock(!isShowBlock)}>
          {isShowBlock ? <Button>Закрыть</Button> : <Button>Открыть</Button>}
        </Col>
      </Row>
      {isShowBlock && payment && (
      <Form ref={formRef}>
        <Form.Item
          name="id"
          initialValue={payment.id}
          hidden
        />
        <Col span={24}>
          <Col style={{ marginTop: '5px', marginBottom: '20px' }}>
            <Col span={6} style={{ fontSize: '1.5vh' }}>
              Доведено средств ИТОГО по всем этапам
            </Col>
            <Col style={{ whiteSpace: 'break-spaces', fontSize: '1.4vh', color: '#212121' }} span={12}>
              <Form.Item
                name="allStepTransferedTotal"
                initialValue={payment.allStepTransferedTotal}
              >
                <InputNumber disabled={disabled} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Col>

          {Array(stepsNumber || 0).fill(0).map((e, index) => (
            <Col span={24}>
              <Row style={{ color: '#212121', fontSize: '1.4rem', marginBottom: '10px' }}>{`Этап ${index + 1}`}</Row>
              <PersonPaymentStep
                key={index + 1}
                payment={payment}
                isGrants2023={payment.isGrants2023}
                disabled={disabled}
                fieldNameDate={`step${index + 1}Date`}
                fieldNameRequisites={`step${index + 1}Requisites`}
                fieldNameTransfered={`step${index + 1}Transfered`}
                fieldNameComment={`step${index + 1}Comment`}
                fieldNameDateClose={`step${index + 1}DateClose`}
                fieldNameRequisitesClose={`step${index + 1}RequisitesClose`}
                fieldNameTransferedClose={`step${index + 1}TransferedClose`}
                fieldNameCommentClose={`step${index + 1}CommentClose`}
                fieldNameTransferedTotal={`step${index + 1}TransferedTotal`}
              />
            </Col>
          ))}

          {!isShowAdditionalPayment && <Button type="primary" style={{ marginBottom: '20px' }} onClick={() => setIsShowAdditionalPayment(true)}>Добавить оплату</Button>}

          {isShowAdditionalPayment && (
          <Col span={24}>
            <Row style={{ color: '#212121', fontSize: '1.4rem', marginBottom: '10px' }}>Дополнительная оплата</Row>
            <PersonPaymentStep
              key="N"
              isShort
              payment={payment}
              isGrants2023={false}
              disabled={disabled}
              fieldNameDate="stepNDate"
              fieldNameRequisites="stepNRequisites"
              fieldNameTransfered="stepNTransfered"
              fieldNameComment="stepNComment"
            />
          </Col>
          )}
        </Col>
        <Button type="primary" onClick={onSave}>Сохранить</Button>
      </Form>
      )}
    </Card>
  );
}

PaymentModule.propTypes = {
  applicationId: PropTypes.string.isRequired,
  updateParentApplication: PropTypes.func.isRequired,
  isAppDisabled: PropTypes.func.isRequired,
};

export default PaymentModule;
